import { connect } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'

import AllocateCases from './AllocateCases'
import {
  clearAllocationResponse,
  fetchActiveAdvisors,
  fetchAllocateCaseList,
  requestCaseAllocation,
  requestSingleDinaArendeAllocation,
  fetchAllocatableTeams,
  fetchAllTeams,
  fetchAllocationDetails,
} from './AllocateCases.actions'
import {
  advisorList,
  caseList,
  allocatableTeams,
  allTeams,
  responseArray,
  allocatingCasesState,
  fetchingAdvisorListState,
  changingClientAllocationTeamState,
  fetchingAllocationDetails,
} from './AllocateCases.selectors'

const mapStateToProps = (state: RootState) => ({
  advisorList: advisorList(state),
  cases: caseList(state),
  allocatableTeams: allocatableTeams(state),
  allTeams: allTeams(state),
  caseAllocationResponse: responseArray(state),
  allocatingCasesState: allocatingCasesState(state),
  fetchingAdvisorListState: fetchingAdvisorListState(state),
  fetchingAllocationDetails: fetchingAllocationDetails(state),
  changingClientAllocationTeamState: changingClientAllocationTeamState(state),
})

const mapDispatchToProps = {
  clearAllocationResponse,
  fetchActiveAdvisors,
  fetchAllocateCaseList,
  fetchAllocatableTeams,
  fetchAllTeams,
  fetchAllocationDetails,
  requestCaseAllocation,
  requestSingleDinaArendeAllocation,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllocateCases)

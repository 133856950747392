import { IStudieAnmalanListItemGetModel } from '../types/StudieAnmalanListGetModel.types'

export const sortStudieAnmalanListItemByBookingDate = (
  a: IStudieAnmalanListItemGetModel,
  b: IStudieAnmalanListItemGetModel
) =>
  (new Date(b.bookingDate).getTime() ?? 0) -
  (new Date(a.bookingDate).getTime() ?? 0)

const oneDay = 24 * 60 * 60 * 1000
export const diffDays = (firstDate: Date, secondDate: Date): number =>
  Math.round(Math.abs((firstDate?.getTime() - secondDate?.getTime()) / oneDay))

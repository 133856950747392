import {
  Chip,
  List,
  ListItem,
  Stack,
  Typography,
  ListItemText,
} from '@mui/material'
import { date } from '@trr/frontend-datetime'
import React from 'react'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import { IStudieAnmalan } from '../../../types/StudieAnmalan.types'
import AnmalanContentItem from '../AnmalanContentItem/AnmalanContentItem'

interface IAttendanceReportProps {
  studieAnmalan: IStudieAnmalan
}

const AttendanceReport: React.FC<
  React.PropsWithChildren<IAttendanceReportProps>
> = ({ studieAnmalan }) => {
  const { attendanceReport } = studieAnmalan
  const epi = useEpiString()
  return (
    <ListItem>
      <AnmalanContentItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'h6' }}
          primary={epi('common.AttendanceReport')}
        />
        <List>
          {attendanceReport ? (
            <>
              <ListItem>
                <ListItemText
                  primary="Närvaro:"
                  secondary={
                    <Chip
                      variant={
                        attendanceReport.attendance
                          ? 'status-success'
                          : 'status-error'
                      }
                      label={
                        attendanceReport.attendance
                          ? epi('common.Yes')
                          : epi('common.No')
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Inkom:"
                  secondary={
                    attendanceReport?.recievedDate &&
                    `${date.format(attendanceReport.recievedDate, {
                      year: true,
                    })}`
                  }
                />
              </ListItem>
            </>
          ) : (
            <Chip variant={'status-info'} label={epi('common.Unknown')} />
          )}
        </List>
        {attendanceReport?.comment && (
          <Stack>
            <Typography gutterBottom={false} variant="body1">
              {`${epi('common.commentFromSupplier')}:`}
            </Typography>
            <Typography gutterBottom={false} variant="subtitle2">
              {attendanceReport?.comment}
            </Typography>
          </Stack>
        )}
      </AnmalanContentItem>
    </ListItem>
  )
}

export default AttendanceReport

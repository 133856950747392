import { createActions } from 'redux-actions'

import { generateRandomId } from '../../Utils/helpers'

export const {
  addErrorMessage,
  addMessage,
  addSuccessMessage,
  removeErrorMessage,
  removeSuccessMessage,
} = createActions({
  addMessage: ({ type, content, id = generateRandomId() }) => ({
    type,
    content,
    id,
  }),
  addSuccessMessage: (successMessage: string) => successMessage,
  addErrorMessage: (errorMessage: string) => {
    return errorMessage
  },
  removeErrorMessage: (index: unknown) => index,
  removeSuccessMessage: (id: unknown) => id,
})

import {
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React from 'react'

interface FilterCheckboxProps {
  label: string
  isChecked: boolean
  onClick: () => void
  facetCount?: string | number
  listItemVariant?: boolean
}

const FilterCheckBox = ({
  label,
  isChecked,
  onClick,
  facetCount,
  listItemVariant = true,
}: FilterCheckboxProps) => {
  if (!listItemVariant && facetCount) {
    console.error('Only listitem-variant can display facet count')
  }

  if (listItemVariant) {
    return (
      <ListItem
        key={label}
        secondaryAction={facetCount && <Typography>{facetCount}</Typography>}
      >
        <ListItemButton role="checkbox" dense onClick={onClick}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              checked={isChecked}
              inputProps={{ 'aria-labelledby': `${label}` }}
            />
          </ListItemIcon>
          <ListItemText
            primary={label}
            primaryTypographyProps={{ variant: 'body1' }}
          />
        </ListItemButton>
      </ListItem>
    )
  } else {
    return (
      <FormControlLabel
        label={label}
        sx={{ alignSelf: 'baseline' }}
        control={
          <Checkbox
            title={label}
            checked={isChecked}
            onClick={onClick}
            inputProps={{ 'aria-labelledby': `${label}` }}
          />
        }
      />
    )
  }
}

export default FilterCheckBox

import { lensPath, view } from 'ramda'
import { Facets, FetchingState } from '@local/src/Common.types'
import { IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import { ICaseListItem } from '@local/src/Components/ActionBasedCaseItem/ActionBasedCaseItem.types'

const arendenTotalHitsLens = lensPath(['arenden', 'caseObject', 'totalHits'])
export const arendenTotalHits = view<unknown, number>(arendenTotalHitsLens)

const arendenTotalPagesLens = lensPath(['arenden', 'caseObject', 'totalPages'])
export const arendenTotalPages = view<unknown, number>(arendenTotalPagesLens)

const arendenFilterLens = lensPath(['arenden', 'filter'])
export const arendenFilter = view<unknown, IArendenFilterState>(
  arendenFilterLens
)

const arendenFacetsLens2 = lensPath(['arenden', 'facets'])
export const arendenFacets2 = view<unknown, Facets>(arendenFacetsLens2)

const arendenFacetedHitsLens2 = lensPath(['arenden', 'facetedHits'])
export const arendenFacetedHits2 = view<unknown, number>(
  arendenFacetedHitsLens2
)

const arendenLens = lensPath(['arenden', 'caseObject', 'arenden'])
export const arenden = view<unknown, ICaseListItem[]>(arendenLens)

const fetchingArendenStateLens = lensPath(['arenden', 'fetchingCaseListState'])
export const fetchingArendenState = view<unknown, FetchingState>(
  fetchingArendenStateLens
)

const avslutskodFilterLens = lensPath([
  'arenden',
  'filter',
  'radgivningAvslutsKoder',
])
export const avslutskodFilter = view<unknown, string[]>(avslutskodFilterLens)

const klientOrtFilterLens = lensPath(['arenden', 'filter', 'klientOrter'])
export const klientOrtFilter = view<unknown, string[]>(klientOrtFilterLens)

import { useEffect, useState } from 'react'
import { useAuthentication } from '@trr/app-shell-data'

const useUserRoles = () => {
  const appShellRoles = useAuthentication().userRoles
  const [userRoles, setUserRoles] = useState<string[]>(appShellRoles)

  useEffect(() => {
    setUserRoles(appShellRoles)
  }, [appShellRoles])

  const hasRole = (role: string): boolean => userRoles?.includes(role)

  return {
    isRgv: hasRole('RADGIVARE-STUDIER'),
    /* add more when needed */
  }
}

export { useUserRoles }

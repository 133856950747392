export interface IStartklarFormValues {
  beskrivning: string
  ejRedo: boolean
  engelsktalande: boolean
  klientId: string
  pratatOmTrrSe: boolean
  klientenSvarade: boolean
  sokerJobb: boolean
}

export const initialValues: IStartklarFormValues = {
  beskrivning: '',
  ejRedo: false,
  engelsktalande: false,
  klientenSvarade: true,
  klientId: '',
  pratatOmTrrSe: false,
  sokerJobb: false,
}

const useToggleFilterOption = (
  selectedItems: string[],
  key: string,
  onChange: (key: string, updatedItems: string[]) => void
) => {
  const toggleFilterOption = (label: string) => {
    let updatedItems

    if (selectedItems.includes(label)) {
      updatedItems = selectedItems.filter((s) => s !== label)
    } else {
      updatedItems = [...selectedItems, label]
    }

    onChange(key, updatedItems)
  }

  const handleClear = () => {
    onChange(key, [])
  }

  return {
    toggleFilterOption,
    handleClear,
  }
}

export { useToggleFilterOption }

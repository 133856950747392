import { GridColDef } from '@mui/x-data-grid-pro'
import { Typography } from '@mui/material'
import React from 'react'

import { FordelningsRadgivare } from '../../Fordelning.types'

export const columns: GridColDef<FordelningsRadgivare>[] = [
  {
    field: 'namn',
    headerName: '',
    flex: 1,
    valueGetter: ({ row }) => `${row.fornamn} ${row.efternamn}`,
    renderCell: (params) => (
      <Typography
        variant="body2"
        fontWeight="bold"
        py={1}
      >{`${params.row.fornamn} ${params.row.efternamn}`}</Typography>
    ),
  },
  {
    field: 'idag',
    headerName: 'In idag',
    valueGetter: ({ row }) => row.radgivarStatistik.idag,
    width: 44,
    headerAlign: 'center',
    align: 'center',
    hideSortIcons: true,
    renderHeader: () => (
      <Typography
        variant="caption"
        whiteSpace="break-spaces"
        textAlign="center"
      >{`In\nidag`}</Typography>
    ),
    renderCell: (params) => (
      <Typography variant="body2" fontWeight="bold">
        {params.row.radgivarStatistik.idag}
      </Typography>
    ),
  },
  {
    field: 'in',
    headerName: 'In +/-',
    valueGetter: ({ row }) => row.radgivarStatistik.inPlusMinus,
    width: 44,
    headerAlign: 'center',
    align: 'center',
    hideSortIcons: true,
    renderHeader: () => (
      <Typography
        variant="caption"
        whiteSpace="break-spaces"
        textAlign="center"
      >
        {`In\n+/-`}{' '}
      </Typography>
    ),
    renderCell: (params) => (
      <Typography variant="body2">
        {params.row.radgivarStatistik.inPlusMinus}
      </Typography>
    ),
  },
  {
    field: 'aktuella',
    headerName: 'Aktuella',
    valueGetter: ({ row }) => row.radgivarStatistik.aktuella,
    width: 64,
    headerAlign: 'center',
    align: 'center',
    hideSortIcons: true,
    renderHeader: () => (
      <Typography variant="caption" whiteSpace="break-spaces">
        Aktuella
      </Typography>
    ),
    renderCell: (params) => (
      <Typography variant="body2">
        {params.row.radgivarStatistik.aktuella}
      </Typography>
    ),
  },
]

import OmfattningspaketBlock from '@local/src/Blocks/OmfattningspaketBlock'
import NextMeetings from '@local/src/Components/NextMeetings'
import React from 'react'
import ArendeWarningText from '@local/src/Components/ArendeWarningText/ArendeWarningText'
import { Stack } from '@mui/material'
import KarriarVagledningProgression from '@local/src/Components/KarriarVagledningProgression'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import ArendeArbetsuppgifter from '@local/src/Components/ArendeArbetsuppgifter/ArendeArbetsuppgifter'

import { IArendeTypTabs, Tabs } from '../Case.types'
import Documents from '../Documents'
import StudieWrapper from '../StudieWrapper/StudieWrapper'
import EventLog from '../EventLog/EventLog'
import AvslutaKompetensstodsArende from '../Avslut/Kompetensstod/AvslutaKompetensstodsArende/AvslutaKompetensstodsArende'
import Ekonomi from '../Ekonomi'

interface Props extends IArendeTypTabs {
  tab: string
}

const KompetensstodsArendeTypTabs = ({
  tab,
  caseId,
  klientId,
  samtyckeHalsa,
  arendeTyp,
  inbokadeMoten,
  caseDetails,
  karriarVagledningProgression,
}: Props) => {
  const isArbetsuppgifterInArendeEnabled = useIsFeatureEnabled(
    'Medarbetare-Microfrontend_arbetsuppgift_in_arende_temp_241108'
  )

  const karriarVagledningProgressionEnabled = useIsFeatureEnabled(
    'Medarbetare-MicroFrontend_karriarvagledningProgression_perm_240619'
  )

  switch (tab) {
    case Tabs.KompetensstodsArende.TIDSLINJE:
      return (
        <Stack
          display="flex"
          flexDirection={{
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'row',
          }}
          justifyContent="space-between"
          mx={0}
          my={4}
          gap={4}
        >
          <EventLog
            caseId={caseId}
            clientId={klientId}
            healthConsent={samtyckeHalsa}
            arendeTyp={arendeTyp}
          />
          <Stack spacing={3} width={{ md: 300 }}>
            <NextMeetings collection={inbokadeMoten} />
            {isArbetsuppgifterInArendeEnabled && (
              <ArendeArbetsuppgifter arendeId={caseId} />
            )}
            <OmfattningspaketBlock />
            <ArendeWarningText />
            {karriarVagledningProgressionEnabled && (
              <KarriarVagledningProgression
                clientId={klientId}
                karriarVagledningProgression={karriarVagledningProgression}
              />
            )}
          </Stack>
        </Stack>
      )
    case Tabs.KompetensstodsArende.EKONOMI:
      return <Ekonomi />
    case Tabs.KompetensstodsArende.STUDIER:
      return <StudieWrapper arendeId={caseDetails.id} />
    case Tabs.KompetensstodsArende.AVSLUT:
      return (
        <AvslutaKompetensstodsArende
          arendeId={caseDetails.id}
          subStatus={caseDetails?.anstallningsArende?.arendeStatus}
        />
      )
    case Tabs.KompetensstodsArende.DOKUMENT:
      return <Documents arendeTyp={arendeTyp} samtyckeHalsa={samtyckeHalsa} />
  }
}

export default KompetensstodsArendeTypTabs

import { useState } from 'react'

import { getResetFilter } from '../Views/Arenden/Arbetsuppgift/Filter/helpers/helpers'
import { ArbetsuppgiftFilter } from '../Views/Arenden/Arbetsuppgift/types'

const useArbetsuppgiftLocalStorageFilter = (
  initialValue: ArbetsuppgiftFilter
): [
  ArbetsuppgiftFilter,
  (value: Partial<ArbetsuppgiftFilter>) => void,
  () => void,
] => {
  const localStorageKey = 'Arbetsuppgifter-filter'

  const readValue = (): ArbetsuppgiftFilter => {
    const item = window.localStorage.getItem(localStorageKey)

    if (!item) {
      return initialValue
    }

    const parsedFilter = JSON.parse(item) as ArbetsuppgiftFilter
    const sanitizedFilter = Object.keys(parsedFilter)
      .filter((key): key is keyof ArbetsuppgiftFilter => key in initialValue)
      .reduce((obj: any, key) => {
        obj[key] = parsedFilter[key]
        return obj as ArbetsuppgiftFilter
      }, {} as ArbetsuppgiftFilter)

    return { ...initialValue, ...sanitizedFilter } as ArbetsuppgiftFilter
  }

  const [storedValue, setStoredValue] = useState<ArbetsuppgiftFilter>(readValue)

  const setValue = (value: ArbetsuppgiftFilter) => {
    const sanitizedValue: ArbetsuppgiftFilter = Object.keys(value)
      .filter((key): key is keyof ArbetsuppgiftFilter => key in initialValue)
      .reduce((obj: any, key) => {
        obj[key] = value[key]
        return obj as ArbetsuppgiftFilter
      }, {} as ArbetsuppgiftFilter)

    setStoredValue({ ...initialValue, ...sanitizedValue })
    window.localStorage.setItem(localStorageKey, JSON.stringify(sanitizedValue))
  }

  const resetFilter = () => {
    const emptyFilter = getResetFilter(storedValue)
    setStoredValue(emptyFilter)
    window.localStorage.setItem(localStorageKey, JSON.stringify(emptyFilter))
  }

  return [storedValue, setValue, resetFilter]
}

export default useArbetsuppgiftLocalStorageFilter

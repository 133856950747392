import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from '@local/src/Utils/ConfigService'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders,
  }),
  endpoints: () => ({}),
})

import { useUser } from '@trr/app-shell-data'
import useArbetsuppgiftLocalStorageFilter from '@local/src/Hooks/useArbetsuppgiftLocalStorageFilter'

import { getDefaultFilters } from '../Views/Arenden/Arbetsuppgift/Filter/helpers/helpers'
import { ArbetsuppgiftFilter } from '../Views/Arenden/Arbetsuppgift/types'

const useArbetsuppgiftFilter = (): [
  Partial<ArbetsuppgiftFilter>,
  (key: string, value: string | string[] | object) => void,
  () => void,
] => {
  const currentUserId = useUser().mdid
  const defaultFilters: ArbetsuppgiftFilter = getDefaultFilters(currentUserId)

  const [filters, setFilter, resetFilter] =
    useArbetsuppgiftLocalStorageFilter(defaultFilters)

  const updateFilter = (
    key: keyof ArbetsuppgiftFilter,
    value: string | string[]
  ) => {
    const updatedFilterObj = {
      ...filters,
      [key]: value,
    }

    setFilter(updatedFilterObj)
  }

  return [filters, updateFilter, resetFilter]
}

export default useArbetsuppgiftFilter

import { sortAscendingArrayOfObjectsByKey as sortAsc } from '@local/src/Utils/helpers'
import { Radgivare } from '@local/src/Common.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v2.0/anstallningsarenden'

export const anstallningsArendenApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['RadgivareStudierList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRadgivareList: builder.query<Radgivare[], string>({
        providesTags: ['RadgivareStudierList'],
        query: () => `${baseUrl}/radgivare`,
        transformResponse: (list: Radgivare[], _, currentUserId) => {
          const sortedList = sortAsc('fornamn', list) as Radgivare[]
          const indexOfCurrentRadgivare = sortedList.findIndex(
            (obj) => obj.id === currentUserId
          )
          if (indexOfCurrentRadgivare !== -1) {
            const currentRadgivare = sortedList.splice(
              indexOfCurrentRadgivare,
              1
            )[0]
            sortedList.unshift(currentRadgivare)
          }
          return sortedList
        },
      }),
    }),
  })

export const { useGetRadgivareListQuery, useLazyGetRadgivareListQuery } =
  anstallningsArendenApi

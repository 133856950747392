import { useEffect } from 'react'

/**
 * Require: errors have class .Mui-error
 * @param isValid - if form is valid
 * @param isSubmitting - if form is in submitting state
 * @param offset - offset pixels where to scroll to
 */
const useScrollToFirstError = (
  isValid: boolean,
  isSubmitting: boolean,
  offset = 0
) => {
  useEffect(() => {
    if (!isValid && isSubmitting) {
      const firstErrorElement = document.querySelector('.Mui-error')
      if (firstErrorElement) {
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement.parentElement.offsetTop - offset,
        })
      }
    }
  }, [isValid, isSubmitting])
}

export default useScrollToFirstError

import { createActions } from 'redux-actions'
import { get, post } from '@local/Utils/network'

export const {
  fetchCurrentActivitiesFailure,
  fetchCurrentActivitiesInitiate,
  fetchCurrentActivitiesSuccess,
  fetchAttendedActivitiesFailure,
  fetchAttendedActivitiesInitiate,
  fetchAttendedActivitiesSuccess,
  unregisterActivitiesFailure,
  unregisterActivitiesInitiate,
  unregisterActivitiesSuccess,
  clearActivities,
} = createActions({
  fetchCurrentActivitiesFailure: (errorMessage) => {
    return `Det gick inte att hämta några aktiviteter. ${String(errorMessage)}`
  },
  fetchCurrentActivitiesInitiate: () => null,
  fetchCurrentActivitiesSuccess: (currentActivities) => ({ currentActivities }),

  fetchAttendedActivitiesFailure: (errorMessage) => {
    return `Det gick inte att hämta några aktiviteter. ${String(errorMessage)}`
  },
  fetchAttendedActivitiesInitiate: () => null,
  fetchAttendedActivitiesSuccess: (attendedActivities) => ({
    attendedActivities,
  }),

  unregisterActivitiesFailure: (errorMessage) => {
    return `Det gick inte att avboka några aktiviteter. ${String(errorMessage)}`
  },
  unregisterActivitiesInitiate: () => null,
  unregisterActivitiesSuccess: (activities: string[]) => ({
    successMessage: 'Aktiviteter avbokades',
    activityList: activities,
  }),

  clearActivities: () => null,
})

export const fetchCurrentActivities = (userId: string) =>
  get({
    url: `/aktivitetsbokningar/activityrounds/user/${userId}/CurrentWithCancelled`,
    initiated: fetchCurrentActivitiesInitiate,
    fulfilled: fetchCurrentActivitiesSuccess,
    rejected: fetchCurrentActivitiesFailure,
  })
export const fetchAttendedActivities = (userId: string) =>
  get({
    url: `/aktivitetsbokningar/activityrounds/user/${userId}/history`,
    initiated: fetchAttendedActivitiesInitiate,
    fulfilled: fetchAttendedActivitiesSuccess,
    rejected: fetchAttendedActivitiesFailure,
  })

export const cancelActivityBookings = (userId: string, activities: string[]) =>
  post({
    url: `/aktivitetsbokningar/activityrounds/unregister/user/${userId}`,
    initiated: unregisterActivitiesInitiate,
    fulfilled: unregisterActivitiesSuccess.bind(null, activities),
    rejected: unregisterActivitiesFailure,
    body: { activityRoundIds: activities },
  })

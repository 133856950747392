import { Radgivare } from '@local/src/Common.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v1.0/userProfile'

export const userProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getColleagues: builder.query<Radgivare[], void>({
      query: () => `${baseUrl}/arbetsuppgift/colleagues`,
    }),
  }),
})

export const { useGetColleaguesQuery } = userProfileApi

import React, { useRef } from 'react'
import { formatAmount, scrollToRef } from '@local/Utils/smallfuncs'
import OrderBy from '@local/src/Components/OrderBy'
import {
  Box,
  Divider,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Pagination from '@local/src/Components/Pagination/Pagination'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { ISelectOption } from '@local/src/Common.types'

import {
  ICaseStartklar,
  IHandlaggare,
  StartklarSortOptions,
} from '../Startklar.types'
import StartklarListItem from '../StartklarListItem/StartklarListItem'

export interface StartklarListProps {
  startklarCases: ICaseStartklar[]
  handlaggareList: IHandlaggare[]
  totalHits: number
  page: number
  pageSize: number
  sortOption: string
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  setSort: (sort: ISelectOption) => void
}

const StartklarList = ({
  startklarCases,
  handlaggareList,
  totalHits,
  page,
  pageSize,
  sortOption,
  setPage,
  setPageSize,
  setSort,
}: StartklarListProps): JSX.Element => {
  const arendeTableRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const pagination = (showPagination = false, showRowsPerPage = false) => (
    <Pagination
      count={totalHits}
      page={page}
      pageSize={pageSize}
      labelRowsPerPage="Ärenden per sida:"
      showPagination={showPagination}
      showRowsPerPage={showRowsPerPage}
      onPageChange={changePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageSize(rowsPerPage)
  }

  const changePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setPage(page + 1)
    scrollToRef(arendeTableRef)
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Typography variant="body2" padding={1}>
            {totalHits !== undefined &&
              `${formatAmount(totalHits)} ${totalHits === 1 ? 'ärende' : 'ärenden'}`}
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
          >
            {pagination(false, true)}
            <OrderBy
              selectOptions={StartklarSortOptions}
              value={StartklarSortOptions.find(
                (sort) => sort.value === sortOption
              )}
              onChange={(sort) => setSort(sort)}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <List data-testid="startklar-list">
        {startklarCases?.map((startklarCase: ICaseStartklar) => {
          return (
            <StartklarListItem
              key={startklarCase.id}
              startklarCase={startklarCase}
              handlaggareList={handlaggareList}
            />
          )
        })}
      </List>
      {startklarCases?.length === 0 && <EmptyResult sx={{ pt: 5 }} />}
      {pagination(true, !isMobile)}
    </>
  )
}

export default StartklarList

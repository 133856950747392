import { IAdvisorDecision } from './IAdvisorDecision'
import { IStudieAnmalanStatuses } from './IStudieAnmalanStatuses'
import { IStudieAnmalanListItem } from './StudieAnmalanListItem.types'

export interface IStudieAnmalanListItemGetModel {
  bookingDate: string
  courseLengthInDays: number
  courseStartDate: string
  educationName: string
  hasAttented: boolean
  id: string
  instituteName: string
  isDistance: boolean
  placeDescription: string
  courseFee: {
    fee: number
    vat: number
    totalFee: number
  }
  statuses: IStudieAnmalanStatuses
  advisorDecision?: IAdvisorDecision
}

export const MapToView = (
  anmalan: IStudieAnmalanListItemGetModel
): IStudieAnmalanListItem => {
  const isCancelled =
    anmalan.statuses.isRejectedByAdvisor ||
    anmalan.statuses.isCancelledByClient ||
    anmalan.statuses.isLateCancelledByClient ||
    anmalan.statuses.isCancelledBySupplier

  return {
    bookingDate: anmalan.bookingDate && new Date(anmalan.bookingDate),
    cancelled: isCancelled,
    courseLengthInDays: anmalan.courseLengthInDays,
    courseStartDate:
      anmalan.courseStartDate && new Date(anmalan.courseStartDate),
    educationName: anmalan.educationName,
    id: anmalan.id,
    instituteName: anmalan.instituteName,
    isDistance: anmalan.isDistance,
    placeDescription: anmalan.placeDescription,
    courseFee: anmalan.courseFee,
    statuses: anmalan.statuses,
    advisorDecision: anmalan.advisorDecision,
  }
}

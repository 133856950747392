import { isToday } from 'date-fns'
import { User } from '@trr/app-shell-data'
import {
  formatDateFromDateTime,
  formatDateWithMonthStringFromDateTime as formatMonth,
} from '@local/Utils/DateTime'
import { arendeBasename, medarbetareBasepath } from '@local/src/basename'
import { Radgivare } from '@local/src/Common.types'
import { Typography } from '@mui/material'
import React from 'react'

import {
  Arbetsuppgift,
  ArbetsuppgiftAction,
  ArbetsuppgiftCategory,
  ArbetsuppgiftFilter,
  ArbetsuppgiftOrderBy,
  ArbetsuppgiftOwnerFacet,
  ArbetsuppgiftStatus,
  DueDate,
  Facet,
} from './types'

export const noOwnerId = 'ffffffff-ffff-ffff-ffff-ffffffffffff'

export const getHeaderLink = (arbetsuppgift: Arbetsuppgift): string => {
  const { category, arende } = arbetsuppgift
  const { id } = arende

  switch (category) {
    case ArbetsuppgiftCategory.NyStudiestodsAnsokan:
    case ArbetsuppgiftCategory.UppdateradStudiestodsAnsokan:
    case ArbetsuppgiftCategory.UtbildningAttGodkanna:
    case ArbetsuppgiftCategory.BegaranYttrande:
      return `${medarbetareBasepath}${arendeBasename}/${id}/studier`
    case ArbetsuppgiftCategory.KommentarFranAvtalErsattningar:
      return `${medarbetareBasepath}${arendeBasename}/${id}/ekonomi`
    case ArbetsuppgiftCategory.NyttOmfattningsbeslut:
      return `${medarbetareBasepath}${arendeBasename}/${id}/tidslinje`
    default:
      return `${medarbetareBasepath}${arendeBasename}/${id}/tidslinje`
  }
}

export const getDeadline = (dueDate: DueDate) => {
  if (!dueDate?.value) return null

  const formattedDate = formatDateFromDateTime(dueDate.value)
  const workingDaysLeft = dueDate.workingDaysLeft

  if (workingDaysLeft <= 7) {
    return (
      <Typography variant="subtitle2" color="error.main">
        {workingDaysLeft} {workingDaysLeft === 1 ? 'arbetsdag' : 'arbetsdagar'}{' '}
        kvar {formattedDate}
      </Typography>
    )
  }

  if (dueDate.updatedAt) {
    return (
      <Typography variant="subtitle2" color="error.main">
        Ny deadline {formattedDate}
      </Typography>
    )
  }

  return (
    <Typography variant="subtitle2" color="text.secondary">
      Deadline {formattedDate}
    </Typography>
  )
}

export const getActionLabel = (action: ArbetsuppgiftAction) => {
  switch (action) {
    case ArbetsuppgiftAction.Info:
      return 'Info'
    case ArbetsuppgiftAction.Uppgift:
      return 'Uppgift'
  }
}

export const getStatusLabel = (status: ArbetsuppgiftStatus) => {
  switch (status) {
    case ArbetsuppgiftStatus.Ny:
      return 'Ny'
    case ArbetsuppgiftStatus.Pagaende:
      return 'Pågående'
    case ArbetsuppgiftStatus.Klar:
      return 'Klar'
  }
}

export const getFormattedDate = (date: string) => {
  if (isToday(new Date(date))) return 'Idag'
  return formatMonth(date)
}

export const getOwnerFacets = (
  facets: Facet[],
  radgivare: Radgivare[],
  currentUserId: string
): ArbetsuppgiftOwnerFacet[] => {
  if (!radgivare) return []

  const owners: ArbetsuppgiftOwnerFacet[] = radgivare
    .map((rdg) => {
      return {
        value: `${rdg.fornamn} ${rdg.efternamn ?? ''}`,
        count: facets?.find((rg) => rg.value === rdg.id)?.count ?? 0,
        id: rdg.id,
      } as ArbetsuppgiftOwnerFacet
    })
    .filter((rg) => rg.id !== currentUserId)
  return owners
}

export const getCurrentRadgivareFacet = (
  facets: Facet[],
  currentUser: User
): ArbetsuppgiftOwnerFacet => {
  const currentRadgivareFacet = facets?.find(
    (facet) => facet.value === currentUser.mdid
  )

  return {
    value: `${currentUser.firstName} ${currentUser.lastName}`,
    count: currentRadgivareFacet?.count ?? 0,
    id: currentUser.mdid,
  }
}

export const getNoOwnerFacet = (facets: Facet[]): ArbetsuppgiftOwnerFacet => {
  const noOwnerFacet = facets?.find((facet) => facet.value === noOwnerId)

  return {
    value: 'Ingen ansvarig',
    count: noOwnerFacet?.count ?? 0,
    id: noOwnerId,
  }
}

export const filterRadgivareOnName = (
  radgivare: ArbetsuppgiftOwnerFacet[],
  searchValue: string
): ArbetsuppgiftOwnerFacet[] => {
  if (searchValue && searchValue.length > 0) {
    return radgivare.filter((rgv: ArbetsuppgiftOwnerFacet) => {
      return rgv.value.toLowerCase().includes(searchValue.toLowerCase())
    })
  } else {
    return radgivare
  }
}

export const getQueryParameters = (
  filter: Partial<ArbetsuppgiftFilter>
): string => {
  const queryParameters = Object.entries(filter).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.filter((item) => item).map((item) => `${key}=${item}`)
    } else if (value && typeof value === 'object') {
      if (key === 'orderBy') {
        const orderBy = value as ArbetsuppgiftOrderBy
        return `${key}=${getColumnSortingQuery(orderBy.property, orderBy.direction)}`
      }
      return Object.entries(value)
        .filter(([_, subValue]) => subValue)
        .map(([subKey, subValue]) => `${subKey}=${subValue}`)
    } else if (value) {
      return `${key}=${value as string}`
    }
    return []
  })

  const queryString = queryParameters.join('&')
  return queryString ? `?${queryString}` : ''
}

export const getColumnSortingQuery = (orderBy: string, order: string) => {
  switch (orderBy) {
    case 'category':
      return `category+${order}`
    case 'action':
      return `action+${order}`
    case 'status':
      return `status+${order}`
    case 'arendenr':
      return `arendenummer+${order}`
    case 'uppgift':
      return `header+${order}`
    case 'createdDate':
      return `createdDate+${order}`
    case 'owner':
      return `owner+${order}`
    default:
      return ''
  }
}

export const getKlarmarkeringHelperText = (
  category: ArbetsuppgiftCategory
): JSX.Element => {
  switch (category) {
    case ArbetsuppgiftCategory.BegaranYttrande:
      return (
        <Typography variant="body1" whiteSpace="pre-wrap">
          Kontrollera att underlaget för yttrandet är skickat till handläggare
          för yttrande och bedömning innan du klarmarkerar uppgiften.{'\n'}Vill
          du klarmarkera uppgiften?
        </Typography>
      )
    default:
      return <Typography variant="body1">N/A</Typography>
  }
}

import { useGetArendenEnv, useArendenLocalStorage } from '@local/src/Hooks'
import {
  IArendenFilterState,
  IDinaArendenFilterState,
} from '@local/src/Components/CasesFilter/CasesFilter.types'
import { isStringArray } from '@local/src/Utils/TypeGuards'
import { useMergedLocalStorage } from '@local/src/Hooks/useMergedLocalStorage'

import {
  initialState,
  arendenEmptyFilterState,
  kompetensstodArendenInitialFilterState,
} from '../Views/Arenden/Arenden.reducers'
import { ArendeTyp, EArendenEnv } from '../Views/Arenden/Arenden.types'
import { dinaArendenInitialFilterState } from '../Views/DinaArenden/DinaArenden.reducers'

export type IFilterValue = string[] | [number, number] | boolean | string

const { isArray } = Array
interface IUseArendenListOptions {
  page: number
  pageSize: number
  setArendenPage: (change: number, total: number) => void
  setPageSize: (pageSize: number) => void
  orderBy: string[]
  setArendenOrderBy: (value: string[]) => void
  filter: IArendenFilterState
  setFilterParameters: (change: { [key: string]: IFilterValue }) => void
  resetArendenFilter: () => void
  searchText: string
  setArendenSearchText: (value: string) => void
  setArendeTyperFilter: (value: string) => void
  removeFilterParameterValuePair: (tag: {
    filterParameter: string
    filterValue: IFilterValue
  }) => void
}

const removeFilterOption = (
  initialFilterState: IDinaArendenFilterState | IArendenFilterState,
  removeParameter: keyof IArendenFilterState | keyof IDinaArendenFilterState,
  filterValue: IFilterValue,
  filter: IDinaArendenFilterState | IArendenFilterState
): IFilterValue => {
  const currentFilterValue = filter[removeParameter]
  return isArray(currentFilterValue)
    ? isStringArray(currentFilterValue)
      ? currentFilterValue.filter((value) => value !== filterValue)
      : [0, 0]
    : (initialFilterState[removeParameter] as IFilterValue)
}

const useArendenListOptions = (): IUseArendenListOptions => {
  const arendenEnv = useGetArendenEnv()
  let initialFilterState: IDinaArendenFilterState | IArendenFilterState

  if (arendenEnv === EArendenEnv.DinaArenden) {
    initialFilterState = { ...dinaArendenInitialFilterState }
  } else if (arendenEnv === EArendenEnv.KompetensstodsArenden) {
    initialFilterState = { ...kompetensstodArendenInitialFilterState }
  } else {
    initialFilterState = { ...arendenEmptyFilterState }
  }

  if (arendenEnv === EArendenEnv.KompetensstodsArenden) {
    initialFilterState.arendeTyper = ArendeTyp.Kompetensstod
  } else if (arendenEnv === EArendenEnv.OmstallningsArenden) {
    initialFilterState.arendeTyper = ArendeTyp.Omstallning
  } else if (arendenEnv === EArendenEnv.AllaArenden) {
    initialFilterState.arendeTyper = ArendeTyp.Alla
  }

  const [page, setPage] = useArendenLocalStorage(
    arendenEnv,
    `page`,
    initialState.page
  )
  const [pageSize, setPageSize] = useArendenLocalStorage(
    arendenEnv,
    `pageSize`,
    initialState.pageSize
  )
  const [orderBy, setArendenOrderBy] = useArendenLocalStorage(
    arendenEnv,
    `orderBy`,
    initialState.orderBy
  )
  const [searchText, setArendenSearchText] = useArendenLocalStorage(
    arendenEnv,
    `searchText`,
    initialState.searchText
  )
  const [storedFilter, setStoredFilter] =
    useMergedLocalStorage<IArendenFilterState>(
      arendenEnv,
      `filter`,
      initialFilterState
    )

  const setFilterParameters = (change: { [key: string]: IFilterValue }) =>
    setStoredFilter({ ...storedFilter, ...change })
  const resetArendenFilter = () => setStoredFilter(initialFilterState)
  const setArendeTyperFilter = (arendeTyp: string) =>
    setStoredFilter({ ...storedFilter, arendeTyper: arendeTyp })

  const removeFilterParameterValuePair = ({
    filterParameter,
    filterValue,
  }: {
    filterParameter: string
    filterValue: IFilterValue
  }) =>
    setStoredFilter({
      ...storedFilter,
      [filterParameter]: removeFilterOption(
        initialFilterState,
        filterParameter,
        filterValue,
        storedFilter
      ),
    })

  const setArendenPage = (change: number, total: number) => {
    const newPage = total < change ? total : change
    setPage(total === 0 ? 1 : newPage)
  }

  return {
    page,
    pageSize,
    setArendenPage,
    setPageSize,
    orderBy,
    setArendenOrderBy,
    filter: storedFilter,
    setFilterParameters,
    resetArendenFilter,
    searchText,
    setArendenSearchText,
    setArendeTyperFilter,
    removeFilterParameterValuePair,
  } as IUseArendenListOptions
}

export { useArendenListOptions }

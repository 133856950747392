import React, { useState } from 'react'
import MobileFilterDrawer from '@local/src/Components/MobileFilterDrawer/MobileFilterDrawer'

import { useArbetsuppgiftFilterContext } from '../ArbetsuppgiftFilterContext'
import useIsArbetsuppgiftFilterActive from '../Hooks/useIsArbetsuppgiftFilterActive'

import AssigneeAccordion from './AssigneeAccordion'
import StatusFilterAccordion from './StatusFilterAccordion'
import CategoryFilterAccordion from './CategoryFilterAccordion'

const ArbetsuppgiftMobileFilter = () => {
  const { filters, clearFilter } = useArbetsuppgiftFilterContext()

  const filtersActive = useIsArbetsuppgiftFilterActive(filters)
  const [categoryIsOpen, setCategoryIsOpen] = useState(false)
  const [statusIsOpen, setStatusIsOpen] = useState(false)
  const [ownerIsOpen, setOwnerIsOpen] = useState(false)

  const totalFilterCount =
    filters.ownerIds.length +
    filters.statusar.length +
    filters.uppgiftsTyper.length

  const statusOpened = () => {
    setOwnerIsOpen(false)
    setCategoryIsOpen(false)
    setStatusIsOpen(true)
  }

  const ownerOpened = () => {
    setStatusIsOpen(false)
    setCategoryIsOpen(false)
    setOwnerIsOpen(true)
  }

  const categoryOpened = () => {
    setStatusIsOpen(false)
    setOwnerIsOpen(false)
    setCategoryIsOpen(true)
  }

  return (
    <MobileFilterDrawer
      filtersActive={filtersActive}
      filterCount={totalFilterCount}
      clearFilter={clearFilter}
    >
      <AssigneeAccordion open={ownerIsOpen} onOpen={ownerOpened} />
      <StatusFilterAccordion open={statusIsOpen} onOpen={statusOpened} />
      <CategoryFilterAccordion open={categoryIsOpen} onOpen={categoryOpened} />
    </MobileFilterDrawer>
  )
}

export default ArbetsuppgiftMobileFilter

import { ISelectOption } from '@local/src/Common.types'
import { FormikProps } from 'formik'

import {
  AvslutsFragaSvar,
  JobFindingOption,
  AnstallningFormValues,
  EgenRorelseFormValues,
} from '../../../Avslut.types'

// type <any> because the type depends on the specific form interface.
// However, errors and touched will always be passed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasError = (
  formikReference: FormikProps<any>,
  name: string,
  ignoreTouched = false
) => {
  if (ignoreTouched) {
    return formikReference.errors[name] !== undefined
  }
  return (
    formikReference.errors[name] !== undefined &&
    formikReference.touched[name] !== undefined
  )
}

/**
 * Create an array of ISelectOption of months where:
 * { value: number, label: '<value> månader' }
 * @param from - the number to start with
 * @param to - the number to end with (including)
 * @returns an array of ISelectOption
 */
export const createMonthsOptions = (
  from: number,
  to: number
): ISelectOption[] => {
  return Array.from({ length: to - from + 1 }, (_, index) => {
    const monthValue = from + index
    return { value: monthValue, label: `${monthValue} månader` }
  })
}

export const antalAnstalldaOptions: ISelectOption[] = [
  { value: '0', label: 'Färre än 25' },
  { value: '1', label: '25-50' },
  { value: '2', label: '51-100' },
  { value: '3', label: 'Fler än 100' },
]

export const forandringOptions: ISelectOption[] = [
  { value: '2', label: 'Högre' },
  { value: '1', label: 'Lika' },
  { value: '0', label: 'Lägre' },
]

export const foretagstypOptions: ISelectOption[] = [
  { value: 'AB', label: 'Aktiebolag' },
  { value: 'EK', label: 'Ekonomisk förening' },
  { value: 'EN', label: 'Enskild firma' },
  { value: 'HB', label: 'Handelsbolag' },
  { value: 'KB', label: 'Kommanditbolag' },
]

export const howKundFoundJobOptions: JobFindingOption[] = [
  {
    value: '184',
    label: 'Tips om jobb från TRR-medarbetare',
    description:
      'Omställningskunden fick ett jobbtips av en TRR-medarbetare, vilket ledde till anställningen.',
    isMatchningstjanst: true,
  },
  {
    value: '192',
    label: 'Arbetsgivaren tog kontakt efter tips från TRR',
    description:
      'Arbetsgivaren kontaktade omställningskunden efter tips från TRR, vilket ledde till anställningen.',
    isMatchningstjanst: true,
  },
  {
    value: '172',
    label: 'Jobbannons på Mitt TRR',
    description:
      'Omställningskunden fick anställningen efter en ansökan via en annons som hittats bland jobbannonser på Mitt TRR. ',
    isMatchningstjanst: true,
  },
  {
    value: '181',
    label: 'Speedintervju',
    description:
      'Omställningskunden deltog på en speedintervju där kontakten initierades med rekryterande arbetsgivare, vilket ledde till anställningen.',
    isMatchningstjanst: true,
  },
  {
    value: '182',
    label: 'Företagspresentation',
    description:
      'Omställningskunden deltog på en företagspresentation eller branschspana, där kontakten initierades med rekryterande arbetsgivare, vilket ledde till anställningen.',
    isMatchningstjanst: true,
  },
  {
    value: '183',
    label: 'Rekryteringsträff',
    description:
      'Omställningskunden deltog på en rekryteringsträff där kontakten initierades med rekryterande arbetsgivare, vilket ledde till anställningen. ',
    isMatchningstjanst: true,
  },
  {
    value: '185',
    label: 'Nätverk via TRR',
    description:
      'Omställningskunden fick anställningen genom ett tips från en person i det nätverk som TRR initierat.',
    isMatchningstjanst: true,
  },
  {
    value: '186',
    label: 'Snabbansökan utan CV på Mitt TRR',
    description:
      'Omställningskunden gjorde en snabbansökan på en annons som ett kundföretag lagt upp i TRRs annonseringstjänst, vilket ledde till anställningen.',
    isMatchningstjanst: true,
  },

  {
    value: '110',
    label: 'Privata kontakter/nätverk',
    description:
      'Omställningskunden fick jobbtips från vänner och bekanta, vilket ledde till anställningen.',
    isMatchningstjanst: false,
  },
  {
    value: '100',
    label: 'Yrkeskontakter/nätverk',
    description:
      'Omställningskunden fick anställningen efter ett jobbtips från yrkeskontakter i såväl fysiska nätverk (exempelvis BNI) som digitala nätverk (exemplevis LinkedIn).',
    isMatchningstjanst: false,
  },
  {
    value: '80',
    label: 'Sökte upp arbetsgivaren',
    description:
      'Omställningskunden fick anställningen efter att på eget initiativ tagit kontakt med arbetsgivaren. ',
    isMatchningstjanst: false,
  },
  {
    value: '191',
    label: 'Uppsökt av arbetsgivaren/rekryteringsföretaget',
    description:
      'Omställningskunden fick anställningen efter att arbetsgivaren/rekryteringsföretaget tagit kontakt med omställningskunden. (Initierades kontakten efter tips från TRR, välj ett alternativ under TRR Matchning) ',
    isMatchningstjanst: false,
  },
  {
    value: '171',
    label: 'Jobbannons på LinkedIn',
    description:
      'Omställningskunden fick anställningen efter att ha ansökt via en annons som hittats på LinkedIn.',
    isMatchningstjanst: false,
  },
  {
    value: '170',
    label: 'Jobbannons på annan plats',
    description:
      'Omställningskunden fick anställningen efter att ha ansökt via en annons som hittats på exempelvis: Indeed, arbetsgivarens webbplats eller i tryck media.',
    isMatchningstjanst: false,
  },
  {
    value: '0',
    label: 'Övrigt ',
    description:
      'Om inget annat alternativ passar för hur omställningskunden hittade sin anställning.',
    isMatchningstjanst: false,
  },
]

export const convertToAvslutsfragorSvar = (
  formValues: AnstallningFormValues
): AvslutsFragaSvar[] => {
  return [
    { key: 'title', value: formValues.title.jobTitle, id: formValues.title.id },
    { key: 'companyName', value: formValues.companyName },
    { key: 'staffingCompany', value: formValues.staffingCompany.toString() },
    { key: 'amountOfEmployees', value: formValues.amountOfEmployees },
    { key: 'industry', value: formValues.industry.trrCode },
    { key: 'publicSector', value: formValues.publicSector.toString() },
    { key: 'isWhiteCollar', value: formValues.isWhiteCollar.toString() },
    { key: 'relativeLevel', value: formValues.relativeLevel },
    { key: 'relativeSalary', value: formValues.relativeSalary },
    { key: 'weeklyHours', value: formValues.weeklyHours.toString() },
    { key: 'employmentDate', value: formValues.employmentDate.toISOString() },
    { key: 'town', value: formValues.isTownAbroad ? null : formValues.town },
    { key: 'isTownAbroad', value: formValues.isTownAbroad.toString() },
    { key: 'requiresMoving', value: formValues.requiresMoving.toString() },
    { key: 'howClientFoundJob', value: formValues.howKundFoundJob.value },
  ]
}

export const convertErToAvslutsfragorSvar = (
  formValues: EgenRorelseFormValues
): AvslutsFragaSvar[] => {
  return [
    { key: 'companyName', value: formValues.companyName },
    {
      key: 'dateOfRegistration',
      value: formValues.dateOfRegistration.toISOString(),
    },
    {
      key: 'spareTimeJobSinceBefore',
      value: formValues.spareTimeJobSinceBefore.toString(),
    },
    { key: 'companyType', value: formValues.companyType },
    { key: 'industry', value: formValues.industry.trrCode },
    { key: 'town', value: formValues.town },
    { key: 'isTownAbroad', value: formValues.isTownAbroad.toString() },
    {
      key: 'previouslyWorkedInIndustry',
      value: formValues.previouslyWorkedInIndustry,
    },
    {
      key: 'previouslyWorkedInProfession',
      value: formValues.previouslyWorkedInProfession,
    },
    {
      key: 'registrationAfterCeasedEmployment',
      value: formValues.registrationAfterCeasedEmployment,
    },
    {
      key: 'foundEmployerAtTRR',
      value: formValues.foundEmployerAtTRR.toString(),
    },
  ]
}

import React, { useEffect, useState, SyntheticEvent } from 'react'
import {
  Dropdown,
  InputField,
  Loading,
  Icons,
  Button,
} from '@trr/common-component-library'
import NoResult from '@local/Components/NoResult'
import classnames from 'classnames'
import { filterOnName } from '@local/Utils/smallfuncs'
import { ITeam, ISelectOption, FetchingState } from '@local/src/Common.types'
import { IAdvisor } from '@local/Views/AllocateCases/AllocateCases.types'
import { useIsSmallScreen } from '@local/src/Hooks'

import s from './AdvisorList.module.scss'
import AdvisorListItem from './AdvisorListItem'

interface IAdvisorList {
  fetchActiveAdvisors(id: string): void
  fetchingAdvisorListState: FetchingState
  advisorList: IAdvisor[]
  allocatableTeams: ITeam[]
  defaultTeam: ITeam
  toggleAdvisorList(event: SyntheticEvent): void
  advisorsVisible: boolean
}

const AdvisorList = ({
  fetchActiveAdvisors,
  fetchingAdvisorListState,
  advisorList,
  allocatableTeams,
  defaultTeam,
  toggleAdvisorList,
  advisorsVisible,
}: IAdvisorList): JSX.Element => {
  const [team, setTeam] = useState(defaultTeam)
  const [searchValue, setSearchValue] = useState('')
  const isSmallScreen = useIsSmallScreen()

  useEffect(() => {
    if (team) {
      fetchActiveAdvisors(team.id)
      setSearchValue(null)
    }
  }, [team, fetchActiveAdvisors])

  useEffect(() => {
    setTeam(defaultTeam)
  }, [defaultTeam])

  const handleSearchChange = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    setSearchValue(eventTarget.value)
  }

  const filteredAdvisorList = filterOnName(advisorList, searchValue)

  const onTeamChange = (event: ISelectOption) => {
    setTeam(
      allocatableTeams.find(
        (teamFromList: ITeam) => teamFromList.id === event.value
      )
    )
  }

  return (
    <aside
      data-name={'advisorListPanel'}
      className={classnames(s.Wrapper, advisorsVisible ? s.Visible : s.Hidden)}
    >
      <div className={s.Section}>
        <div className={s.AdvisorListTop}>
          <div className={s.TitleGroup}>
            <h2>Rådgivare</h2>
            <Button
              icon
              onClick={toggleAdvisorList}
              customClass={s.HideElement}
            >
              <span>
                Dölj
                <Icons.ArrowRightIcon color={s.purple100} size={8} />
              </span>
            </Button>
          </div>
          <Dropdown
            label="Rådgivarteam"
            placeholder="Rådgivarteam"
            isSearchable={!isSmallScreen}
            onChange={onTeamChange}
            value={(({ id, namn }) => ({ value: id, label: namn }))(team)}
            selectOptions={allocatableTeams.map((teamFromList: ITeam) => ({
              value: teamFromList.id,
              label: teamFromList.namn,
            }))}
          />
        </div>
      </div>
      <div data-testid={'asideList'} className={s.AdvisorListSearch}>
        <InputField
          label="Sök efter rådgivare"
          placeholder="Sök efter rådgivare"
          value={searchValue}
          onChange={handleSearchChange}
        />
      </div>
      <div className={s.StatWrapper}>
        <p>Idag</p>
        <p>In</p>
        <p>In+/-</p>
        <p>Aktuella</p>
      </div>
      {fetchingAdvisorListState === FetchingState.LOADING ? (
        <Loading />
      ) : filteredAdvisorList.length === 0 ? (
        <NoResult>Inga resultat</NoResult>
      ) : (
        <div className={s.ListContents} data-testid={'advisorList'}>
          {fetchingAdvisorListState === FetchingState.SUCCESS &&
            filteredAdvisorList.map((advisor: IAdvisor) => (
              <AdvisorListItem
                key={advisor.id}
                advisorName={`${advisor.fornamn} ${advisor.efternamn}`}
                statistik={advisor.radgivarStatistik}
              />
            ))}
        </div>
      )}
    </aside>
  )
}

export default AdvisorList

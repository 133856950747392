import {
  Box,
  Link,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import { EArendenEnv } from '@local/src/Views/Arenden/Arenden.types'
import { useGetArendenEnv } from '@local/src/Hooks'
import { useLocation } from 'react-router-dom'
import BegaranYttrandeDeadline from '@local/Components/ActionBasedCaseItem/BegaranYttrande/BegaranYttrandeDeadline'

import { ICaseListItem } from '../ActionBasedCaseItem/ActionBasedCaseItem.types'
import CaseAvatar from '../CaseAvatar/CaseAvatar'

import NextMeeting from './NextMeeting/NextMeeting'
import LatestContact from './LatestContact/LatestContact'
import ActionBar from './ActionBar/ActionBar'

interface ActionBasedCaseItemProps {
  arende: ICaseListItem
}

const ActionBasedCaseItem = ({
  arende,
}: ActionBasedCaseItemProps): JSX.Element => {
  const arendenEnv = useGetArendenEnv()
  const isAllaArenden = arendenEnv === EArendenEnv.AllaArenden

  const { omstallning, anstallning, studier } = arende
  const { tilltalsnamn, fornamn, efternamn, kommun } = arende.klient
  const { anstallningsuppgifter, nastaMote } = anstallning ?? omstallning
  const { befattning, foretag } = anstallningsuppgifter

  const clientName =
    `${(tilltalsnamn || fornamn || '').trim()} ${efternamn || ''}`.trim() ||
    'Namn saknas'
  const employmentText = `${kommun ? kommun + ' - ' : ''}${befattning}, ${foretag.namn}`
  const subStatus =
    omstallning?.radgivning?.subStatus || anstallning?.radgivning?.subStatus
  const radgivningInitiated = ['Nytt', 'Fordelning'].some(
    (status) => status !== subStatus
  )

  const { pathname } = useLocation()
  const pathSlash = pathname.endsWith('/') ? pathname : `${pathname}/`

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ListItem
      divider
      className="Arende"
      key={arende.id}
      alignItems="flex-start"
      sx={{
        paddingY: 2,
        paddingX: isSmallScreen ? 2 : 1,
        gap: 1,
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        width={1}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        gap={1}
      >
        <Stack>
          <Box display="flex" alignItems="center" gap={1} marginBottom={0.5}>
            {isAllaArenden && <CaseAvatar arendeTyp={arende.arendeTyp} />}
            <Link
              href={`${pathSlash}arende/${arende.id}`}
              color="inherit"
              aria-label={`ärende ${clientName}`}
            >
              <Typography variant="h6" sx={{ wordBreak: 'break-all' }}>
                {clientName}
              </Typography>
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {employmentText}
          </Typography>
        </Stack>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          paddingTop={0.5}
          gap={4}
        >
          <BegaranYttrandeDeadline studier={studier} />
          {radgivningInitiated && (
            <>
              {!isSmallScreen && (
                <LatestContact date={arende.senasteKontaktDatum} />
              )}
              <NextMeeting meeting={nastaMote} />
            </>
          )}
        </Box>
      </Box>

      <ActionBar
        arendeTyp={arende.arendeTyp}
        arendeId={arende.id}
        actions={arende.actions}
        radgivare={omstallning?.radgivare}
        tiaRadgivare={arende.anstallning?.radgivare}
        isSmallScreen={isSmallScreen}
      />
    </ListItem>
  )
}

export default ActionBasedCaseItem

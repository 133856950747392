import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { baseApi } from '../Api/baseApi'

import { errorReporter } from './middleWares'
import createRootReducer from './rootReducer'

const rootReducer = createRootReducer()

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    preloadedState,
    reducer: rootReducer,
    devTools: { trace: true, traceLimit: 25 },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([errorReporter, baseApi.middleware]),
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default setupStore()

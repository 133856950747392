/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseQueryResult } from '@tanstack/react-query'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

import { useEpiString } from '../../../Context/EpiStringContext'
import { addErrorMessage } from '../../../Blocks/UserFeedback/UserFeedback.actions'
import { approveStudieAnmalan } from '../StudieAnmalanApi'

interface IDialogProps {
  instituteName: string
  educationName: string
  open: boolean
  id: string
  setOpen: (arg0: boolean) => void
  refetch: () => Promise<UseQueryResult>
}

const ApproveDialog: React.FC<IDialogProps> = ({
  setOpen,
  open,
  educationName,
  instituteName,
  id,
  refetch,
}) => {
  const [loading, setLoading] = useState(false)
  const epi = useEpiString()
  const dispatch = useDispatch()
  const onApproveClick = (e: React.MouseEvent) => {
    setLoading(true)
    e.preventDefault()
    void approveStudieAnmalan(id, true)
      .then(async () => {
        setOpen(false)
        await refetch()
        setLoading(false)
      })
      .catch(async () => {
        setLoading(false)
        dispatch(addErrorMessage('Det gick inte att godkänna anmälan'))
        setOpen(false)
        await refetch()
      })
  }
  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <DialogTitle>{epi('common.approveBooking')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <Typography sx={{ fontWeight: 'bold' }} variant="body1">
            {educationName}
          </Typography>
          <Typography variant="caption">{instituteName}</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Tänk på att beslutet inte kan ändras efter det är fattat. <br />
            <br />
            Notera att det kan dröja en stund innan status uppdateras i ärendet.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          size="small"
          variant="text"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            setOpen(false)
          }}
        >
          {epi('common.cancel')}
        </Button>
        <Button
          disabled={loading}
          size="small"
          variant="text"
          onClick={onApproveClick}
        >
          {epi('common.approveBooking')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApproveDialog

import Close from '@mui/icons-material/Close'
import {
  Popper,
  ClickAwayListener,
  Paper,
  Box,
  Typography,
  Button,
  IconButton,
} from '@mui/material'
import React from 'react'

interface CustomPopperProps {
  label: string
  open: boolean
  anchor: HTMLElement
  handleClose: () => void
  onClear?: () => void
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  width?: number
  hideHeader?: boolean
  children: React.ReactNode
  disableClear?: boolean
}

const CustomPopper = ({
  label,
  open,
  anchor,
  handleClose,
  onClear,
  onClick,
  width,
  hideHeader,
  children,
  disableClear,
}: CustomPopperProps) => {
  return (
    <Popper
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      role={'dialog'}
      open={open}
      anchorEl={anchor}
      onClick={onClick}
      placement="bottom-start"
      onKeyDown={(e) => e.key === 'Enter' && handleClose()}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ mt: 1, width: width }}>
          <Box display="flex" flexDirection="column">
            {!hideHeader && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pl={2}
                pr={0.5}
                py={1}
              >
                <Typography variant="h5" sx={{ fontSize: 16 }}>
                  {label}
                </Typography>
                <IconButton onClick={handleClose} aria-label="Stäng väljare">
                  <Close />
                </IconButton>
              </Box>
            )}
            {children}
            {onClear && (
              <Box p={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={onClear}
                  sx={{ p: 0 }}
                  disabled={disableClear}
                >
                  Rensa
                </Button>
              </Box>
            )}
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

export default CustomPopper

import React from 'react'
import MobileFilterDrawer from '@local/src/Components/MobileFilterDrawer/MobileFilterDrawer'

import { useStartklarFilterContext } from '../StartklarFilterContext'
import useIsStartklarFilterActive from '../../Hooks/useIsStartklarFilterActive'
import ForetagFilter from '../Filters/ForetagFilter'
import ArbetsmarknadsomradeFilter from '../Filters/ArbetsmarknadsomradeFilter'
import RadgivareFilter from '../Filters/RadgivareFilter'

const StartklarMobileFilter = () => {
  const {
    filters,
    initialFacets,
    handlaggareList,
    clearFilter,
    clearHandlaggareFilter,
    setFilter: handleFilter,
  } = useStartklarFilterContext()

  const filtersActive = useIsStartklarFilterActive(filters)

  const setEjTilldeladFilter = () => {
    filters.ejTilldeladHandlaggare === 'true'
      ? handleFilter('ejTilldeladHandlaggare', '')
      : handleFilter('ejTilldeladHandlaggare', 'true')
  }

  const totalFilterCount =
    filters.handlaggareIdn.length +
    filters.arbetsmarknadsomraden.length +
    filters.foretagNamn.length +
    (filters.ejTilldeladHandlaggare === 'true' ? 1 : 0)

  return (
    <MobileFilterDrawer
      filtersActive={filtersActive}
      filterCount={totalFilterCount}
      clearFilter={clearFilter}
    >
      <RadgivareFilter
        chosenRadgivare={filters.handlaggareIdn}
        radgivareList={handlaggareList}
        clearFilter={clearHandlaggareFilter}
        count={
          filters.handlaggareIdn.length +
          (filters.ejTilldeladHandlaggare === 'true' ? 1 : 0)
        }
        setFilter={handleFilter}
        notSetChecked={filters.ejTilldeladHandlaggare === 'true'}
        notSetOptionClicked={() => setEjTilldeladFilter()}
        enableNotSetOption
        isMobile
      />
      <ArbetsmarknadsomradeFilter
        chosenValues={filters.arbetsmarknadsomraden}
        count={filters.arbetsmarknadsomraden.length}
        availableValues={initialFacets?.facets.ArbetsmarknadsomradeNamn}
        setFilter={handleFilter}
        isMobile
      />
      <ForetagFilter
        count={filters.foretagNamn.length}
        filterState={filters}
        setFilter={handleFilter}
        value={filters.foretagNamn}
        isMobile
      />
    </MobileFilterDrawer>
  )
}

export default StartklarMobileFilter

import React from 'react'
import { Typography } from '@mui/material'

interface INoResult {
  children: React.ReactNode
  alignLeft?: boolean
}

const NoResult = ({ children, alignLeft }: INoResult): JSX.Element => (
  <Typography
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: alignLeft ? 'flex-start' : 'center',
      marginTop: 1,
    }}
  >
    {children}
  </Typography>
)

export default NoResult

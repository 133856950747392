import Email from '@local/src/Components/Email'
import PhoneNumber from '@local/src/Components/PhoneNumber'
import { Box } from '@mui/material'
import React from 'react'

interface KontaktuppgifterProps {
  telefonnummer: string
  epost: string
}

const Kontaktuppgifter = ({ telefonnummer, epost }: KontaktuppgifterProps) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      gap={{ xs: 1, sm: 3 }}
      flexWrap="wrap"
    >
      <PhoneNumber>{telefonnummer}</PhoneNumber>
      <Email>{epost}</Email>
    </Box>
  )
}

export default Kontaktuppgifter

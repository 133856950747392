import { useState, SyntheticEvent } from 'react'
import { useUser } from '@trr/app-shell-data'
import { Radgivare } from '@local/src/Common.types'

import { ArbetsuppgiftFilter, Facet } from '../../types'
import {
  getCurrentRadgivareFacet,
  getOwnerFacets,
  filterRadgivareOnName,
  getNoOwnerFacet,
} from '../../helpers'

export const useAssigneeFilter = (
  facets: Facet[],
  advisors: Radgivare[],
  selectedAssignees: string[],
  onChange: (key: keyof ArbetsuppgiftFilter, values: string | string[]) => void
) => {
  const assigneeKey = 'ownerIds'
  const currentUser = useUser()
  const currentUserFacet = getCurrentRadgivareFacet(facets, currentUser)
  const noOwnerFacet = getNoOwnerFacet(facets)
  const ownerFacets = getOwnerFacets(facets, advisors, currentUser.mdid)
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredAdvisorList = filterRadgivareOnName(ownerFacets, searchValue)

  const handleClose = () => {
    setSearchValue('')
  }

  const updateSearchValue = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    setSearchValue(eventTarget.value)
  }

  const pickAdvisor = (radgivareId: string) => {
    let updatedAssignees

    if (selectedAssignees.some((sa) => sa === radgivareId)) {
      updatedAssignees = selectedAssignees.filter((s) => s !== radgivareId)
    } else {
      updatedAssignees = [...selectedAssignees, radgivareId]
    }

    onChange(assigneeKey, updatedAssignees)
  }

  const isRadgivareSelected = (radgivareId: string) => {
    return selectedAssignees.some((sa) => sa === radgivareId)
  }

  return {
    handleClose,
    searchValue,
    updateSearchValue,
    pickAdvisor,
    isRadgivareSelected,
    currentUserFacet,
    noOwnerFacet,
    filteredAdvisorList,
    ownerFacets,
  }
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useFormik } from 'formik'
import { FetchingState, ISelectOption } from '@local/src/Common.types'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import Loading from '@local/src/Components/Loading/Loading'
import { avslutaArendeStateSelector } from '@local/Views/Case/Avslut/Avslut.selectors'
import HandleActivities from '@local/Views/Case/Avslut/Omstallningsstod/HandleActivities/HandleActivities'
import { hasError } from '@local/src/Views/Case/Avslut/Omstallningsstod/AvslutForms/Common/AvslutForms.helpers'
import { Avslutskod } from '@local/src/Views/Case/Avslut/Avslut.types'
import { avslutaArendeWithQuestions } from '@local/src/Views/Case/Avslut/Avslut.actions'
import useScrollToFirstError from '@local/src/Hooks/useScrollToFirstError'

import {
  utbildningOptions,
  sunKodOptions,
  validationSchema,
  FormValues,
  convertToAvslutsfragorSvar,
} from './StudierLangreTid.helpers'

const StudierLangreTid = ({
  arendeId,
  requireLosningStartdatum,
}: {
  arendeId: string
  requireLosningStartdatum: boolean
}) => {
  const dispatch = useDispatch()
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const [activitiesHandled, setActivitiesHandled] = useState(false)
  const [showActivitiesErrorMsg, setShowActivitiesErrorMsg] = useState(false)

  useEffect(() => {
    if (showActivitiesErrorMsg && activitiesHandled) {
      setShowActivitiesErrorMsg(false)
    }
  }, [activitiesHandled])

  const handleSubmit = (values: FormValues) => {
    if (activitiesHandled === false) {
      setShowActivitiesErrorMsg(true)
      return
    }

    dispatch(
      avslutaArendeWithQuestions({
        arendeId,
        losningStartdatum: requireLosningStartdatum
          ? values.startDatum.toISOString()
          : null,
        radgivningAvslutskod: Avslutskod.StudierLangreTid,
        radgivningAvslutsdatum: values.avslutsDatum.toISOString(),
        avslutsFragorSvar: convertToAvslutsfragorSvar(values),
      })
    )
  }

  const initialValues: Record<string, unknown> = {
    avslutsDatum: new Date(),
    requireLosningStartdatum,
    startDatum: null,
    typeOfEducation: undefined,
    sunCode: undefined,
    educationLength: null,
    educationOccupancy: null,
    distanceStudies: null,
    retraining: null,
  }

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues,
    validationSchema,
    validateOnMount: true,
  })
  useScrollToFirstError(formik.isValid, formik.isSubmitting, 24)

  return (
    <Stack
      display="flex"
      flexDirection="column"
      gap={5}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Box>
        <Typography variant="h4" mb={2}>
          Studier längre tid
        </Typography>
        <Typography variant="body1" mb={2} whiteSpace="pre-line">
          Avslutsdatum för STL är det datum då omställningskunden mottar
          utbildningens antagningsbesked. Avslutsdatum styr beräkningen av
          omställningskundens kvarvarande tid för omställningsstöd.
        </Typography>
        <Typography variant="body1" mb={3}>
          Om omställningskunden har nyttjat TRRs stöd efter det, ska i stället
          datum för sista dag med TRRs stöd anges som avslutsdatum. Dock senast
          när studierna påbörjas.
        </Typography>
        <DesktopDatePicker
          label="Avslutsdatum"
          slotProps={{
            textField: {
              name: 'avslutsDatum',
              error: hasError(formik, 'avslutsDatum', true),
              helperText: formik.errors?.avslutsDatum,
              onBlur: formik.handleBlur,
            },
          }}
          disableFuture
          sx={{ maxWidth: '228px' }}
          onChange={(value: Date) =>
            void formik.setFieldValue('avslutsDatum', value)
          }
          value={formik.values.avslutsDatum}
        />
      </Box>

      {requireLosningStartdatum && (
        <Box my={3}>
          <Typography variant="body1" mb={2}>
            För att beräkna AGE korrekt ska startdatum för studierna anges. Om
            omställningskunden påbörjat sina studier, fyll i dagens datum.
          </Typography>
          <DesktopDatePicker
            label="Startdatum"
            disablePast
            sx={{ maxWidth: '228px' }}
            onChange={(value: Date) =>
              void formik.setFieldValue('startDatum', value)
            }
            value={formik.values.startDatum}
            slotProps={{
              textField: {
                name: 'startDatum',
                error: hasError(formik, 'startDatum'),
                helperText:
                  hasError(formik, 'startDatum') && formik.errors?.startDatum,
                onBlur: formik.handleBlur,
              },
            }}
          />
        </Box>
      )}

      <HandleActivities
        setActivitiesHandled={setActivitiesHandled}
        showErrorMessage={showActivitiesErrorMsg}
      />

      <Box>
        <Typography variant="h5" mb={3}>
          Studieuppgifter
        </Typography>
        <Dropdown
          label="Typ av utbildning"
          name="typeOfEducation"
          options={utbildningOptions}
          getOptionLabel={(option: ISelectOption) => option.label}
          getOptionValue={(option: ISelectOption) => option.value}
          value={formik.values.typeOfEducation}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={hasError(formik, 'typeOfEducation')}
          errorText={formik.errors?.typeOfEducation}
          maxWidth="480px"
        />
      </Box>

      <Box>
        <Dropdown
          label="SUN-kod"
          name="sunCode"
          options={sunKodOptions}
          getOptionLabel={(option: ISelectOption) => option.label}
          getOptionValue={(option: ISelectOption) => option.value}
          value={formik.values.sunCode}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={hasError(formik, 'sunCode')}
          errorText={formik.errors?.sunCode}
          maxWidth="228px"
        />
      </Box>

      <Box>
        <FormControl
          fullWidth={false}
          error={
            formik.touched.educationLength &&
            formik.errors?.educationLength !== undefined
          }
        >
          <Typography variant="subtitle1">Utbildningens längd</Typography>
          <RadioGroup
            aria-required
            aria-labelledby="lblEducationLength"
            name="educationLength"
            value={formik.values.educationLength}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="En termin"
              name="educationLength"
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="2-4 terminer"
              name="educationLength"
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="5-8 terminer"
              name="educationLength"
            />
            <FormControlLabel
              value={4}
              control={<Radio />}
              label="Mer än 8 terminer"
              name="educationLength"
            />
          </RadioGroup>
          {hasError(formik, 'educationLength') && (
            <FormHelperText>{formik.errors?.educationLength}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box>
        <FormControl
          fullWidth={false}
          error={
            formik.touched.educationOccupancy &&
            formik.errors?.educationOccupancy !== undefined
          }
        >
          <Typography variant="subtitle1">Utbildningens omfattning</Typography>
          <RadioGroup
            aria-required
            aria-labelledby="lblEducationLength"
            name="educationOccupancy"
            value={formik.values.educationOccupancy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Heltid"
              name="educationOccupancy"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Deltid"
              name="educationOccupancy"
            />
          </RadioGroup>
          {hasError(formik, 'educationOccupancy') && (
            <FormHelperText>{formik.errors?.educationOccupancy}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box>
        <FormControl
          fullWidth={false}
          error={
            formik.touched.distanceStudies &&
            formik.errors?.distanceStudies !== undefined
          }
        >
          <Typography variant="subtitle1">Studieform</Typography>
          <RadioGroup
            aria-required
            aria-labelledby="lblDistanceStudies"
            name="distanceStudies"
            value={formik.values.distanceStudies}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Undervisning på plats"
              name="distanceStudies"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Distansundervisning"
              name="distanceStudies"
            />
          </RadioGroup>
          {hasError(formik, 'distanceStudies') && (
            <FormHelperText>{formik.errors?.distanceStudies}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box>
        <FormControl
          fullWidth={false}
          error={
            formik.touched.retraining && formik.errors?.retraining !== undefined
          }
        >
          <Typography variant="subtitle1">Påbyggnad/Omskolning</Typography>
          <RadioGroup
            aria-required
            aria-labelledby="lblRetraining"
            name="retraining"
            value={formik.values.retraining}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Påbyggnad i tidigare yrkesinriktning"
              name="retraining"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Omskolning i ny yrkesinriktning"
              name="retraining"
            />
          </RadioGroup>
          {hasError(formik, 'retraining') && (
            <FormHelperText>{formik.errors?.retraining}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Divider flexItem />

      {avslutaArendeState === FetchingState.LOADING ? (
        <Loading />
      ) : (
        <Button size="large" type="submit">
          Avsluta ärendet
        </Button>
      )}
    </Stack>
  )
}

export default StudierLangreTid

import { ICaseDetails } from '@local/src/Views/Case/Case.types'
import { Fordelningsdetaljer } from '@local/src/Views/Fordelning/Fordelning.types'

import { baseApi } from '../baseApi'

interface GetFordelningsDetaljerRequest {
  arendeId: string
  klientId: string
}

const baseUrl = '/v2.0/arenden'

export const arendenApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getArende: builder.query<ICaseDetails, string>({
      query: (arendeId) => `${baseUrl}/${arendeId}`,
    }),
    getFordelningsDetaljer: builder.query<
      Fordelningsdetaljer,
      GetFordelningsDetaljerRequest
    >({
      query: (request) =>
        `${baseUrl}/${request.arendeId}/klient/${request.klientId}/detaljer`,
      transformResponse: (response: Fordelningsdetaljer) => {
        // filter out duplicate tidigare radgivare
        if (response.tidigareRadgivare.length > 0) {
          response.tidigareRadgivare = response.tidigareRadgivare.filter(
            (radgivare, index, self) =>
              index === self.findIndex((idx) => idx.id === radgivare.id)
          )
        }
        return response
      },
    }),
  }),
})

export const { useGetArendeQuery, useGetFordelningsDetaljerQuery } = arendenApi

export interface EventBody {
  clientId: string
  healthNote: string
  note: string
  noteType: EventTypes
  occurrenceDate: Date
}

export enum EventTypes {
  Meeting = 'OnSiteMeeting',
  DigitalMeeting = 'VideoMeeting',
  SMS = 'Sms',
  Phone = 'Phone',
  Email = 'Email',
  Note = 'OtherNote',
  Startklar = 'Startklar',
  GroupEmail = 'GroupEmail',
}

export interface IEvent {
  createdBy: string
  healthNote?: string
  note: string
  id: string
  noteType: EventTypes
  occurrenceDate: Date
  registrationDate: Date
  isHealthNote: boolean
  isReadOnly: boolean
}

export interface IEventsPerMonth {
  date: Date
  events: IEvent[]
}

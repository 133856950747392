import { useState } from 'react'

const useToggle = (
  initialState = false
): {
  isEnabled: boolean
  toggle: () => void
  enable: () => void
  disable: () => void
} => {
  const [isEnabled, setIsEnabled] = useState<boolean>(initialState)

  const toggle = () => setIsEnabled((state) => !state)
  const enable = () => setIsEnabled(true)
  const disable = () => setIsEnabled(false)

  return { isEnabled, toggle, enable, disable }
}
export { useToggle }

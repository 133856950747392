import { createActions } from 'redux-actions'
import { get, put } from '@local/Utils/network'

export const {
  fetchTodoListInitiate,
  fetchTodoListSuccess,
  fetchTodoListFailure,

  completeTodoInitiate,
  completeTodoSuccess,
  completeTodoFailure,

  reactivateTodoInitiate,
  reactivateTodoSuccess,
  reactivateTodoFailure,
} = createActions({
  fetchTodoListInitiate: () => ({}),
  fetchTodoListSuccess: (todoList) => ({ todoList }),
  fetchTodoListFailure: (errorMessage) => {
    return `Det gick inte att hämta "Att göra"-listan. ${String(errorMessage)}`
  },

  completeTodoInitiate: () => ({}),
  completeTodoSuccess: (completedTodo) => ({ completedTodo }),
  completeTodoFailure: (errorMessage) => {
    return `Det gick inte att slutföra uppgiften. ${String(errorMessage)}`
  },

  reactivateTodoInitiate: () => ({}),
  reactivateTodoSuccess: (reactivatedTodo) => ({ reactivatedTodo }),
  reactivateTodoFailure: (errorMessage) => {
    return `Det gick inte att återöppna uppgiften. ${String(errorMessage)}`
  },
})

export const fetchTodoList = () =>
  get({
    url: '/radgivningsarende/arbetsuppgift',
    initiated: fetchTodoListInitiate,
    fulfilled: fetchTodoListSuccess,
    rejected: fetchTodoListFailure,
  })
export const completeTodo = (arbetsuppgiftId: string) =>
  put({
    url: `/radgivningsarende/arbetsuppgift/${arbetsuppgiftId}/complete`,
    initiated: completeTodoInitiate,
    fulfilled: completeTodoSuccess,
    rejected: completeTodoFailure,
  })

export const reactivateTodo = (arbetsuppgiftId: string) =>
  put({
    url: `/radgivningsarende/arbetsuppgift/${arbetsuppgiftId}/reactivate`,
    initiated: reactivateTodoInitiate,
    fulfilled: reactivateTodoSuccess,
    rejected: reactivateTodoFailure,
  })

import getConfig from '@local/src/Utils/ConfigService'
import { warningTextsSelector } from '@local/src/Views/Case/Case.selectors'
import { Alert } from '@mui/material'
import { HTMLMapper } from '@trr/html-mapper'
import React from 'react'
import { useSelector } from 'react-redux'

const ArendeWarningText = () => {
  const warningTexts = useSelector(warningTextsSelector)

  return (
    <>
      {warningTexts?.bedomningPagar && (
        <Alert severity="warning" variant="outlined" sx={{ borderRadius: 2 }}>
          <HTMLMapper
            body={warningTexts?.bedomningPagar}
            mediaUrl={getConfig().MEDIA_URL}
            removeLastElementGutter
          />
        </Alert>
      )}
    </>
  )
}

export default ArendeWarningText

import { Box, Chip } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { EventTypes } from '../EventLog.types'
import { getLabelFor } from '../EventLog.helpers'

interface EventLogFilterProps {
  uniqueEventTypes: EventTypes[]
  onChosenFilter: (eventTypes: string[]) => void
}

export interface ResetEventLogFilter {
  reset: () => void
}

const EventLogFilter = forwardRef<ResetEventLogFilter, EventLogFilterProps>(
  (props: EventLogFilterProps, ref) => {
    const allEventTypes = 'alla'
    const [clickedChips, setClickedChips] = useState<string[]>([allEventTypes])

    useImperativeHandle(ref, () => ({
      reset: () => setClickedChips([allEventTypes]),
    }))

    useEffect(() => {
      props.onChosenFilter(clickedChips)
    }, [clickedChips])

    const handleClick = (eventType: string) => {
      if (eventType === allEventTypes) {
        setClickedChips([allEventTypes])
        return
      }
      if (chipIsClicked(eventType)) {
        const updated = clickedChips.filter((value) => value !== eventType)
        if (updated.length === 0) {
          setClickedChips([allEventTypes])
        } else {
          setClickedChips(updated)
        }
        return
      }

      let updated = clickedChips.filter((value) => value !== allEventTypes)
      updated = [...updated, eventType]
      if (updated.length === props.uniqueEventTypes.length) {
        setClickedChips([allEventTypes])
        return
      }
      setClickedChips(updated)
    }

    const chipIsClicked = (eventType: string) => {
      return clickedChips.includes(eventType)
    }

    return (
      <Box display="flex" flexDirection="row" gap={1} mt={2} flexWrap="wrap">
        <Chip
          key={allEventTypes}
          variant={chipIsClicked(allEventTypes) ? 'filled' : 'outlined'}
          label="Alla"
          onClick={() => handleClick(allEventTypes)}
        />
        {Object.values(props.uniqueEventTypes).map((value: string) => {
          const label = getLabelFor(value as EventTypes)
          return (
            <Chip
              key={label}
              variant={chipIsClicked(value) ? 'filled' : 'outlined'}
              label={label}
              onClick={() => handleClick(value)}
            />
          )
        })}
      </Box>
    )
  }
)

EventLogFilter.displayName = 'EventLogFilter'

export default EventLogFilter

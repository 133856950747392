import React, { useState } from 'react'
import { date } from '@trr/frontend-datetime'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import {
  Alert,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material'
import Event from '@mui/icons-material/Event'
import WatchLater from '@mui/icons-material/WatchLater'
import LocationOn from '@mui/icons-material/LocationOn'
import Money from '@mui/icons-material/Money'
import { UseQueryResult } from '@tanstack/react-query'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import { IStudieAnmalanListItem } from '../types/StudieAnmalanListItem.types'
import { useEpiString } from '../../../Context/EpiStringContext'
import StudieAnmalanStatusChip from '../StudieAnmalanStatusChip/StudieAnmalanStatusChip'
import { removeTrailingSlash } from '../../../Utils/helpers'
import ApproveDialog from '../ApproveDialog/ApproveDialog'
import RejectDialog from '../RejectDialog/RejectDialog'
import AdvisorDecisionSuccessAlert from '../StudieAnmalanDetails/components/AdvisorDecisionSuccessAlert/AdvisorDecisionSuccessAlert'

interface IStudieAnmalanListItemProps {
  studieAnmalan: IStudieAnmalanListItem
  refetch: () => Promise<UseQueryResult>
}

const StudieAnmalanListItem: React.FC<
  React.PropsWithChildren<IStudieAnmalanListItemProps>
> = ({ studieAnmalan, refetch }) => {
  const [approveOpen, setApproveOpen] = useState(false)
  const [rejectOpen, setRejectOpen] = useState(false)

  const {
    id,
    educationName,
    instituteName,
    courseStartDate,
    courseLengthInDays,
    isDistance,
    placeDescription,
    statuses,
    courseFee,
    bookingDate,
  } = studieAnmalan
  const history = useHistory()
  const isInFuture = (date: Date) => date > new Date()
  const epi = useEpiString()
  const metaDataTexts = (): { text: string; icon: JSX.Element }[] => {
    const meta = []
    if (courseStartDate) {
      meta.push({
        icon: <Event />,
        text: `${isInFuture(courseStartDate) ? epi('common.StudyStart') : epi('common.Began')} ${date.format(
          courseStartDate,
          {
            year: true,
          }
        )}`,
      })
    }
    if (courseLengthInDays)
      meta.push({
        icon: <WatchLater />,
        text: `${courseLengthInDays} ${courseLengthInDays > 1 ? epi('common.days') : epi('common.day')}`,
      })
    if (isDistance)
      meta.push({ icon: <LocationOn />, text: epi('common.Distance') })
    if (!isDistance && placeDescription)
      meta.push({ icon: <LocationOn />, text: placeDescription })
    if (courseFee?.totalFee)
      meta.push({
        icon: <Money />,
        text: `${courseFee.totalFee.toLocaleString()}kr`,
      })
    return meta
  }
  const findCoursesProDetailsEnabled = useIsFeatureEnabled(
    'Medarbetare-MicroFrontend_findcourcesProDetails_perm_240918'
  )
  const decideOnStudieAnmalanEnabled = useIsFeatureEnabled(
    'Medarbetare-MicroFrontend_studieAnmalanDecision_perm_240918'
  )
  return (
    <>
      <RejectDialog
        refetch={refetch}
        instituteName={instituteName}
        educationName={educationName}
        open={rejectOpen}
        setOpen={setRejectOpen}
        id={id}
      />
      <ApproveDialog
        refetch={refetch}
        instituteName={instituteName}
        educationName={educationName}
        open={approveOpen}
        setOpen={setApproveOpen}
        id={id}
      />
      <Card
        data-testid="studie-anmalan-list-item"
        sx={{
          mb: 2,
          mt: 1,
        }}
      >
        <ButtonBase
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
          component={RouterLink}
          to={
            findCoursesProDetailsEnabled
              ? `${removeTrailingSlash(history.location.pathname)}/anmalan/${id}`
              : ''
          }
        >
          <CardHeader
            sx={{ width: '100%', flexWrap: 'wrap' }}
            title={educationName}
            subheader={instituteName}
            action={<StudieAnmalanStatusChip statuses={statuses} />}
          />
          <CardContent sx={{ width: '100%' }}>
            <Stack flexWrap={'wrap'} gap={3} sx={{ mr: 3 }} direction="row">
              {metaDataTexts().map((m) => (
                <Stack
                  key={`${m.text} - ${id} - ${courseFee.totalFee}`}
                  direction="row"
                >
                  {m.icon}
                  <Typography sx={{ ml: 0.5 }} variant="body1">
                    {m.text}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            {statuses.isWaitingForAdvisorApproval &&
              studieAnmalan.advisorDecision &&
              decideOnStudieAnmalanEnabled && (
                <AdvisorDecisionSuccessAlert
                  advisorDecision={studieAnmalan.advisorDecision}
                />
              )}
            {statuses.isWaitingForAdvisorApproval &&
              studieAnmalan.advisorDecision === null &&
              decideOnStudieAnmalanEnabled && (
                <Alert sx={{ mt: 2 }} severity="warning">
                  {bookingDate && (
                    <Typography variant="subtitle1">
                      {epi('common.bookingSubmitted')}{' '}
                      {bookingDate.toLocaleDateString()}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {epi('common.decisionParagraph')}
                  </Typography>
                </Alert>
              )}
          </CardContent>
        </ButtonBase>

        {statuses.isWaitingForAdvisorApproval &&
          studieAnmalan.advisorDecision === null &&
          decideOnStudieAnmalanEnabled && (
            <CardActions sx={{ flexDirection: 'row-reverse' }}>
              <Button
                onClick={() => setApproveOpen(true)}
                size="small"
                variant="outlined"
              >
                {epi('common.approveBooking')}
              </Button>
              <Button
                onClick={() => setRejectOpen(true)}
                size="small"
                variant="text"
              >
                {epi('common.rejectBooking')}
              </Button>
            </CardActions>
          )}
      </Card>
    </>
  )
}

export default StudieAnmalanListItem

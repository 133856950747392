import React, { useState, useEffect } from 'react'
import {
  formatDateWithMonthStringFromDateTime as formatMonth,
  formatTimeFromDateTime as formatTime,
} from '@local/Utils/DateTime'
import { isRareFolkbokforingsStatus } from '@local/src/Utils/FolkbokföringsStatus'
import FolkbokforingsStatusChip from '@local/src/Components/PeopleRegistrationStatusChip/PeopleRegistrationStatusChip'
import {
  ICaseStartklar,
  IHandlaggare,
} from '@local/Views/Startklar/Startklar.types'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import { getUrlByCaseEnv } from '@local/src/basename'
import { equals } from 'ramda'
import { Box, Chip, Link, ListItem, Typography } from '@mui/material'
import {
  useAssignHandlaggareMutation,
  useUnassignHandlaggareMutation,
  useUpdateNoOfUppringningsforsokMutation,
} from '@local/src/Api/Startklar/startklarApi'

import IncrementationChip from '../../../Components/IncrementationChip'
import PickAdvisor from '../../../Components/AdvisorSelectors/AdvisorSelectorDesktop'

export interface IStartklarListItem {
  startklarCase: ICaseStartklar
  handlaggareList: IHandlaggare[]
}

const StartklarListItem = ({
  startklarCase: sc,
  handlaggareList,
}: IStartklarListItem): JSX.Element => {
  const caseEnvBasePath = useCaseEnvBasePath()
  const startklarHandlaggare = sc.handlaggare
  const [chosenAdvisor, setChosenAdvisor] = useState(startklarHandlaggare)
  const [assignHandlaggare] = useAssignHandlaggareMutation()
  const [unassignHandlaggare] = useUnassignHandlaggareMutation()
  const [updateNoOfUppringningsforsok] =
    useUpdateNoOfUppringningsforsokMutation()

  useEffect(() => {
    chosenAdvisor &&
      !equals(chosenAdvisor, startklarHandlaggare) &&
      assignHandlaggare({ arendeId: sc.id, handlaggare: chosenAdvisor })
  }, [chosenAdvisor])

  const removeHandlaggare = () => {
    unassignHandlaggare(sc.id)
    setChosenAdvisor(undefined)
  }

  const increaseNoOfUppringningsforsok = (number: number) => {
    updateNoOfUppringningsforsok({ arendeId: sc.id, number: number })
  }

  const showFolkbokforingschip = isRareFolkbokforingsStatus(
    sc.folkbokforingsStatus
  )

  return (
    <ListItem
      divider
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        py: 2,
        px: { xs: 2, md: 1 },
        gap: 1,
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" alignSelf="baseline">
          <Link
            href={`${getUrlByCaseEnv(caseEnvBasePath, sc.id)}`}
            color="inherit"
            aria-label={`ärende ${sc.klientNamn}`}
            sx={{ alignSelf: 'baseline' }}
          >
            <Typography
              variant="h6"
              sx={{ wordBreak: 'break-all', wordWrap: 'break-word' }}
            >
              {sc.klientNamn}
            </Typography>
          </Link>
          <Typography variant="body2" color="text.secondary">
            {sc.kommun ? sc.kommun : 'Information saknas'} -{' '}
            {`${sc.befattning}, ${sc.arbetsstalle}`}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          alignItems="flex-start"
          paddingTop={0.5}
          gap={2}
          flexWrap="wrap"
        >
          <PickAdvisor
            advisorList={handlaggareList}
            choose={setChosenAdvisor}
            chosenAdvisor={chosenAdvisor}
            remove={removeHandlaggare}
          />
          <IncrementationChip
            onIncrease={increaseNoOfUppringningsforsok}
            number={sc.antalUppringningsforsok}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        gap={2}
        flexWrap="nowrap"
        justifyContent="flex-start"
        alignItems="center"
        tabIndex={-1}
      >
        <Typography variant="body2" pr={{ xs: 0, md: 1 }}>
          {`${formatMonth(sc.attesteringsDatum)} kl. ${formatTime(sc.attesteringsDatum)}`}
        </Typography>
        {sc.dagNummer >= 2 && (
          <Chip label={`Dag ${sc.dagNummer}`} variant="status-error" />
        )}
        {showFolkbokforingschip && (
          <FolkbokforingsStatusChip status={sc.folkbokforingsStatus} />
        )}
      </Box>
    </ListItem>
  )
}
export default StartklarListItem

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { IDokumentEPIContent } from './Documents.types'

interface DeleteDocumentModalInterface {
  isRemoveDialogActive: boolean
  setIsRemoveDialogActive: (active: boolean) => void
  isTypeStudieIntyg: boolean
  content: IDokumentEPIContent
  deleteDocument: (arendeId: string, documentId: string) => void
  arendeId: string
  documentId: string
}

const DeleteDocumentModal = ({
  isRemoveDialogActive,
  setIsRemoveDialogActive,
  isTypeStudieIntyg,
  content,
  deleteDocument,
  arendeId,
  documentId,
}: DeleteDocumentModalInterface) => {
  if (!isRemoveDialogActive) return null

  return isTypeStudieIntyg ? (
    <Dialog
      open={isRemoveDialogActive}
      onClose={() => setIsRemoveDialogActive(false)}
      data-testid="remove-studieintyg-dialog"
    >
      <DialogTitle>{content.dialogDeleteStudieintygHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content.dialogDeleteStudieintygInfoText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setIsRemoveDialogActive(false)}>
          {content.dialogDeleteStudieintygConfirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={isRemoveDialogActive}
      onClose={() => setIsRemoveDialogActive(false)}
    >
      <DialogTitle>{content.dialogDeleteDocumentHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content.dialogGdprDeleteDocumentInfoText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            deleteDocument(arendeId, documentId)
            setIsRemoveDialogActive(false)
          }}
        >
          {content.dialogDeleteDocumentConfirmButtonText}
        </Button>
        <Button variant="text" onClick={() => setIsRemoveDialogActive(false)}>
          {content.dialogDeleteDocumentCancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDocumentModal

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ButtonBase, Typography } from '@mui/material'
import React from 'react'

import { useEpiString } from '../../../../../Context/EpiStringContext'

interface IBackButtonProps {
  onClick: () => void
}

const BackButton: React.FC<React.PropsWithChildren<IBackButtonProps>> = ({
  onClick,
}) => {
  const epi = useEpiString()
  return (
    <ButtonBase sx={{ mb: 1 }} data-testid="back-button" onClick={onClick}>
      <ArrowBackIosIcon />
      <Typography variant="body1">{epi('common.Back')}</Typography>
    </ButtonBase>
  )
}

export default BackButton

import { FordelningsArende } from '@local/src/Views/Fordelning/Fordelning.types'
import {
  addErrorMessage,
  addSuccessMessage,
} from '@local/src/Blocks/UserFeedback/UserFeedback.actions'

import { baseApi } from '../baseApi'
import { radgivareApi } from '../Radgivare/radgivareApi'

interface ChangeFordelningsteamRequest {
  arendeId: string
  teamId: string
}

interface FordelaArendenRequest {
  fordelningList: { arendeId: string; radgivareId: string }[]
}

const baseUrl = '/v1.0/fordelning'

export const fordelningApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['FordelningsArenden'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFordelningsArenden: builder.query<FordelningsArende[], string>({
        query: (teamId) => `${baseUrl}/fordelningsarenden?teamId=${teamId}`,
        providesTags: (result, _, args) =>
          result
            ? [
                ...result.map(
                  (item) =>
                    ({ type: 'FordelningsArenden', id: item.id }) as const
                ),
                { type: 'FordelningsArenden', id: args },
              ]
            : [{ type: 'FordelningsArenden', id: args }],
      }),
      changeFordelningsteam: builder.mutation<
        void,
        ChangeFordelningsteamRequest
      >({
        query: (body) => ({
          url: `${baseUrl}/bytfordelningsteam`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: body,
        }),
        onQueryStarted: (args, api) => {
          const queryCache = fordelningApi.util.selectInvalidatedBy(
            api.getState(),
            ['FordelningsArenden']
          )
          queryCache.forEach((cache) => {
            const patchResult = api.dispatch(
              fordelningApi.util.updateQueryData(
                'getFordelningsArenden',
                cache.originalArgs as string,
                (data) => {
                  const currentTeamCache = cache.originalArgs as string

                  const index = data.findIndex(
                    (item) => item.id === args.arendeId
                  )

                  if (data[index] && currentTeamCache !== args.teamId) {
                    data[index].teamId = args.teamId
                    if (currentTeamCache !== args.teamId) {
                      data.splice(index, 1)
                    }
                  } else {
                    // invalidate cache for the new team.
                    api.dispatch(
                      fordelningApi.util.invalidateTags([
                        { type: 'FordelningsArenden', id: args.teamId },
                      ])
                    )
                  }
                  return data
                }
              )
            )
            api.queryFulfilled
              .then(() => api.dispatch(addSuccessMessage('Teamet är bytt')))
              .catch(() => {
                patchResult.undo()
                api.dispatch(addErrorMessage('Något gick fel vid byte av team'))
              })
          })
        },
      }),
      fordelaArenden: builder.mutation<void, FordelaArendenRequest>({
        query: (body) => ({
          url: `${baseUrl}/fordelaArenden`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: body,
        }),
        onQueryStarted: (args, api) => {
          const queryCache = fordelningApi.util.selectInvalidatedBy(
            api.getState(),
            ['FordelningsArenden']
          )
          queryCache.forEach((cache) => {
            const patchResult = api.dispatch(
              fordelningApi.util.updateQueryData(
                'getFordelningsArenden',
                cache.originalArgs as string,
                (data) => {
                  args.fordelningList.forEach((arende) => {
                    const index = data.findIndex(
                      (item) => item.id === arende.arendeId
                    )
                    if (data[index]) {
                      data.splice(index, 1)
                    }
                  })
                  return data
                }
              )
            )
            api.queryFulfilled
              .then(() => {
                // refresh radgivarstatistik
                api.dispatch(
                  radgivareApi.util.invalidateTags(['aktivaRadgivareList'])
                )
                api.dispatch(
                  addSuccessMessage(
                    `${args.fordelningList.length} ${args.fordelningList.length === 1 ? 'ärende' : 'ärenden'} har fördelats`
                  )
                )
              })
              .catch(() => {
                patchResult.undo()
                api.dispatch(
                  addErrorMessage('Något gick fel vid fördelning av ärenden')
                )
              })
          })
        },
      }),
    }),
  })

export const {
  useGetFordelningsArendenQuery,
  useChangeFordelningsteamMutation,
  useFordelaArendenMutation,
} = fordelningApi

import { Divider, List } from '@mui/material'
import React from 'react'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import { IStudieAnmalan } from '../../../types/StudieAnmalan.types'
import {
  StudieAnmalanEducation,
  StudieAnmalanHeader,
  AttendanceReport,
} from '..'
import CourseFees from '../CourseFees/CourseFees'
import Contact from '../Contact/Contact'
import StudieAnmalanStatuses from '../StudieAnmalanStatuses/StudieAnmalanStatuses'
import AdvisorDecisionSuccessAlert from '../AdvisorDecisionSuccessAlert/AdvisorDecisionSuccessAlert'

interface IStudieAnmalanInfoProps {
  studieAnmalan: IStudieAnmalan
}

const StudieAnmalanInfo: React.FC<
  React.PropsWithChildren<IStudieAnmalanInfoProps>
> = ({ studieAnmalan }) => {
  const decideOnStudieAnmalanEnabled = useIsFeatureEnabled(
    'Medarbetare-MicroFrontend_studieAnmalanDecision_perm_240918'
  )

  return (
    <List data-testid="studie-anmalan-details">
      <StudieAnmalanHeader studieAnmalan={studieAnmalan} />

      <StudieAnmalanStatuses
        statuses={studieAnmalan.statuses}
        advisorDecision={studieAnmalan.advisorDecision}
      />
      {studieAnmalan.statuses.isWaitingForAdvisorApproval &&
        studieAnmalan.advisorDecision &&
        decideOnStudieAnmalanEnabled && (
          <AdvisorDecisionSuccessAlert
            advisorDecision={studieAnmalan.advisorDecision}
          />
        )}
      <Divider component="li" />
      <StudieAnmalanEducation studieAnmalan={studieAnmalan} />
      <Divider component="li" />
      <Contact studieAnmalan={studieAnmalan} />
      <Divider component="li" />
      <CourseFees studieAnmalan={studieAnmalan} />
      <Divider component="li" />
      <AttendanceReport studieAnmalan={studieAnmalan} />
    </List>
  )
}

export default StudieAnmalanInfo

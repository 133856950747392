import { isEmpty, isNil } from 'ramda'
import { formatAmount } from '@local/Utils/smallfuncs'
import { Facets } from 'src/Common.types'

export const getFacetCount = (
  facets: Facets,
  facetProp: string,
  nameOfFacet: string
): string => {
  if (!isNil(facetProp) && !isEmpty(facetProp)) {
    const foundFacet = facets?.[facetProp]?.find(
      (obj: { value: string; count: number }) => obj.value === nameOfFacet
    )
    return !isNil(foundFacet) ? formatAmount(foundFacet.count) : '0'
  } else {
    return '0'
  }
}

export const folkbokforingsStatusDisplayNameDic: {
  [key: string]: string
} = {
  Okand: 'Okänd',
  Avliden: 'Avliden',
  EjAktiv: 'Ej aktiv',
  Avregistrerad: 'Avregistrerad',
  PersonnummerAndring: 'Personnummerändring',
  Sekretess: 'Sekretess',
  Aktiv: 'Aktiv',
  Saknas: 'Adress saknas',
}

export const isRareFolkbokforingsStatus = (
  folkbokforingsStatus: string
): boolean =>
  folkbokforingsStatus === 'Saknas' || folkbokforingsStatus === 'Sekretess'

export const isSensitiveFolkbokforingsStatus = (
  folkbokforingsStatus: string
): boolean => folkbokforingsStatus === 'Sekretess'

import dinaArenden from '@local/Views/DinaArenden/DinaArenden.reducers'
import arenden from '@local/src/Views/Arenden/Arenden.reducers'
import allocateCases from '@local/Views/AllocateCases/AllocateCases.reducers'
import selectedCase from '@local/src/Views/Case/Case.reducers'
import activities from '@local/Views/Case/Activities/Activities.reducer'
import todo from '@local/Blocks/TodoList/TodoList.reducer'
import createEmail from '@local/Blocks/CreateEmail/CreateEmail.reducer'
import avslut from '@local/Views/Case/Avslut/Avslut.reducer'
import { combineReducers } from '@reduxjs/toolkit'

import userFeedback from '../Blocks/UserFeedback/UserFeedback.reducers'
import { baseApi } from '../Api/baseApi'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () =>
  combineReducers({
    userFeedback,
    dinaArenden,
    arenden,
    allocateCases,
    selectedCase,
    createEmail,
    activities,
    todo,
    avslut,
    [baseApi.reducerPath]: baseApi.reducer,
  })

export const isStringArray = (array: any[]): array is string[] => {
  return typeof array[0] === 'string'
}

export const isMinMaxArray = (array: any[]): array is [number, number] => {
  return (
    typeof array[0] === 'number' &&
    typeof array[1] === 'number' &&
    array[0] <= array[1] &&
    array[2] === undefined
  )
}

import React from 'react'
import { Box } from '@mui/material'

interface FilterProps {
  children: JSX.Element
}

export const Filter = ({ children }: FilterProps): JSX.Element => (
  <Box
    display="flex"
    flexDirection="column"
    sx={{ direction: 'ltr' }}
    position="sticky"
    overflow="auto"
    top={4}
  >
    {children}
  </Box>
)

export default Filter

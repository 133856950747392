import { useMemo } from 'react'

import { StartklarFilter } from '../Startklar.types'

const useIsStartklarFilterActive = (filters: Partial<StartklarFilter>) => {
  const filtersActive = useMemo(() => {
    return Boolean(
      filters.arbetsmarknadsomraden.length > 0 ||
        filters.foretagNamn.length > 0 ||
        filters.handlaggareIdn.length > 0
    )
  }, [filters])

  return filtersActive
}

export default useIsStartklarFilterActive

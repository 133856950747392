import React, { useState, useEffect, useRef } from 'react'
import { FetchingState } from '@local/src/Common.types'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  createEventStateSelector,
  eventsSelector,
  fetchEventsStateSelector,
} from '../Case.selectors'
import { fetchEvents } from '../Case.actions'

import EventForm from './EventForm/EventForm'
import EventCard from './EventCard/EventCard'
import {
  createSortedEventsArray,
  getLabelFor,
  mapDateToTitle,
} from './EventLog.helpers'
import { EventTypes, IEvent, IEventsPerMonth } from './EventLog.types'
import EventLogFilter, {
  ResetEventLogFilter,
} from './EventLogFilter/EventLogFilter'

interface EventLogProps {
  caseId: string
  clientId: string
  arendeTyp: string
  healthConsent: boolean
}

const EventLog = ({
  caseId,
  clientId,
  arendeTyp,
  healthConsent = false,
}: EventLogProps): JSX.Element => {
  const dispatch = useDispatch()
  const events = useSelector(eventsSelector)
  const createEventState = useSelector(createEventStateSelector)
  const fetchEventsState = useSelector(fetchEventsStateSelector)

  const [createNewActive, setCreateNewActive] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [filters, setFilters] = useState<string[]>([])
  const [eventsPerMonth, setEventsPerMonth] = useState<IEventsPerMonth[]>([])
  const [filteredMonthlyEvents, setFilteredMonthlyEvents] = useState<
    IEventsPerMonth[]
  >([])
  const filterRef = useRef<ResetEventLogFilter>(null)

  const abortNewLog = () => setCreateNewActive(false)

  useEffect(() => {
    caseId && dispatch(fetchEvents(caseId))
  }, [caseId, fetchEvents])

  useEffect(() => {
    if (events) {
      setEventsPerMonth(createSortedEventsArray(events))
      setFilters([])
      filterRef.current?.reset()
    }
  }, [events])

  useEffect(() => {
    setIsEditing(createNewActive)
  }, [createNewActive])

  useEffect(() => {
    createEventState === FetchingState.SUCCESS && abortNewLog()
  }, [createEventState])

  useEffect(() => {
    handleSetFilteredMonthlyEvents(filters)
  }, [filters, eventsPerMonth])

  const handleSetFilteredMonthlyEvents = (filters: string[]) => {
    if (isNoneOrAllTypesSelected(filters)) {
      setFilteredMonthlyEvents(eventsPerMonth)
      return
    }
    const filteredEvents = [] as IEvent[]
    for (const month of eventsPerMonth) {
      for (const event of month.events) {
        if (filters.includes(event.noteType)) {
          filteredEvents.push(event)
        }
      }
    }
    setFilteredMonthlyEvents(createSortedEventsArray(filteredEvents))
  }

  const isNoneOrAllTypesSelected = (filters: string[]): boolean =>
    filters.length === 0 || (filters.length === 1 && filters[0] === 'alla')
  const isFirstContact = () =>
    !events.some(
      (event) =>
        event.noteType !== EventTypes.Note &&
        event.noteType !== EventTypes.Startklar
    )
  const isLoadingEvents = fetchEventsState === FetchingState.LOADING
  const isEmptyEventList =
    fetchEventsState === FetchingState.SUCCESS &&
    filteredMonthlyEvents.length === 0
  const hasLoadedEvents = fetchEventsState === FetchingState.SUCCESS
  const uniqueEventTypes = Array.from(
    new Set(events?.map((event) => event.noteType))
  ).sort((a, b) => getLabelFor(a).localeCompare(getLabelFor(b)))

  return (
    <Box flex="1 1">
      {!createNewActive && (
        <>
          <Button
            size="large"
            onClick={() => setCreateNewActive(true)}
            disabled={isEditing}
          >
            Skapa händelse
          </Button>
          {isEmptyEventList && (
            <Typography variant="body1" paddingTop={2}>
              Det finns inga händelser på detta ärende.
            </Typography>
          )}
        </>
      )}
      {createNewActive && (
        <EventForm
          arendeTyp={arendeTyp}
          healthConsent={healthConsent}
          leaveEditMode={abortNewLog}
          registeredDate={new Date()}
          eventDate={new Date()}
          clientId={clientId}
          arendeId={caseId}
          isFirstContact={isFirstContact()}
        />
      )}

      {isLoadingEvents && (
        <Box display="flex" justifyContent="center">
          <CircularProgress sx={{ display: 'flex', alignItems: 'center' }} />
        </Box>
      )}
      {hasLoadedEvents && (
        <>
          {uniqueEventTypes.length > 1 && (
            <EventLogFilter
              ref={filterRef}
              uniqueEventTypes={uniqueEventTypes}
              onChosenFilter={(filters) => setFilters(filters)}
            />
          )}
          <List aria-label="Händelselista" sx={{ pt: 3 }}>
            {filteredMonthlyEvents.map((monthChunkEntries) => {
              return (
                <ListItem
                  key={new Date(monthChunkEntries.date).getTime()}
                  divider={false}
                  disableGutters
                  sx={{ flexDirection: 'column', alignItems: 'stretch' }}
                >
                  <Typography
                    variant="preamble"
                    fontWeight="700"
                    fontSize="1rem"
                  >
                    {mapDateToTitle(monthChunkEntries.date)}
                  </Typography>
                  <Divider sx={{ marginY: 1 }} />
                  {monthChunkEntries.events.map((event) => {
                    return (
                      <EventCard
                        arendeTyp={arendeTyp}
                        bodyText={event.note}
                        eventDate={new Date(event.occurrenceDate)}
                        eventType={event.noteType}
                        healthConsent={healthConsent}
                        healthNote={event.healthNote}
                        key={event.id}
                        id={event.id}
                        clientId={clientId}
                        registeredBy={event.createdBy}
                        registeredDate={event.registrationDate}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        isReadOnly={event.isReadOnly}
                        isHealthNote={event.isHealthNote}
                      />
                    )
                  })}
                </ListItem>
              )
            })}
          </List>
        </>
      )}
    </Box>
  )
}

export default EventLog

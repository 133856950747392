import { Radgivare } from '@local/src/Common.types'

import { getAuthHeaders } from '../../Services/ApiServiceHelper'
import getConfig from '../../Utils/ConfigService'

import { IStudieAnmalanGetModel } from './types/StudieAnmalanGetModel.types'
import { IStudieAnmalanListItemGetModel } from './types/StudieAnmalanListGetModel.types'

const { API_URL } = getConfig()

const request = (appendUrl: string, method: string) => {
  const options = {
    headers: getAuthHeaders(),
    method,
  }
  return fetch(`${API_URL}/v1.0/${appendUrl}`, options)
}
const get = <T>(appendUrl: string) =>
  request(appendUrl, 'GET').then((res): Promise<T> => {
    if (res.status > 299) throw new Error()
    return res.json()
  })

const post = (appendUrl: string) =>
  request(appendUrl, 'POST').then((res): Promise<string> => {
    if (res.status > 299) throw new Error()
    return res.text()
  })

export const getStudieAnmalanListByCaseId = (
  arendeId: string
): Promise<IStudieAnmalanListItemGetModel[]> =>
  get<IStudieAnmalanListItemGetModel[]>(`kompetens/booking/arende/${arendeId}`)

export const getStudieAnmalan = (
  bookingId: string
): Promise<IStudieAnmalanGetModel> =>
  get<IStudieAnmalanGetModel>(`kompetens/booking/${bookingId}`)

export const approveStudieAnmalan = (bookingId: string, isApproved: boolean) =>
  post(
    `kompetens/booking/approvebooking/${bookingId}?isApproved=${isApproved.toString()}`
  )

export const getActiveAdvisors = (): Promise<Radgivare[]> =>
  get<Radgivare[]>(`/radgivare/aktivaradgivare`)

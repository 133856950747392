import { isNil, isEmpty } from 'ramda'
import { ISelectOption } from '@local/src/Common.types'

export const formatAmount = (numericAmount: number): string =>
  Math.round(numericAmount).toLocaleString('en').replace(/,/g, ' ')

interface IPerson {
  fornamn: string
  efternamn: string
}

export const filterOnName = (
  persons: IPerson[],
  searchValue: string
): IPerson[] => {
  if (!isNil(searchValue) && !isEmpty(searchValue)) {
    return persons.filter((person: IPerson) => {
      const fullName = `${person.fornamn} ${person.efternamn}`
      return fullName.toLowerCase().includes(searchValue.toLowerCase())
    })
  } else {
    return persons
  }
}

export const formatSSN = (SSN: string): string =>
  SSN
    ? `${SSN.slice(0, SSN.length - 4)}-${SSN.slice(SSN.length - 4, SSN.length)}`
    : SSN

export const findSelectOption = (
  inputValue: string | number,
  selectOptionlist: ISelectOption[]
): ISelectOption => {
  return selectOptionlist.find(
    ({ value }: ISelectOption) => value === inputValue
  )
}

export const isNotNullOrEmpty = (variable: unknown): boolean => {
  return !isNil(variable) && !isEmpty(variable)
}

/**
 * When called will smoothly scroll to the given reference.
 * @param ref - a reference to the DOM element
 * @param offset - optional, add a pixel offset to scroll above the selected ref.
 * @returns void
 */
export const scrollToRef = (
  ref: React.MutableRefObject<HTMLDivElement>,
  offset = 0
) => {
  if (!ref?.current) return
  const rect = ref.current.getBoundingClientRect()
  const documentTop = document.body.getBoundingClientRect().top
  if (rect.top < 0 || rect.bottom > window.innerHeight) {
    window.scrollTo({
      behavior: 'smooth',
      top: rect.top - documentTop - offset,
    })
  }
}

/**
 * When called will smoothly scroll to top of page.
 * @returns void
 */
export const scrollToTop = () => {
  const documentTop = document.body.getBoundingClientRect().top
  window.scrollTo({
    behavior: 'smooth',
    top: documentTop,
  })
}

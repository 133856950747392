import React from 'react'
import { Box, Link, ListItemText, Stack, Typography } from '@mui/material'
import { formatDateFromDateTime as formatDate } from '@local/src/Utils/DateTime'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import {
  orgCompanyLinkBuilder,
  orgProjectLinkBuilder,
} from '@local/src/basename'
import { getCurrentEnvironment } from '@local/src/Utils/helpers'
import getConfig from '@local/src/Utils/ConfigService'
import SetPreferredLanguage from '@local/src/Components/SetPreferredLanguage/SetPreferredLanguage'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import { OmfattningsbeslutStatus } from '@local/src/Views/Arenden/Arenden.types'
import { useHistory } from 'react-router-dom'
import { isAvslutatArende } from '@local/src/Utils/SubStatus'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { useGetHasRattTillAgeQuery } from '@local/src/Api/AgeArende/ageArendeApi'
import Loading from '@local/src/Components/Loading/Loading'

import { ICaseDetails } from '../../../Case.types'
import getDagarKvarDisplayText from '../../../Helpers/getDagarKvarDisplayText'
import Personnummer from '../Common/Personnummer/Personnummer'
import ArendeMarkningar from '../Common/ArendeMarkningar/ArendeMarkningar'

interface OmstallningsstodDetailsProps {
  arende: ICaseDetails
}

const OmstallningsstodDetails = ({
  arende,
}: OmstallningsstodDetailsProps): JSX.Element => {
  const history = useHistory()
  const { klient } = arende
  const { omstallningsArende } = arende
  const { anstallningsuppgifter, inkomstuppgifter, radgivning } =
    omstallningsArende
  const isAttesterad =
    arende.omfattningsbeslutStatus === OmfattningsbeslutStatus.Attesterad
  const isAvslutat = isAvslutatArende(radgivning.subStatus)
  const ageFlag = useGetHasRattTillAgeQuery({
    arendeId: arende.id,
    klientId: klient.id,
  })

  return (
    <Stack
      display="grid"
      gridTemplateColumns={{
        xs: '1fr',
        sm: '1fr 1fr',
        md: '1fr 1fr 1fr',
        lg: '1fr 1fr 1fr 1fr',
      }}
    >
      <Typography
        variant="body1"
        fontWeight="bold"
        gridColumn={{ xs: '1', sm: '1/-1' }}
      >
        Personinformation
      </Typography>
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Personnummer</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            <Personnummer
              personnummer={klient.personnummer}
              alder={klient.alder}
            />
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Attestdatum</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {formatDate(omstallningsArende.attesteringsDatum)}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Samtycke hälsa</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {klient.samtyckeHalsa ? 'Ja' : 'Nej'}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Bostadsadress</Typography>}
        secondary={
          <Typography
            variant="body1"
            color="text.secondary"
          >{`${klient.gatuadress}, ${klient.postnummer} ${klient.ort}\n${klient.kommun}, ${klient.lan}`}</Typography>
        }
      />
      {ageFlag.isLoading && ageFlag.isFetching ? (
        <Loading />
      ) : (
        <ListItemText
          disableTypography
          primary={<Typography variant="subtitle1">AGE</Typography>}
          secondary={
            <Typography variant="body1" color="text.secondary">
              {ageFlag.data}
            </Typography>
          }
        />
      )}
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Övriga ärenden</Typography>}
        secondary={
          arende.ovrigaArenden?.length > 0 ? (
            <Link
              variant="subtitle1"
              href="avslut"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                history.push(
                  history.location.pathname.replace(
                    history.location.pathname.slice(
                      history.location.pathname.lastIndexOf('/')
                    ),
                    '/avslut'
                  )
                )
                window.scrollTo({ top: 800, behavior: 'smooth' })
              }}
            >
              Ja
            </Link>
          ) : (
            <Typography variant="body1" color="text.secondary">
              Nej
            </Typography>
          )
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Team/AMO</Typography>}
        secondary={
          <Typography
            variant="body1"
            color="text.secondary"
          >{`${omstallningsArende.radgivning.team.namn}, ${omstallningsArende.radgivning.arbetsmarknadsomrade.namn}`}</Typography>
        }
      />

      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle1" display="flex" gap={1}>
            Rådgivningsdagar kvar
            {isAvslutat && (
              <InformationPopover
                iconSize="small"
                infoText="Korrekt antal rådgivningsdagar kvar visas när ärendet återöppnas. För att få aktuell information, kontakta KC."
                disablePadding
              />
            )}
          </Typography>
        }
        secondary={
          <Typography variant="body1" color="text.secondary">
            {isAttesterad
              ? getDagarKvarDisplayText({
                  orsakTillUppsagning: arende.omstallningsArende.orsakAnsokan,
                  antalKvarvarandeRadgivningsDagar:
                    arende.omstallningsArende.radgivning
                      .antalKvarvarandeRadgivningsDagar,
                  slutDatumRadgivning:
                    arende.omstallningsArende.radgivning.slutDatum,
                  radgivningsSubStatus:
                    arende.omstallningsArende.radgivning.subStatus,
                })
              : '-'}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Föredraget språk</Typography>}
        secondary={<SetPreferredLanguage disablePadding />}
      />
      <Box
        display="flex"
        flexDirection="column"
        mb={{ xs: 1.5, sm: 0 }}
        sx={{
          gridColumnStart: { xs: 1, sm: 2, md: 3, lg: 4 },
          gridRowStart: { xs: 'auto', sm: 2 },
          gridRowEnd: { xs: 'auto', sm: 5 },
        }}
      >
        <ArendeMarkningar
          arendeId={arende.id}
          arendeMarkningar={arende.arendeMarkningar}
        />
      </Box>
      <Typography
        variant="body1"
        fontWeight="bold"
        gridColumn={{ xs: '1', sm: '1/-1' }}
      >
        Anställningsuppgifter
      </Typography>

      <ListItemText
        primary="Arbetsställe"
        secondary={
          <LinkWithIcon
            icon={<LaunchIcon />}
            text={anstallningsuppgifter.foretag.namn}
            external
            href={orgCompanyLinkBuilder(
              getCurrentEnvironment(getConfig().API_URL),
              anstallningsuppgifter.foretag.id
            )}
          />
        }
      />
      <ListItemText
        sx={{ pr: 1, wordBreak: 'break-all' }}
        disableTypography
        primary={<Typography variant="subtitle1">Befattning</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {anstallningsuppgifter.befattning}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Anställningsdatum</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {formatDate(anstallningsuppgifter.anstallningsDatum)}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Anställningsgrad</Typography>}
        secondary={
          <Typography
            variant="body1"
            color="text.secondary"
          >{`${anstallningsuppgifter.anstallningsgrad}%`}</Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Projekt</Typography>}
        secondary={
          arende.omstallningsArende.projekt ? (
            <LinkWithIcon
              icon={<LaunchIcon />}
              text={`${arende.omstallningsArende.projekt.nummer} - ${arende.omstallningsArende.projekt.namn}`}
              external
              href={orgProjectLinkBuilder(
                getCurrentEnvironment(getConfig().API_URL),
                anstallningsuppgifter.foretag.id,
                arende.omstallningsArende.projekt.id
              )}
            />
          ) : (
            <Typography variant="body1" color="text.secondary">
              Inget projekt
            </Typography>
          )
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Totallön</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {formatAmount(inkomstuppgifter.totalLon)}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Uppsägningsdatum</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {formatDate(anstallningsuppgifter.uppsagningsDatum)}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Uppsägningsgrad</Typography>}
        secondary={
          <Typography
            variant="body1"
            color="text.secondary"
          >{`${anstallningsuppgifter.uppsagningsgrad}%`}</Typography>
        }
      />
      <ListItemText
        sx={{ pr: 1 }}
        disableTypography
        primary={
          <Typography variant="subtitle1">Orsak till uppsägning</Typography>
        }
        secondary={
          <Typography variant="body1" color="text.secondary">
            {omstallningsArende.orsakAnsokanDisplayText}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">Avgångsvederlag</Typography>}
        secondary={
          <Typography variant="body1" color="text.secondary">
            {anstallningsuppgifter.avgangsvederlagSlutDatum
              ? `Till: ${formatDate(anstallningsuppgifter.avgangsvederlagSlutDatum)}`
              : 'Nej'}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle1">Sista anställningsdag</Typography>
        }
        secondary={
          <Typography variant="body1" color="text.secondary">
            {formatDate(inkomstuppgifter.sistaDagMedLon)}
          </Typography>
        }
      />
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle1">Arbetstimmar/vecka</Typography>
        }
        secondary={
          <Typography
            variant="body1"
            color="text.secondary"
          >{`${anstallningsuppgifter.arbetstimmarPerVecka} timmar`}</Typography>
        }
      />
    </Stack>
  )
}

export default OmstallningsstodDetails

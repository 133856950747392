import React from 'react'
import { Alert } from '@mui/material'
import { HTMLMapper } from '@trr/html-mapper'
import getConfig from '@local/src/Utils/ConfigService'

interface ArendeInfoTextProps {
  content: string
}

const ArendeInfoText = ({ content }: ArendeInfoTextProps) => (
  <Alert severity="info" variant="outlined" sx={{ borderRadius: 2, order: 2 }}>
    <HTMLMapper
      body={content}
      mediaUrl={getConfig().MEDIA_URL}
      removeLastElementGutter
    />
  </Alert>
)

export default ArendeInfoText

import { Button, SxProps, Theme } from '@mui/material'
import React from 'react'

interface ClearFilterButtonProps {
  filtersActive: boolean
  clearFilter: () => void
  sx?: SxProps<Theme>
}

const ClearFilterButton = ({
  filtersActive,
  clearFilter,
  sx,
}: ClearFilterButtonProps) => {
  if (!filtersActive) {
    return <></>
  }

  return (
    <Button variant="text" size="small" onClick={() => clearFilter()} sx={sx}>
      Rensa filter
    </Button>
  )
}

export default ClearFilterButton

import { ListItem, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  Avslutshandelse,
  Bransch,
  amountsOfEmployees,
  jobFindingOptions,
  relativeOptions,
} from '@local/Views/Case/Avslut/Avslut.types'
import { formatDateFromDateTime } from '@local/Utils/DateTime'

import { getLabelByValue } from '../Common'
import { AvslutshandelseCardBase } from '../Common/AvslutshandelseCardBase'

export interface NaOaEmploymentDetails {
  companyName: string
  staffingCompany: string
  amountOfEmployees: string
  industry: string
  publicSector: string
  title: string
  isWhiteCollar: string
  relativeLevel: string
  relativeSalary: string
  weeklyHours: string
  employmentDate: string
  isTownAbroad: string
  town: string
  requiresMoving: string
  howClientFoundJob: string
}

export type NaOaEmploymentDetailsKey = keyof NaOaEmploymentDetails

interface NaOaAvslutshandelseProps {
  avslutshandelse: Avslutshandelse
  branscher: Bransch[]
}

export const NaOaAvslutshandelse = ({
  avslutshandelse,
  branscher,
}: NaOaAvslutshandelseProps) => {
  const [industry, setIndustry] = useState<string[]>([])

  const answers: NaOaEmploymentDetails = Object.fromEntries(
    avslutshandelse.avslutsFragorSvar?.map((entry) => [
      entry.key,
      entry.value,
    ]) || []
  ) as Record<NaOaEmploymentDetailsKey, string>

  useEffect(() => {
    setIndustry(
      branscher
        .filter((bransch: Bransch) => bransch.trrCode === answers.industry)
        .map((industry: Bransch) => industry.name)
    )
  }, [answers.industry, branscher])

  const howClientFoundJob = getLabelByValue(
    jobFindingOptions,
    answers.howClientFoundJob
  )
  const relativeLevel = getLabelByValue(relativeOptions, answers.relativeLevel)
  const amountOfEmployees = getLabelByValue(
    amountsOfEmployees,
    answers.amountOfEmployees
  )
  const relativeSalary = getLabelByValue(
    relativeOptions,
    answers.relativeSalary
  )

  return (
    <AvslutshandelseCardBase avslutshandelse={avslutshandelse}>
      <Typography variant={'h6'}>Arbetsgivaruppgifter</Typography>
      <ListItem>
        <ListItemText
          primary={'Arbetsgivare'}
          secondary={answers.companyName}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Bemanningsföretag'}
          secondary={answers.staffingCompany === 'true' ? 'Ja' : 'Nej'}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Antal anställda'}
          secondary={amountOfEmployees}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary={'Bransch'} secondary={industry} />
      </ListItem>
      <ListItem sx={{ marginBottom: 2 }}>
        <ListItemText
          primary={'Offentliga sektorn'}
          secondary={answers.publicSector === 'true' ? 'Ja' : 'Nej'}
        />
      </ListItem>
      <Typography variant={'h6'}>Anställningsuppgifter</Typography>
      <ListItem>
        <ListItemText primary={'Befattning'} secondary={answers.title} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Tjänsteman'}
          secondary={answers.isWhiteCollar === 'true' ? 'Ja' : 'Nej'}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary={'Befattningsnivå'} secondary={relativeLevel} />
      </ListItem>
      <ListItem>
        <ListItemText primary={'Löneförändring'} secondary={relativeSalary} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Timmar per vecka'}
          secondary={answers.weeklyHours}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Anställningsdatum'}
          secondary={formatDateFromDateTime(answers.employmentDate)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Stationeringsort'}
          secondary={
            answers.isTownAbroad === 'true' ? 'Utlandsort' : answers.town
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Utlandsort'}
          secondary={answers.isTownAbroad === 'true' ? 'Ja' : 'Nej'}
        />
      </ListItem>
      <ListItem sx={{ marginBottom: 2 }}>
        <ListItemText
          primary={'Omställningskunden behöver flytta'}
          secondary={answers.requiresMoving === 'true' ? 'Ja' : 'Nej'}
        />
      </ListItem>
      <Typography variant={'h6'}>Hur hittades anställningen?</Typography>
      <ListItem>
        <ListItemText primary={howClientFoundJob} />
      </ListItem>
    </AvslutshandelseCardBase>
  )
}

import { format, endOfDay, startOfDay } from 'date-fns'
import { sv } from 'date-fns/locale'

export const formatDateFromDateTime = (
  dateTime: string | Date,
  twoYearDigit = false
): string => {
  return twoYearDigit
    ? dateTime && format(new Date(dateTime), 'yy-MM-dd')
    : dateTime && format(new Date(dateTime), 'yyyy-MM-dd')
}

export const midnightToday = startOfDay(new Date())
export const justBeforeMidnightTomorrow = endOfDay(new Date())

export const formatTimeFromDateTime = (dateTime: string | Date): string => {
  return `${format(new Date(dateTime), 'HH:mm')}`
}

export const formatDateWithMonthStringFromDateTime = (
  dateTime: string | Date,
  includeYear = false
): string => {
  return `${format(new Date(dateTime), 'd')} ${format(
    new Date(dateTime),
    'MMM',
    { locale: sv }
    // When using locale it returns some months with a '.' in the end
  ).replace(
    '.',
    ''
  )}${includeYear ? ` ${format(new Date(dateTime), 'yyyy')}` : ''}`
}

import React from 'react'
import { FetchingState } from '@local/src/Common.types'
import { Box, CircularProgress, List } from '@mui/material'

import DocumentCard from './DocumentCard'
import { IDocument } from './Documents'

interface IDocumentList {
  documents: IDocument[]
  arendeId: string
  fetchDocument(
    arendeId: string,
    documentId: string,
    fileName: string,
    type: string,
    isFetchDocumentUrlV2Enabled: boolean
  ): void
  deleteDocument(arendeId: string, documentId: string): void
  deleteDocumentState: FetchingState
}

const DocumentList = ({
  documents = [],
  arendeId,
  fetchDocument,
  deleteDocument,
  deleteDocumentState,
}: IDocumentList): JSX.Element => {
  if (deleteDocumentState === FetchingState.LOADING) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <CircularProgress data-testid={'isLoading'} />
      </Box>
    )
  }

  return (
    <List>
      {documents.map((document) => (
        <DocumentCard
          key={document.dokumentId}
          id={document.dokumentId}
          hasHealthInformation={document.isHealthRelated}
          documentName={document.fileName}
          uploadedDate={document.timeStamp}
          uploadedBy={document.uploadedBy}
          arendeId={arendeId}
          fetchDocument={fetchDocument}
          deleteDocument={deleteDocument}
          type={document.type}
        />
      ))}
    </List>
  )
}
export default DocumentList

import { ICreateEmailForm } from '@local/src/Blocks/CreateEmail/CreateEmail.types'
import { FetchingState, ISelectOption } from 'src/Common.types'

import { ICaseListItem } from '../ActionBasedCaseItem/ActionBasedCaseItem.types'

export interface ICaseList {
  facetedHits: number
  cases: ICaseListItem[]
  orderBy: string[]
  page: number
  pageSize: number
  searchQuery: string
  setCasesPage(page: number, total: number): void
  setPageSize(pageSize: number): void
  setOrderBy(orderBy: string[]): void
  setSearchText(query: string): void
  totalHits: number
  totalPages: number
  createEmail: (emailForm: ICreateEmailForm) => void
  createEmailState: FetchingState
  fetchingCaseListState: FetchingState
}

export const allSortingOptions: ISelectOption[] = [
  {
    value: 'StudiestodAnsokanSenastUppdaterad desc',
    label: 'Studier - nyligen uppdaterad',
  },
  { value: 'SistaSvarsDatum asc', label: 'Begäran om yttrande' },
  { value: 'NastaMote asc', label: 'Nästa möte' },
  { value: 'AttesteringsDatum desc', label: 'Attestdatum - senaste' },
  { value: 'AttesteringsDatum asc', label: 'Attestdatum - äldsta' },
  { value: 'SenasteKontakt desc', label: 'Kontakt - senaste' },
  { value: 'SenasteKontakt asc', label: 'Kontakt - äldsta' },
  { value: 'KlientFornamn asc', label: 'Förnamn A - Ö' },
  { value: 'KlientFornamn desc', label: 'Förnamn Ö - A' },
  { value: 'KlientEfternamn asc', label: 'Efternamn A - Ö' },
  { value: 'KlientEfternamn desc', label: 'Efternamn Ö - A' },
  { value: 'Befattning asc', label: 'Befattning A - Ö' },
  { value: 'Befattning desc', label: 'Befattning Ö - A' },
  { value: 'ForetagNamn asc', label: 'Företag A - Ö' },
  { value: 'ForetagNamn desc', label: 'Företag Ö - A' },
  { value: 'RadgivareNamn asc', label: 'Rådgivare förnamn A - Ö' },
  { value: 'RadgivareNamn desc', label: 'Rådgivare förnamn Ö - A' },
  { value: 'RadgivningSlutDatum asc', label: 'Dagar kvar - stigande' },
  { value: 'RadgivningSlutDatum desc', label: 'Dagar kvar - fallande' },
]

export const advisorSortingOptions: ISelectOption[] = [
  {
    value: 'StudiestodAnsokanSenastUppdaterad desc',
    label: 'Studier - nyligen uppdaterad',
  },
  { value: 'SistaSvarsDatum asc', label: 'Begäran om yttrande' },
  { value: 'NastaMote asc', label: 'Nästa möte' },
  { value: 'AttesteringsDatum desc', label: 'Attestdatum - senaste' },
  { value: 'AttesteringsDatum asc', label: 'Attestdatum - äldsta' },
  { value: 'SenasteKontakt desc', label: 'Kontakt - senaste' },
  { value: 'SenasteKontakt asc', label: 'Kontakt - äldsta' },
  { value: 'KlientFornamn asc', label: 'Förnamn A - Ö' },
  { value: 'KlientFornamn desc', label: 'Förnamn Ö - A' },
  { value: 'KlientEfternamn asc', label: 'Efternamn A - Ö' },
  { value: 'KlientEfternamn desc', label: 'Efternamn Ö - A' },
  { value: 'Befattning asc', label: 'Befattning A - Ö' },
  { value: 'Befattning desc', label: 'Befattning Ö - A' },
  { value: 'ForetagNamn asc', label: 'Företag A - Ö' },
  { value: 'ForetagNamn desc', label: 'Företag Ö - A' },
  { value: 'RadgivningSlutDatum asc', label: 'Dagar kvar - stigande' },
  { value: 'RadgivningSlutDatum desc', label: 'Dagar kvar - fallande' },
]

export const allaArendenTabOrderByOptions: ISelectOption[] = [
  {
    value: 'StudiestodAnsokanSenastUppdaterad desc',
    label: 'Studier - nyligen uppdaterad',
  },
  { value: 'SistaSvarsDatum asc', label: 'Begäran om yttrande' },
  { value: 'NastaMote asc', label: 'Nästa möte' },
  { value: 'AttesteringsDatum desc', label: 'Attestdatum - senaste' },
  { value: 'AttesteringsDatum asc', label: 'Attestdatum - äldsta' },
  { value: 'SenasteKontakt desc', label: 'Kontakt - senaste' },
  { value: 'SenasteKontakt asc', label: 'Kontakt - äldsta' },
  { value: 'KlientFornamn asc', label: 'Förnamn A - Ö' },
  { value: 'KlientFornamn desc', label: 'Förnamn Ö - A' },
  { value: 'KlientEfternamn asc', label: 'Efternamn A - Ö' },
  { value: 'KlientEfternamn desc', label: 'Efternamn Ö - A' },
  { value: 'Befattning asc', label: 'Befattning A - Ö' },
  { value: 'Befattning desc', label: 'Befattning Ö - A' },
  { value: 'ForetagNamn asc', label: 'Företag A - Ö' },
  { value: 'ForetagNamn desc', label: 'Företag Ö - A' },
]

export const kompetensstodTabOrderByOptions = allaArendenTabOrderByOptions

export const omstallningTabOrderByOptions = allSortingOptions

export const dinaArendenTabOrderByOptions = advisorSortingOptions

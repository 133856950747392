import React, { useEffect, useState } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Typography,
  styled,
  AccordionDetailsProps,
  AccordionProps,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownIcon color="primary" />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    flexGrow: 0,
  },
  justifyContent: 'flex-start',
  padding: 0,
  gap: theme.spacing(1),
  alignSelf: 'baseline',
}))

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  padding: 0,
}))

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  display: 'flex',
  flexDirection: 'column',
  '&:before': {
    display: 'none',
  },
}))

interface AccordionBoxProps {
  label: string
  children?: React.ReactNode
  expanded?: boolean
  onToggle?: (open: boolean) => void
  buttonPlacement?: 'left' | 'right' | 'center'
  buttonOnBottom?: boolean
}

const AccordionBox: React.FC<AccordionBoxProps> = ({
  label,
  children,
  expanded = false,
  onToggle,
  buttonPlacement = 'left',
  buttonOnBottom = false,
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded)

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    setIsExpanded(expanded)
    onToggle?.(expanded)
  }

  const setButtonPlacement = () => {
    switch (buttonPlacement) {
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      case 'center':
        return 'center'
      default:
        return 'flex-start'
    }
  }

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      sx={{ flexDirection: buttonOnBottom ? 'column-reverse' : 'column' }}
    >
      <AccordionSummary sx={{ alignSelf: setButtonPlacement() }}>
        <Typography variant="body1" color="primary.main" display="flex" gap={1}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default AccordionBox

import React from 'react'
import ChipWithPopper from '@local/src/Components/Chips/ChipWithPopover/ChipWithPopper'
import { useLazyGetFacetsForPropertyQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useToggleFilterOption } from '@local/src/Hooks'

import StatusFilter from '../FilterTypes/StatusFilter'
import { ArbetsuppgiftFilter } from '../../types'

interface StatusChipProps {
  filter: Partial<ArbetsuppgiftFilter>
  selectedStatuses: string[]
  onChange: (key: keyof ArbetsuppgiftFilter, values: string[]) => void
}

const StatusChip = ({
  filter,
  selectedStatuses = [],
  onChange,
}: StatusChipProps) => {
  const [fetchFacets, data] = useLazyGetFacetsForPropertyQuery()
  const statusKey = 'statusar'
  const { toggleFilterOption, handleClear } = useToggleFilterOption(
    selectedStatuses,
    statusKey,
    onChange
  )

  return (
    <ChipWithPopper
      label="Status"
      count={selectedStatuses.length}
      onClear={handleClear}
      width={360}
      onOpen={() => void fetchFacets({ key: 'Status', filter })}
      disableClear={selectedStatuses.length === 0}
    >
      <StatusFilter
        statuses={data?.data?.Status}
        selectedStatuses={selectedStatuses}
        handleCheckbox={toggleFilterOption}
      />
    </ChipWithPopper>
  )
}

export default StatusChip

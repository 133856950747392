import { formatSSN } from '@local/src/Utils/smallfuncs'
import React, { useState } from 'react'
import { Button } from '@mui/material'

interface PersonnummerProps {
  personnummer: string
  alder?: number | string
}
const Personnummer = ({
  personnummer,
  alder,
}: PersonnummerProps): JSX.Element => {
  const [showFourLastDigits, setShowFourLastDigits] = useState(false)

  return (
    <span>
      {formatSSN(personnummer).slice(0, 9)}
      <Button
        variant="text"
        onClick={() => setShowFourLastDigits(!showFourLastDigits)}
        sx={{ p: 0, m: 0, display: 'contents', userSelect: 'text' }}
        disableRipple
      >
        {showFourLastDigits ? formatSSN(personnummer).slice(9, 13) : 'XXXX'}
      </Button>
      , {alder} år
    </span>
  )
}

export default Personnummer

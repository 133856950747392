import { useContext } from 'react'
import { AppShellDataProps } from '@trr/app-shell-data'

import { Context } from './Context'

/**
 * @deprecated This is only used by the MicroFrontendLoader and should not be used in new code. Use the specific hooks instead from @trr/app-shell-data.
 */
export const useAppShellData = <TResult = unknown>(
  selector: (data: AppShellDataProps) => TResult
): TResult => {
  const data = useContext(Context)
  return selector(data)
}

import React, { useState } from 'react'
import { FetchingState } from '@local/src/Common.types'
import { Button } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'

import CreateEmailModal from './CreateEmailModal/CreateEmailModal'
import { ICreateEmailForm } from './CreateEmail.types'

export interface ICreateEmailProps {
  numberOfEmailRecipients: number
  createEmail: (emailForm: ICreateEmailForm) => void
  createEmailState: FetchingState
}

const CreateEmail: React.FunctionComponent<
  React.PropsWithChildren<ICreateEmailProps>
> = ({ numberOfEmailRecipients, createEmail, createEmailState }) => {
  const [isModalsOpen, setIsModalsOpen] = useState(false)

  const openModal = () => {
    setIsModalsOpen(true)
  }

  const closeModal = () => {
    setIsModalsOpen(false)
  }

  const handleCreateEmail = (emailForm: ICreateEmailForm) => {
    createEmail(emailForm)
    closeModal()
  }

  return (
    numberOfEmailRecipients > 0 && (
      <>
        <Button variant="outlined" startIcon={<MailIcon />} onClick={openModal}>
          Skapa gruppmejl
        </Button>
        <CreateEmailModal
          isOpen={isModalsOpen}
          closeModal={closeModal}
          createEmail={handleCreateEmail}
          createEmailState={createEmailState}
          numberOfEmailRecipients={numberOfEmailRecipients}
        />
      </>
    )
  )
}

export default CreateEmail

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { Alert, Divider, SelectChangeEvent, Typography } from '@mui/material'
import Loading from '@local/src/Components/Loading/Loading'
import { FetchingState } from '@local/src/Common.types'

import {
  avslutaArendeStateSelector,
  avslutskoderSelector,
  fetchingAvslutskoderStateSelector,
} from '../../Avslut.selectors'
import { fetchAvslutskoder } from '../../Avslut.actions'
import { AvslutOption, Avslutskod } from '../../Avslut.types'
import Anstallning from '../AvslutForms/Anstallning/Anstallning'
import StudierLangreTid from '../AvslutForms/StudierLangreTid/StudierLangreTid'
import AvslutWithoutDate from '../AvslutForms/AvslutWithoutDate/AvslutWithoutDate'
import AvslutWithDate from '../AvslutForms/AvslutWithDate/AvslutWithDate'
import AvslutWithNumberOfMonths from '../AvslutForms/AvslutWithNumberOfMonths/AvslutWithNumberOfMonths'
import EgenRorelse from '../AvslutForms/EgenRorelse/EgenRorelse'

export const requireAvslutsfragor = [
  Avslutskod.EgenRorelse,
  Avslutskod.NyAnstallning,
  Avslutskod.StudierLangreTid,
  Avslutskod.OvrigAnstallning,
]

interface AvslutBaseFormProps {
  arendeId: string
  setIsAvslutsformOpen: (isOpen: boolean) => void
}

const AvslutBaseForm = ({
  arendeId,
  setIsAvslutsformOpen,
}: AvslutBaseFormProps) => {
  const dispatch = useDispatch()
  const avslutskoder = useSelector(avslutskoderSelector)
  const avslutskoderLoading = useSelector(fetchingAvslutskoderStateSelector)
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const [selectedAvslutskod, setSelectedAvslutskod] =
    useState<AvslutOption>(null)

  useEffect(() => {
    if (avslutaArendeState === FetchingState.SUCCESS) {
      setSelectedAvslutskod(null)
      setIsAvslutsformOpen(false)
    }
  }, [avslutaArendeState])

  useEffect(() => {
    dispatch(fetchAvslutskoder(arendeId))
    setSelectedAvslutskod(null)

    return () => {
      setIsAvslutsformOpen(false)
    }
  }, [arendeId])

  const selectedAvslutskodOnChange = (event: SelectChangeEvent) => {
    setSelectedAvslutskod(
      avslutskoder.find((avslutskod) => avslutskod.key === event.target.value)
    )
    setIsAvslutsformOpen(true)
  }

  const getOptionLabelInfo = (avslutskod: AvslutOption) => {
    switch (avslutskod.key) {
      case Avslutskod.NyAnstallning:
        return ' - Tillsvidare'
      case Avslutskod.OvrigAnstallning:
        return ' - Visstid'
      default:
        return ''
    }
  }

  if (avslutskoderLoading === FetchingState.LOADING) {
    return <Loading />
  }

  if (avslutskoderLoading === FetchingState.ERROR) {
    return <Alert severity="error">Det gick inte att hämta avslutskoder.</Alert>
  }

  return (
    <>
      <Typography variant="h6" mb={2}>
        Avslut du kan göra på detta ärende
      </Typography>
      <Dropdown
        label="Välj avslut"
        maxWidth="480px"
        options={avslutskoder}
        getOptionLabel={(value: AvslutOption) =>
          `${value.description} (${value.key}) ${getOptionLabelInfo(value)}`
        }
        getOptionValue={(value: AvslutOption) => value.key}
        value={selectedAvslutskod?.key}
        onChange={selectedAvslutskodOnChange}
      />
      <Divider sx={{ mt: 5, mb: 5 }} />
      {selectedAvslutskod && (
        <>
          {selectedAvslutskod.key === Avslutskod.NyAnstallning && (
            <Anstallning
              key="NA"
              arendeId={arendeId}
              avslutskod={Avslutskod.NyAnstallning}
              requireLosningStartdatum={
                selectedAvslutskod.requireLosningStartdatum
              }
              title="Ny anställning - Tillsvidare"
              description="Avslutsdatum är tidigast det datum då omställningskunden undertecknar sitt anställningskontrakt.
              Det styr även beräkningen av omställningskundens kvarvarande tid för omställningsstöd.

              Om omställningskunden behöver fortsatt stöd i form av rådgivning eller deltar i aktiviteter på plats ska istället sista dag för detta anges som avslutsdatum. Dock senast när anställningen påbörjas."
            />
          )}

          {selectedAvslutskod.key === Avslutskod.OvrigAnstallning && (
            <Anstallning
              key="OA"
              arendeId={arendeId}
              avslutskod={Avslutskod.OvrigAnstallning}
              title="Övrig anställning - Visstid"
              requireLosningStartdatum={
                selectedAvslutskod.requireLosningStartdatum
              }
              description="Avslutsdatum är tidigast det datum då omställningskunden undertecknar sitt anställningskontrakt.
              Det styr även beräkningen av omställningskundens kvarvarande tid för omställningsstöd.

              Om omställningskunden behöver fortsatt stöd i form av rådgivning eller deltar i aktiviteter på plats ska istället sista dag för detta anges som avslutsdatum. Dock senast när anställningen påbörjas."
            />
          )}

          {selectedAvslutskod.key === Avslutskod.EgenRorelse && (
            <EgenRorelse title="Egen rörelse" arendeId={arendeId} />
          )}

          {selectedAvslutskod.key === Avslutskod.StudierLangreTid && (
            <StudierLangreTid
              arendeId={arendeId}
              requireLosningStartdatum={
                selectedAvslutskod.requireLosningStartdatum
              }
            />
          )}

          {!requireAvslutsfragor.includes(selectedAvslutskod.key) &&
            selectedAvslutskod.provideDate && (
              <AvslutWithDate
                arendeId={arendeId}
                avslutskod={selectedAvslutskod.key}
                title={selectedAvslutskod.description}
              />
            )}

          {!requireAvslutsfragor.includes(selectedAvslutskod.key) &&
            selectedAvslutskod.requireNumberOfMonths && (
              <AvslutWithNumberOfMonths
                arendeId={arendeId}
                avslutskod={selectedAvslutskod.key}
                title={selectedAvslutskod.description}
                minMonths={selectedAvslutskod.requireNumberOfMonthsMin}
                maxMonths={selectedAvslutskod.requireNumberOfMonthsMax}
              />
            )}

          {!selectedAvslutskod.provideDate &&
            !selectedAvslutskod.requireNumberOfMonths && (
              <AvslutWithoutDate
                arendeId={arendeId}
                avslutskod={selectedAvslutskod.key}
                title={selectedAvslutskod.description}
                requireLosningStartdatum={
                  selectedAvslutskod.requireLosningStartdatum
                }
              />
            )}
        </>
      )}
    </>
  )
}

export default AvslutBaseForm

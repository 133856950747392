import { useSelector } from 'react-redux'

import { ArendeTyp } from '../Views/Arenden/Arenden.types'
import { caseDetailsSelector } from '../Views/Case/Case.selectors'

const useIsUndantagsgrupp = (): boolean => {
  const arende = useSelector(caseDetailsSelector)

  if (arende.arendeTyp === ArendeTyp.Omstallning) {
    return arende.omstallningsArende.anstallningsuppgifter.undantagsgrupp
  }

  if (arende.arendeTyp === ArendeTyp.Kompetensstod) {
    return arende.anstallningsArende.anstallningsuppgifter.undantagsgrupp
  }
}

export { useIsUndantagsgrupp }

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purpleDark": `#411782`,
	"purple100": `#6222c3`,
	"purple80": `#732fdb`,
	"purple60": `#8a51e1`,
	"purple40": `#a77de8`,
	"purple20": `#d4c1ee`,
	"purple10": `#f2edf8`,
	"green100": `#089188`,
	"green80": `#0bc1b5`,
	"green60": `#26d9cd`,
	"green40": `#50e2d9`,
	"green20": `#b5ede9`,
	"green10": `#e3f8f6`,
	"yellow100": `#ffc700`,
	"yellow80": `#ffe433`,
	"yellow60": `#ffeb66`,
	"yellow40": `#fff5b2`,
	"yellow20": `#fdf9e2`,
	"yellow10": `#fdf9e2`,
	"blue100": `#1437b8`,
	"blue80": `#264cd9`,
	"blue60": `#476aeb`,
	"blue40": `#7d94e8`,
	"blue20": `#bfcaf2`,
	"blue10": `#edf0fd`,
	"orange100": `#cc4900`,
	"orange80": `#e56319`,
	"orange60": `#f57f3d`,
	"orange40": `#fb946a`,
	"orange20": `#fdcab5`,
	"orange10": `#fff1eb`,
	"rose100": `#bf072f`,
	"rose80": `#e51946`,
	"rose60": `#eb476b`,
	"rose40": `#f07590`,
	"rose20": `#f9b9c7`,
	"rose10": `#fdedf0`,
	"petrol100": `#0a5c56`,
	"petrol80": `#0f8a82`,
	"petrol60": `#0bc1b5`,
	"petrol40": `#26d9cd`,
	"petrol20": `#81e4de`,
	"petrol10": `#e3f8f6`,
	"gray0": `#1a1a1a`,
	"gray1": `#4c4c4c`,
	"gray2": `gray`,
	"gray3": `#999`,
	"gray4": `#b3b3b3`,
	"gray5": `#ccc`,
	"gray6": `#f2f2f2`,
	"white": `#fff`,
	"black": `#000`,
	"gray": `#1a1a1a`,
	"textColor": `#1a1a1a`,
	"disabledColor": `#f2f2f2`,
	"letterSpacingHeading": `-0.3px`,
	"linkColor": `#6222c3`
};
export default ___CSS_LOADER_EXPORT___;

import { IconButton } from '@mui/material'
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRowId,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PushPinIcon from '@mui/icons-material/PushPin'

interface CustomDetailPanelButtonProps {
  id: GridRowId
  isExpanded: boolean
  onPinClick: () => void
}

const CustomDetailPanelButton = ({
  id,
  isExpanded,
  onPinClick,
}: CustomDetailPanelButtonProps) => {
  const apiRef = useGridApiContext()

  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  )
  const hasDetail = React.isValidElement(contentCache[id])

  if (!hasDetail) {
    return (
      <IconButton
        size="small"
        tabIndex={-1}
        aria-label="Pinned"
        onClick={onPinClick}
      >
        <PushPinIcon fontSize="inherit" sx={{ transform: 'rotateZ(33deg)' }} />
      </IconButton>
    )
  }
  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Stäng' : 'Öppna'}
    >
      <ExpandMoreIcon
        sx={(theme) => ({
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        })}
        fontSize="inherit"
      />
    </IconButton>
  )
}

export default CustomDetailPanelButton

import { StartklarFilter } from '../Startklar.types'

export const getQueryParameters = (
  filter: Partial<StartklarFilter>
): string => {
  // returns array containing [key, value]
  const queryParameters = Object.entries(filter).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.filter((item) => item).map((item) => [key, item])
    } else if (value && typeof value === 'object') {
      return Object.entries(value)
        .filter(([_, subValue]) => subValue)
        .map(([subKey, subValue]) => [subKey, subValue as string])
    } else if (value) {
      return [[key, value as string]]
    }
    return []
  })

  const params = new URLSearchParams()
  queryParameters.forEach((parameter) => {
    params.append(parameter[0], parameter[1])
  })

  return `?${params.toString()}`
}

import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { useGetRadgivarTeamsQuery } from '@local/src/Api/Team/teamApi'
import { useGetAktivaRadgivareQuery } from '@local/src/Api/Radgivare/radgivareApi'
import Loading from '@local/src/Components/Loading/Loading'
import { useIsSmallScreen } from '@local/src/Hooks'
import MobileDrawer from '@local/src/Components/MobileDrawer/MobileDrawer'
import { Team } from '@local/src/Common.types'
import { buildFordelningsteamDropdownOptions } from '@local/src/Utils/helpers/fordelningsTeamDropdownHelpers/fordelningsTeamDropdownHelpers'

import RadgivarStatistikTable from './RadgivarStatistikTable/RadgivarStatistikTable'

interface RadgivarStatistikProps {
  selectedRadgivarteam: Team
  onRadgivarteamChanged: (team: Team) => void
}

const RadgivarStatistik = ({
  selectedRadgivarteam,
  onRadgivarteamChanged,
}: RadgivarStatistikProps) => {
  const isMobile = useIsSmallScreen()

  const [selectedRadgivarTeam, setSelectedRadgivarTeam] =
    useState(selectedRadgivarteam)

  const { data: radgivarTeam } = useGetRadgivarTeamsQuery()
  const { data: radgivareList, isFetching: isFetchingRadgivareList } =
    useGetAktivaRadgivareQuery(selectedRadgivarTeam?.id, {
      skip: selectedRadgivarTeam === null || selectedRadgivarTeam === undefined,
    })

  useEffect(() => {
    setSelectedRadgivarTeam(selectedRadgivarteam)
  }, [selectedRadgivarteam])

  useEffect(() => {
    onRadgivarteamChanged(selectedRadgivarTeam)
  }, [selectedRadgivarTeam])

  const radgivarteamDropdown = (
    <Box width="132px">
      <Dropdown
        customOptions={buildFordelningsteamDropdownOptions(radgivarTeam)}
        getOptionLabel={(team: Team) => team.namn}
        getOptionValue={(team: Team) => team.id}
        value={selectedRadgivarTeam?.id}
        label="Rådgivarteam"
        size="small"
        onChange={(event) =>
          setSelectedRadgivarTeam(
            radgivarTeam.find((team) => team.id === event.target.value)
          )
        }
      />
    </Box>
  )

  if (isMobile) {
    return (
      <MobileDrawer buttonText="Rådgivarstatistik" drawerHeader="Rådgivare">
        {radgivarTeam && radgivarteamDropdown}
        {isFetchingRadgivareList ? (
          <Loading />
        ) : (
          <RadgivarStatistikTable radgivareList={radgivareList} />
        )}
      </MobileDrawer>
    )
  }

  return (
    <Paper
      sx={{
        backgroundColor: 'surface.blue',
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        minWidth: '304px',
        position: 'sticky',
        top: 8,
        maxHeight: '100vh',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Rådgivare</Typography>
        {radgivarTeam && radgivarteamDropdown}
      </Box>
      {isFetchingRadgivareList ? (
        <Loading />
      ) : (
        <RadgivarStatistikTable radgivareList={radgivareList} />
      )}
    </Paper>
  )
}

export default RadgivarStatistik

import React from 'react'
import ChipWithPopper from '@local/src/Components/Chips/ChipWithPopover/ChipWithPopper'
import { useLazyGetFacetsForPropertyQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useToggleFilterOption } from '@local/src/Hooks'

import { ArbetsuppgiftCategoryFacet, ArbetsuppgiftFilter } from '../../types'
import CategoryFilter from '../FilterTypes/CategoryFilter'

interface CategoryChipProps {
  filter: Partial<ArbetsuppgiftFilter>
  initialCategories: ArbetsuppgiftCategoryFacet[]
  selectedCategories: string[]
  onChange: (key: keyof ArbetsuppgiftFilter, values: string[]) => void
}

const CategoryChip = ({
  filter,
  initialCategories,
  selectedCategories = [],
  onChange,
}: CategoryChipProps) => {
  const categoryKey = 'uppgiftsTyper'
  const { toggleFilterOption, handleClear } = useToggleFilterOption(
    selectedCategories,
    categoryKey,
    onChange
  )
  const [fetchFacets, data] = useLazyGetFacetsForPropertyQuery()

  return (
    <ChipWithPopper
      count={selectedCategories.length}
      label="Kategori"
      onClear={handleClear}
      width={360}
      disableClear={selectedCategories.length === 0}
      onOpen={() => void fetchFacets({ key: 'Category', filter })}
    >
      <CategoryFilter
        categories={data?.data?.Category}
        initialCategories={initialCategories}
        selectedCategories={selectedCategories}
        handleCheckbox={toggleFilterOption}
      />
    </ChipWithPopper>
  )
}

export default CategoryChip

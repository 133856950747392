import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { medarbetareBasepath } from '@local/src/basename'
import { useCurrentArendeListPathname, useGuidFromUrl } from '@local/src/Hooks'
import Modal from '@local/src/Components/Modal/Modal'
import { Box, SelectChangeEvent, Typography } from '@mui/material'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import Loading from '@local/src/Components/Loading/Loading'
import { useGetFordelningsteamsQuery } from '@local/src/Api/Team/teamApi'
import { useChangeFordelningsteamMutation } from '@local/src/Api/Fordelning/fordelningApi'
import { buildFordelningsteamDropdownOptions } from '@local/src/Utils/helpers/fordelningsTeamDropdownHelpers/fordelningsTeamDropdownHelpers'
import { Team } from '@local/src/Common.types'

interface ChangeTeamModalProps {
  active: boolean
  teamId: string
  close(): void
}

const ChangeTeamModal = ({
  active,
  teamId,
  close,
}: ChangeTeamModalProps): JSX.Element => {
  const { data: teams } = useGetFordelningsteamsQuery()
  const [changeTeam, { isLoading, isSuccess }] =
    useChangeFordelningsteamMutation()

  const [selectedTeam, setSelectedTeam] = useState<Team>(
    teams && teamId ? teams?.find(({ id }) => teamId === id) : undefined
  )
  const [error, setError] = useState<boolean>(false)
  const arendeId = useGuidFromUrl()
  const history = useHistory()
  const currentArendeListPathname = useCurrentArendeListPathname()

  const onConfirm = () => {
    if (isLoading || !selectedTeam) {
      setError(true)
    } else {
      setError(false)
      changeTeam({ arendeId, teamId: selectedTeam.id })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      history.push(`${medarbetareBasepath}${currentArendeListPathname}`)
    }
  }, [isSuccess])

  useEffect(() => {
    !active && setSelectedTeam(undefined)
    setSelectedTeam(teams?.find(({ id }) => teamId === id))
  }, [active, teams, teamId])

  return (
    <Modal
      open={active}
      label="Byt team"
      onCancel={close}
      onConfirm={onConfirm}
      confirmText="Byt"
      disableSubmit={isLoading}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box paddingTop={1}>
          <Dropdown
            label="Välj team"
            value={selectedTeam?.id || ''}
            error={error}
            errorText="Välj ett alternativ"
            options={teams}
            customOptions={buildFordelningsteamDropdownOptions(teams)}
            getOptionLabel={(team: Team) => team.namn}
            getOptionValue={(team: Team) => team.id}
            onChange={(event: SelectChangeEvent) =>
              setSelectedTeam(
                teams?.find(({ id }) => event.target.value === id)
              )
            }
          />
          <Typography variant="body1" paddingTop={2}>
            När du byter team kommer ärendet att skickas till fördelning och
            tilldelas en rådgivare i det teamet.
          </Typography>
        </Box>
      )}
    </Modal>
  )
}

export default ChangeTeamModal

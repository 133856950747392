import React from 'react'
import { Typography, TypographyVariant } from '@mui/material'

interface MuiIconWithTextProps {
  text: string | JSX.Element
  icon: JSX.Element
  role?: string
  variant: TypographyVariant
}

const MuiIconWithText = ({
  text,
  icon,
  role = '',
  variant,
}: MuiIconWithTextProps): JSX.Element => {
  return (
    <Typography
      role={role}
      variant={variant}
      sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
    >
      {icon}
      {text}
    </Typography>
  )
}
export default MuiIconWithText

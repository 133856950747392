import React from 'react'
import { useIsSmallScreen } from '@local/src/Hooks'
import { useSelector } from 'react-redux'
import { HTMLMapper } from '@trr/html-mapper'
import {
  infoTextSelector,
  omfattningsbeslutStatusSelector,
  paketSelector,
} from '@local/src/Views/Case/Case.selectors'
import {
  IOmfattningspaket,
  ITjanst,
  Omfattningspaket,
} from '@local/src/Views/Case/Case.types'
import { OmfattningsbeslutStatus } from '@local/src/Views/Arenden/Arenden.types'
import { Card, Stack, Typography } from '@mui/material'
import AccordionBox from '@local/src/Components/AccordionBox/AccordionBox'
import getConfig from '@local/src/Utils/ConfigService'

import ArendeInfoText from './ArendeInfoText/ArendeInfoText'

export const removeAGE = (paketLista: IOmfattningspaket[]) =>
  paketLista.filter((p) => !p.namn.toUpperCase().includes('AGE'))

export const OmfattningspaketBlock = () => {
  const isMobile = useIsSmallScreen({ onlyMobile: true })
  const rawPaketlista = useSelector(paketSelector)
  const paketlista = removeAGE(rawPaketlista)
  const infoText = useSelector(infoTextSelector)
  const omfattningsbeslutStatus = useSelector(omfattningsbeslutStatusSelector)
  const isAttesterad =
    omfattningsbeslutStatus === OmfattningsbeslutStatus.Attesterad

  const paketStyleDict: {
    [key in Omfattningspaket]: { backgroundColor: string; order: number }
  } = {
    Studiestöd: { backgroundColor: 'surface.yellow', order: 2 },
    Kompetensstöd: { backgroundColor: 'surface.blue', order: 1 },
    'Kompetensstöd Bas': { backgroundColor: 'surface.pink', order: 1 },
    Omställningsstöd: { backgroundColor: 'surface.green', order: 1 },
    'Omställningsstöd Bas': { backgroundColor: 'surface.pink', order: 1 },
    'Omställningsstöd Risk': { backgroundColor: 'surface.pink', order: 1 },
  }

  const accordionWrapperOnMobile = (children: JSX.Element) =>
    isMobile && isAttesterad ? (
      <Card sx={{ pl: 3, pr: 3 }}>
        <AccordionBox label="Omfattningspaket">{children}</AccordionBox>
      </Card>
    ) : (
      children
    )

  return accordionWrapperOnMobile(
    <>
      {isAttesterad && (
        <Stack display="flex" gap={3} marginBottom={3}>
          {paketlista.map((p) => (
            <Card
              key={p.namn}
              variant="outlined"
              sx={{
                backgroundColor: paketStyleDict[p.namn].backgroundColor,
                order: paketStyleDict[p.namn].order,
                p: 3,
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6">{p.namn}</Typography>
              {p.produkt.tjanster.map((tjanst: ITjanst) => (
                <Typography
                  variant="subtitle2"
                  key={tjanst.namn}
                  marginBottom={0.5}
                >
                  {tjanst.namn}
                </Typography>
              ))}
              <HTMLMapper
                body={p.content}
                mediaUrl={getConfig().MEDIA_URL}
                removeLastElementGutter
              />
            </Card>
          ))}
          {infoText && <ArendeInfoText content={infoText} />}
        </Stack>
      )}
    </>
  )
}

export default OmfattningspaketBlock

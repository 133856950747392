import { handleActions, combineActions } from 'redux-actions'
import { compose, filter, propEq, not } from 'ramda'
import {
  changeAdvisorFailure,
  changeAdvisorSuccess,
  changeTeamFailure,
  changeTeamSuccess,
  updateEventFailure,
  fetchEventsFailure,
  deleteEventFailure,
  deleteEventSuccess,
  createEventFailure,
  updateEventSuccess,
  createEventSuccess,
  postDocumentSuccess,
  postDocumentFailure,
  fetchDocumentsFailure,
  deleteDocumentSuccess,
  deleteDocumentFailure,
  fetchUtbetalningsbeslutFailure,
  updateMarkningSuccess,
  updateMarkningFailure,
  updatePreferredLanguageSuccess,
  updatePreferredLanguageFailure,
} from '@local/src/Views/Case/Case.actions'
import {
  fetchCaseFailure,
  fetchMyCaseListFailure,
} from '@local/Views/DinaArenden/DinaArenden.actions'
import {
  createEmailFailure,
  createEmailSuccess,
} from '@local/Blocks/CreateEmail/CreateEmail.actions'
import {
  fetchAllAllocateTeamListFailure,
  fetchAllocateAdvisorListFailure,
  fetchAllocateCaseListFailure,
  fetchAllocateTeamListFailure,
  allocateCasesFailure,
  changeClientAllocationTeamSuccess,
  changeClientAllocationTeamFailure,
} from '@local/Views/AllocateCases/AllocateCases.actions'
import { fetchTodoListFailure } from '@local/Blocks/TodoList/TodoList.actions'
import { fetchCaseListFailure } from '@local/src/Views/Arenden/Arenden.actions'

import {
  fetchAttendedActivitiesFailure,
  fetchCurrentActivitiesFailure,
  unregisterActivitiesFailure,
  unregisterActivitiesSuccess,
} from '../../Views/Case/Activities/Activities.actions'
import {
  ateroppnaArendeError,
  ateroppnaArendeSuccess,
  avslutaArendeWithQuestionsError,
  avslutaArendeWithQuestionsSuccess,
  avslutaArendeWithReminderError,
  avslutaArendeWithReminderSuccess,
  avslutaArendeWithoutDateError,
  avslutaArendeWithoutDateSuccess,
  avslutaKompetensstodsArendeError,
  avslutaKompetensstodsArendeSuccess,
} from '../../Views/Case/Avslut/Avslut.actions'

import {
  addErrorMessage,
  addSuccessMessage,
  removeErrorMessage,
  removeSuccessMessage,
} from './UserFeedback.actions'
import { UserFeedbackReducerType } from './UserFeedback.types'

export const initialState: UserFeedbackReducerType = {
  errorMessages: [],
  successMessages: [],
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const userFeedbackReducer = handleActions(
  {
    [combineActions(
      // PUT/POST/DELETE
      allocateCasesFailure,
      changeAdvisorFailure,
      changeClientAllocationTeamFailure,
      changeTeamFailure,
      avslutaKompetensstodsArendeError,
      avslutaArendeWithoutDateError,
      ateroppnaArendeError,
      avslutaArendeWithQuestionsError,
      avslutaArendeWithReminderError,
      unregisterActivitiesFailure,
      updateEventFailure,
      deleteEventFailure,
      createEventFailure,
      postDocumentFailure,
      createEmailFailure,
      deleteDocumentFailure,
      updateMarkningFailure,

      // GET
      fetchAllAllocateTeamListFailure,
      fetchAllocateAdvisorListFailure,
      fetchAllocateCaseListFailure,
      fetchAllocateTeamListFailure,
      fetchCaseFailure,
      fetchCaseListFailure,
      fetchMyCaseListFailure,
      fetchEventsFailure,
      fetchAttendedActivitiesFailure,
      fetchCurrentActivitiesFailure,
      fetchTodoListFailure,
      fetchDocumentsFailure,
      fetchUtbetalningsbeslutFailure,
      updatePreferredLanguageFailure,
      addErrorMessage
    ).toString()]: (state, action) => ({
      ...state,
      errorMessages: [...state.errorMessages, action.payload],
    }),

    [combineActions(
      // PUT/POST/DELETE
      // allocateCasesSuccess, special case, handled in component
      ateroppnaArendeSuccess,
      avslutaArendeWithoutDateSuccess,
      avslutaArendeWithReminderSuccess,
      avslutaArendeWithQuestionsSuccess,
      avslutaKompetensstodsArendeSuccess,
      unregisterActivitiesSuccess,
      changeAdvisorSuccess,
      changeClientAllocationTeamSuccess,
      changeTeamSuccess,
      deleteEventSuccess,
      updateEventSuccess,
      createEventSuccess,
      postDocumentSuccess,
      deleteDocumentSuccess,
      updateMarkningSuccess,
      updatePreferredLanguageSuccess,
      createEmailSuccess
    ).toString()]: (state, action: any) => {
      return {
        ...state,
        successMessages: [
          ...state.successMessages,
          {
            id: Math.floor(Math.random() * 10000),
            title: action.payload.successMessage,
          },
        ],
      }
    },

    [addSuccessMessage.toString()]: (state: any, action: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        successMessages: [
          ...state.successMessages,
          {
            id: Math.floor(Math.random() * 10000),
            title: action.payload,
          },
        ],
      }
    },

    [removeSuccessMessage.toString()]: (state: any, action: any) => {
      const messages = filter(
        compose(not, propEq('id', action.payload)),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        state.successMessages
      )
      return {
        ...(state as UserFeedbackReducerType),
        successMessages: messages.length > 0 ? messages : [],
      }
    },
    [removeErrorMessage.toString()]: (state: any, action: any) => {
      const tempErrorMessages = (
        state as UserFeedbackReducerType
      ).errorMessages.slice()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      tempErrorMessages.splice(action, 1)
      return {
        ...(state as UserFeedbackReducerType),
        errorMessages: tempErrorMessages,
      }
    },
  },
  initialState
)

export default userFeedbackReducer

import { connect } from 'react-redux'
import { lensPath, view } from 'ramda'
import { RootState } from '@local/src/Store/configureStore'

import {
  fetchDocuments,
  postDocument,
  fetchDocument,
  resetPostDocumentState,
  deleteDocument,
} from '../Case.actions'

import Documents from './Documents'

const documentsLens = lensPath(['selectedCase', 'documents'])
const fetchDocumentsStateLens = lensPath([
  'selectedCase',
  'fetchDocumentsState',
])

const deleteDocumentStateLens = lensPath([
  'selectedCase',
  'deleteDocumentState',
])
const postDocumentStateLens = lensPath(['selectedCase', 'postDocumentState'])

const postDocumentState = view(postDocumentStateLens)
const documents = view(documentsLens)
const fetchDocumentsState = view(fetchDocumentsStateLens)
const deleteDocumentState = view(deleteDocumentStateLens)

const mapStateToProps = (state: RootState) => ({
  documents: documents(state),
  fetchDocumentsState: fetchDocumentsState(state),
  postDocumentState: postDocumentState(state),
  deleteDocumentState: deleteDocumentState(state),
})

const mapDispatchToProps = {
  fetchDocuments,
  postDocument,
  fetchDocument,
  resetPostDocumentState,
  deleteDocument,
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)

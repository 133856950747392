import { useState } from 'react'

import { StartklarFilter } from '../Startklar.types'

import { getResetFilter } from './useStartklarFilter'

const useStartklarLocalStorageFilter = (
  initialValue: StartklarFilter
): [StartklarFilter, (value: Partial<StartklarFilter>) => void, () => void] => {
  const localStorageKey = 'Startklar-filter'

  const readValue = (): StartklarFilter => {
    const item = window.localStorage.getItem(localStorageKey)

    if (!item) {
      return initialValue
    }

    const parsedFilter = JSON.parse(item) as StartklarFilter
    const sanitizedFilter = Object.keys(parsedFilter)
      .filter((key): key is keyof StartklarFilter => key in initialValue)
      .reduce((obj: any, key) => {
        obj[key] = parsedFilter[key]
        return obj as StartklarFilter
      }, {} as StartklarFilter)

    return { ...initialValue, ...sanitizedFilter } as StartklarFilter
  }

  const [storedValue, setStoredValue] = useState<StartklarFilter>(readValue)

  const setValue = (value: StartklarFilter) => {
    const sanitizedValue: StartklarFilter = Object.keys(value)
      .filter((key): key is keyof StartklarFilter => key in initialValue)
      .reduce((obj: any, key) => {
        obj[key] = value[key]
        return obj as StartklarFilter
      }, {} as StartklarFilter)

    setStoredValue({ ...initialValue, ...sanitizedValue })
    window.localStorage.setItem(localStorageKey, JSON.stringify(sanitizedValue))
  }

  const resetFilter = () => {
    const emptyFilter = getResetFilter(storedValue)
    setStoredValue(emptyFilter)
    window.localStorage.setItem(localStorageKey, JSON.stringify(emptyFilter))
  }

  return [storedValue, setValue, resetFilter]
}

export default useStartklarLocalStorageFilter

import { path } from 'ramda'
import { FetchingState } from '@local/src/Common.types'

import { ITodo } from './TodoList.types'

export const todoListSelector = path<ITodo[]>(['todo', 'todoList'])
export const fetchingTodoListStateSelector = path<FetchingState>([
  'todo',
  'fetchingTodoListState',
])

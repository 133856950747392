import { useGetArbetsuppgifterQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import {
  getQueryParameters,
  getStatusLabel,
} from '@local/src/Views/Arenden/Arbetsuppgift/helpers'
import { Box, Button, Card, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { arendenPath, medarbetareBasepath } from '@local/src/basename'
import { formatDateWithMonthStringFromDateTime as formatMonth } from '@local/Utils/DateTime'
import {
  Actor,
  ArbetsuppgiftStatus,
} from '@local/src/Views/Arenden/Arbetsuppgift/types'
import { useUser } from '@trr/app-shell-data'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import TogglestatusChip from '@local/src/Views/Arenden/Arbetsuppgift/Table/ToggleStatusChip'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

interface ArendeArbetsuppgifterProps {
  arendeId: string
}

const ArendeArbetsuppgifter = ({ arendeId }: ArendeArbetsuppgifterProps) => {
  const user = useUser()
  const { data, isLoading, isUninitialized } = useGetArbetsuppgifterQuery(
    getQueryParameters({
      arendeIds: [arendeId],
      statusar: [ArbetsuppgiftStatus.Ny, ArbetsuppgiftStatus.Pagaende],
      orderBy: { direction: 'desc', property: 'createdDate' },
    })
  )

  if (isLoading || isUninitialized || data?.results?.length === 0) {
    return <></>
  }

  return (
    <Card variant="outlined" tabIndex={0} sx={{ pb: 1 }}>
      <Box display="flex" justifyContent="flex-start" p={2}>
        <Button variant="outlined" size="small">
          <Link
            href={`${medarbetareBasepath}${arendenPath}/att-gora`}
            variant="body1"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            Att göra
            <ArrowForwardIcon fontSize="small" />
          </Link>
        </Button>
      </Box>
      <Stack display="flex" flexDirection="column">
        {data?.results.map((arbetsuppgift) => (
          <Box
            key={arbetsuppgift.id}
            display="flex"
            flexDirection="column"
            gap={1}
            p={2}
            borderTop="1px solid rgba(0, 0, 0, 0.12)"
          >
            <Box display="flex" flexDirection="row" gap={0.5}>
              {arbetsuppgift.status === ArbetsuppgiftStatus.Ny &&
                arbetsuppgift.owner.id === user.mdid && (
                  <FiberManualRecordIcon
                    sx={{ mx: 'auto' }}
                    color="primary"
                    data-testid={`newstatusicon-${arbetsuppgift.id}`}
                  />
                )}
              <Typography variant="subtitle1">
                {arbetsuppgift.header}
              </Typography>
            </Box>
            {arbetsuppgift.latestStatusUpdate?.triggeredBy === Actor.User && (
              <Typography variant="caption" color="text.secondary">
                {`${getStatusLabel(arbetsuppgift.status)} ${formatMonth(arbetsuppgift.latestStatusUpdate.occuredAt)} - `}
                {arbetsuppgift.latestStatusUpdate.user.id === user.mdid
                  ? 'Jag'
                  : `${arbetsuppgift.latestStatusUpdate.user.firstName} ${arbetsuppgift.latestStatusUpdate.user.lastName}`}
              </Typography>
            )}
            {arbetsuppgift.latestStatusUpdate?.triggeredBy === Actor.System && (
              <Typography variant="caption" color="text.secondary">
                {getStatusLabel(arbetsuppgift.status)}{' '}
                {formatMonth(arbetsuppgift.latestStatusUpdate.occuredAt)} -
                Systemet
              </Typography>
            )}
            <TogglestatusChip
              id={arbetsuppgift.id}
              status={arbetsuppgift.status}
            />
          </Box>
        ))}
      </Stack>
    </Card>
  )
}

export default ArendeArbetsuppgifter

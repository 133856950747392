import * as yup from 'yup'
import { ISelectOption } from '@local/src/Common.types'
import { AvslutsFragaSvar } from '@local/src/Views/Case/Avslut/Avslut.types'

export const utbildningOptions: ISelectOption[] = [
  { value: '00001', label: 'Högskola/Universitet' },
  { value: '00002', label: 'Yrkeshögskola' },
  { value: '00003', label: 'Gymnasienivå, teoretisk inriktning' },
  { value: '00004', label: 'Gymnasienivå, yrkesinriktning' },
  { value: '00005', label: 'Arbetsmarknadsutbildning' },
  { value: '00006', label: 'Annat' },
]

export const sunKodOptions: ISelectOption[] = [
  { value: '1', label: 'Bred, generell utbildning' },
  { value: '8', label: 'Läs- och skrivinlärning för vuxna' },
  { value: '9', label: 'Personlig utveckling' },
  { value: '14', label: 'Pedagogik och lärarutbildning' },
  { value: '21', label: 'Konst och media' },
  { value: '22', label: 'Humaniora' },
  { value: '31', label: 'Samhälls- och beteendevetenskap' },
  { value: '32', label: 'Journalistik och information' },
  { value: '34', label: 'Företagsekonomi, handel och admistration' },
  { value: '38', label: 'Juridik och rättsvetenskap' },
  { value: '42', label: 'Biologi och miljövetenskap' },
  { value: '44', label: 'Fysik, kemi och geovetenskap' },
  { value: '46', label: 'Matematik och övrig naturvetenskap' },
  { value: '48', label: 'Informations- och kommunikationsteknik (IKT' },
  { value: '52', label: 'Teknik och teknisk industri' },
  { value: '54', label: 'Material och tillverkning' },
  { value: '58', label: 'Samhällsbyggnad och byggnadsteknik' },
  { value: '62', label: 'Lantbruk, trädgård, skog och fiske' },
  { value: '64', label: 'Djursjukvård' },
  { value: '72', label: 'Hälso- och sjukvård' },
  { value: '76', label: 'Socialt arbete och omsorg' },
  { value: '81', label: 'Personliga tjänster' },
  { value: '84', label: 'Transporttjänster' },
  { value: '85', label: 'Arbetsmiljö och renhållning' },
  { value: '86', label: 'Säkerhetstjänster' },
  { value: '99', label: 'Okänd' },
]

export interface FormValues {
  [key: string]: string | Date
  avslutsDatum: Date
  startDatum: Date
  requireLosningStartdatum: 'true' | 'false'
  typeOfEducation: string
  sunCode: string
  educationLength?: 'true' | 'false'
  educationOccupancy?: 'true' | 'false'
  distanceStudies?: 'true' | 'false'
  retraining?: 'true' | 'false'
}

export const validationSchema = yup.object({
  avslutsDatum: yup
    .date()
    .required('Ange avslutsdatum')
    .typeError('Ange avslutsdatum'),
  requireLosningStartdatum: yup.string(),
  startDatum: yup
    .date()
    .typeError('Startdatum måste vara ett giltigt datum')
    .nullable()
    .when('requireLosningStartdatum', {
      is: 'true',
      then: () =>
        yup
          .date()
          .typeError('Startdatum måste vara ett giltigt datum')
          .required('Ange startdatum'),
    }),
  typeOfEducation: yup.string().required('Välj en utbildningstyp'),
  sunCode: yup.string().required('Välj en SUN-kod'),
  educationLength: yup.string().required('Ange utbildningens längd'),
  educationOccupancy: yup.boolean().required('Ange utbildningens omfattning'),
  distanceStudies: yup.boolean().required('Ange studieform'),
  retraining: yup
    .boolean()
    .required('Ange om det är påbyggnad eller omskolning.'),
})

export const convertToAvslutsfragorSvar = (
  formValues: FormValues
): AvslutsFragaSvar[] => {
  return [
    { key: 'typeOfEducation', value: formValues.typeOfEducation },
    { key: 'sunCode', value: formValues.sunCode },
    { key: 'educationLength', value: formValues.educationLength },
    { key: 'educationOccupancy', value: formValues.educationOccupancy },
    { key: 'distanceStudies', value: formValues.distanceStudies },
    { key: 'retraining', value: formValues.retraining },
  ]
}

import { useLocation } from 'react-router-dom'
import { guidRegex } from '@local/Utils/helpers'

const useGuidFromUrl = (): string => {
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const guid = pathname.match(guidRegex)

  if (guid) {
    return guid[0]
  } else {
    return null
  }
}

export { useGuidFromUrl }

import { useDevice } from '@trr/app-shell-data'

const useIsSmallScreen = (
  { onlyMobile }: { onlyMobile?: boolean } = { onlyMobile: false }
): boolean => {
  const { isMobile, isTablet } = useDevice()
  return onlyMobile ? isMobile : isMobile || isTablet
}

export { useIsSmallScreen }

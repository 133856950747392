import { ISelectOption, Team } from '@local/src/Common.types'
import { Divider, MenuItem } from '@mui/material'
import React from 'react'

interface AmoSeparatedOption {
  amoName: string
  default: boolean
  options: ISelectOption[]
}

export const buildFordelningsteamDropdownOptions = (
  fordelningsteams: Team[]
) => {
  if (!fordelningsteams?.length) {
    return []
  }

  const dropdownOptions = buildAmoSeparatedDropdownOptions(fordelningsteams)

  const dropdownOptionsToReactNodes = dropdownOptions.map((amo) => {
    const teamOptions = amo.options.map((team) => (
      <MenuItem key={team.value} value={team.value}>
        {team.label}
      </MenuItem>
    ))
    return [
      ...teamOptions,
      <Divider key={`${amo.amoName}-divider`} sx={{ mx: 1 }} />,
    ]
  })

  // remove last divider.
  dropdownOptionsToReactNodes.at(-1).pop()

  return dropdownOptionsToReactNodes
}

const buildAmoSeparatedDropdownOptions = (teams: Team[]) => {
  if (!teams) return [] as AmoSeparatedOption[]

  const groupedByAmo = teams.reduce(
    (acc, item) => {
      if (!acc[item.arbetsmarknadsomradeNamn]) {
        acc[item.arbetsmarknadsomradeNamn] = {
          options: [],
          default: item.default,
        }
      }
      acc[item.arbetsmarknadsomradeNamn].options.push({
        value: item.id,
        label: item.namn,
      })
      return acc
    },
    {} as Record<string, { options: ISelectOption[]; default: boolean }>
  )

  const separatedOptions = Object.entries(groupedByAmo).map(
    ([amoName, { options, default: isDefault }]) => ({
      amoName,
      options,
      default: isDefault,
    })
  )

  // place the amo that has a team with default = true at top of list.
  separatedOptions.sort((a, b) => Number(b.default) - Number(a.default))

  return separatedOptions
}

import { IconButton, Popover, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'

interface InformationPopoverProps {
  infoText: string
  testSelector?: string
  disablePadding?: boolean
  iconSize?: 'small' | 'medium' | 'large'
}

const InformationPopover = ({
  infoText,
  testSelector = 'infoPopover',
  disablePadding = false,
  iconSize = 'medium',
}: InformationPopoverProps): JSX.Element => {
  const [popoverElement, setPopoverElement] =
    React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverElement(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverElement(null)
  }

  const open = Boolean(popoverElement)
  const id = open ? 'popover' : undefined

  return (
    <>
      <IconButton
        size="small"
        data-testid={testSelector}
        onClick={handleClick}
        sx={{ p: disablePadding ? 0 : '5px' }}
      >
        <InfoIcon fontSize={iconSize} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={popoverElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography padding={2} width="300px">
          {infoText}
        </Typography>
      </Popover>
    </>
  )
}

export default InformationPopover

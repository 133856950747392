import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'

interface DropdownProps {
  label: string
  value: unknown
  options?: unknown[]
  getOptionValue: (option: unknown) => string | number
  getOptionLabel: (option: unknown) => string | number
  onChange: (event: SelectChangeEvent) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  name?: string
  error?: boolean
  errorText?: string
  fullWidth?: boolean
  disabled?: boolean
  maxWidth?: string
  testSelector?: string
  size?: 'medium' | 'small'
  customOptions?: React.ReactNode
}

const Dropdown = ({
  label,
  value,
  options = [],
  getOptionValue,
  getOptionLabel,
  onChange,
  onBlur,
  name,
  error = false,
  errorText,
  fullWidth = true,
  disabled = false,
  maxWidth,
  testSelector,
  size = 'medium',
  customOptions,
}: DropdownProps): JSX.Element => {
  if (!options && customOptions) {
    console.error(
      'You need to pass options or customOptions for this component to work properly.'
    )
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{ maxWidth: maxWidth ? maxWidth : 'inherit' }}
      disabled={disabled}
      size={size}
    >
      <InputLabel error={error}>{label}</InputLabel>
      <Select
        name={name}
        error={error}
        data-testid={testSelector}
        aria-label={label}
        defaultValue=""
        value={value || ''}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        size={size}
        sx={{ backgroundColor: '#fff' }}
        MenuProps={{ PaperProps: { sx: { maxHeight: 355 } } }}
      >
        {customOptions && customOptions}
        {!customOptions &&
          options?.map((option, index: number) => (
            <MenuItem key={index} value={getOptionValue(option)}>
              {getOptionLabel(option)}
            </MenuItem>
          ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Dropdown

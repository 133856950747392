import Modal from '@local/src/Components/Modal/Modal'
import { formatDateWithMonthStringFromDateTime } from '@local/src/Utils/DateTime'
import { ActivityRound } from '@local/src/Views/Case/Activities/Activities.types'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

interface HandleActivitiesModalProps {
  open: boolean
  activities: ActivityRound[]
  onCancel: () => void
  onSubmit: (activitiesToCancel: string[]) => void
}

const HandleActivitiesModal = ({
  open,
  activities,
  onCancel,
  onSubmit,
}: HandleActivitiesModalProps) => {
  const [activitiesToCancel, setActivitiesToCancel] = useState<string[]>([])

  const toggleActivityToCancel = (activityId: string) => {
    if (activitiesToCancel.includes(activityId)) {
      setActivitiesToCancel(
        activitiesToCancel.filter((id) => id !== activityId)
      )
    } else {
      setActivitiesToCancel([...activitiesToCancel, activityId])
    }
  }

  const handleConfirm = () => {
    onSubmit(activitiesToCancel)
    setActivitiesToCancel([])
  }

  const handleCancel = () => {
    setActivitiesToCancel([])
    onCancel()
  }

  const isSameDay = (startDate: string, endDate: string) => {
    const d1 = new Date(startDate)
    const d2 = new Date(endDate)
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    )
  }

  return (
    <Modal
      open={open}
      label="Hantera aktiviteter"
      closeIcon
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmText={activitiesToCancel.length > 0 ? 'Spara' : 'Avboka ej'}
    >
      <Typography variant="body1" mb={4}>
        Välj vilka aktiviteter du vill avboka. Aktiviteterna du väljer att
        avboka sparas direkt och kan inte ändras.
      </Typography>

      <Typography variant="subtitle1">
        Inbokade aktiviteter ({activities.length})
      </Typography>
      <List>
        {activities.map((activity) => (
          <ListItem key={activity.id}>
            <ListItemButton onClick={() => toggleActivityToCancel(activity.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  checked={activitiesToCancel.some((id) => id === activity.id)}
                  inputProps={{ 'aria-labelledby': activity.id }}
                />
              </ListItemIcon>
              <ListItemText
                id={activity.id}
                primary={activity.activity.name}
                secondary={`
                  ${formatDateWithMonthStringFromDateTime(activity.schedule.start, true)}
                  ${
                    !isSameDay(activity.schedule.start, activity.schedule.end)
                      ? ` - ${formatDateWithMonthStringFromDateTime(activity.schedule.end, true)}`
                      : ''
                  }`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Modal>
  )
}

export default HandleActivitiesModal

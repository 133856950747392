import React from 'react'
import classnames from 'classnames'
import {
  Checkbox,
  Icons,
  AccordionLink,
  Link,
  CollapsibleList,
  Typography,
} from '@trr/common-component-library'
import { formatDateFromDateTime } from '@local/Utils/DateTime'
import { getUrlByCaseEnv } from '@local/src/basename'
import { isRareFolkbokforingsStatus } from '@local/src/Utils/FolkbokföringsStatus'
import FolkbokforingsStatusChip from '@local/src/Components/PeopleRegistrationStatusChip/PeopleRegistrationStatusChip'
import AllocateDetails from '@local/src/Components/AllocateDetails'
import IconWithText from '@local/src/Components/IconWithText'
import DateWrapper from '@local/src/Components/DateWrapper'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { Chip } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { IAllocateCaseListItemProps } from './AllocateCaseListItem.types'
import s from './AllocateCaseListItem.module.scss'

const AllocateCaseListItem = ({
  item,
  allTeams,
  allocatableTeams,
  changeTeam,
  changingClientAllocationTeamState,
  checked = false,
  children,
  fetchingAllocationDetails,
  fetchAllocationDetails,
  isSmallScreen,
  isStaged,
  nextMeeting,
  onCheck = () => (checked = !checked),
}: IAllocateCaseListItemProps): JSX.Element => {
  const id = item.id
  const caseEnvBasePath = useCaseEnvBasePath()

  return (
    <li
      className={classnames(s.ListItemWrapper, isStaged && s.Staged)}
      data-status={isStaged ? 'staged' : 'unassigned'}
    >
      <div className={s.ListItem} key={id}>
        {isSmallScreen && (
          <div className={s.Checkbox}>
            <Checkbox checked={checked} onChange={onCheck} name={id} />
          </div>
        )}
        <div className={s.LeftSection}>
          <div data-id={id} className={classnames(s.ListInfo, s.ListIcon)}>
            <CollapsibleList classNames={{ list: s.SpacedList }}>
              <Typography variant="subtitle1">#{item.arendeNr}</Typography>
              {item.ateroppnatForRadgivning && <Chip label="Återöppnad" />}
              <Chip label={item.radgivningsSubStatusDisplayText} />
              {isRareFolkbokforingsStatus(item.folkbokforingsStatus) && (
                <FolkbokforingsStatusChip status={item.folkbokforingsStatus} />
              )}
              {item.engelskTalande && <Chip label="Engelsktalande" />}
              {item.executiveChef && <Chip label="Executive chef" />}
              {item.hasProjekt && (
                <Chip label="Projekt" icon={<AssignmentIcon />} />
              )}
              {item.undantagsgrupp && (
                <Chip icon={<WarningAmberIcon />} label="Undantagsgrupp" />
              )}
            </CollapsibleList>

            <div className={s.Name}>
              <Link noChevron link={getUrlByCaseEnv(caseEnvBasePath, id)}>
                <Typography variant="h4">
                  {item.fornamn} {item.efternamn},
                </Typography>
              </Link>

              <span data-age={item.klientAlder} className={s.Age}>
                {item.klientAlder} år
              </span>
            </div>
            <div className={s.ListInfoName}>
              <IconWithText
                icon={
                  <Icons.HomeOutlinedIcon
                    color={s.textColor}
                    size={16}
                    title="Klientens bostadsort"
                  />
                }
              >
                <>
                  {item.ort}, {item.kommun}
                </>
              </IconWithText>
              <IconWithText
                icon={
                  <Icons.BriefcaseOutlinedIcon
                    color={s.textColor}
                    size={16}
                    title="Befattning och arbetsställe"
                  />
                }
              >{`${item.befattning}, ${item.arbetsstalle}`}</IconWithText>
            </div>
            {nextMeeting && isSmallScreen && (
              <div className={s.NextMeeting}>
                <div className={s.TransStatus}>
                  <span>Nästa möte</span>
                </div>
                <div className={s.ListIconGroup}>
                  <DateWrapper>
                    {formatDateFromDateTime(nextMeeting)}
                  </DateWrapper>
                </div>
              </div>
            )}
          </div>
        </div>
        <div data-id={id} className={s.RightSection}>
          {children}
        </div>
      </div>

      <div className={s.Details}>
        <AccordionLink label={'Detaljer'}>
          <AllocateDetails
            changingClientAllocationTeamState={
              changingClientAllocationTeamState
            }
            firstName={item.fornamn}
            lastName={item.efternamn}
            allTeams={allTeams}
            allocatableTeams={allocatableTeams}
            dismissedBy={item.orsakTillUppsagningTxt}
            salary={item.lon}
            teamId={item.teamId}
            clientId={item.klientId}
            caseId={id}
            changeTeam={changeTeam}
            isStaged={isStaged}
            fetchAllocationDetails={fetchAllocationDetails}
            pastCases={item.allocationDetails?.ovrigaArenden}
            pastAdvisors={item.allocationDetails?.tidigareRadgivare}
            fetchingAllocationDetails={fetchingAllocationDetails}
          />
        </AccordionLink>
      </div>
    </li>
  )
}

export default AllocateCaseListItem

import { useLocation } from 'react-router-dom'

const useCurrentArendeListPathname = (): string => {
  const { pathname } = useLocation()
  const listSegmentOfUrl =
    pathname.split('/').find((segment) => segment.includes('arenden')) ?? ''
  return `/${listSegmentOfUrl}`
}

export { useCurrentArendeListPathname }

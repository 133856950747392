import { medarbetareBasepath } from '@local/src/basename'
import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PersonIcon from '@mui/icons-material/Person'

interface KundprofilerProps {
  klientId: string
  linkedInUrl: string
  hasActiveMatchProfile: boolean
}

const Kundprofiler = ({
  klientId,
  linkedInUrl,
  hasActiveMatchProfile,
}: KundprofilerProps) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems="baseline"
      gap={{ xs: 1, md: 2 }}
    >
      {linkedInUrl ? (
        <Link
          href={linkedInUrl}
          display="flex"
          alignItems="center"
          gap={1}
          rel="noopener noreferrer"
          target="_blank"
        >
          <LinkedInIcon fontSize="small" />
          <Typography variant="body1">Linkedinprofil</Typography>
        </Link>
      ) : (
        <Typography
          variant="body1"
          display="flex"
          alignItems="center"
          alignSelf="baseline"
          color="text.secondary"
          gap={1}
        >
          <LinkedInIcon fontSize="small" />
          Ingen Linkedinprofil
        </Typography>
      )}
      {hasActiveMatchProfile ? (
        <Link
          href={`${medarbetareBasepath}/matcha-kandidater/profil?id=${klientId}`}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <PersonIcon fontSize="small" />
          <Typography variant="body1">Matchningsprofil aktiverad</Typography>
        </Link>
      ) : (
        <Typography
          variant="body1"
          display="flex"
          alignItems="center"
          alignSelf="baseline"
          color="text.secondary"
          gap={1}
        >
          <PersonIcon fontSize="small" />
          Ingen matchningsprofil
        </Typography>
      )}
    </Box>
  )
}

export default Kundprofiler

import { Theme } from '@mui/material'

export const getDatagridDefaultStyles = (
  theme: Theme,
  isMobile: boolean,
  floatLastColumnRight: boolean
) => ({
  border: 0,
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    padding: isMobile ? 0 : undefined,
  },
  '& .MuiDataGrid-columnHeader': {
    padding: isMobile ? 0 : undefined,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    flexDirection: 'initial !important',
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-columnHeaderTitleContainerContent':
    {
      marginLeft: floatLastColumnRight && 'auto',
    },
  '& .MuiDataGrid-row--detailPanelExpanded': {
    background: theme.palette?.surface?.grey,
  },
  '& .MuiDataGrid-row--detailPanelExpanded > .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-detailPanel': {
    background: theme.palette?.surface?.grey,
  },
})

import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Paper,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'

interface SearchFieldProps {
  search(searchText: string): void
  value: string
}

const SearchField = ({ search, value }: SearchFieldProps): JSX.Element => {
  const [searchText, setSearchText] = useState(value)
  const [showClearButton, setShowClearButton] = useState(value.length > 0)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const searchLabel = isSmall
    ? 'Sök'
    : isMedium
      ? 'Sök på namn, mejl, personnr, mobil eller ärendenr.'
      : 'Sök på namn, mejl, personnummer, mobil eller ärendenummer'

  useEffect(() => {
    setSearchText(value)
    setShowClearButton(value?.length > 0)
  }, [value])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
    setShowClearButton(false)
  }

  const handleSearchButtonClick = () => {
    search(searchText)
    setShowClearButton(searchText.length > 0)
  }

  const handleClearButtonClick = () => {
    search('')
    setSearchText('')
    setShowClearButton(false)
  }

  const searchOnEnterKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      search(searchText)
      setShowClearButton(searchText.length > 0)
    }
  }

  const getSearchFieldIcon = () => {
    if (!showClearButton) {
      return (
        <InputAdornment position="end">
          <IconButton aria-label="search" onClick={handleSearchButtonClick}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )
    }

    if (showClearButton) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear search"
            onClick={handleClearButtonClick}
            data-testid="clear-search-btn"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
        alignItems: { xs: 'center', md: 'inherit' },
      }}
    >
      <TextField
        label={searchLabel}
        variant="outlined"
        onChange={handleSearchChange}
        onEmptied={handleClearButtonClick}
        onKeyDown={searchOnEnterKeyDown}
        value={searchText}
        fullWidth
        InputProps={{
          endAdornment: getSearchFieldIcon(),
        }}
        inputProps={{
          'data-testid': 'search-field',
        }}
      />
    </Paper>
  )
}

export default SearchField

import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  formatDateFromDateTime,
  formatTimeFromDateTime,
} from '@local/src/Utils/DateTime'

import { INastaMote } from '../ActionBasedCaseItem.types'

interface NextMeetingProps {
  meeting: INastaMote
}

const NextMeeting = ({ meeting }: NextMeetingProps): JSX.Element => {
  const meetingText = meeting
    ? `${formatDateFromDateTime(meeting.startDatumTid, true)}\n${formatTimeFromDateTime(meeting.startDatumTid)} - ${formatTimeFromDateTime(meeting.slutDatumTid)}`
    : 'Inget inbokat'

  return (
    <Box display="grid" alignContent="center">
      <Typography variant="body2">Möte</Typography>
      <Typography
        variant="caption"
        whiteSpace="pre-line"
        color="text.secondary"
      >
        {meetingText}
      </Typography>
    </Box>
  )
}

export default NextMeeting

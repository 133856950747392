import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
} from '@mui/x-data-grid-pro'
import { Box, Chip, Link, Typography } from '@mui/material'
import React from 'react'
import PickAdvisor from '@local/src/Components/PickAdvisor'
import { getUrlByCaseEnv } from '@local/src/basename'
import { isRareFolkbokforingsStatus } from '@local/src/Utils/FolkbokföringsStatus'
import FolkbokforingsStatusChip from '@local/src/Components/PeopleRegistrationStatusChip/PeopleRegistrationStatusChip'
import { Radgivare } from '@local/src/Common.types'

import {
  ArendeStagedForFordelning,
  FordelningsArende,
  FordelningsRadgivare,
} from '../Fordelning.types'

import CustomDetailPanelButton from './CustomDetailPanelButton/CustomDetailPanelButton'

export const getColumns = (
  arendeBasePath: string,
  aktivaRadgivare: FordelningsRadgivare[],
  addStagedArende: (arendeId: string, radgivar: Radgivare) => void,
  removeStagedArende: (arendeId: string) => void,
  stagedArenden: ArendeStagedForFordelning[]
): GridColDef<FordelningsArende>[] => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelButton
          id={params.id}
          isExpanded={params.value}
          onPinClick={() => removeStagedArende(params.row.id)}
        />
      ),
    },
    {
      field: 'kund',
      headerName: 'Kund',
      minWidth: 386,
      valueGetter: ({ row }) => `${row.fornamn} ${row.efternamn}`,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column" gap={1} mb={2} mt={1}>
          <Box display="flex" flexDirection="column">
            <Link
              variant="subtitle1"
              target="_blank"
              href={getUrlByCaseEnv(arendeBasePath, params.row.id)}
              color="inherit"
            >
              {params.row.fornamn} {params.row.efternamn},{' '}
              {params.row.klientAlder} år
            </Link>
            <Typography variant="body2" color="text.primary">
              #{params.row.arendeNr}, {params.row.befattning}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            {params.row.engelskTalande && (
              <Chip variant="status-info" label="Engelsktalande" />
            )}
            {params.row.hasProjekt && (
              <Chip variant="status-info" label="Projekt" />
            )}
            {params.row.ateroppnatForRadgivning && (
              <Chip variant="status-warning" label="Återöppnad" />
            )}
            {isRareFolkbokforingsStatus(params.row.folkbokforingsStatus) && (
              <FolkbokforingsStatusChip
                status={params.row.folkbokforingsStatus}
              />
            )}
            {params.row.undantagsgrupp && (
              <Chip variant="status-error" label="Undantagsgrupp" />
            )}
          </Box>
          <PickAdvisor
            advisorList={aktivaRadgivare || []}
            remove={() => removeStagedArende(params.row.id)}
            chosenAdvisor={
              stagedArenden.find((x) => x.arendeId === params.row.id)?.radgivare
            }
            choose={(radgivare) => addStagedArende(params.row.id, radgivare)}
          />
        </Box>
      ),
    },
    {
      field: 'foretag',
      headerName: 'Företag',
      minWidth: 136,
      flex: 1,
      valueGetter: ({ row }) => row.arbetsstalle,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="flex-start" pt={1}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ wordBreak: 'break-word' }}
          >
            {params.row.arbetsstalle}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'bostadsort',
      headerName: 'Bostadsort',
      minWidth: 136,
      valueGetter: ({ row }) => row.ort,
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="flex-start" pt={1}>
          <Typography variant="subtitle1" color="text.secondary">
            {params.row.ort}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'kommun',
      headerName: 'Kommun',
      minWidth: 136,
      valueGetter: ({ row }) => row.kommun,
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="flex-start" pt={1}>
          <Typography variant="subtitle1" color="text.secondary">
            {params.row.kommun}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'fordelning',
      headerName: 'Fördelning',
      minWidth: 136,
      valueGetter: ({ row }) => row.radgivningsSubStatus,
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="flex-start" pt={1}>
          <Typography variant="subtitle1" color="text.secondary">
            {params.row.radgivningsSubStatus === 'FOROMFORDELNING'
              ? 'Omfördelning'
              : ''}
          </Typography>
        </Box>
      ),
    },
  ]
}

import React, { ChangeEvent, useEffect, useState } from 'react'
import { FetchingState } from '@local/src/Common.types'
import { useGuidFromUrl } from '@local/src/Hooks'
import NoResult from '@local/src/Components/NoResult'
import { IArendeContent } from '@local/src/Views/Case/Case.types'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import {
  useEpiContent,
  useIsFeatureEnabled,
  useUser,
} from '@trr/app-shell-data'

import DocumentList from './DocumentList'
import FileForm from './FileForm'

export interface IDocument {
  dokumentId: string
  fileName: string
  timeStamp: string
  uploadedBy: string
  isHealthRelated?: boolean
  type: string
}

export interface IDocuments {
  fetchDocumentsState: FetchingState
  documents: IDocument[]
  fetchDocuments(arendeId: string, isFetchDocumentUrlV2Enabled: boolean): void
  postDocument(arendeId: string, body: FormData): void
  deleteDocument(arendeId: string, documentId: string): void
  fetchDocument(
    arendeId: string,
    documentId: string,
    fileName: string,
    type: string,
    isFetchDocumentUrlV2Enabled: boolean
  ): void
  resetPostDocumentState(): void
  postDocumentState: FetchingState
  deleteDocumentState: FetchingState
  samtyckeHalsa: boolean
  arendeTyp: string
}

const Documents = ({
  deleteDocumentState,
  fetchDocumentsState,
  postDocumentState,
  documents,
  samtyckeHalsa,
  arendeTyp,
  fetchDocuments,
  postDocument,
  fetchDocument,
  deleteDocument,
  resetPostDocumentState,
}: IDocuments): JSX.Element => {
  const isFetchDocumentUrlV2Enabled = useIsFeatureEnabled(
    'Medarbetare-Microfrontend_fetchDocuments-url-v2_temp_241030'
  )

  const {
    dokument: { dokumentLista: content },
  }: Partial<IArendeContent> = useEpiContent()
  const user = useUser()
  const name = `${user.firstName} ${user.lastName}`
  const [uploadedFile, setUploadedFile] = useState(null)
  const [isErrorDialogActive, setIsErrorDialogActive] = useState(false)
  const [showWarning, setShowWarning] = useState(documents.length > 0)

  const acceptedFileExtensions = [
    '.pdf',
    '.ppt',
    '.pptx',
    '.doc',
    '.rtf',
    '.docx',
    '.txt',
    '.gif',
    '.png',
    '.jpeg',
    '.jpg',
    '.xls',
    '.xlsx',
  ]

  const closeModal = () => setIsErrorDialogActive(false)
  const onFilePicked = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    const fiftyMbInBytes = 50000000
    const fileExtensionIsAccepted = acceptedFileExtensions.some(
      (extension) =>
        extension === '.' + file.name.toLowerCase().split('.').pop()
    )
    file.size > fiftyMbInBytes || !fileExtensionIsAccepted
      ? fileUploadError()
      : setUploadedFile(file)
  }
  const abortUpload = () => setUploadedFile(null)
  const fileUploadError = () => setIsErrorDialogActive(true)

  const arendeId = useGuidFromUrl()

  useEffect(() => {
    fetchDocuments(arendeId, isFetchDocumentUrlV2Enabled)
  }, [fetchDocuments, arendeId])

  useEffect(() => {
    postDocumentState === FetchingState.SUCCESS &&
      fetchDocuments(arendeId, isFetchDocumentUrlV2Enabled)
  }, [postDocumentState, fetchDocuments, arendeId])

  useEffect(() => {
    setShowWarning(documents.length > 0)
  }, [documents])

  return (
    <Box
      component="article"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: '32px 16px', lg: '32px 0' },
        width: { xs: '100%', lg: '80%' },
      }}
    >
      <Dialog open={isErrorDialogActive} onClose={closeModal}>
        <DialogTitle>Det gick inte att ladda upp filen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content.fileUploadErrorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Stäng</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ marginBottom: 2 }}>
        {uploadedFile ? (
          <FileForm
            uploadedBy={name}
            abortUpload={abortUpload}
            file={uploadedFile}
            postDocument={postDocument}
            arendeId={arendeId}
            postDocumentState={postDocumentState}
            samtyckeHalsa={samtyckeHalsa}
            arendeTyp={arendeTyp}
            resetPostDocumentState={resetPostDocumentState}
          />
        ) : (
          <Button
            size="large"
            component="label"
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            {content.uploadButton}
            <input
              name="File"
              type="file"
              hidden
              accept={acceptedFileExtensions.join(',')}
              multiple={false}
              onChange={onFilePicked}
            />
          </Button>
        )}
      </Box>
      {showWarning && (
        <Alert onClose={() => setShowWarning(false)} severity="warning">
          {content.gdprInfoText}
        </Alert>
      )}
      {fetchDocumentsState === FetchingState.LOADING ? (
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <CircularProgress data-testid={'isLoading'} />
        </Box>
      ) : documents.length > 0 ? (
        <DocumentList
          documents={documents}
          arendeId={arendeId}
          fetchDocument={fetchDocument}
          deleteDocument={deleteDocument}
          deleteDocumentState={deleteDocumentState}
        />
      ) : (
        <NoResult alignLeft>{content.noDocumentsInfoText}</NoResult>
      )}
    </Box>
  )
}
export default Documents

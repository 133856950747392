import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'
import {
  DateValidationError,
  DesktopDatePicker,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers'

import {
  AvslutaArendeWithoutDateRequest,
  Avslutskod,
} from '../../../Avslut.types'
import HandleActivities from '../../HandleActivities/HandleActivities'
import { avslutaArendeWithoutDate } from '../../../Avslut.actions'
import { avslutaArendeStateSelector } from '../../../Avslut.selectors'

interface AvslutWithoutDateProps {
  arendeId: string
  avslutskod: Avslutskod
  title: string
  requireLosningStartdatum: boolean
}

const AvslutWithoutDate = ({
  arendeId,
  avslutskod,
  title,
  requireLosningStartdatum,
}: AvslutWithoutDateProps) => {
  const dispatch = useDispatch()
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const [dateHasError, setDateHasError] = useState(false)
  const [startdatum, setStartdatum] = useState<Date>(null)
  const [activitiesHandled, setActivitesHandled] = useState(false)
  const [showActivitiesErrorMsg, setShowActivitiesErrorMsg] = useState(false)

  useEffect(() => {
    if (showActivitiesErrorMsg && activitiesHandled) {
      setShowActivitiesErrorMsg(false)
    }
  }, [activitiesHandled])

  const onSubmit = () => {
    if (
      activitiesHandled === false ||
      (requireLosningStartdatum && !startdatum)
    ) {
      setShowActivitiesErrorMsg(activitiesHandled === false)
      setDateHasError(requireLosningStartdatum && !startdatum)
      return
    }

    const request: AvslutaArendeWithoutDateRequest = {
      arendeId,
      radgivningAvslutskod: avslutskod,
      losningStartdatum: requireLosningStartdatum
        ? startdatum.toISOString()
        : null,
    }
    dispatch(avslutaArendeWithoutDate(request))
  }

  const handleChangeDate = (
    value: Date,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    setStartdatum(value)
    setDateHasError(context.validationError !== null)
  }

  if (avslutaArendeState === FetchingState.LOADING) {
    return <Loading />
  }

  return (
    <Stack spacing={5}>
      <Typography variant="h4">{title}</Typography>
      {requireLosningStartdatum && avslutskod === Avslutskod.Foraldraledig && (
        <Box my={3}>
          <Typography variant="body1" mb={2}>
            För att beräkna AGE korrekt ska startdatum för föräldraledighet
            anges. Om omställningskunden påbörjat sin föräldraledighet, fyll i
            dagens datum.
          </Typography>
          <DesktopDatePicker
            label="Startdatum"
            disableFuture
            onChange={handleChangeDate}
            sx={{ maxWidth: '220px' }}
            slotProps={{
              textField: {
                name: 'startDatum',
                error: dateHasError,
                helperText: dateHasError && 'Startdatum måste anges',
              },
            }}
          />
        </Box>
      )}
      <HandleActivities
        setActivitiesHandled={setActivitesHandled}
        showErrorMessage={showActivitiesErrorMsg}
      />
      <Button onClick={onSubmit}>Avsluta ärendet</Button>
    </Stack>
  )
}

export default AvslutWithoutDate

import React from 'react'
import { useUser } from '@trr/app-shell-data'
import { Box } from '@mui/material'

import InternalLinks from './InternalLinks/InternalLinks'
import ExternalLinks from './ExternalLinks/ExternalLinks'
import WelcomeMessage from './WelcomeMessage/WelcomeMessage'

const Home = (): JSX.Element => {
  const name = useUser().preferredFirstName
  const currentDate = new Date()

  return (
    <Box
      mx="auto"
      mt={10}
      display="flex"
      flexDirection="column"
      maxWidth="900px"
      gap={3}
      textAlign={{ xs: 'center', lg: 'unset' }}
    >
      <WelcomeMessage name={name} date={currentDate} />
      <InternalLinks />
      <ExternalLinks />
    </Box>
  )
}

export default Home

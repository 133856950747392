import React, { useEffect, useRef } from 'react'
import {
  Box,
  Divider,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FetchingState, ISelectOption } from '@local/src/Common.types'
import SearchField from '@local/Components/SearchField'
import OrderBy from '@local/Components/OrderBy'
import CreateEmail from '@local/Blocks/CreateEmail'
import SearchResultSnippet from '@local/Components/SearchResultSnippet'
import { formatAmount, scrollToRef } from '@local/Utils/smallfuncs'
import { getSortingOptionsFor } from '@local/src/Views/Arenden/Arenden.helpers'
import { useGetArendenEnv } from '@local/src/Hooks'

import { ICaseListItem } from '../ActionBasedCaseItem/ActionBasedCaseItem.types'
import ActionBasedCaseItem from '../ActionBasedCaseItem'
import Pagination from '../Pagination/Pagination'
import Loading from '../Loading/Loading'
import EmptyResult from '../EmptyResult/EmptyResult'

import { ICaseList } from './CaseList.types'

const CaseList = ({
  createEmail,
  createEmailState,
  facetedHits,
  fetchingCaseListState,
  cases,
  orderBy,
  page,
  pageSize,
  searchQuery,
  setOrderBy,
  setCasesPage,
  setPageSize,
  setSearchText,
  totalHits,
  totalPages,
}: ICaseList): JSX.Element => {
  const arendeTableRef = useRef<HTMLDivElement>(null)
  const doneFetching = fetchingCaseListState === FetchingState.SUCCESS
  const isLoading = fetchingCaseListState === FetchingState.LOADING
  const changePage = (change: number) => setCasesPage(change, totalPages)
  const arendenEnv = useGetArendenEnv()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    doneFetching && changePage(page)
  }, [doneFetching])

  const sortingOptions = getSortingOptionsFor(arendenEnv) ?? []
  const orderByDisplayValue = sortingOptions.find(
    ({ value }: Partial<ISelectOption>) => value === orderBy.join(', ')
  )
  const searchSnippet = searchQuery && !isLoading && (
    <SearchResultSnippet
      pluralName="ärenden"
      searchQuery={searchQuery}
      singularName="ärende"
      totalHits={totalHits}
    />
  )

  const prettyTotals = formatAmount(totalHits)
  const prettyFacets = formatAmount(facetedHits)
  const hitsCountDisplayText = `${prettyTotals} ${prettyTotals === '1' ? 'ärende' : 'ärenden'} av ${prettyFacets}`

  const changeMuiPage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setCasesPage(page + 1, totalPages)
    scrollToRef(arendeTableRef)
  }

  const handleChangeRowsPerPage = (event: number) => {
    setPageSize(event)
    setCasesPage(1, totalPages)
  }

  const onSortChange = (sortingOption: ISelectOption) => {
    setOrderBy(String(sortingOption.value).split(', '))
    setCasesPage(1, totalPages)
  }

  const pagination = (showPagination = false, showRowsPerPage = false) => (
    <Pagination
      count={totalHits}
      page={page}
      pageSize={pageSize}
      labelRowsPerPage="Ärenden per sida:"
      showPagination={showPagination}
      showRowsPerPage={showRowsPerPage}
      onPageChange={changeMuiPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )

  return (
    <>
      <header>
        <Box
          display="grid"
          gridTemplateColumns={{ xs: '1fr', md: '1fr auto' }}
          justifyItems={{ xs: 'center', md: 'flex-start' }}
          gap={3}
          paddingTop={isMobile && 1}
          marginX={0}
          ref={arendeTableRef}
        >
          <SearchField value={searchQuery} search={setSearchText} />
          <CreateEmail
            numberOfEmailRecipients={totalHits}
            createEmail={createEmail}
            createEmailState={createEmailState}
          />
        </Box>
        <Box paddingLeft={isMobile ? 0 : 1} paddingTop={2}>
          {searchSnippet}
        </Box>
        <Box
          display="flex"
          gap={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          paddingX={isMobile ? 2 : 0}
          paddingLeft={isMobile && 2}
        >
          <Typography variant="body2" padding={1}>
            {hitsCountDisplayText}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            paddingRight={1}
            flexDirection={isMobile ? 'column-reverse' : 'row'}
          >
            {pagination(false, true)}
            <OrderBy
              onChange={onSortChange}
              selectOptions={sortingOptions}
              value={orderByDisplayValue}
            />
          </Box>
        </Box>
        <Divider />
      </header>
      {isLoading && <Loading />}
      {doneFetching && (
        <>
          <List disablePadding>
            {cases.length === 0 ? (
              <EmptyResult sx={{ pt: 5 }} />
            ) : (
              cases.map((arende: ICaseListItem) => (
                <ActionBasedCaseItem key={arende.id} arende={arende} />
              ))
            )}
          </List>
          {cases.length > 0 && (
            <Box display="flex" justifyContent="flex-end">
              {pagination(true, !isMobile)}
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default CaseList

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import {
  Avslutshandelse,
  Avslutskod,
} from '@local/Views/Case/Avslut/Avslut.types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { isNotNullOrEmpty } from '@local/src/Utils/smallfuncs'
import { useIsSmallScreen } from '@local/src/Hooks'

import {
  avslutskodRequiresAnswers,
  getAvslutshandelseUrl,
  isMigratedBeforeGoLiveDate,
} from '../Common'

interface UnhandledAvslutProps {
  avslutshandelse: Avslutshandelse
}

export const UnhandledAvslutshandelse = ({
  avslutshandelse,
}: UnhandledAvslutProps) => {
  const history = useHistory()
  const closingReasonIsRUS =
    avslutshandelse.radgivningAvslutskod === Avslutskod.RadgivningUppskjuten
  const answersRequired = avslutskodRequiresAnswers(avslutshandelse)
  const isMigrated = isMigratedBeforeGoLiveDate(
    new Date(avslutshandelse.radgivningAvslutsdatum)
  )
  const isSmallScreen = useIsSmallScreen()

  const isReOpened = avslutshandelse.ateroppnadDatum !== null
  const willBeReOpened = avslutshandelse.ateroppnasDatumAuto !== null

  const reOpenDate =
    willBeReOpened || isReOpened
      ? isReOpened
        ? format(new Date(avslutshandelse.ateroppnadDatum), 'yyyy-MM-dd')
        : format(new Date(avslutshandelse.ateroppnasDatumAuto), 'yyyy-MM-dd')
      : null

  const handleAnswerQuestionsButton = () => {
    const queryParams = new URLSearchParams()
    queryParams.append('avslutskod', avslutshandelse.radgivningAvslutskod)
    queryParams.append('avslutsdatum', avslutshandelse.radgivningAvslutsdatum)

    const avslutshandelseUrl = getAvslutshandelseUrl(
      history.location.pathname,
      avslutshandelse.ateroppnaGuid,
      queryParams
    )

    history.push(avslutshandelseUrl)
  }

  return (
    <Card>
      <CardHeader
        title={`${avslutshandelse.radgivningAvslutsBeskr} (${avslutshandelse.radgivningAvslutskod})
        ${closingReasonIsRUS ? ` i ${avslutshandelse.radgivningUppskjutenManader} månader` : ''}`}
        sx={{ flexGrow: '1' }}
        action={
          answersRequired && !isMigrated ? (
            <Chip variant={'status-error'} label={'Ej ifyllda'} />
          ) : null
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListItem>
            <ListItemText
              primary={'Avslutat av'}
              secondary={
                isSmallScreen ? (
                  <>
                    <Typography variant="body2">
                      {format(
                        new Date(avslutshandelse.registreringsDatum),
                        'yyyy-MM-dd'
                      )}
                      ,
                    </Typography>
                    <Typography variant="body2">
                      {isNotNullOrEmpty(avslutshandelse.registreradAv)
                        ? avslutshandelse.registreradAv
                        : 'Rådgivare saknas'}
                    </Typography>
                  </>
                ) : (
                  `${format(new Date(avslutshandelse.registreringsDatum), 'yyyy-MM-dd')}, ${
                    isNotNullOrEmpty(avslutshandelse.registreradAv)
                      ? avslutshandelse.registreradAv
                      : 'Rådgivare saknas'
                  }`
                )
              }
            />
          </ListItem>
          <Box gap={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <ListItem>
              {(willBeReOpened || isReOpened) && (
                <ListItemText
                  data-testid="ateroppnad-datum"
                  primary={
                    isReOpened
                      ? 'Återöppnad'
                      : willBeReOpened
                        ? 'Återöppnas'
                        : null
                  }
                  secondary={reOpenDate}
                />
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                data-testid="avslutsdatum"
                primary={'Avslutsdatum'}
                secondary={format(
                  new Date(avslutshandelse.radgivningAvslutsdatum),
                  'yyyy-MM-dd'
                )}
              />
            </ListItem>
          </Box>
        </Box>
        {answersRequired && !isMigrated && (
          <CardActions disableSpacing sx={{ padding: 0 }}>
            <Button
              sx={{ padding: 0 }}
              variant={'text'}
              onClick={handleAnswerQuestionsButton}
            >
              Fyll i avslutsfrågorna
            </Button>
          </CardActions>
        )}
        {isMigrated && answersRequired && (
          <Box mt={1}>
            <Typography>
              Avslutsfrågorna är besvarade i tidigare system.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

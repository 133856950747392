import React, { useEffect } from 'react'
import {
  Box,
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import {
  KarriarVagledningStatus,
  IKarriarVagledningProgression,
} from '@local/src/Views/Case/Case.types'
import { fetchUserJmtStatus } from '@local/src/Views/Case/Case.actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserStatusStateSelector } from '@local/src/Views/Case/Case.selectors'
import { FetchingState } from '@local/src/Common.types'
import AccordionBox from '@local/src/Components/AccordionBox/AccordionBox'
import { useIsSmallScreen } from '@local/src/Hooks'

import { getKarriarVagledningProgression } from './helper'

interface KarriarVagledningProgressionProps {
  clientId: string
  karriarVagledningProgression: IKarriarVagledningProgression
}

const KarriarVagledningProgression = ({
  clientId,
  karriarVagledningProgression,
}: KarriarVagledningProgressionProps) => {
  const userStatusLoading = useSelector(fetchUserStatusStateSelector)
  const userDoneLoading = userStatusLoading === FetchingState.SUCCESS
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useIsSmallScreen({ onlyMobile: true })

  const accordionWrapperOnMobile = (children: JSX.Element) =>
    isMobile ? (
      <Card sx={{ pl: 3, pr: 3 }}>
        <AccordionBox label="Karriärvägledning">{children}</AccordionBox>
      </Card>
    ) : (
      children
    )

  useEffect(() => {
    // If the karriarguide status is not COMPLETED we need to fetch the correct status from our jmt-api
    if (
      userDoneLoading &&
      karriarVagledningProgression?.karriarguide !==
        KarriarVagledningStatus.COMPLETED
    ) {
      dispatch(fetchUserJmtStatus(clientId))
    }
  }, [clientId, userDoneLoading])

  if (!karriarVagledningProgression) return null

  return accordionWrapperOnMobile(
    <Card
      variant="outlined"
      tabIndex={0}
      sx={{
        border: { xs: 'none', sm: '1px solid rgba(0, 0, 0, 0.12)' },
        pt: { sm: 3 },
        pb: { sm: 1 },
        px: { sm: 2 },
      }}
    >
      <Typography variant="h6">Karriärvägledning</Typography>
      <Typography
        variant="subtitle2"
        color={(theme) => theme.palette.text.secondary}
      >
        Framsteg i övningarna
      </Typography>
      <List>
        {Object.entries(karriarVagledningProgression).map((el, index) => {
          const progression = getKarriarVagledningProgression(
            el[0],
            el[1] as KarriarVagledningStatus,
            theme
          )
          return (
            <React.Fragment key={index}>
              <ListItem sx={{ paddingBottom: '8px', paddingTop: '8px' }}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{ width: '100%' }}
                >
                  <ListItemText
                    primary={progression.label}
                    primaryTypographyProps={{
                      variant: 'body1',
                      overflow: 'hidden',
                    }}
                  />
                  <Box sx={{ flexShrink: 0, minWidth: '109px' }}>
                    <Chip
                      variant={progression.chipVariant}
                      sx={{
                        '&::before': {
                          backgroundColor: progression.backgroundColor,
                        },
                      }}
                      label={progression.chipText}
                    />
                  </Box>
                </Stack>
              </ListItem>
              {(index === 3 && <Divider />) || (index === 6 && <Divider />)}
            </React.Fragment>
          )
        })}
      </List>
    </Card>
  )
}

export default KarriarVagledningProgression

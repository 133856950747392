import React from 'react'
import Panel from '@local/Components/Panel'
import { isEmpty } from 'ramda'
import { addMonths } from 'date-fns'
import { formatDateFromDateTime } from '@local/Utils/DateTime'
import { getFacetCount } from '@local/Utils/Facets'
import { markningChipDisplayNameDic } from '@local/src/Utils/Categories'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import { useLocation } from 'react-router-dom'
import { isKompetensstodsTab } from '@local/src/Views/Arenden/Arenden.helpers'
import { ArendeMarkning } from '@local/src/Views/Arenden/Arenden.types'
import { Box } from '@mui/material'
import { ForedragetSprak } from '@local/src/Views/Case/Case.types'
import { Facets } from '@local/src/Common.types'

import SuggestionsAutocomplete from '../SuggestionsAutocomplete/SuggestionsAutocomplete'
import FilterCheckBox from '../FilterCheckbox/FilterCheckbox'

import {
  IArendenFilterState,
  IDinaArendenFilterState,
} from './CasesFilter.types'
import {
  isFilterOptionInUse,
  isVisibleInKompetensstodsTab,
} from './CasesFilter.helpers'

interface CasesFilterProps {
  isDinaArenden: boolean
  emptyFilterState: IArendenFilterState | IDinaArendenFilterState
  facets: Facets
  subStatusFacets: Facets
  filter: IArendenFilterState | IDinaArendenFilterState
  setFilterParameters(
    filterParams: Partial<IArendenFilterState | IDinaArendenFilterState>
  ): void
}

export const CasesFilter = ({
  isDinaArenden,
  facets,
  subStatusFacets,
  filter,
  setFilterParameters,
}: CasesFilterProps): JSX.Element => {
  const { pathname } = useLocation()
  const isKompetensstod = isKompetensstodsTab(pathname)

  const toggleCheckbox = (name: string, value: string) => {
    const targetInputElementName = name as keyof (
      | IArendenFilterState
      | IDinaArendenFilterState
    )
    const currentListOfFilteredValues = filter[
      targetInputElementName
    ] as string[]
    let updatedListOfFilteredValues = []
    if (currentListOfFilteredValues.includes(value)) {
      updatedListOfFilteredValues = currentListOfFilteredValues.filter(
        (filterValue: string) => filterValue !== value
      )
    } else {
      updatedListOfFilteredValues = [...currentListOfFilteredValues, value]
    }

    setFilterParameters({
      [targetInputElementName]: updatedListOfFilteredValues,
    })
  }

  const toggleRadgivningSlutDatumMax = () => {
    let newMaxDate = formatDateFromDateTime(addMonths(new Date(), 6).toString())
    if (!isEmpty(filter.radgivningSlutDatumMax)) {
      newMaxDate = ''
    }
    setFilterParameters({
      radgivningSlutDatumMax: newMaxDate,
    })
  }

  const setSuggestionValue = (
    filterParameter: string,
    selectedOptions: string | string[]
  ) => {
    const selectedValues = selectedOptions ? selectedOptions : []
    setFilterParameters({
      [filterParameter]: selectedValues,
    })
  }

  const filters: { title: string; isInUse: boolean; content: JSX.Element }[] = [
    ...(isKompetensstod
      ? [
          {
            title: 'Rådgivare',
            isInUse: isFilterOptionInUse('Rådgivare', filter),
            content: (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                my={1}
                mr={2}
                ml={1.5}
              >
                <SuggestionsAutocomplete
                  multiple
                  propertyName="radgivareNamn"
                  label="Rådgivare"
                  size="small"
                  value={filter.radgivareNamn}
                  getSelectedValues={(radgivareNamn: string | string[]) =>
                    setSuggestionValue('radgivareNamn', radgivareNamn)
                  }
                  filterState={filter}
                  disabled={filter.saknarRadgivare}
                />
                <FilterCheckBox
                  label="Ingen rådgivare"
                  isChecked={filter.saknarRadgivare}
                  key="saknarRadgivare"
                  listItemVariant={false}
                  onClick={() =>
                    setFilterParameters({
                      saknarRadgivare: !filter.saknarRadgivare,
                      radgivareNamn:
                        !filter.saknarRadgivare === true
                          ? []
                          : filter.radgivareNamn,
                    })
                  }
                />
              </Box>
            ),
          },
        ]
      : []),
    {
      title: 'Ärendestatus',
      isInUse: isFilterOptionInUse('Ärendestatus', filter),
      content: (
        <>
          {!isKompetensstod && (
            <>
              <FilterCheckBox
                label="Nya för mig"
                key="NyttForMig"
                onClick={() => toggleCheckbox('subStatusar', 'NyttForMig')}
                facetCount={getFacetCount(
                  subStatusFacets,
                  'SubStatusar',
                  'NyttForMig'
                )}
                isChecked={filter.subStatusar.includes('NyttForMig')}
              />
              <FilterCheckBox
                label="Ej kontaktade"
                key="KlientEjKontaktad"
                onClick={() =>
                  toggleCheckbox('subStatusar', 'KlientEjKontaktad')
                }
                facetCount={getFacetCount(
                  subStatusFacets,
                  'SubStatusar',
                  'KlientEjKontaktad'
                )}
                isChecked={filter.subStatusar.includes('KlientEjKontaktad')}
              />
            </>
          )}
          <FilterCheckBox
            label="Aktuella"
            key="Aktuellt"
            onClick={() => toggleCheckbox('subStatusar', 'Aktuellt')}
            facetCount={getFacetCount(
              subStatusFacets,
              'SubStatusar',
              'Aktuellt'
            )}
            isChecked={filter.subStatusar.includes('Aktuellt')}
          />
          {!isKompetensstod && !isDinaArenden && (
            <>
              <FilterCheckBox
                label="Nya"
                key="Nytt"
                onClick={() => toggleCheckbox('subStatusar', 'Nytt')}
                facetCount={getFacetCount(
                  subStatusFacets,
                  'SubStatusar',
                  'Nytt'
                )}
                isChecked={filter.subStatusar.includes('Nytt')}
              />
              <FilterCheckBox
                label="Fördelning"
                key="Fordelning"
                onClick={() => toggleCheckbox('subStatusar', 'Fordelning')}
                facetCount={getFacetCount(
                  subStatusFacets,
                  'SubStatusar',
                  'Fordelning'
                )}
                isChecked={filter.subStatusar.includes('Fordelning')}
              />
              <FilterCheckBox
                label="Omfördelning"
                key="ForOmfordelning"
                onClick={() => toggleCheckbox('subStatusar', 'ForOmfordelning')}
                facetCount={getFacetCount(
                  subStatusFacets,
                  'SubStatusar',
                  'ForOmfordelning'
                )}
                isChecked={filter.subStatusar.includes('ForOmfordelning')}
              />
            </>
          )}
          <FilterCheckBox
            label="Avslutade"
            key="Avslutat"
            onClick={() => toggleCheckbox('subStatusar', 'Avslutat')}
            facetCount={getFacetCount(
              subStatusFacets,
              'SubStatusar',
              'Avslutat'
            )}
            isChecked={filter.subStatusar.includes('Avslutat')}
          />
        </>
      ),
    },
    {
      title: 'Avslutskoder',
      isInUse: isFilterOptionInUse('Avslutskoder', filter),
      content: (
        <>
          {facets?.AvslutsKoder?.map(
            (avslutsKod: { value: string; count: number }) =>
              avslutsKod.value && (
                <FilterCheckBox
                  label={avslutsKod.value}
                  key={`avslutskod-${avslutsKod.value}`}
                  onClick={() =>
                    toggleCheckbox('radgivningAvslutsKoder', avslutsKod.value)
                  }
                  facetCount={formatAmount(avslutsKod.count)}
                  isChecked={filter.radgivningAvslutsKoder.includes(
                    avslutsKod.value
                  )}
                />
              )
          )}
        </>
      ),
    },
    {
      title: 'Ärendemärkning',
      isInUse: isFilterOptionInUse('Ärendemärkning', filter),
      content: (
        <>
          {!isKompetensstod && (
            <>
              <FilterCheckBox
                label={
                  markningChipDisplayNameDic[ArendeMarkning.Chefsomstallning]
                }
                key={ArendeMarkning.Chefsomstallning}
                onClick={() =>
                  toggleCheckbox(
                    'arendeMarkningar',
                    ArendeMarkning.Chefsomstallning
                  )
                }
                isChecked={filter.arendeMarkningar.includes(
                  ArendeMarkning.Chefsomstallning
                )}
              />
              <FilterCheckBox
                label={markningChipDisplayNameDic[ArendeMarkning.ForstarktStod]}
                key={ArendeMarkning.ForstarktStod}
                onClick={() =>
                  toggleCheckbox(
                    'arendeMarkningar',
                    ArendeMarkning.ForstarktStod
                  )
                }
                isChecked={filter.arendeMarkningar.includes(
                  ArendeMarkning.ForstarktStod
                )}
              />
              <FilterCheckBox
                label={markningChipDisplayNameDic[ArendeMarkning.StartaForetag]}
                key={ArendeMarkning.StartaForetag}
                onClick={() =>
                  toggleCheckbox(
                    'arendeMarkningar',
                    ArendeMarkning.StartaForetag
                  )
                }
                isChecked={filter.arendeMarkningar.includes(
                  ArendeMarkning.StartaForetag
                )}
              />
            </>
          )}
          <FilterCheckBox
            label={markningChipDisplayNameDic[ArendeMarkning.Studier]}
            key={ArendeMarkning.Studier}
            onClick={() =>
              toggleCheckbox('arendeMarkningar', ArendeMarkning.Studier)
            }
            isChecked={filter.arendeMarkningar.includes(ArendeMarkning.Studier)}
          />
          <FilterCheckBox
            label={markningChipDisplayNameDic[ArendeMarkning.Undantagsgrupp]}
            key={ArendeMarkning.Undantagsgrupp}
            onClick={() =>
              setFilterParameters({ undantagsgrupp: !filter.undantagsgrupp })
            }
            isChecked={filter.undantagsgrupp}
          />
        </>
      ),
    },
    {
      title: 'Begäran om yttrande',
      isInUse: isFilterOptionInUse('Begäran om yttrande', filter),
      content: (
        <>
          <FilterCheckBox
            label="Ny begäran"
            key="Ny"
            onClick={() => toggleCheckbox('begaranYttrandeStatusar', 'Ny')}
            isChecked={filter.begaranYttrandeStatusar?.includes('Ny')}
            facetCount={getFacetCount(facets, 'BegaranYttrandeStatusar', 'Ny')}
          />
          <FilterCheckBox
            label="Under bedömning"
            key="UnderBedomning"
            onClick={() =>
              toggleCheckbox('begaranYttrandeStatusar', 'UnderBedomning')
            }
            isChecked={filter.begaranYttrandeStatusar?.includes(
              'UnderBedomning'
            )}
            facetCount={getFacetCount(
              facets,
              'BegaranYttrandeStatusar',
              'UnderBedomning'
            )}
          />
          <FilterCheckBox
            label="Skickad till CSN"
            key="Skickad"
            onClick={() => toggleCheckbox('begaranYttrandeStatusar', 'Skickad')}
            isChecked={filter.begaranYttrandeStatusar?.includes('Skickad')}
            facetCount={getFacetCount(
              facets,
              'BegaranYttrandeStatusar',
              'Skickad'
            )}
          />
        </>
      ),
    },
    {
      title: 'Studiestödsansökan',
      isInUse: isFilterOptionInUse('Studiestödsansökan', filter),
      content: (
        <>
          <FilterCheckBox
            label="Ny studiestödsansökan"
            key="studiestodAnsokanStatusNy"
            onClick={() => toggleCheckbox('studiestodAnsokanStatusar', 'Ny')}
            isChecked={filter.studiestodAnsokanStatusar?.includes('Ny')}
            facetCount={getFacetCount(
              facets,
              'StudiestodAnsokanStatusar',
              'Ny'
            )}
          />
          <FilterCheckBox
            label="Handläggs"
            key="studiestodAnsokanStatusHandlaggs"
            onClick={() =>
              toggleCheckbox('studiestodAnsokanStatusar', 'Handlaggs')
            }
            isChecked={filter.studiestodAnsokanStatusar?.includes('Handlaggs')}
            facetCount={getFacetCount(
              facets,
              'StudiestodAnsokanStatusar',
              'Handlaggs'
            )}
          />
          <FilterCheckBox
            label="Godkänd"
            key="studiestodAnsokanStatusGodkand"
            onClick={() =>
              toggleCheckbox('studiestodAnsokanStatusar', 'Godkand')
            }
            isChecked={filter.studiestodAnsokanStatusar?.includes('Godkand')}
            facetCount={getFacetCount(
              facets,
              'StudiestodAnsokanStatusar',
              'Godkand'
            )}
          />
          <FilterCheckBox
            label="Godkänn köpt utbildning"
            key="AvvaktarSvar"
            onClick={() =>
              toggleCheckbox('utbildningsAnmalanStatusar', 'AvvaktarSvar')
            }
            isChecked={filter.utbildningsAnmalanStatusar?.includes(
              'AvvaktarSvar'
            )}
            facetCount={getFacetCount(
              facets,
              'UtbildningsAnmalanStatusar',
              'AvvaktarSvar'
            )}
          />
        </>
      ),
    },
    {
      title: 'Språk',
      isInUse: isFilterOptionInUse('Språk', filter),
      content: (
        <>
          <FilterCheckBox
            label="Svenska"
            key="foredragetSprakSvenska"
            onClick={() =>
              toggleCheckbox('klientForedragnaSprak', ForedragetSprak.SWEDISH)
            }
            isChecked={filter.klientForedragnaSprak?.includes(
              ForedragetSprak.SWEDISH
            )}
          />
          <FilterCheckBox
            label="Engelska"
            key="foredragetSprakEngelska"
            onClick={() =>
              toggleCheckbox('klientForedragnaSprak', ForedragetSprak.ENGLISH)
            }
            isChecked={filter.klientForedragnaSprak?.includes(
              ForedragetSprak.ENGLISH
            )}
          />
        </>
      ),
    },
    ...(isDinaArenden
      ? [
          {
            title: 'Kontakt',
            isInUse: isFilterOptionInUse('Kontakt', filter),
            content: (
              <FilterCheckBox
                label="Inga inbokade möten"
                key="saknarInbokatMote"
                onClick={() =>
                  setFilterParameters({
                    saknarInbokatMote: !filter.saknarInbokatMote,
                  })
                }
                isChecked={filter.saknarInbokatMote}
              />
            ),
          },
        ]
      : []),

    {
      title: 'Tid',
      isInUse: isFilterOptionInUse('Tid', filter),
      content: (
        <>
          <FilterCheckBox
            label="Risk"
            key="RiskForUppsagningPgaArbetsbrist"
            onClick={() =>
              toggleCheckbox(
                'orsakerAnsokan',
                'RiskForUppsagningPgaArbetsbrist'
              )
            }
            isChecked={filter.orsakerAnsokan.includes(
              'RiskForUppsagningPgaArbetsbrist'
            )}
            facetCount={getFacetCount(
              facets,
              'OrsakerAnsokan',
              'RiskForUppsagningPgaArbetsbrist'
            )}
          />
          <FilterCheckBox
            label="1,5 åring"
            key="radgivningSlutDatumMax"
            onClick={toggleRadgivningSlutDatumMax}
            isChecked={filter.radgivningSlutDatumMax ? true : false}
            facetCount={getFacetCount(
              facets,
              'RadgivningSlutDatum',
              'EttOchEttHalvtAring'
            )}
          />
        </>
      ),
    },
    {
      title: 'Plats',
      isInUse: isFilterOptionInUse('Plats', filter),
      content: (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          my={1}
          mr={2}
          ml={1.5}
        >
          <SuggestionsAutocomplete
            multiple
            propertyName="klientOrter"
            label="Bostadsort"
            size="small"
            value={filter.klientOrter}
            getSelectedValues={(klientOrter: string | string[]) =>
              setSuggestionValue('klientOrter', klientOrter)
            }
            filterState={filter}
          />
          <SuggestionsAutocomplete
            multiple
            propertyName="klientKommuner"
            label="Kommun"
            size="small"
            value={filter.klientKommuner}
            getSelectedValues={(klientKommuner: string | string[]) =>
              setSuggestionValue('klientKommuner', klientKommuner)
            }
            filterState={filter}
          />
          <SuggestionsAutocomplete
            multiple
            propertyName="klientLan"
            label="Län"
            size="small"
            value={filter.klientLan}
            getSelectedValues={(klientLan: string | string[]) =>
              setSuggestionValue('klientLan', klientLan)
            }
            filterState={filter}
          />
        </Box>
      ),
    },
    {
      title: 'Företag',
      isInUse: isFilterOptionInUse('Företag', filter),
      content: (
        <Box mt={1} mr={2} ml={1.5} my={1}>
          <SuggestionsAutocomplete
            multiple
            propertyName="foretagNamn"
            label="Företag"
            size="small"
            value={filter.foretagNamn}
            getSelectedValues={(foretagNamn: string | string[]) =>
              setSuggestionValue('foretagNamn', foretagNamn)
            }
            filterState={filter}
          />
        </Box>
      ),
    },
    {
      title: 'Befattning',
      isInUse: isFilterOptionInUse('Befattning', filter),
      content: (
        <Box mt={1} mr={2} ml={1.5} my={1}>
          <SuggestionsAutocomplete
            multiple
            propertyName="befattningar"
            label="Befattning"
            size="small"
            value={filter.befattningar}
            getSelectedValues={(befattningar: string | string[]) =>
              setSuggestionValue('befattningar', befattningar)
            }
            filterState={filter}
          />
        </Box>
      ),
    },
    {
      title: 'Projekt',
      isInUse: isFilterOptionInUse('Projekt', filter),
      content: (
        <Box mt={1} mr={2} ml={1.5} my={1}>
          <SuggestionsAutocomplete
            multiple
            size="small"
            propertyName="projektNamn"
            label="Projektnamn"
            value={filter.projektNamn}
            getSelectedValues={(projektNamn: string | string[]) =>
              setSuggestionValue('projektNamn', projektNamn)
            }
            filterState={filter}
          />
        </Box>
      ),
    },
    ...(isDinaArenden
      ? []
      : [
          {
            title: 'TRR-organisation',
            isInUse: isFilterOptionInUse('TRR-organisation', filter),
            content: (
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                my={1}
                mr={2}
                ml={1.5}
              >
                <SuggestionsAutocomplete
                  multiple
                  size="small"
                  propertyName="radgivareNamn"
                  label="Rådgivare"
                  value={filter.radgivareNamn}
                  getSelectedValues={(radgivareNamn: string | string[]) =>
                    setSuggestionValue('radgivareNamn', radgivareNamn)
                  }
                  filterState={filter}
                />
                <SuggestionsAutocomplete
                  multiple
                  size="small"
                  propertyName="teamNamn"
                  label="Team"
                  value={filter.teamNamn}
                  getSelectedValues={(teamNamn: string | string[]) =>
                    setSuggestionValue('teamNamn', teamNamn)
                  }
                  filterState={filter}
                />
                <SuggestionsAutocomplete
                  multiple
                  size="small"
                  propertyName="arbetsmarknadsomraden"
                  label="Arbetsmarknadsområde"
                  value={filter.arbetsmarknadsomraden}
                  getSelectedValues={(
                    arbetsmarknadsomraden: string | string[]
                  ) =>
                    setSuggestionValue(
                      'arbetsmarknadsomraden',
                      arbetsmarknadsomraden
                    )
                  }
                  filterState={filter}
                />
              </Box>
            ),
          },
        ]),
  ]

  return (
    <Box>
      {filters.map((filter, index) => {
        if (isKompetensstod && !isVisibleInKompetensstodsTab(filter.title))
          return null

        return (
          <Panel
            key={index}
            isInUse={filter.isInUse}
            title={filter.title}
            defaultOpen={index === 0}
          >
            {filter.content}
          </Panel>
        )
      })}
    </Box>
  )
}

export default CasesFilter

import { FetchingState } from '@local/src/Common.types'
import { path } from 'ramda'

import { IRadgivare } from '../Case.types'

import {
  AteroppnaPreconditions,
  AvslutOption,
  Befattning,
  Bransch,
  Avslutshandelse,
} from './Avslut.types'

export const avslutskoderSelector = path<AvslutOption[]>([
  'avslut',
  'avslutskoder',
])
export const activeRadgivareSelector = path<IRadgivare[]>([
  'avslut',
  'activeRadgivare',
])
export const branscherSelector = path<Bransch[]>(['avslut', 'branscher'])
export const befattningarSelector = path<Befattning[]>([
  'avslut',
  'befattningar',
])
export const orterSelector = path<string[]>(['avslut', 'orter'])
export const fetchingOrterStateSelector = path<FetchingState>([
  'avslut',
  'fetchingOrterState',
])
export const fetchingBefattningarStateSelector = path<FetchingState>([
  'avslut',
  'fetchingBefattningarState',
])
export const fetchingBranscherStateSelector = path<FetchingState>([
  'avslut',
  'fetchingBranscherState',
])
export const ateroppnaPreconditionsSelector = path<AteroppnaPreconditions>([
  'avslut',
  'ateroppnaPreconditions',
])
export const fetchingAvslutskoderStateSelector = path<FetchingState>([
  'avslut',
  'fetchingAvslutskoderState',
])
export const fetchingAteroppnaPreconditionsStateSelector = path<FetchingState>([
  'avslut',
  'fetchingAteroppnaPreconditionsState',
])
export const fetchingActiveRadgivareStateSelector = path<FetchingState>([
  'avslut',
  'fetchingActiveRadgivareState',
])
export const ateroppnaArendeStateSelector = path<FetchingState>([
  'avslut',
  'ateroppnaArendeState',
])
export const avslutaArendeStateSelector = path<FetchingState>([
  'avslut',
  'avslutaArendeState',
])
export const submittingQuestionResponsesStateSelector = path<FetchingState>([
  'avslut',
  'submittingQuestionResponsesState',
])

export const tidigareAvslutSelector = path<Avslutshandelse[]>([
  'avslut',
  'tidigareAvslut',
])
export const tidigareAvslutStateSelector = path<FetchingState>([
  'avslut',
  'tidigareAvslutState',
])

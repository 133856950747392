import React, { useEffect, useState } from 'react'
import { useLazyGetFacetsForPropertyQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useToggleFilterOption } from '@local/src/Hooks'
import FilterAccordion from '@local/src/Components/FilterAccordion/FilterAccordion'

import { useArbetsuppgiftFilterContext } from '../ArbetsuppgiftFilterContext'
import CategoryFilter from '../FilterTypes/CategoryFilter'

interface CategoryFilterAccordionProps {
  open: boolean
  onOpen: () => void
}

const CategoryFilterAccordion = ({
  open,
  onOpen,
}: CategoryFilterAccordionProps) => {
  const [isOpen, setIsOpen] = useState(open)
  const {
    filters,
    initialFacets,
    setFilter: handleFilter,
  } = useArbetsuppgiftFilterContext()

  const [fetchFacets, data] = useLazyGetFacetsForPropertyQuery()
  const categoryKey = 'uppgiftsTyper'
  const { toggleFilterOption: toggleCategoryFilter } = useToggleFilterOption(
    filters.uppgiftsTyper,
    categoryKey,
    handleFilter
  )

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isOpen) {
      onOpen()
      fetchFacets({ key: 'Category', filter: filters })
    }
  }, [isOpen])

  return (
    <FilterAccordion
      label="Kategori"
      open={isOpen}
      indicatorCount={filters.uppgiftsTyper?.length}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <CategoryFilter
        categories={data?.data?.Category}
        initialCategories={initialFacets?.Category}
        handleCheckbox={toggleCategoryFilter}
        selectedCategories={filters.uppgiftsTyper}
      />
    </FilterAccordion>
  )
}

export default CategoryFilterAccordion

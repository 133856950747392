export const medarbetareBasename = '/medarbetare'

const { pathname, href } = document.location

export const medarbetareNoPullRequestBasepath = pathname.substring(
  0,
  pathname.indexOf(medarbetareBasename) + medarbetareBasename.length
)

export const medarbetarePullRequestBasepath =
  pathname.substring(
    0,
    pathname.indexOf(
      '/',
      pathname.indexOf(medarbetareBasename) + medarbetareBasename.length + 1
    )
  ) + medarbetareBasename

export const medarbetareBasepath = href.includes('pull-request')
  ? medarbetarePullRequestBasepath
  : medarbetareNoPullRequestBasepath

export const dinaArendenPath = '/dina-arenden'
export const fordelaArendenPath = '/fordela-arenden'
export const startklarPath = '/startklar'
export const allaArendenPath = '/arenden/alla'
export const arendenPath = '/arenden'
export const omstallningsArendenPath = '/arenden/omstallning'
export const kompetensstodsArendenPath = '/arenden/kompetensstod'

export const dinaArendenArendeBasename = `${dinaArendenPath}/arende`
export const startklarCaseBasename = `${startklarPath}/arende`
export const arendeBasename = `${allaArendenPath}/arende`
export const tioBasename = `${omstallningsArendenPath}/arende`
export const tiaBasename = `${kompetensstodsArendenPath}/arende`

export const getUrlByCaseEnv = (caseEnv: string, query?: string): string => {
  const queryString = query ? '/' + query : ''
  return medarbetareBasepath + caseEnv + queryString
}

export const orgCompanyLinkBuilder = (
  environment: 'dev' | 'test' | 'prod',
  companyGuid: string
): string => {
  const baseUrl = `https://${environment === 'dev' ? 'dev-next.' : environment === 'test' ? 'test-next.' : ''}trr.se`

  return `${baseUrl}/medarbetare/organisationsregistret/foretag/detaljer/${companyGuid}`
}

export const orgProjectLinkBuilder = (
  environment: 'dev' | 'test' | 'prod',
  companyGuid: string,
  projectGuid: string
): string => {
  const baseUrl = `https://${environment === 'dev' ? 'dev-next.' : environment === 'test' ? 'test-next.' : ''}trr.se`
  return `${baseUrl}/medarbetare/organisationsregistret/foretag/detaljer/${companyGuid}/projekt/${projectGuid}`
}

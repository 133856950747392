import { FetchingState } from '@local/src/Common.types'
import { updatePreferredLanguage } from '@local/src/Views/Case/Case.actions'
import {
  klientSelector,
  updatePreferredLanguageState,
} from '@local/src/Views/Case/Case.selectors'
import { ForedragetSprak } from '@local/src/Views/Case/Case.types'
import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface SetPreferredLanguageProps {
  disablePadding?: boolean
}
const SetPreferredLanguage = ({
  disablePadding = false,
}: SetPreferredLanguageProps): JSX.Element => {
  const dispatch = useDispatch()
  const kund = useSelector(klientSelector)
  const updateLanguageState = useSelector(updatePreferredLanguageState)
  const previousUpdateLanguageState = useRef(updateLanguageState)
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = (_: never, checked: boolean) => {
    const request = {
      klientId: kund.id,
      languageCode: checked ? ForedragetSprak.ENGLISH : ForedragetSprak.SWEDISH,
    }
    dispatch(updatePreferredLanguage(request))
  }

  useEffect(() => {
    setIsChecked(kund?.foredragetSprak === ForedragetSprak.ENGLISH)
  }, [kund])

  useEffect(() => {
    if (
      updateLanguageState === FetchingState.SUCCESS &&
      previousUpdateLanguageState.current === FetchingState.LOADING
    ) {
      setIsChecked(!isChecked)
    }
    previousUpdateLanguageState.current = updateLanguageState
  }, [updateLanguageState])

  if (!kund) {
    return <></>
  }

  return (
    <FormControlLabel
      label="Engelska"
      control={
        <Checkbox
          checked={isChecked}
          onChange={handleChange}
          sx={{ py: disablePadding ? 0 : 1 }}
        />
      }
    />
  )
}

export default SetPreferredLanguage

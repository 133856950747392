import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { FetchingState, ISelectOption } from '@local/src/Common.types'
import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { klientSelector } from '@local/src/Views/Case/Case.selectors'
import { useFormik } from 'formik'
import { boolean, date, number, object, string } from 'yup'
import Loading from '@local/src/Components/Loading/Loading'
import { useHistory } from 'react-router-dom'
import useScrollToFirstError from '@local/src/Hooks/useScrollToFirstError'

import HandleActivities from '../../HandleActivities/HandleActivities'
import {
  ISubmitQuestionResponsesRequestParameters,
  avslutaArendeWithQuestions,
  fetchBefattningar,
  fetchBranscher,
  fetchOrter,
  submitQuestionsResponses,
} from '../../../Avslut.actions'
import {
  avslutaArendeStateSelector,
  befattningarSelector,
  branscherSelector,
  fetchingBefattningarStateSelector,
  fetchingBranscherStateSelector,
  fetchingOrterStateSelector,
  orterSelector,
} from '../../../Avslut.selectors'
import {
  AvslutaArendeWithQuestionsRequest,
  Avslutskod,
  Befattning,
  Bransch,
  JobFindingOption,
  AnstallningFormValues,
} from '../../../Avslut.types'
import {
  antalAnstalldaOptions,
  convertToAvslutsfragorSvar,
  forandringOptions,
  hasError,
  howKundFoundJobOptions,
} from '../Common/AvslutForms.helpers'
import BranschModal from '../Common/BranschModal/BranschModal'
import MatchningModal from '../Common/MatchningModal/MatchningModal'
import AvslutaWithReminder from '../Common/AvslutaWithReminder/AvslutaWithReminder'

interface AnstallningProps {
  arendeId: string
  avslutskod: Avslutskod.NyAnstallning | Avslutskod.OvrigAnstallning
  title: string
  description: string
  requireLosningStartdatum: boolean
  isUnhandledAvslutshandelse?: boolean
  avslutsdatum?: Date
  avslutsId?: string
}

const Anstallning = ({
  arendeId,
  avslutskod,
  title,
  description,
  requireLosningStartdatum,
  isUnhandledAvslutshandelse,
  avslutsdatum,
  avslutsId,
}: AnstallningProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const kund = useSelector(klientSelector)
  const branscher = useSelector(branscherSelector)
  const befattningar = useSelector(befattningarSelector)
  const fetchingBefattningar = useSelector(fetchingBefattningarStateSelector)
  const orter = useSelector(orterSelector)
  const fetchingBranscher = useSelector(fetchingBranscherStateSelector)
  const fetchingOrter = useSelector(fetchingOrterStateSelector)
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)

  const loading =
    fetchingBranscher === FetchingState.LOADING ||
    fetchingOrter === FetchingState.LOADING

  const [activitiesHandled, setActivitiesHandled] = useState(false)
  const [showActivitiesErrorMessage, setShowActivitiesErrorMessage] =
    useState(false)
  const [foundJobByMatchningstjant, setFoundJobByMatchningstjant] =
    useState(null)

  useEffect(() => {
    if (orter.length === 0 || branscher.length === 0) {
      dispatch(fetchBranscher())
      dispatch(fetchOrter())
    }
  }, [])

  useEffect(() => {
    if (orter?.length > 0 && kund) {
      const kundOrt = orter.find(
        (ort) => ort.toLocaleLowerCase() === kund.ort?.toLocaleLowerCase()
      )
      kundOrt && formik.setFieldValue('town', kundOrt)
    }
  }, [orter])

  const handleFetchBefattningar = (searchTerm: string) => {
    dispatch(fetchBefattningar(searchTerm))
  }

  const handleCancel = () => history.goBack()

  const handleSubmit = (values: AnstallningFormValues) => {
    if (isUnhandledAvslutshandelse && formik.isValid) {
      const request: ISubmitQuestionResponsesRequestParameters = {
        arendeId,
        avslutskod,
        avslutsId,
        frageSvar: convertToAvslutsfragorSvar(values),
      }
      dispatch(submitQuestionsResponses(request))
      return
    }

    if (!activitiesHandled) {
      setShowActivitiesErrorMessage(true)
      return
    }

    if (formik.isValid && activitiesHandled) {
      const request: AvslutaArendeWithQuestionsRequest = {
        arendeId,
        radgivningAvslutskod: avslutskod,
        losningStartdatum: requireLosningStartdatum
          ? values.startDatum.toISOString()
          : null,
        radgivningAvslutsdatum: values.avslutsDatum.toISOString(),
        avslutsFragorSvar: convertToAvslutsfragorSvar(values),
      }
      dispatch(avslutaArendeWithQuestions(request))
    }
  }

  const initialValues: AnstallningFormValues = {
    avslutsDatum: new Date(),
    weeklyHours: 40,
    companyName: undefined,
    isTownAbroad: false,
    publicSector: false,
    staffingCompany: false,
    town: undefined,
    industry: undefined,
    requiresMoving: false,
    relativeSalary: undefined,
    relativeLevel: undefined,
    isWhiteCollar: true,
    title: undefined,
    employmentDate: new Date(),
    startDatum: null,
    requireLosningStartdatum: requireLosningStartdatum,
    amountOfEmployees: undefined,
    howKundFoundJob: undefined,
  }

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues,
    validationSchema,
    validateOnMount: true,
  })

  useScrollToFirstError(formik.isValid, formik.isSubmitting, 24)

  const handleFoundJobByMatchningstjanstChange = (_: never, value: string) => {
    setFoundJobByMatchningstjant(value === 'true')
    formik.setFieldValue('howKundFoundJob', null)
  }

  const handleAvslutWithReminderClick = () => {
    if (!activitiesHandled) {
      setShowActivitiesErrorMessage(true)
    }
    if (hasError(formik, 'startDatum', true)) {
      formik.setFieldTouched('startDatum')
      formik.setSubmitting(true)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Stack
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="h4">{title}</Typography>
      {!isUnhandledAvslutshandelse && (
        <>
          <Typography variant="body1" whiteSpace="pre-line" mb={3} mt={2}>
            {description}
          </Typography>
          <DesktopDatePicker
            label="Avslutsdatum"
            disableFuture
            sx={{ maxWidth: '228px' }}
            onChange={(value: Date) =>
              void formik.setFieldValue('avslutsDatum', value)
            }
            value={
              isUnhandledAvslutshandelse
                ? avslutsdatum
                : formik.values.avslutsDatum
            }
            slotProps={{
              textField: {
                name: 'avslutsDatum',
                error: hasError(formik, 'avslutsDatum', true),
                helperText: formik.errors?.avslutsDatum as string,
                onBlur: formik.handleBlur,
              },
            }}
          />
        </>
      )}

      {requireLosningStartdatum && (
        <Box my={3}>
          <Typography variant="body1" mb={2}>
            För att beräkna AGE korrekt ska startdatum för anställningen anges.
            Om omställningskunden påbörjat sin anställning, fyll i dagens datum.
          </Typography>
          <DesktopDatePicker
            label="Startdatum"
            disablePast
            sx={{ maxWidth: '228px' }}
            onChange={(value: Date) =>
              void formik.setFieldValue('startDatum', value)
            }
            value={formik.values.startDatum}
            slotProps={{
              textField: {
                name: 'startDatum',
                error: hasError(formik, 'startDatum'),
                helperText:
                  hasError(formik, 'startDatum') &&
                  (formik.errors?.startDatum as string),
                onBlur: formik.handleBlur,
              },
            }}
          />
        </Box>
      )}

      <HandleActivities
        setActivitiesHandled={setActivitiesHandled}
        showErrorMessage={showActivitiesErrorMessage}
      />

      <Typography variant="h5" mt={5}>
        Arbetsgivaruppgifter
      </Typography>
      <Typography variant="body1">
        Namnet på det företag eller den organisation som är omställningskundens
        nya arbetsgivare.
      </Typography>

      <FormGroup sx={{ gap: 2, mt: 3 }}>
        <TextField
          sx={{ width: { xs: '100%', sm: '480px' } }}
          label="Arbetsgivare"
          name="companyName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyName}
          error={hasError(formik, 'companyName', false)}
          helperText={
            hasError(formik, 'companyName', false) && formik.errors?.companyName
          }
        />

        <FormControlLabel
          label="Det är ett bemanningsföretag"
          name="staffingCompany"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.staffingCompany}
          sx={{ alignSelf: 'baseline' }}
          control={<Checkbox />}
        />
      </FormGroup>

      <Typography variant="h6" mt={2}>
        Antal anställda
      </Typography>
      <Typography variant="body1" whiteSpace="pre-line" mt={1} mb={2}>
        Totalt antal anställda, inklusive alla arbetsställen med samma
        organisationsnummer.{'\n'}
        Går att hitta på{' '}
        <Link
          href="https://www.allabolag.se"
          target="_blank"
          referrerPolicy="no-referrer"
        >
          www.allabolag.se
        </Link>
      </Typography>
      <Dropdown
        label="Antal"
        name="amountOfEmployees"
        options={antalAnstalldaOptions}
        getOptionLabel={(value: ISelectOption) => value.label}
        getOptionValue={(value: ISelectOption) => value.value}
        value={formik.values.amountOfEmployees}
        onBlur={formik.handleBlur}
        error={hasError(formik, 'amountOfEmployees')}
        errorText={formik.errors?.amountOfEmployees}
        maxWidth="228px"
        onChange={formik.handleChange}
      />

      <Typography variant="h6" mt={5}>
        Bransch
      </Typography>
      <Typography variant="body1" mt={1}>
        Den bransch som arbetsgivaren verkar inom.
      </Typography>

      <FormGroup sx={{ mt: 3, gap: 3 }}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Autocomplete
            label="Bransch"
            name="industry"
            sx={{ width: { xs: '100%', sm: '480px' } }}
            options={branscher}
            value={formik.values.industry}
            getOptionLabel={(value: Bransch) => value.name}
            onChange={(value: Bransch) =>
              void formik.setFieldValue('industry', value)
            }
            onBlur={formik.handleBlur}
            error={hasError(formik, 'industry')}
            errorText={formik.errors?.industry as string}
          />
          <BranschModal branscher={branscher} />
        </Box>
        <FormControlLabel
          label="Arbetsgivaren verkar inom den offentliga sektorn"
          name="publicSector"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.publicSector}
          sx={{ alignSelf: 'baseline' }}
          control={<Checkbox />}
        />
      </FormGroup>

      <Typography variant="h5" mt={5}>
        Anställningsuppgifter
      </Typography>
      <DesktopDatePicker
        sx={{ maxWidth: '228px', mt: 4, mb: 4 }}
        label="Anställningsdatum"
        onChange={(value: Date) =>
          void formik.setFieldValue('employmentDate', value)
        }
        value={formik.values.employmentDate}
        slotProps={{
          textField: {
            name: 'employmentDate',
            error: hasError(formik, 'employmentDate', true),
            helperText: formik.errors?.employmentDate as string,
          },
        }}
      />

      <Autocomplete
        label="Befattning"
        name="title"
        sx={{ width: { xs: '100%', sm: '480px' } }}
        options={befattningar}
        getOptionLabel={(value: Befattning) => value.jobTitle}
        value={formik.values.title || undefined}
        inputValueChanged={(value) => handleFetchBefattningar(value)}
        onChange={(value: Befattning) =>
          void formik.setFieldValue('title', value)
        }
        onBlur={formik.handleBlur}
        loading={fetchingBefattningar === FetchingState.LOADING}
        error={hasError(formik, 'title')}
        errorText={formik.errors?.title as string}
      />

      <FormControl
        sx={{ my: 3 }}
        error={formik.errors?.isWhiteCollar !== undefined}
      >
        <Typography id="isWhiteCollar" variant="h6">
          Tjänsteman
        </Typography>
        <RadioGroup
          aria-labelledby="isWhiteCollar"
          name="isWhiteCollar"
          value={formik.values.isWhiteCollar}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          sx={{ alignSelf: 'baseline' }}
        >
          <FormControlLabel value="true" control={<Radio />} label="Ja" />
          <FormControlLabel value="false" control={<Radio />} label="Nej" />
        </RadioGroup>
        {hasError(formik, 'isWhiteCollar') && (
          <FormHelperText>{formik.errors?.isWhiteCollar}</FormHelperText>
        )}
      </FormControl>

      <Typography variant="h6" mb={1}>
        Befattningsnivå
      </Typography>
      <Typography variant="body1" mb={2}>
        Ange omställningskundens uppfattning om tjänsten har en högre, lika
        eller lägre befattningsnivå jämfört med den tjänst omställningskunden
        blev uppsagd ifrån.
      </Typography>
      <Dropdown
        label="Befattningsnivå"
        maxWidth="480px"
        name="relativeLevel"
        value={formik.values.relativeLevel}
        onChange={formik.handleChange}
        getOptionLabel={(value: ISelectOption) => value.label}
        getOptionValue={(value: ISelectOption) => value.value}
        options={forandringOptions}
        onBlur={formik.handleBlur}
        error={hasError(formik, 'relativeLevel')}
        errorText={formik.errors?.relativeLevel}
      />

      <Typography variant="h6" mt={3} mb={1}>
        Löneförändring
      </Typography>
      <Typography variant="body1" mb={2}>
        Ange omställningskundens uppfattning om lönenivån på den nya tjänsten
        (inkl andra ersättningar och förmåner) är högre, lika eller lägre
        jämfört med den tjänst omställningskunden blev uppsagd ifrån.
      </Typography>
      <Dropdown
        label="Löneförändring"
        maxWidth="480px"
        name="relativeSalary"
        value={formik.values.relativeSalary}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        getOptionLabel={(value: ISelectOption) => value.label}
        getOptionValue={(value: ISelectOption) => value.value}
        options={forandringOptions}
        error={hasError(formik, 'relativeSalary')}
        errorText={formik.errors?.relativeSalary}
      />

      <TextField
        name="weeklyHours"
        sx={{ maxWidth: '228px', mt: 4 }}
        label="Timmar per vecka"
        value={formik.values.weeklyHours}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={hasError(formik, 'weeklyHours', true)}
        helperText={formik.errors?.weeklyHours}
      />

      <Typography variant="h6" mt={3} mb={1}>
        Stationeringsort
      </Typography>
      <Typography variant="body1" mb={2}>
        Den ort där omställningskunden enligt anställningskontraktet är
        stationerad.
      </Typography>
      <Autocomplete
        label="Stationeringsort"
        name="town"
        options={orter}
        value={formik.values.town}
        sx={{ width: { xs: '100%', sm: '480px' } }}
        getOptionLabel={(value: string) => value}
        onChange={(value: string) => void formik.setFieldValue('town', value)}
        onBlur={formik.handleBlur}
        disabled={formik.values.isTownAbroad}
        error={hasError(formik, 'town')}
        errorText={formik.errors?.town}
      />

      <FormGroup sx={{ mt: 3, alignSelf: 'baseline' }}>
        <FormControlLabel
          label="Utlandsort"
          name="isTownAbroad"
          value={formik.values.isTownAbroad}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          control={<Checkbox />}
        />
        <FormControlLabel
          label="Omställningskunden behöver flytta"
          name="requiresMoving"
          value={formik.values.requiresMoving}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          control={<Checkbox />}
        />
      </FormGroup>

      <Typography variant="h5" mt={4}>
        Hur hittades anställningen?
      </Typography>
      <FormControl>
        <RadioGroup
          value={foundJobByMatchningstjant}
          onChange={handleFoundJobByMatchningstjanstChange}
          sx={{ alignSelf: 'baseline' }}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Via TRRs Matchningstjänster"
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="På annat sätt"
          />
        </RadioGroup>
        {foundJobByMatchningstjant === true && (
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <MatchningModal
              linkLabel="Vilken av TRRs Matchningstjänster ska jag välja?"
              modalLabel="Anställningen hittades via TRRs Matchningstjänster"
              options={howKundFoundJobOptions.filter(
                (item) => item.isMatchningstjanst
              )}
            />
            <Autocomplete
              label="Välj Matchningstjänst"
              name="howKundFoundJob"
              sx={{ width: { xs: '100%', sm: '480px' } }}
              options={howKundFoundJobOptions.filter(
                (item) => item.isMatchningstjanst
              )}
              onChange={(value: string) =>
                void formik.setFieldValue('howKundFoundJob', value)
              }
              onBlur={formik.handleBlur}
              getOptionLabel={(value: JobFindingOption) => value.label}
              value={formik.values.howKundFoundJob}
              error={hasError(formik, 'howKundFoundJob')}
            />
          </Box>
        )}
        {foundJobByMatchningstjant === false && (
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <MatchningModal
              linkLabel="Vilket annat sätt ska jag välja?"
              modalLabel="Anställningen hittades på annat sätt"
              options={howKundFoundJobOptions.filter(
                (item) => !item.isMatchningstjanst
              )}
            />
            <Autocomplete
              label="Välj sätt"
              name="howKundFoundJob"
              sx={{ width: { xs: '100%', sm: '480px' } }}
              options={howKundFoundJobOptions.filter(
                (item) => !item.isMatchningstjanst
              )}
              onChange={(value: string) =>
                void formik.setFieldValue('howKundFoundJob', value)
              }
              onBlur={formik.handleBlur}
              getOptionLabel={(value: JobFindingOption) => value.label}
              value={formik.values.howKundFoundJob}
              error={hasError(formik, 'howKundFoundJob')}
            />
          </Box>
        )}
        {hasError(formik, 'howKundFoundJob') && (
          <FormHelperText sx={{ color: 'error.main' }}>
            {formik.errors?.howKundFoundJob as string}
          </FormHelperText>
        )}
      </FormControl>

      <Divider sx={{ my: 5, width: '100%' }} />

      <Box
        display="flex"
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        gap={2}
      >
        {avslutaArendeState === FetchingState.LOADING ? (
          <Loading />
        ) : (
          <>
            {isUnhandledAvslutshandelse ? (
              <>
                <Button type="submit">Spara</Button>
                <Button variant="outlined" onClick={handleCancel}>
                  Avbryt
                </Button>
              </>
            ) : (
              <>
                <Button type="submit">Avsluta ärendet</Button>
                <AvslutaWithReminder
                  arendeId={arendeId}
                  avslutsKod={avslutskod}
                  avslutsdatum={formik.values.avslutsDatum}
                  requireLosningStartdatum={requireLosningStartdatum}
                  losningStartdatum={formik.values.startDatum}
                  hasError={
                    !activitiesHandled || hasError(formik, 'startDatum', true)
                  }
                  onClick={handleAvslutWithReminderClick}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Stack>
  )
}

export default Anstallning

const validationSchema = object<AnstallningFormValues>({
  avslutsDatum: date()
    .typeError('Avslutsdatum måste vara ett giltigt datum')
    .required('Ange avslutsdatum'),
  requireLosningStartdatum: boolean(),
  startDatum: date()
    .typeError('Startdatum måste vara ett giltigt datum')
    .nullable()
    .when('requireLosningStartdatum', {
      is: true,
      then: () =>
        date()
          .typeError('Startdatum måste vara ett giltigt datum')
          .required('Ange startdatum'),
    }),
  companyName: string()
    .required('Ange företagets namn')
    .max(128, 'Namnet får inte överskrida 128 tecken'),
  industry: object().required('Ange bransch'),
  staffingCompany: boolean().required(),
  publicSector: boolean().required(),
  amountOfEmployees: string().required(
    'Ange antal anställda på det nya företaget'
  ),
  employmentDate: date()
    .typeError('Anställningsdatum måste vara ett giltigt datum')
    .required('Ange anställningsdatum')
    .nullable(),
  title: object().required('Ange omställningskundens nya befattning'),
  isWhiteCollar: boolean().required(
    'Ange om omställningskunden i sin nya befattning är tjänsteman eller inte'
  ),
  relativeLevel: string().required(
    'Ange befattningsnivå på omställningskundens nya tjänst'
  ),
  relativeSalary: string().required(
    'Ange lönenivå på omställningskundens nya tjänst'
  ),
  weeklyHours: number()
    .required('Ange hur många timmar per vecka tjänsten avser')
    .typeError('Timmar måste anges i siffror')
    .min(1, 'Ange minst 1 timme per vecka')
    .max(99, 'Ange max 99 timmar per vecka'),
  isTownAbroad: boolean().required(),
  town: string()
    .when('isTownAbroad', {
      is: false,
      then: () => string().required('Ange stationeringsort'),
    })
    .when('isTownAbroad', {
      is: true,
      then: () => string().nullable(),
    }),
  requiresMoving: boolean().required(),
  howKundFoundJob: object().required(
    'Välj hur omställningskunden hittade jobbet'
  ),
})

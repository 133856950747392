import { lensPath, view } from 'ramda'
import { Facets, FetchingState } from '@local/src/Common.types'
import { ICaseListItem } from '@local/src/Components/ActionBasedCaseItem/ActionBasedCaseItem.types'

const facetsLens = lensPath(['dinaArenden', 'facets'])
const facetedHitsLens = lensPath(['dinaArenden', 'facetedHits'])
const fetchingCaseListFacetsStateLens = lensPath([
  'dinaArenden',
  'fetchingCaseListFacetsState',
])
const caseListLens = lensPath(['dinaArenden', 'caseObject', 'arenden'])
const fetchingCaseListStateLens = lensPath([
  'dinaArenden',
  'fetchingCaseListState',
])

const dinaArendenTotalHitsLens = lensPath([
  'dinaArenden',
  'caseObject',
  'totalHits',
])
export const dinaArendenTotalHits = view<unknown, number>(
  dinaArendenTotalHitsLens
)

const dinaArendenTotalPagesLens = lensPath([
  'dinaArenden',
  'caseObject',
  'totalPages',
])
export const dinaArendenTotalPages = view<unknown, number>(
  dinaArendenTotalPagesLens
)

const caseFetchingStateLens = lensPath(['selectedCase', 'caseFetchingState'])
const fetchingAdvisorListStateLens = lensPath([
  'dinaArenden',
  'fetchingAdvisorListState',
])

export const facets = view<unknown, Facets>(facetsLens)
export const facetedHits = view<unknown, number>(facetedHitsLens)
export const fetchingCaseListFacetsState = view<unknown, FetchingState>(
  fetchingCaseListFacetsStateLens
)
export const fetchingAdvisorListState = view(fetchingAdvisorListStateLens)
export const caseList = view<unknown, ICaseListItem[]>(caseListLens)
export const fetchingCaseListState = view(fetchingCaseListStateLens)
export const caseFetchingState = view<unknown, FetchingState>(
  caseFetchingStateLens
)

import React from 'react'
import { Card, List, Typography } from '@mui/material'
import { IMeeting } from '@local/src/Views/Case/Case.types'

import Meeting from './Meeting/Meeting'

const NextMeetings = ({ collection: meetings }: { collection: IMeeting[] }) => {
  const indexOfLastItem = (meetings || []).length - 1

  return (
    <Card variant="outlined" tabIndex={0} sx={{ px: 3, pt: 3, pb: 1 }}>
      <Typography variant="h6">Bokade möten</Typography>
      {(meetings || []).length > 0 ? (
        <List sx={{ paddingTop: '12px', paddingBottom: '16px' }}>
          {meetings.map((meeting, i) => (
            <Meeting
              key={meeting.id}
              meeting={meeting}
              divider={i !== indexOfLastItem}
            />
          ))}
        </List>
      ) : (
        <Typography variant="subtitle2" pb={2}>
          Inget inbokat
        </Typography>
      )}
    </Card>
  )
}

export default NextMeetings

import React, { useMemo, Suspense } from 'react'
import { useAppShellData } from '@local/src/AppShellData'

import Loading from '../Loading/Loading'

type Frontend = 'csn' | 'medarbetare-studier' | 'medarbetare-ekonomi'

interface Props {
  frontend: Frontend
}

const MicroFrontendLoader = ({ frontend }: Props) => {
  const MicroFrontend = useMemo(
    () => React.lazy(() => System.import(frontend)),
    [frontend]
  )
  const appShellData = useAppShellData((data) => data)

  return (
    <Suspense fallback={<Loading />}>
      <MicroFrontend appShellData={appShellData} />
    </Suspense>
  )
}

export default MicroFrontendLoader

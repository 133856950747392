import CheckboxFacetable from '@local/src/Components/Chips/ChipWithPopover/PopoverInputs/CheckboxFacetable'
import { Box, TextField, Divider, List } from '@mui/material'
import React from 'react'

import { ArbetsuppgiftOwnerFacet, Facet } from '../../types'
import { noOwnerId } from '../../helpers'

interface AssigneeFilterProps {
  facets: Facet[]
  searchValue: string
  updateSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  pickAdvisor: (radgivareId: string) => void
  currentUserFacet: ArbetsuppgiftOwnerFacet
  noOwnerFacet: ArbetsuppgiftOwnerFacet
  isRadgivareSelected: (radgivareId: string) => boolean
  filteredAdvisorList: ArbetsuppgiftOwnerFacet[]
}

const AssigneeFilter = ({
  facets,
  searchValue,
  updateSearchValue,
  pickAdvisor,
  currentUserFacet,
  noOwnerFacet,
  isRadgivareSelected,
  filteredAdvisorList,
}: AssigneeFilterProps) => {
  return (
    <>
      {facets?.length >= 5 && (
        <Box mb={1} px={2}>
          <TextField
            data-testid="ansvarig-search"
            label="Sök ansvarig"
            value={searchValue}
            // we really do want to autoFocus the user, hence the eslint-disable.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onChange={updateSearchValue}
            fullWidth
            inputProps={{ 'data-testid': 'search-advisor-textfield' }}
          />
        </Box>
      )}
      <List>
        <CheckboxFacetable
          facetCount={noOwnerFacet?.count}
          label={noOwnerFacet?.value}
          checked={isRadgivareSelected(noOwnerId)}
          onClick={() => pickAdvisor(noOwnerId)}
        />
        <CheckboxFacetable
          facetCount={currentUserFacet?.count}
          label={currentUserFacet?.value}
          checked={isRadgivareSelected(currentUserFacet.id)}
          onClick={() => pickAdvisor(currentUserFacet.id)}
        />
      </List>
      <Divider sx={{ mx: 2 }} />
      <Box sx={{ overflowY: 'auto', maxHeight: { xs: 'inherit', lg: '40vh' } }}>
        <List>
          {filteredAdvisorList?.map((radgivare) => (
            <CheckboxFacetable
              key={radgivare.id}
              label={radgivare.value}
              facetCount={radgivare.count}
              checked={isRadgivareSelected(radgivare.id)}
              onClick={() => pickAdvisor(radgivare.id)}
            />
          ))}
        </List>
      </Box>
    </>
  )
}

export default AssigneeFilter

import React from 'react'
import { format } from 'date-fns'
import DateWrapper from '@local/src/Components/DateWrapper'
import { Typography } from '@trr/common-component-library'

import s from './AllocateDetailsDate.module.scss'

interface IAllocateDetailsDate {
  lastDayOfEmployment: string
}

const AllocateDetailsDate = ({
  lastDayOfEmployment,
}: IAllocateDetailsDate): JSX.Element => {
  return (
    <div className={s.Wrapper}>
      <Typography variant="subtitle2">Sista anställningsdag</Typography>
      <DateWrapper>
        {lastDayOfEmployment
          ? format(new Date(lastDayOfEmployment), 'yyyy-MM-dd')
          : 'Inget datum'}
      </DateWrapper>
    </div>
  )
}
export default AllocateDetailsDate

import { createContext, useContext } from 'react'
import { Radgivare } from '@local/src/Common.types'

import { ArbetsuppgiftFacets, ArbetsuppgiftFilter } from '../types'

export interface ArbetsuppgiftFilterContextState {
  filters: Partial<ArbetsuppgiftFilter>
  advisors: Radgivare[]
  initialFacets: ArbetsuppgiftFacets
  setFilter: (key: keyof ArbetsuppgiftFilter, values: string | string[]) => void
  clearFilter: () => void
}

const ArbetsuppgiftFilterContext =
  createContext<ArbetsuppgiftFilterContextState | null>(null)

export const useArbetsuppgiftFilterContext =
  (): ArbetsuppgiftFilterContextState => {
    const context = useContext(ArbetsuppgiftFilterContext)
    if (context === null) {
      console.error(
        'useArbetsuppgiftContext must be used within a FilterContextProvider'
      )
    }
    return context
  }

export default ArbetsuppgiftFilterContext

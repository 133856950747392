// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LBEvefTfC53o1LowZMnY div{font-weight:bold !important}`, "",{"version":3,"sources":["webpack://./src/Features/StudieAnmalan/StudieAnmalanDetails/components/CourseFees/CourseFees.module.scss"],"names":[],"mappings":"AAAA,0BACE,2BAAA","sourcesContent":[".bold div {\n  font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bold": `LBEvefTfC53o1LowZMnY`
};
export default ___CSS_LOADER_EXPORT___;

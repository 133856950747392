import { List, ListItem, ListItemText, Typography, Stack } from '@mui/material'
import React from 'react'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import { IStudieAnmalan } from '../../../types/StudieAnmalan.types'
import AnmalanContentItem from '../AnmalanContentItem/AnmalanContentItem'

interface IContactProps {
  studieAnmalan: IStudieAnmalan
}

const Contact: React.FC<React.PropsWithChildren<IContactProps>> = ({
  studieAnmalan,
}) => {
  const { supplier, instituteComment } = studieAnmalan
  const epi = useEpiString()
  if (!supplier && !instituteComment) return null
  return (
    <ListItem>
      <AnmalanContentItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'h6' }}
          primary={epi('common.ContactPerson')}
        />
        <List dense>
          <ListItem>
            <ListItemText
              primary={`${epi('common.Name')}:`}
              secondary={supplier?.contactPersonName || epi('common.Unknown')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`${epi('common.Email')}:`}
              secondary={supplier?.email || epi('common.Unknown')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`${epi('common.Phone')}:`}
              secondary={supplier?.phone || epi('common.Unknown')}
            />
          </ListItem>
        </List>

        <br />
        {instituteComment && (
          <Stack>
            <Typography gutterBottom={false} variant="subtitle1">
              {`${epi('common.commentFromSupplier')}:`}
            </Typography>
            <Typography gutterBottom={false} variant="subtitle2">
              {instituteComment}
            </Typography>
          </Stack>
        )}
      </AnmalanContentItem>
    </ListItem>
  )
}

export default Contact

import React from 'react'
import { BoxProps, Box, Typography, Stack, styled } from '@mui/material'

import EmptyResultIllustration from './EmptyResultIllustraton'

interface EmptyResultProps extends BoxProps {
  heading?: string
  description?: string
}

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '386px',
  padding: theme.spacing(0, 5),
}))

const EmptyResult = ({
  heading = 'Inga resultat',
  description = 'Din sökning genererade inte några resultat. Prova att förenkla eller bredda sökningen.',
  ...boxProps
}: EmptyResultProps): JSX.Element => (
  <Box {...boxProps}>
    <Stack
      spacing={3}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <IllustrationWrapper>
        <EmptyResultIllustration />
      </IllustrationWrapper>
      <Typography variant="h3" gutterBottom>
        {heading}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Stack>
  </Box>
)

export default EmptyResult

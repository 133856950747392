import { FetchingState } from '@local/src/Common.types'
import { path } from 'ramda'

import { ActivityRound } from './Activities.types'

export const attendedActivitiesSelector = path<ActivityRound[]>([
  'activities',
  'attendedActivities',
])
export const currentActivitiesSelector = path<ActivityRound[]>([
  'activities',
  'currentActivities',
])
export const fetchingActivitiesStateSelector = path<FetchingState>([
  'activities',
  'fetchingActivitiesState',
])
export const unregisteringActivitiesStateSelector = path<FetchingState>([
  'activities',
  'unregisteringActivitiesState',
])

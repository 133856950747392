import React from 'react'
import { Button, Icons } from '@trr/common-component-library'
import classnames from 'classnames'
import { FetchingState } from '@local/src/Common.types'
import FilterButton from '@local/Blocks/Filter/FilterButton'
import FilterTags from '@local/Components/FilterTags'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import IconWithText from '@local/src/Components/IconWithText'

import { IStagedCase } from './AllocateCaseList/AllocateCaseList.types'
import s from './AllocateCases.module.scss'

interface IAllocateCasesBar {
  advisorsVisible: boolean
  allocateStagedCases(): void
  allocatingCasesState: FetchingState
  filterTags: IFilterTag[]
  isFilterVisible: boolean
  removeFilterParameterValuePair(filterTag: IFilterTag): void
  stagedCases: IStagedCase[]
  toggleAdvisorList(): void
  toggleFilterVisibility(): void
  disableAllocateButton: boolean
}

const AllocateCasesBar = ({
  advisorsVisible,
  allocateStagedCases,
  allocatingCasesState,
  filterTags,
  isFilterVisible,
  removeFilterParameterValuePair,
  stagedCases,
  toggleAdvisorList,
  toggleFilterVisibility,
  disableAllocateButton,
}: IAllocateCasesBar): JSX.Element => (
  <div className={s.TopBar}>
    <div className={s.PrimaryContent}>
      <FilterButton
        onClick={toggleFilterVisibility}
        isSmallScreen={false}
        isFilterVisible={isFilterVisible}
      />

      {filterTags.length !== 0 && (
        <FilterTags
          filterTags={filterTags}
          removeFilterParameterValuePair={removeFilterParameterValuePair}
          halfPadding
        />
      )}

      <Button
        customClass={s.AllocateButton}
        onClick={allocateStagedCases}
        primary
        disabled={
          disableAllocateButton ||
          stagedCases.length === 0 ||
          allocatingCasesState === FetchingState.LOADING
        }
        testSelector="primaryselector"
      >
        {allocatingCasesState === FetchingState.LOADING
          ? 'Fördelar...'
          : stagedCases.length > 0
            ? `Fördela ${stagedCases.length}`
            : 'Välj ärenden'}
      </Button>
    </div>

    <Button
      name="Visa rådgivningslista"
      icon
      onClick={toggleAdvisorList}
      customClass={classnames(
        s.ShowElement,
        advisorsVisible ? s.Hidden : s.Visible
      )}
    >
      <IconWithText
        icon={<Icons.ArrowRightIcon color={s.purple100} size={8} />}
      >
        Rådgivare
      </IconWithText>
    </Button>
  </div>
)

export default AllocateCasesBar

import CheckboxFacetable from '@local/src/Components/Chips/ChipWithPopover/PopoverInputs/CheckboxFacetable'
import { List } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { getCategoryLabel } from '../helpers/helpers'
import { ArbetsuppgiftCategoryFacet } from '../../types'

interface CategoryChipProps {
  categories: ArbetsuppgiftCategoryFacet[]
  initialCategories: ArbetsuppgiftCategoryFacet[]
  selectedCategories: string[]
  handleCheckbox: (value: string) => void
}

const CategoryFilter = ({
  categories,
  initialCategories,
  selectedCategories,
  handleCheckbox,
}: CategoryChipProps) => {
  const [sortedCategories, setSortedCategories] = useState<
    ArbetsuppgiftCategoryFacet[]
  >([])

  useEffect(() => {
    if (categories && initialCategories) {
      const newCategoryMap = new Map(
        categories.map((cat) => [cat.value, cat.count])
      )

      // Create the final sorted list
      const finalCategories = initialCategories.map((cat) => {
        return {
          value: cat.value,
          count: newCategoryMap.get(cat.value) || 0,
        }
      })

      // Add any new categories from newCategories that are not in initialCategories
      categories.forEach((cat) => {
        if (
          !finalCategories.some((initialCat) => initialCat.value === cat.value)
        ) {
          finalCategories.push(cat)
        }
      })

      // Sort the final list based on counts in descending order
      finalCategories.sort((a, b) => b.count - a.count)
      setSortedCategories(finalCategories)
    }
  }, [initialCategories, categories])

  return (
    <List data-testid="kategori-list">
      {sortedCategories?.map((category) => (
        <CheckboxFacetable
          key={category.value}
          label={getCategoryLabel(category.value)}
          facetCount={category?.count ?? 0}
          checked={selectedCategories.some((s) => s === category.value)}
          onClick={() => handleCheckbox(category.value)}
        />
      ))}
    </List>
  )
}

export default CategoryFilter

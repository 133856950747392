import { Link, List, ListItemText } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import React, { useState } from 'react'
import Modal from '@local/src/Components/Modal/Modal'
import { useIsSmallScreen } from '@local/src/Hooks'

import { Bransch } from '../../../../Avslut.types'

interface BranschModalProps {
  branscher: Bransch[]
}

const BranschModal = ({ branscher }: BranschModalProps) => {
  const isMobile = useIsSmallScreen()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Link
        display="flex"
        pl={0}
        gap={1}
        alignSelf="baseline"
        sx={{ cursor: 'pointer' }}
        onClick={() => setModalIsOpen(true)}
        variant="body1"
      >
        <InfoIcon />
        Vilken bransch ska jag välja?
      </Link>
      <Modal
        open={modalIsOpen}
        label="Vilken bransch ska jag välja?"
        closeIcon
        onClose={() => setModalIsOpen(false)}
        onCancel={() => setModalIsOpen(false)}
        cancelText="Stäng"
        fullScreen={isMobile}
      >
        <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {branscher.map((bransch) => (
            <ListItemText
              key={bransch.id}
              primary={bransch.name}
              secondary={bransch.description}
            />
          ))}
        </List>
      </Modal>
    </>
  )
}

export default BranschModal

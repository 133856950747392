import { connect } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'

import {
  allocatingCasesState,
  allTeams,
  changingClientAllocationTeamState,
  fetchingAdvisorListState,
  fetchingCaseListState,
  allocatableTeams,
} from '../AllocateCases.selectors'
import { changeTeam } from '../AllocateCases.actions'

import AllocateCaseList from './AllocateCaseList'

const mapStateToProps = (state: RootState) => ({
  allocatingCasesState: allocatingCasesState(state),
  allTeams: allTeams(state),
  changingClientAllocationTeamState: changingClientAllocationTeamState(state),
  fetchingAdvisorListState: fetchingAdvisorListState(state),
  fetchingCaseListState: fetchingCaseListState(state),
  allocatableTeams: allocatableTeams(state),
})

const mapDispatchToProps = {
  changeTeam,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllocateCaseList)

import { StartklarFilter, StartklarPagination } from '../Startklar.types'

import useStartklarLocalStorageFilter from './useStartklarLocalStorageFilter'

export const useStartklarFilter = (): [
  Partial<StartklarFilter>,
  (key: string, value: string | string[] | object | number) => void,
  () => void,
  () => void,
] => {
  const defaultFilters: StartklarFilter = getDefaultFilters()

  const [filters, setFilter, resetFilter] =
    useStartklarLocalStorageFilter(defaultFilters)

  const updateFilter = (
    key: keyof StartklarFilter,
    value: string | string[] | number | object
  ) => {
    let updatedFilterObj: Partial<StartklarFilter>
    if (key === 'pagination') {
      updatedFilterObj = {
        ...filters,
        pagination: value as StartklarPagination,
      }
    } else {
      updatedFilterObj = {
        ...filters,
        [key]: value,
        pagination: {
          ...filters.pagination,
          page: 1,
        },
      }
    }

    setFilter(updatedFilterObj)
  }

  const clearHandlaggareFilter = () => {
    const updatedFilterObj: Partial<StartklarFilter> = {
      ...filters,
      ejTilldeladHandlaggare: '',
      handlaggareIdn: [],
      pagination: {
        ...filters.pagination,
        page: 1,
      },
    }

    setFilter(updatedFilterObj)
  }

  return [filters, updateFilter, resetFilter, clearHandlaggareFilter]
}

const getDefaultFilters = () => {
  const defaultFilters: StartklarFilter = {
    searchText: '',
    subStatusar: 'Nytt',
    arbetsmarknadsomraden: [],
    foretagNamn: [],
    ejTilldeladHandlaggare: '',
    handlaggareIdn: [],
    pagination: {
      page: 1,
      pageSize: 10,
    },
    orderBy: 'SubStatusDatum asc',
  }

  return defaultFilters
}

export const getResetFilter = (currentFilter: Partial<StartklarFilter>) => {
  const emptyFilter: StartklarFilter = {
    searchText: '',
    subStatusar: 'Nytt',
    arbetsmarknadsomraden: [],
    foretagNamn: [],
    handlaggareIdn: [],
    ejTilldeladHandlaggare: '',
    pagination: {
      page: 1,
      pageSize: currentFilter.pagination.pageSize,
    },
    orderBy: currentFilter.orderBy,
  }

  return emptyFilter
}

import { path } from 'ramda'

import { SuccessMessage } from './UserFeedback.types'

export const errorMessagesSelector = path<string[]>([
  'userFeedback',
  'errorMessages',
])
export const successMessagesSelector = path<SuccessMessage[]>([
  'userFeedback',
  'successMessages',
])

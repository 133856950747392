import { get, post, put } from '@local/src/Utils/network'
import { createActions } from 'redux-actions'

import {
  AteroppnaArendeRequestParameters,
  AteroppnaPreconditions,
  AvslutaArendeWithDateRequest,
  AvslutaArendeWithNumberOfMonthsRequest,
  AvslutaArendeWithQuestionsRequest,
  AvslutaArendeWithReminderRequest,
  AvslutaArendeWithoutDateRequest,
} from './Avslut.types'

export const {
  fetchAvslutskoderInitiate,
  fetchAvslutskoderSuccess,
  fetchAvslutskoderError,
  fetchAteroppnaPreconditionsInitiate,
  fetchAteroppnaPreconditionsSuccess,
  fetchAteroppnaPreconditionsError,
  ateroppnaArendeInitiate,
  ateroppnaArendeSuccess,
  ateroppnaArendeError,
  fetchActiveRadgivareInitiated,
  fetchActiveRadgivareSuccess,
  fetchActiveRadgivareError,
  fetchBranscherInitiate,
  fetchBranscherSuccess,
  fetchBranscherError,
  fetchBefattningarInitiate,
  fetchBefattningarSuccess,
  fetchBefattningarError,
  fetchOrterInitiate,
  fetchOrterSuccess,
  fetchOrterError,
  avslutaArendeWithReminderInitiate,
  avslutaArendeWithReminderSuccess,
  avslutaArendeWithReminderError,
  avslutaArendeWithQuestionsInitiate,
  avslutaArendeWithQuestionsSuccess,
  avslutaArendeWithQuestionsError,
  avslutaArendeWithoutDateInitiate,
  avslutaArendeWithoutDateSuccess,
  avslutaArendeWithoutDateError,
  avslutaArendeWithDateInitiate,
  avslutaArendeWithDateSuccess,
  avslutaArendeWithDateError,
  avslutaArendeWithNumberOfMonthsInitiate,
  avslutaArendeWithNumberOfMonthsSuccess,
  avslutaArendeWithNumberOfMonthsError,
  avslutaKompetensstodsArendeInitiate,
  avslutaKompetensstodsArendeSuccess,
  avslutaKompetensstodsArendeError,
  resetAvslut,
  fetchTidigareAvslutInitiate,
  fetchTidigareAvslutSuccess,
  fetchTidigareAvslutFailure,
  submitQuestionsResponsesFailure,
  submitQuestionsResponsesInitiate,
  submitQuestionsResponsesSuccess,
} = createActions({
  resetAvslut: () => null,

  fetchAvslutskoderError: (errorMessage) =>
    `Det gick inte att hämta några avslutskoder. ${String(errorMessage)}`,
  fetchAvslutskoderInitiate: () => null,
  fetchAvslutskoderSuccess: (avslutskoder) => ({ avslutskoder }),

  fetchAteroppnaPreconditionsInitiate: () => null,
  fetchAteroppnaPreconditionsSuccess: (
    ateroppnaPreconditions: AteroppnaPreconditions
  ) => ({ ateroppnaPreconditions }),
  fetchAteroppnaPreconditionsError: (errorMessage) =>
    `Något gick fel. Kontakta servicedesk. ${String(errorMessage)}`,

  ateroppnaArendeInitiate: () => null,
  ateroppnaArendeSuccess: () => ({ successMessage: 'Ärendet återöppnades' }),
  ateroppnaArendeError: (errorMessage) =>
    `Det gick inte att återöppna ärendet. ${String(errorMessage)}`,

  fetchActiveRadgivareInitiated: () => null,
  fetchActiveRadgivareSuccess: (activeRadgivare) => ({ activeRadgivare }),
  fetchActiveRadgivareError: (errorMessage) =>
    `Något gick fel. Det gick inte att byta rådgivare. ${String(errorMessage)}`,

  fetchBranscherInitiate: () => null,
  fetchBranscherSuccess: (branscher) => ({ branscher }),
  fetchBranscherError: (errorMessage) =>
    `Det gick inte att hämta några branscher. ${String(errorMessage)}`,

  fetchBefattningarInitiate: () => null,
  fetchBefattningarSuccess: (befattningar) => ({ befattningar }),
  fetchBefattningarError: (errorMessage) =>
    `Det gick inte att hämta några befattningar. ${String(errorMessage)}`,

  fetchOrterInitiate: () => null,
  fetchOrterSuccess: (orter) => ({ orter }),
  fetchOrterError: (errorMessage) =>
    `Det gick inte att hämta några stationeringsorter. ${String(errorMessage)}`,

  avslutaArendeWithReminderInitiate: () => null,
  avslutaArendeWithReminderSuccess: () => ({
    successMessage: 'Ärendet avslutades',
  }),
  avslutaArendeWithReminderError: (errorMessage) =>
    `Det gick inte att avsluta ärendet. ${String(errorMessage)}`,

  avslutaArendeWithQuestionsInitiate: () => null,
  avslutaArendeWithQuestionsSuccess: () => ({
    successMessage: `Ärendet avslutades`,
  }),
  avslutaArendeWithQuestionsError: (errorMessage) =>
    `Det gick inte att avsluta ärendet. ${String(errorMessage)}`,

  avslutaArendeWithoutDateInitiate: () => null,
  avslutaArendeWithoutDateSuccess: () => ({
    successMessage: 'Ärendet avslutades',
  }),
  avslutaArendeWithoutDateError: (errorMessage) =>
    `Det gick inte att avsluta ärendet. ${String(errorMessage)}`,

  avslutaArendeWithDateInitiate: () => null,
  avslutaArendeWithDateSuccess: () => ({
    successMessage: 'Ärendet avslutades',
  }),
  avslutaArendeWithDateError: (errorMessage) =>
    `Det gick inte att avsluta ärendet. ${String(errorMessage)}`,

  avslutaKompetensstodsArendeInitiate: () => null,
  avslutaKompetensstodsArendeSuccess: () => ({
    successMessage: 'Ärendet avslutades',
  }),
  avslutaKompetensstodsArendeError: (errorMessage) => {
    return `Det gick inte att avsluta ärendet. ${String(errorMessage)}`
  },

  avslutaArendeWithNumberOfMonthsInitiate: () => null,
  avslutaArendeWithNumberOfMonthsSuccess: () => ({
    successMessage: 'Ärendet avslutades',
  }),
  avslutaArendeWithNumberOfMonthsError: (errorMessage) =>
    `Det gick inte att avsluta ärendet. ${String(errorMessage)}`,
  fetchTidigareAvslutInitiate: () => null,
  fetchTidigareAvslutSuccess: (tidigareAvslut) => ({ tidigareAvslut }),
  fetchTidigareAvslutFailure: (errorMessage) => {
    return `Det gick inte att hämta några avslutshändelser. ${String(errorMessage)}`
  },

  submitQuestionsResponsesFailure: (errorMessage) => {
    return `Det gick inte att spara svar på avslutsfrågor. ${String(errorMessage)}`
  },
  submitQuestionsResponsesInitiate: () => null,
  submitQuestionsResponsesSuccess: (
    operationIdWrapper: Record<string, unknown>
  ) => ({
    ...operationIdWrapper,
    successMessage: 'Formulärsvaren sparades',
  }),
})

export const fetchAvslutskoder = (caseId: string) =>
  get({
    url: `/radgivningsarende/${caseId}/avslutskoder`,
    initiated: fetchAvslutskoderInitiate,
    fulfilled: fetchAvslutskoderSuccess,
    rejected: fetchAvslutskoderError,
  })

export const fetchAteroppnaArendePreconditions = (arendeId: string) =>
  get({
    url: `/radgivare/arenden/${arendeId}/ateroppnaPreconditions`,
    initiated: fetchAteroppnaPreconditionsInitiate,
    fulfilled: fetchAteroppnaPreconditionsSuccess,
    rejected: fetchAteroppnaPreconditionsError,
  })

export const ateroppnaArende = (body: AteroppnaArendeRequestParameters) =>
  put({
    url: `/radgivningsarende/ateroppna`,
    initiated: ateroppnaArendeInitiate,
    fulfilled: ateroppnaArendeSuccess,
    rejected: ateroppnaArendeError,
    body,
  })

export const fetchActiveRadgivare = (teamId: string) => {
  return get({
    url: `/radgivare/aktivaradgivare?teamId=${teamId}`,
    initiated: fetchActiveRadgivareInitiated,
    fulfilled: fetchActiveRadgivareSuccess,
    rejected: fetchActiveRadgivareError,
  })
}

export const fetchBranscher = () =>
  get({
    url: `/referencedata/branscher`,
    initiated: fetchBranscherInitiate,
    fulfilled: fetchBranscherSuccess,
    rejected: fetchBranscherError,
  })

export const fetchBefattningar = (partialTitle: string) =>
  get({
    url: `/referencedata/jobtitles/v2/suggestions/${partialTitle}`,
    initiated: fetchBefattningarInitiate,
    fulfilled: fetchBefattningarSuccess,
    rejected: fetchBefattningarError,
  })

export const fetchOrter = () =>
  get({
    url: `/referencedata/orter/ortnamn`,
    initiated: fetchOrterInitiate,
    fulfilled: fetchOrterSuccess,
    rejected: fetchOrterError,
  })

export const avslutaArendeWithReminder = (
  body: AvslutaArendeWithReminderRequest
) =>
  post({
    url: `/radgivningsarende/avslut`,
    initiated: avslutaArendeWithReminderInitiate,
    fulfilled: avslutaArendeWithReminderSuccess,
    rejected: avslutaArendeWithReminderError,
    body,
  })

export const avslutaArendeWithQuestions = (
  body: AvslutaArendeWithQuestionsRequest
) =>
  post({
    url: `/radgivningsarende/avslut`,
    initiated: avslutaArendeWithQuestionsInitiate,
    fulfilled: avslutaArendeWithQuestionsSuccess,
    rejected: avslutaArendeWithQuestionsError,
    body,
  })

export const avslutaArendeWithoutDate = (
  body: AvslutaArendeWithoutDateRequest
) =>
  post({
    url: `/radgivningsarende/avslut`,
    initiated: avslutaArendeWithoutDateInitiate,
    fulfilled: avslutaArendeWithoutDateSuccess,
    rejected: avslutaArendeWithoutDateError,
    body,
  })

export const avslutaArendeWithDate = (body: AvslutaArendeWithDateRequest) =>
  post({
    url: `/radgivningsarende/avslut`,
    initiated: avslutaArendeWithDateInitiate,
    fulfilled: avslutaArendeWithDateSuccess,
    rejected: avslutaArendeWithDateError,
    body,
  })

export const avslutaArendeWithNumberOfMonths = (
  body: AvslutaArendeWithNumberOfMonthsRequest
) =>
  post({
    url: `/radgivningsarende/avslut`,
    initiated: avslutaArendeWithNumberOfMonthsInitiate,
    fulfilled: avslutaArendeWithNumberOfMonthsSuccess,
    rejected: avslutaArendeWithNumberOfMonthsError,
    body,
  })

export const avslutaKompetensstodsArende = (arendeId: string) =>
  post({
    url: `/anstallningsarenden/${arendeId}/avslut`,
    initiated: avslutaKompetensstodsArendeInitiate,
    fulfilled: avslutaKompetensstodsArendeSuccess,
    rejected: avslutaKompetensstodsArendeError,
    apiVersion: '2.0',
    body: { arendeId },
  })

export const fetchTidigareAvslut = (caseId: string) =>
  get({
    url: `/radgivningsarende/${caseId}/avslut`,
    initiated: fetchTidigareAvslutInitiate,
    fulfilled: fetchTidigareAvslutSuccess,
    rejected: fetchTidigareAvslutFailure,
  })

export interface ISubmitQuestionResponsesRequestParameters {
  arendeId: string
  avslutsId: string
  avslutskod: string
  frageSvar: { key: string; value: string }[]
}

export const submitQuestionsResponses = (
  body: ISubmitQuestionResponsesRequestParameters
) =>
  post({
    url: `/radgivningsarende/avslutsfragorsvar`,
    initiated: submitQuestionsResponsesInitiate,
    fulfilled: submitQuestionsResponsesSuccess,
    rejected: submitQuestionsResponsesFailure,
    body,
  })

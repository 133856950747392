import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'
import { useEpiContent, useIsFeatureEnabled } from '@trr/app-shell-data'

import { EpiStringProvider } from '../../../Context/EpiStringContext'
import { IStudieAnmalanContent } from '../../../Types/StudieAnmalanContent'
import StudieAnmalan from '../StudieAnmalanDetails/StudieAnmalan'
import StudieAnmalanList from '../StudieAnmalanList/StudieAnmalanList'

interface IStudieAnmalanWrapperProps {
  arendeId: string
}

const StudieAnmalanWrapper: React.FC<
  React.PropsWithChildren<IStudieAnmalanWrapperProps>
> = ({ arendeId }) => {
  const history = useHistory()

  const match = useRouteMatch<{ bookingId: string; arendeId: string }>({
    path: '*/arende/:arendeId/studier/anmalan/:bookingId',
  })
  const bookingId = match?.params?.bookingId
  const isAnmalanDetailsPage = Boolean(bookingId)

  const content = useEpiContent<IStudieAnmalanContent>()
  const findCoursesProDetailsEnabled = useIsFeatureEnabled(
    'Medarbetare-MicroFrontend_findcourcesProDetails_perm_240918'
  )
  const location = useLocation()
  return (
    <Box sx={{ mt: 4 }}>
      <EpiStringProvider value={content?.studiertab?.studieAnmalan}>
        {isAnmalanDetailsPage && findCoursesProDetailsEnabled ? (
          <StudieAnmalan
            arendeId={arendeId}
            bookingId={bookingId}
            onBackButtonClick={() =>
              history.push(location.pathname.split('anmalan')[0])
            }
          />
        ) : (
          <StudieAnmalanList arendeId={arendeId} />
        )}
      </EpiStringProvider>
    </Box>
  )
}

export default StudieAnmalanWrapper

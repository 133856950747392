import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface MobileDrawerProps {
  children: React.ReactNode | JSX.Element | JSX.Element[]
  buttonText: string
  drawerHeader: string
  buttonIcon?: React.ReactNode | JSX.Element
}

const MobileDrawer = ({
  children,
  buttonText,
  drawerHeader,
  buttonIcon,
}: MobileDrawerProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpen(true)}
        startIcon={buttonIcon}
      >
        {buttonText}
      </Button>
      <SwipeableDrawer
        open={open}
        anchor="right"
        PaperProps={{ style: { width: '95%' } }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box mx={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            py={2}
          >
            <Typography variant="h6">{drawerHeader}</Typography>
            <IconButton onClick={() => setOpen(false)} aria-label="stäng">
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </SwipeableDrawer>
    </>
  )
}

export default MobileDrawer

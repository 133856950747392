import { ArendeMarkning } from '@local/src/Views/Arenden/Arenden.types'

export const markningChipDisplayNameDic: {
  [key in ArendeMarkning]: string
} = {
  CHEFSOMSTALLNING: 'Chefsomställning',
  VDUNDANTAGSGRUPP: 'Undantagsgrupp',
  STARTAFORETAG: 'Starta företag',
  FORSTARKTSTOD: 'Förstärkt stöd',
  STUDIESPECIALIST: 'Studier',
}

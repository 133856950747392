import { Facets, FetchingState } from 'src/Common.types'
import { IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import { ICaseListItem } from '@local/src/Components/ActionBasedCaseItem/ActionBasedCaseItem.types'

export interface IArendenState {
  readonly caseObject: {
    arenden: ICaseListItem[]
    totalHits: number
    totalPages: number
    readonly facets?: Facets
  }
  readonly filter: IArendenFilterState
  readonly searchText: string
  readonly orderBy: string[]
  readonly page: number
  readonly pageSize: number
  readonly facetedHits: number
  readonly facets: Facets
  readonly subStatusFacets: Facets
  readonly fetchingCaseListFacetsState: FetchingState
  readonly fetchingInitialCaseListFacetsState: FetchingState
  readonly fetchingCaseListState: FetchingState
  readonly pendingArendeId: string
  readonly putTiaRadgivareState: FetchingState
  readonly deleteTiaRadgivareState: FetchingState
}

export interface IFilterTag {
  filterParameter: string
  filterValue: string
  displayValue: string
}

export enum ArendeTyp {
  Omstallning = 'OmstallningsArende',
  Kompetensstod = 'AnstallningsArende',
  Alla = '',
}

export enum OmfattningsbeslutStatus {
  Odefinierad = 'Odefinierad',
  Nytt = 'Nytt',
  Manuellt = 'Manuellt',
  Signerad = 'Signerad',
  Granskad = 'Granskad',
  Attesterad = 'Attesterad',
  EjGodkand = 'Ej godkänd',
  Ersatt = 'Ersatt',
}

export enum ArendeMarkning {
  Chefsomstallning = 'CHEFSOMSTALLNING',
  Undantagsgrupp = 'VDUNDANTAGSGRUPP',
  ForstarktStod = 'FORSTARKTSTOD',
  StartaForetag = 'STARTAFORETAG',
  Studier = 'STUDIESPECIALIST',
}

export enum EArendenEnv {
  DinaArenden = 'DinaArenden',
  AllaArenden = 'AllaArenden',
  OmstallningsArenden = 'OmstallningsArenden',
  KompetensstodsArenden = 'KompetensstodsArenden',
}

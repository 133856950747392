import React, { useEffect } from 'react'
import { removeTrailingSlash } from '@local/src/Utils/helpers'
import {
  ITabItem,
  TabsContainer,
} from '@local/src/Components/TabsContainer/TabsContainer'
import { Redirect, useLocation } from 'react-router-dom'
import { Avatar, Box, useMediaQuery, useTheme } from '@mui/material'
import {
  useCanViewArbetslista,
  usePluppUpdatedListener,
} from '@local/src/Hooks'
import { useLazyGetPluppQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'

import { noActiveTab } from './Arenden.helpers'
import ArendeList from './ArendeList/ArendeList'
import Arbetsuppgift from './Arbetsuppgift/Arbetsuppgift'

const Arenden = (): JSX.Element => {
  const { pathname } = useLocation()
  const path = removeTrailingSlash(pathname)
  usePluppUpdatedListener()

  const canViewArbetslista = useCanViewArbetslista()
  const [fetchPlupp, pluppCount] = useLazyGetPluppQuery()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (canViewArbetslista) {
      fetchPlupp()
    }
  }, [canViewArbetslista])

  if (noActiveTab(path)) return <Redirect to={path + '/alla'} />

  const arbetslistaTabItem: ITabItem = {
    heading: 'Att göra',
    url: 'att-gora',
    content: <Arbetsuppgift />,
    iconPosition: 'start',
    icon: (
      <>
        {pluppCount?.data > 0 && (
          <Avatar
            sx={{
              height: 24,
              width: 24,
              fontSize: '0.75rem',
              backgroundColor: 'primary.main',
              color: '#fff',
              mr: 1,
            }}
          >
            {pluppCount.data}
          </Avatar>
        )}
      </>
    ),
  }

  return (
    <Box sx={{ p: 0, maxWidth: '1512px', m: '0 auto' }}>
      {/* exactly the same as the old view. Refactor later? */}
      <TabsContainer
        size={isSmallScreen ? 'small' : null}
        items={[
          {
            heading: isSmallScreen ? 'Alla' : 'Alla ärenden',
            url: 'alla',
            content: <ArendeList key="alla-arenden" />,
          },
          {
            heading: isSmallScreen ? 'OS-Kund' : 'Omställning',
            url: 'omstallning',
            content: <ArendeList key="omstallning" />,
          },
          {
            heading: isSmallScreen ? 'KS-Kund' : 'Kompetensstöd',
            url: 'kompetensstod',
            content: <ArendeList key="kompetensstod" />,
          },
          ...(canViewArbetslista ? [arbetslistaTabItem] : []),
        ]}
      />
    </Box>
  )
}

export default Arenden

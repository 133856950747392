/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { addMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/explicit-module-boundary-types
export const errorReporter = (store: any) => (next: any) => (action: any) => {
  if (action.error) {
    store.dispatch(addMessage({ type: 'error', content: action.type }))
  }
  return next(action)
}

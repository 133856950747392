import React from 'react'
import { Checkbox, Dropdown, RangeSlider } from '@trr/common-component-library'

import { IAllocateCasesFilter } from './AllocateCasesFilter.types'
import s from './AllocateCasesFilter.module.scss'

export const AllocateCasesFilter = ({
  residenceDropdownOptions,
  onResidenceChange,
  pickedResidence,

  age,
  maxAge,
  setAge,

  companyDropdownOptions,
  onCompanyChange,
  pickedCompany,

  roleDropdownOptions,
  onRoleChange,
  pickedRole,

  fordelningStatus,
  toggleFordelningStatus,

  replacementClients,
  toggleReplacementClients,
}: IAllocateCasesFilter): JSX.Element => {
  // Cannot include salary due to circular dependency
  return (
    <div className={s.AllocateCasesFilter}>
      <div className={s.FilterSection}>
        <h2>Fördelningsstatus</h2>
        <Checkbox
          onChange={toggleFordelningStatus}
          label="Fördelning"
          checked={fordelningStatus}
          customClass={s.FilterCheckbox}
        />
        <Checkbox
          onChange={toggleReplacementClients}
          label="Omfördelning"
          customClass={s.FilterCheckbox}
          checked={replacementClients}
        />
      </div>
      <div className={s.FilterSection}>
        <Dropdown
          label={'Bostadsort'}
          placeholder={'Bostadsort'}
          value={{ value: pickedResidence, label: pickedResidence }}
          selectOptions={residenceDropdownOptions}
          onChange={onResidenceChange}
        />
      </div>
      <div className={s.FilterSection}>
        <Dropdown
          label={'Företag'}
          selectOptions={companyDropdownOptions}
          value={{ value: pickedCompany, label: pickedCompany }}
          placeholder={'Företag'}
          onChange={onCompanyChange}
        />
      </div>
      <div className={s.FilterSection}>
        <Dropdown
          selectOptions={roleDropdownOptions}
          value={{ value: pickedRole, label: pickedRole }}
          label={'Befattning'}
          placeholder={'Befattning'}
          onChange={onRoleChange}
        />
      </div>
      <div className={s.FilterSection}>
        <h2>Ålder</h2>
        <RangeSlider
          cross={false}
          max={maxAge}
          min={0}
          name="alder"
          onChange={setAge}
          className={s.Slider}
          textInputs
          value={age}
        />
      </div>
    </div>
  )
}

export default AllocateCasesFilter

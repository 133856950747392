import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Radgivare } from '@local/src/Common.types'
import { useGuidFromUrl, useCurrentArendeListPathname } from '@local/src/Hooks'
import { medarbetareBasepath } from '@local/src/basename'
import Modal from '@local/src/Components/Modal/Modal'
import { Alert, Box, Checkbox, FormControlLabel } from '@mui/material'
import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'
import { useGetAktivaRadgivareQuery } from '@local/src/Api/Radgivare/radgivareApi'
import { useChangeRadgivareMutation } from '@local/src/Api/RadgivningsArende/radgivningsArendeApi'
import Loading from '@local/src/Components/Loading/Loading'

interface ChangeRadgivareModalProps {
  active: boolean
  close(): void
  teamId: string
}

const ChangeRadgivareModal = ({
  active,
  close,
  teamId,
}: ChangeRadgivareModalProps): JSX.Element => {
  const [isShowingAllRadgivare, setIsShowingAllRadgivare] = useState(false)
  const { data: radgivareList, isLoading } = useGetAktivaRadgivareQuery(
    isShowingAllRadgivare ? null : teamId
  )
  const [changeRadgivare, { isLoading: isLoadingChangeRadgivare, isSuccess }] =
    useChangeRadgivareMutation()
  const [selectedRadgivare, setSelectedRadgivare] = useState<Radgivare>()

  const arendeId = useGuidFromUrl()
  const history = useHistory()
  const currentArendeListPathname = useCurrentArendeListPathname()
  const [errorMsg, setErrorMsg] = useState<string>(null)

  useEffect(() => {
    active === false && setSelectedRadgivare(null)
    if (isSuccess) {
      history.push(`${medarbetareBasepath}${currentArendeListPathname}`)
    }
  }, [isSuccess, active])

  const selectRadgivare = (event: Radgivare) => {
    if (event === null) {
      setSelectedRadgivare(null)
      setErrorMsg('Välj ett alternativ')
    } else {
      setSelectedRadgivare(
        radgivareList.find((advisor: Radgivare) => event.id === advisor.id)
      )
      setErrorMsg(null)
    }
  }

  const handleConfirm = () => {
    if (selectedRadgivare) {
      setErrorMsg(null)
      changeRadgivare({ arendeId: arendeId, radgivareId: selectedRadgivare.id })
    } else {
      setErrorMsg('Välj ett alternativ')
    }
  }

  const handleCancel = () => {
    setErrorMsg(null)
    close()
  }

  return (
    <Modal
      label="Byt rådgivare"
      open={active}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      closeIcon
      confirmText="Byt"
    >
      <Box display="flex" flexDirection="column" gap={2} paddingTop={1}>
        {isLoadingChangeRadgivare ? (
          <Loading />
        ) : (
          <>
            <Autocomplete
              value={selectedRadgivare}
              label={
                isShowingAllRadgivare
                  ? 'Välj en rådgivare'
                  : 'Välj en rådgivare i ditt team'
              }
              options={radgivareList || []}
              onChange={selectRadgivare}
              getOptionLabel={(option: Radgivare) =>
                `${option.fornamn} ${option.efternamn}`
              }
              error={errorMsg !== null}
              errorText={errorMsg}
            />

            <FormControlLabel
              label="Välj bland alla rådgivare"
              sx={{ alignSelf: 'baseline' }}
              control={
                <Checkbox
                  checked={isShowingAllRadgivare}
                  onChange={() =>
                    setIsShowingAllRadgivare(!isShowingAllRadgivare)
                  }
                  disabled={isLoading}
                />
              }
            />
            <Alert severity="warning">
              Om omställningskunden byter bostadsort, välj hellre att ändra team
              istället för rådgivare.
            </Alert>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default ChangeRadgivareModal

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ateroppnaArendeStateSelector,
  avslutaArendeStateSelector,
  branscherSelector,
  tidigareAvslutSelector,
  tidigareAvslutStateSelector,
} from '@local/Views/Case/Avslut/Avslut.selectors'
import { Avslutskod } from '@local/Views/Case/Avslut/Avslut.types'
import {
  fetchBranscher,
  fetchTidigareAvslut,
} from '@local/Views/Case/Avslut/Avslut.actions'
import { NaOaAvslutshandelse } from '@local/src/Views/Case/Avslut/Omstallningsstod/Avslutshandelser/NaOaAvslutshandelse/NaOaAvslutshandelse'
import { Box, Typography } from '@mui/material'
import { UnhandledAvslutshandelse } from '@local/src/Views/Case/Avslut/Omstallningsstod/Avslutshandelser/UnhandledAvslutshandelse/UnhandledAvslutshandelse'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'

import StlAvslutshandelse from './StlAvslutshandelse/StlAvslutshandelse'
import ErAvslutshandelse from './ErAvslutshandelse/ErAvslutshandelse'

interface AvslutshandelserProps {
  arendeId: string
}

export const Avslutshandelser = ({ arendeId }: AvslutshandelserProps) => {
  const dispatch = useDispatch()
  const branscher = useSelector(branscherSelector)
  const avslutState = useSelector(avslutaArendeStateSelector)
  const ateroppnaArendeState = useSelector(ateroppnaArendeStateSelector)
  const tidigareAvslut = useSelector(tidigareAvslutSelector)
  const tidigareAvslutState = useSelector(tidigareAvslutStateSelector)

  useEffect(() => {
    dispatch(fetchTidigareAvslut(arendeId))
    dispatch(fetchBranscher())
  }, [arendeId])

  useEffect(() => {
    if (
      avslutState === FetchingState.SUCCESS ||
      ateroppnaArendeState === FetchingState.SUCCESS
    ) {
      dispatch(fetchTidigareAvslut(arendeId))
    }
  }, [avslutState, ateroppnaArendeState])

  if (tidigareAvslutState === FetchingState.LOADING) {
    return <Loading sx={{ mt: 8 }} />
  }

  if (!tidigareAvslut.length) return null

  return (
    <Box sx={{ display: 'grid', gridRowGap: 16 }}>
      <Typography variant="h4">Avslutshändelser</Typography>
      {tidigareAvslut?.map((avslut, index) => {
        if (!avslut.avslutsFragorSvar?.length) {
          return (
            <UnhandledAvslutshandelse
              key={avslut.radgivningAvslutskod + index.toString()}
              avslutshandelse={avslut}
            />
          )
        }
        if (
          avslut.radgivningAvslutskod === Avslutskod.NyAnstallning ||
          avslut.radgivningAvslutskod === Avslutskod.OvrigAnstallning
        ) {
          return (
            <NaOaAvslutshandelse
              key={avslut.radgivningAvslutskod + index.toString()}
              avslutshandelse={avslut}
              branscher={branscher}
            />
          )
        }
        if (avslut.radgivningAvslutskod === Avslutskod.StudierLangreTid) {
          return (
            <StlAvslutshandelse
              key={avslut.radgivningAvslutskod + index.toString()}
              avslutshandelse={avslut}
            />
          )
        }
        if (avslut.radgivningAvslutskod === Avslutskod.EgenRorelse) {
          return (
            <ErAvslutshandelse
              key={avslut.radgivningAvslutskod + index.toString()}
              avslutshandelse={avslut}
              branscher={branscher}
            />
          )
        }
      })}
    </Box>
  )
}

import { Team } from '@local/src/Common.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v1.0'

export const teamApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRadgivarTeams: builder.query<Team[], void>({
      query: () => `${baseUrl}/teams`,
    }),
    getFordelningsteams: builder.query<Team[], void>({
      query: () => `${baseUrl}/fordelningsansvarig/minateam`,
    }),
  }),
})

export const { useGetRadgivarTeamsQuery, useGetFordelningsteamsQuery } = teamApi

import { useGetArbetsuppgifterQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import {
  getDeadline,
  getQueryParameters,
  getStatusLabel,
} from '@local/src/Views/Arenden/Arbetsuppgift/helpers'
import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { arendenPath, medarbetareBasepath } from '@local/src/basename'
import { formatDateWithMonthStringFromDateTime as formatMonth } from '@local/Utils/DateTime'
import {
  Actor,
  ArbetsuppgiftStatus,
} from '@local/src/Views/Arenden/Arbetsuppgift/types'
import { useEpiContent, useUser } from '@trr/app-shell-data'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import TogglestatusChip from '@local/src/Views/Arenden/Arbetsuppgift/Table/ToggleStatusChip'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { IArendeContent } from '@local/src/Views/Case/Case.types'

interface ArendeArbetsuppgifterProps {
  arendeId: string
}

const ArendeArbetsuppgifter = ({ arendeId }: ArendeArbetsuppgifterProps) => {
  const { arendeArbetsuppgifter }: Partial<IArendeContent> = useEpiContent()
  const user = useUser()
  const { data, isLoading, isUninitialized } = useGetArbetsuppgifterQuery(
    getQueryParameters({
      arendeIds: [arendeId],
      statusar: [ArbetsuppgiftStatus.Ny, ArbetsuppgiftStatus.Pagaende],
      orderBy: { direction: 'desc', property: 'createdDate' },
    })
  )

  if (isLoading || isUninitialized) {
    return <></>
  }

  return (
    <Card variant="outlined" tabIndex={0} sx={{ pb: 1 }}>
      {arendeArbetsuppgifter && (
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Button variant="outlined" size="small" sx={{ mt: 2, ml: 2, mb: 2 }}>
            <Link
              href={`${medarbetareBasepath}${arendenPath}/att-gora`}
              variant="body1"
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              Att göra
              <ArrowForwardIcon fontSize="small" />
            </Link>
          </Button>
          <Divider />
        </Box>
      )}
      <Stack display="flex" flexDirection="column">
        {data?.results.length > 0 ? (
          <>
            {data?.results.map((arbetsuppgift, index) => (
              <Box
                key={arbetsuppgift.id}
                display="flex"
                flexDirection="column"
                gap={1}
                p={2}
                borderBottom={
                  index < data.results.length - 1 &&
                  '1px solid rgba(0, 0, 0, 0.12)'
                }
              >
                <Box display="flex" flexDirection="row" gap={0.5}>
                  {arbetsuppgift.status === ArbetsuppgiftStatus.Ny &&
                    arbetsuppgift.owner.id === user.mdid && (
                      <FiberManualRecordIcon
                        color="primary"
                        data-testid={`newstatusicon-${arbetsuppgift.id}`}
                      />
                    )}
                  <Typography variant="subtitle1">
                    {arbetsuppgift.header}
                  </Typography>
                </Box>
                {getDeadline(arbetsuppgift.dueDate)}
                {arbetsuppgift.latestStatusUpdate?.triggeredBy ===
                  Actor.User && (
                  <Typography variant="caption" color="text.secondary">
                    {`${getStatusLabel(arbetsuppgift.status)} ${formatMonth(arbetsuppgift.latestStatusUpdate.occuredAt)} - `}
                    {arbetsuppgift.latestStatusUpdate.user.id === user.mdid
                      ? 'Jag'
                      : `${arbetsuppgift.latestStatusUpdate.user.firstName} ${arbetsuppgift.latestStatusUpdate.user.lastName}`}
                  </Typography>
                )}
                {arbetsuppgift.latestStatusUpdate?.triggeredBy ===
                  Actor.System && (
                  <Typography variant="caption" color="text.secondary">
                    {getStatusLabel(arbetsuppgift.status)}{' '}
                    {formatMonth(arbetsuppgift.latestStatusUpdate.occuredAt)} -
                    Systemet
                  </Typography>
                )}
                <TogglestatusChip
                  id={arbetsuppgift.id}
                  status={arbetsuppgift.status}
                  category={arbetsuppgift.category}
                />
              </Box>
            ))}
          </>
        ) : (
          <Typography variant="subtitle2" mb={1} mt={2} ml={2}>
            Just nu finns inga uppgifter
          </Typography>
        )}
      </Stack>
    </Card>
  )
}

export default ArendeArbetsuppgifter

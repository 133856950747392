import { Chip, styled } from '@mui/material'

interface StyledChipProps {
  visible: boolean
}

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<StyledChipProps>(({ visible }) => ({
  '& .MuiChip-icon': {
    margin: 0,
    marginLeft: visible ? 16 : 4,
    marginRight: visible ? 4 : -6,
  },
}))

import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'
import Modal from '@local/src/Components/Modal/Modal'
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRadgivare } from '@local/src/Views/Case/Case.types'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'

import {
  ateroppnaArende,
  fetchActiveRadgivare,
  fetchAteroppnaArendePreconditions,
} from '../../../Avslut.actions'
import { AteroppnaArendeRequestParameters } from '../../../Avslut.types'
import {
  activeRadgivareSelector,
  ateroppnaArendeStateSelector,
  ateroppnaPreconditionsSelector,
  fetchingActiveRadgivareStateSelector,
} from '../../../Avslut.selectors'

enum AteroppnaOptions {
  SAME_ADVISOR,
  OTHER_ADVISOR,
  REALLOCATE,
}

interface AteroppnaModalProps {
  arendeId: string
  teamId: string
}

const AteroppnaModal = ({ arendeId, teamId }: AteroppnaModalProps) => {
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState<AteroppnaOptions>(null)
  const [selectedRadgivare, setSelectedRadgivare] = useState<IRadgivare>(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const preconditions = useSelector(ateroppnaPreconditionsSelector)
  const activeRadgivare = useSelector(activeRadgivareSelector)
  const fetchingRadgivareState = useSelector(
    fetchingActiveRadgivareStateSelector
  )
  const ateroppnaArendeState = useSelector(ateroppnaArendeStateSelector)
  const canReopenOtherRadgivare =
    preconditions?.teamIsValid && preconditions?.radgivareIsValid

  useEffect(() => {
    dispatch(fetchAteroppnaArendePreconditions(arendeId))
  }, [])

  useEffect(() => {
    ateroppnaArendeState === FetchingState.SUCCESS && setModalIsOpen(false)
  }, [ateroppnaArendeState])

  const fetchRadgivare = () => {
    if (
      activeRadgivare.length === 0 &&
      fetchingRadgivareState === FetchingState.IDLE
    ) {
      dispatch(fetchActiveRadgivare(teamId))
    }
  }

  const handleSelectedOption = (_: unknown, value: string) => {
    const selected = parseInt(value) as AteroppnaOptions
    setSelectedOption(selected)
    if (selected !== AteroppnaOptions.OTHER_ADVISOR) {
      setSelectedRadgivare(null)
    }
  }

  const handleCancel = () => {
    setSelectedOption(null)
    setModalIsOpen(false)
  }

  const handleConfirm = () => {
    const request: AteroppnaArendeRequestParameters = {
      arendeId,
      omfordela: selectedOption === AteroppnaOptions.REALLOCATE,
      radgivarId:
        selectedOption === AteroppnaOptions.OTHER_ADVISOR
          ? selectedRadgivare.id
          : null,
    }
    dispatch(ateroppnaArende(request))
  }

  return (
    <>
      <Button size="large" onClick={() => setModalIsOpen(true)}>
        Återöppna
      </Button>

      <Modal
        open={modalIsOpen}
        closeIcon
        confirmText="Återöppna"
        label="Återöppna"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        disableSubmit={
          ateroppnaArendeState === FetchingState.LOADING ||
          selectedOption === null ||
          (selectedOption === AteroppnaOptions.OTHER_ADVISOR &&
            selectedRadgivare === null)
        }
      >
        {ateroppnaArendeState === FetchingState.LOADING ? (
          <Loading />
        ) : (
          <FormControl>
            <RadioGroup value={selectedOption} onChange={handleSelectedOption}>
              <FormControlLabel
                label="Samma rådgivare kvarstår"
                value={AteroppnaOptions.SAME_ADVISOR}
                control={<Radio />}
                disabled={!canReopenOtherRadgivare}
              />
              <FormControlLabel
                label="Byt till en annan rådgivare i samma team"
                onClick={fetchRadgivare}
                value={AteroppnaOptions.OTHER_ADVISOR}
                control={<Radio />}
                disabled={!canReopenOtherRadgivare}
              />
              {selectedOption === AteroppnaOptions.OTHER_ADVISOR && (
                <>
                  {fetchingRadgivareState === FetchingState.LOADING && (
                    <Loading />
                  )}
                  {fetchingRadgivareState === FetchingState.ERROR && (
                    <Alert severity="error">Kunde inte hämta rådgivare.</Alert>
                  )}
                  {fetchingRadgivareState === FetchingState.SUCCESS && (
                    <Autocomplete
                      label="Välj rådgivare"
                      value={selectedRadgivare}
                      onChange={(event: IRadgivare) =>
                        setSelectedRadgivare(event)
                      }
                      options={activeRadgivare}
                      getOptionLabel={(option: IRadgivare) =>
                        `${option.fornamn} ${option.efternamn}`
                      }
                    />
                  )}
                </>
              )}
              <FormControlLabel
                sx={{
                  mt:
                    selectedOption === AteroppnaOptions.OTHER_ADVISOR
                      ? 2
                      : 'inherit',
                }}
                label="Ärendet ska tillbaka till fördelning"
                value={AteroppnaOptions.REALLOCATE}
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        )}
      </Modal>
    </>
  )
}

export default AteroppnaModal

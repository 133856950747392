import React, { useCallback, useState, useEffect } from 'react'
import { FetchingState } from '@local/src/Common.types'
import { formatAmount } from '@local/Utils/smallfuncs'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { ICreateEmailForm } from '../CreateEmail.types'

export interface ICreateEmailModalProps {
  isOpen: boolean
  closeModal: () => void
  createEmail: (emailForm: ICreateEmailForm) => void
  createEmailState: FetchingState
  numberOfEmailRecipients: number
}

const CreateEmailModal = ({
  isOpen,
  closeModal,
  createEmail,
  createEmailState,
  numberOfEmailRecipients,
}: ICreateEmailModalProps) => {
  const [subject, setSubject] = useState('')
  const [shouldCreateEvent, setShouldCreateEvent] = useState(true)
  const [note, setNote] = useState('')

  const clearForm = () => {
    setSubject('')
    setNote('')
    setShouldCreateEvent(true)
  }

  useEffect(() => {
    if (createEmailState === FetchingState.SUCCESS) {
      clearForm()
    }
  }, [createEmailState])

  useEffect(() => {
    if (!isOpen) {
      clearForm()
    }
  }, [isOpen])

  const handleUpdateEmailSubject = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(event.target.value)
    },
    [setSubject]
  )

  const handleCreateEmail = () => {
    createEmail({
      subject,
      note: {
        createNote: shouldCreateEvent,
        text: shouldCreateEvent ? note : '',
      },
    })
  }

  const handleUpdateNotering = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target
      setNote(value)
    },
    [setNote]
  )

  const toggleCreateEvent = useCallback(() => {
    setShouldCreateEvent((prev) => !prev)
  }, [setShouldCreateEvent])

  const hasAllowedNumberOfRecipients = numberOfEmailRecipients < 500

  if (!hasAllowedNumberOfRecipients) {
    return (
      <Dialog open={isOpen} onClose={onclose}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>För många mottagare</DialogTitle>
          <IconButton aria-label="Stäng dialog" onClick={closeModal}>
            <CloseIcon sx={{ color: 'neutral.main' }} />
          </IconButton>
        </Box>
        <DialogContent sx={{ paddingTop: 0 }}>
          <Typography>
            Du kan som mest skicka gruppmejl till 500 mottagare.
          </Typography>
          <Typography>
            Du har valt {formatAmount(numberOfEmailRecipients)} mottagare.
          </Typography>
          <Typography>Justera filtreringen och försök igen.</Typography>
        </DialogContent>
      </Dialog>
    )
  } else {
    return (
      <Dialog open={isOpen} onClose={onclose}>
        <DialogTitle>Skapa gruppmejl</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}
        >
          <Typography>
            Du skapar ett utkast till {numberOfEmailRecipients} mottagare.
          </Typography>
          <FormControlLabel
            label="Skapa händelse"
            control={
              <Checkbox
                title="Skapa händelse"
                checked={shouldCreateEvent}
                onChange={toggleCreateEvent}
              />
            }
          />
          <TextField
            label="Mejlets ämnesrad"
            placeholder="Skriv ämne"
            onChange={handleUpdateEmailSubject}
            value={subject}
          />
          {shouldCreateEvent && (
            <TextField
              label="Anteckningar i händelse (frivilligt)"
              placeholder="Skriv händelse"
              rows={5}
              multiline
              onChange={handleUpdateNotering}
              data-testid="event-note-text"
              value={note}
            />
          )}
          <Typography>
            När du trycker på &quot;Skapa utkast&quot; skapas ett mejl i mappen
            Utkast i Outlook. Där kan du redigera och skicka ditt mejl.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCreateEmail}>
            Skapa utkast
          </Button>
          <Button variant="text" onClick={closeModal}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default CreateEmailModal

import { useMemo } from 'react'

import { ArbetsuppgiftFilter } from '../../types'

const useIsArbetsuppgiftFilterActive = (
  filters: Partial<ArbetsuppgiftFilter>
) => {
  const filtersActive = useMemo(() => {
    return Boolean(
      filters.ownerIds.length > 0 ||
        filters.statusar.length > 0 ||
        filters.uppgiftsTyper.length > 0
    )
  }, [filters])

  return filtersActive
}

export default useIsArbetsuppgiftFilterActive

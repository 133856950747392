import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import StudieAnmalanStatusChip from '../../../StudieAnmalanStatusChip/StudieAnmalanStatusChip'
import { IStudieAnmalan } from '../../../types/StudieAnmalan.types'

interface IStudieAnmalanHeaderProps {
  studieAnmalan: IStudieAnmalan
}

const StudieAnmalanHeader: React.FC<
  React.PropsWithChildren<IStudieAnmalanHeaderProps>
> = ({ studieAnmalan }) => {
  const { educationId = '12345', educationName, statuses } = studieAnmalan
  const epi = useEpiString()
  return (
    <Stack
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      sx={{ width: '100%', maxWidth: 'unset' }}
      direction="row"
    >
      <Box>
        <Typography gutterBottom={false} variant="h3">
          {educationName}
        </Typography>
        <Typography gutterBottom={false} variant="subtitle2">
          {educationId && `${epi('common.EducationNumber')} ${educationId}`}
        </Typography>
      </Box>

      <StudieAnmalanStatusChip statuses={statuses} />
    </Stack>
  )
}

export default StudieAnmalanHeader

import { HubConnectionState } from '@microsoft/signalr'
import { useSignalR, useUser } from '@trr/app-shell-data'
import { useEffect } from 'react'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { arbetsuppgiftApi } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'

import getConfig from '../Utils/ConfigService'

const usePluppUpdatedListener = (): void => {
  const connection = useSignalR(`${getConfig().PUBLIC_WEB_API_URL}/medarbetare`)
  const dispatch = useAppDispatch()
  const userId = useUser().mdid

  useEffect(() => {
    if (connection.state === HubConnectionState.Connected) {
      connection.on(`refetchArbetsuppgiftCount-${userId}`, () => {
        dispatch(arbetsuppgiftApi.util.invalidateTags(['Plupp']))
      })
      return () => {
        connection.off(`refetchArbetsuppgiftCount-${userId}`)
      }
    }
  }, [connection.state])
}

export { usePluppUpdatedListener }

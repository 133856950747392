import { useIsUndantagsgrupp } from '@local/src/Hooks'
import { ArendeMarkning } from '@local/src/Views/Arenden/Arenden.types'
import { updateMarkning } from '@local/src/Views/Case/Case.actions'
import { IMarkning } from '@local/src/Views/Case/Case.types'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

interface ArendeMarkingarProps {
  arendeId: string
  arendeMarkningar: IMarkning[]
}

const ArendeMarkningar = ({
  arendeId,
  arendeMarkningar,
}: ArendeMarkingarProps) => {
  const dispatch = useDispatch()
  const isUndantagsgrupp = useIsUndantagsgrupp()

  const updateArendeMarkning = (name: string, checked: boolean) => {
    dispatch(updateMarkning(arendeId, name, checked))
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        display={{ xs: 'flex', sm: 'none' }}
        mt={1}
      >
        Ärendemärkningar
      </Typography>
      {arendeMarkningar.map(
        (markning) =>
          (markning.id !== ArendeMarkning.Undantagsgrupp || markning.isSet) && (
            <FormControlLabel
              key={markning.id}
              name={markning.id}
              label={markning.displayText}
              disabled={!markning.editable}
              control={
                <Checkbox
                  checked={markning.isSet}
                  onChange={(e) =>
                    updateArendeMarkning(
                      e.currentTarget.name,
                      e.currentTarget.checked
                    )
                  }
                />
              }
            />
          )
      )}
      {isUndantagsgrupp && (
        <FormControlLabel
          name="Undantagsgrupp"
          label="Undantagsgrupp"
          disabled
          control={<Checkbox checked={isUndantagsgrupp} />}
        />
      )}
    </>
  )
}

export default ArendeMarkningar

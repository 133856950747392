// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z0EpFOEIiF8QCKE0fhKV{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/Features/StudieAnmalan/StudieAnmalanDetails/components/StudieAnmalanEducation/StudieAnmalanEducation.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".ListItemWithSpacing {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListItemWithSpacing": `Z0EpFOEIiF8QCKE0fhKV`
};
export default ___CSS_LOADER_EXPORT___;

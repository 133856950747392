import { HubConnection } from '@microsoft/signalr'

import { SignalRRoom } from './signalR.types'

export const connectToSignalRRoom = ({
  connection,
  room,
  onConnection,
  groupName,
}: {
  connection: HubConnection
  room: SignalRRoom
  onConnection: () => void
  groupName: string
}): void => {
  if (connection) {
    const connect = () => {
      connection.invoke('AddToGroup', groupName).then(() =>
        connection.on(room, () => {
          onConnection()
        })
      )
    }
    if (connection.state === 'Connected') {
      connect()
    } else if (connection.state === 'Connecting') {
      setTimeout(() => {
        connect()
      }, 3000)
    }
  }
}

export const disconnectFromSignalRRoom = ({
  connection,
  groupName,
  room,
}: {
  connection: HubConnection
  groupName: string
  room: SignalRRoom
}): void => {
  if (connection) {
    connection.off(room)
    connection.invoke('RemoveFromGroup', groupName)
  }
}

import { Box, CircularProgress, SxProps, Theme } from '@mui/material'
import React from 'react'

interface LoadingProps {
  size?: 'small' | 'medium' | 'large'
  sx?: SxProps<Theme>
}

const Loading = ({ size = 'medium', sx }: LoadingProps): JSX.Element => {
  const fontSize =
    size === 'small' ? '1rem' : size === 'medium' ? '2rem' : '3rem'

  return (
    <Box display="flex" justifyContent="center" sx={sx}>
      <CircularProgress size={fontSize} data-testid="loading" />
    </Box>
  )
}

export default Loading

import React, { useEffect } from 'react'
import LaunchIcon from '@mui/icons-material/Launch'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import { getCurrentEnvironment } from '@local/src/Utils/helpers'
import getConfig from '@local/src/Utils/ConfigService'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'

import { klientSelector } from '../Case.selectors'

import {
  attendedActivitiesSelector,
  currentActivitiesSelector,
  fetchingActivitiesStateSelector,
} from './Activities.selectors'
import ActivityList from './ActivityList/ActivityList'
import {
  fetchAttendedActivities,
  fetchCurrentActivities,
} from './Activities.actions'

export const buildBokaLink = (environment: 'dev' | 'test' | 'prod'): string => {
  switch (environment) {
    case 'dev':
      return 'https://boka-dev.trr.se/'

    case 'test':
      return 'https://boka-test.trr.se/'

    case 'prod':
      return 'https://boka.trr.se/'
  }
}

const Activities = () => {
  const bokaLink = buildBokaLink(getCurrentEnvironment(getConfig().API_URL))
  const dispatch = useDispatch()
  const kund = useSelector(klientSelector)
  const attendedActivities = useSelector(attendedActivitiesSelector)
  const currentActivities = useSelector(currentActivitiesSelector)
  const activitiesLoading = useSelector(fetchingActivitiesStateSelector)

  useEffect(() => {
    if (kund) {
      dispatch(fetchCurrentActivities(kund.id))
      dispatch(fetchAttendedActivities(kund.id))
    }
  }, [kund])

  if (activitiesLoading === FetchingState.LOADING) {
    return <Loading />
  }

  return (
    <Stack
      display="flex"
      flexDirection="column"
      gap={5}
      mt={5}
      width={{ lg: '80%' }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" mb={1}>
          Gå till aktiviteter för omställningskunder för att dela länk så att de
          själva kan anmäla sig.
        </Typography>
        <LinkWithIcon
          text="Aktiviteter för omställningskund"
          href="/medarbetare/aktiviteter-for-omstallningskunder"
          icon={<LaunchIcon fontSize="small" />}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" mb={1}>
          Det går även att anmäla omställningskunden via Boka
        </Typography>
        <LinkWithIcon
          text="Boka"
          href={bokaLink}
          external
          icon={<LaunchIcon fontSize="small" />}
        />
      </Box>

      <ActivityList
        key="aktuella"
        title="Aktuella"
        emptyListText={`${kund.fornamn} har inga aktiviteter inbokade`}
        activities={currentActivities}
      />
      <ActivityList
        key="genomforda"
        title="Genomförda"
        emptyListText={`${kund.fornamn} har inga genomförda aktiviteter`}
        activities={attendedActivities}
      />
    </Stack>
  )
}

export default Activities

import { formatDateFromDateTime } from '@local/src/Utils/DateTime'
import { isAvslutatArende } from '@local/src/Utils/SubStatus'
import { isNotNullOrEmpty } from '@local/src/Utils/smallfuncs'

const getDagarKvarDisplayText = ({
  orsakTillUppsagning,
  antalKvarvarandeRadgivningsDagar,
  slutDatumRadgivning,
  radgivningsSubStatus,
}: {
  orsakTillUppsagning?: string
  antalKvarvarandeRadgivningsDagar?: number
  slutDatumRadgivning?: string
  radgivningsSubStatus?: string
}): string => {
  const harDagarKvar = [
    orsakTillUppsagning,
    antalKvarvarandeRadgivningsDagar,
    slutDatumRadgivning,
    radgivningsSubStatus,
  ].some((value) => value !== undefined)

  const isRisk = ['RISK_UPPSAGNING', 'RiskForUppsagningPgaArbetsbrist'].some(
    (orsak) => orsak === orsakTillUppsagning
  )
  const quantityDays = antalKvarvarandeRadgivningsDagar
  const slutDatum = formatDateFromDateTime(slutDatumRadgivning)
  const dagarKvarText = isRisk
    ? 'RISK'
    : `${quantityDays} st${isNotNullOrEmpty(slutDatum) ? ` - ${slutDatum}` : ''}`
  const isCaseClosed = isAvslutatArende(radgivningsSubStatus)

  return harDagarKvar ? (isCaseClosed ? 'Kontakta KC' : dagarKvarText) : ''
}

export default getDagarKvarDisplayText

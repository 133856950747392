import React, { useState } from 'react'
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { formatDateFromDateTime } from '@local/src/Utils/DateTime'
import { getUrlByCaseEnv } from '@local/src/basename'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import { useSelector } from 'react-redux'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import LockIcon from '@mui/icons-material/Lock'
import CaseAvatar from '@local/src/Components/CaseAvatar/CaseAvatar'
import Modal from '@local/src/Components/Modal/Modal'

import { IOtherCase } from '../../Case.types'
import { ovrigaArendenSelector } from '../../Case.selectors'

const OvrigaArenden = () => {
  const arenden = useSelector(ovrigaArendenSelector)
  const caseEnvBasePath = useCaseEnvBasePath()
  const [noAccessModalOpen, setNoAccessModalIsOpen] = useState(false)

  const setOvrigtArendeDisplayText = (arende: IOtherCase) => {
    let displayText = arende.avslutsDatum
      ? `Avslutat ${formatDateFromDateTime(arende.avslutsDatum)}`
      : 'Aktuellt'
    displayText +=
      arende.radgivareFornamn || arende.radgivareEfternamn
        ? ` - ${arende.radgivareFornamn} ${arende.radgivareEfternamn}`
        : ' - Rådgivare saknas'
    return displayText
  }

  const onClickArende = (arende: IOtherCase) => {
    if (arende.hasAccess) {
      return
    }
    setNoAccessModalIsOpen(true)
  }

  return (
    <>
      {arenden?.length > 0 && (
        <>
          <Typography variant="h4" mb={1}>
            Övriga ärenden
          </Typography>
          <List disablePadding>
            {arenden.map((arende) => {
              return (
                <ListItemButton
                  key={arende.arendeId}
                  href={
                    arende.hasAccess
                      ? getUrlByCaseEnv(caseEnvBasePath, arende.arendeId)
                      : undefined
                  }
                  onClick={() => onClickArende(arende)}
                  target="_blank"
                >
                  <ListItemIcon>
                    <CaseAvatar arendeTyp={arende.arendeTyp} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body1' }}
                    primary={setOvrigtArendeDisplayText(arende)}
                  />
                  <ListItemIcon sx={{ justifyContent: 'center' }}>
                    {arende.hasAccess ? <OpenInNewIcon /> : <LockIcon />}
                  </ListItemIcon>
                </ListItemButton>
              )
            })}
          </List>
          <Divider sx={{ my: 5 }} />
        </>
      )}
      <Modal
        label="Ingen behörighet"
        open={noAccessModalOpen}
        closeIcon
        onClose={() => setNoAccessModalIsOpen(false)}
      >
        <Box display="flex" mb={3}>
          <Typography variant="body1">
            Du har inte behörighet till detta ärende.
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default OvrigaArenden

import { useLocation } from 'react-router-dom'

import {
  dinaArendenArendeBasename,
  fordelaArendenPath,
  startklarPath,
  dinaArendenPath,
  startklarCaseBasename,
  allaArendenPath,
  arendeBasename,
  omstallningsArendenPath,
  tioBasename,
  kompetensstodsArendenPath,
  tiaBasename,
} from '../basename'

const useCaseEnvBasePath = (): string => {
  const { pathname: p } = useLocation()

  if (p.includes(dinaArendenPath)) return dinaArendenArendeBasename
  if (p.includes(fordelaArendenPath)) return arendeBasename
  if (p.includes(startklarCaseBasename)) return arendeBasename
  if (p.includes(startklarPath)) return startklarCaseBasename
  if (p.includes(omstallningsArendenPath)) return tioBasename
  if (p.includes(kompetensstodsArendenPath)) return tiaBasename
  if (p.includes(allaArendenPath)) return arendeBasename

  return arendeBasename
}

export { useCaseEnvBasePath }

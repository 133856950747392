import React from 'react'
import { Typography } from '@mui/material'

interface IconWithTextProps {
  children: string | JSX.Element
  icon: JSX.Element
}

const IconWithText = ({ children, icon }: IconWithTextProps): JSX.Element => {
  return (
    <Typography display="flex" alignItems="center" gap={1} alignSelf="baseline">
      {icon}
      {children}
    </Typography>
  )
}
export default IconWithText

import {
  Actor,
  ArbetsuppgiftFacets,
  ArbetsuppgiftFilter,
  ArbetsuppgiftResult,
  ArbetsuppgiftStatus,
  UpdateArbetsuppgiftStatusRequest,
} from '@local/src/Views/Arenden/Arbetsuppgift/types'
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query'
import { getQueryParameters } from '@local/src/Views/Arenden/Arbetsuppgift/helpers'

import { baseApi } from '../baseApi'

export interface GetFacetRequest {
  key: keyof ArbetsuppgiftFacets
  filter: Partial<ArbetsuppgiftFilter>
}

const baseUrl = '/v1.0/arbetsuppgift'

export const arbetsuppgiftApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Arbetsuppgifter', 'Plupp'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitialArbetsuppgiftFacets: builder.query<ArbetsuppgiftFacets, void>({
        query: () => `${baseUrl}/search`,
        transformResponse: (response: ArbetsuppgiftResult) => response.facets,
        keepUnusedDataFor: 0,
      }),
      getFacetsForProperty: builder.query<ArbetsuppgiftFacets, GetFacetRequest>(
        {
          query: (params) => {
            const filter = {
              ...params.filter,
              pagination: { ...params.filter.pagination },
            }
            filter.pagination.pageSize = '0'

            if (params.key === 'Status') {
              filter.statusar = []
            }
            if (params.key === 'Category') {
              filter.uppgiftsTyper = []
            }
            if (params.key === 'Owner') {
              filter.ownerIds = []
            }
            const queryParams = getQueryParameters(filter)
            return `${baseUrl}/search${queryParams}`
          },
          transformResponse: (response: ArbetsuppgiftResult) => response.facets,
        }
      ),
      getPlupp: builder.query<number, void>({
        query: () => `${baseUrl}/plupp`,
        keepUnusedDataFor: 0,
        providesTags: ['Plupp'],
      }),
      getArbetsuppgifter: builder.query<ArbetsuppgiftResult, string>({
        query: (queryParams) => `${baseUrl}/search${queryParams}`,
        keepUnusedDataFor: 0,
        providesTags: (result: ArbetsuppgiftResult, error: any) => {
          if (error) {
            console.error(error)
            return []
          }
          return [
            ...result.results.map(({ id }) => ({
              type: 'Arbetsuppgifter' as const,
              id,
            })),
          ]
        },
      }),
      updateStatus: builder.mutation<void, UpdateArbetsuppgiftStatusRequest>({
        query: (body) => ({
          url: `${baseUrl}/${body.id}/status`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: `"${body.status}"`,
        }),
        onQueryStarted: async (args, api) => {
          const queryCache = arbetsuppgiftApi.util.selectInvalidatedBy(
            api.getState(),
            ['Arbetsuppgifter']
          )
          const patchResult = api.dispatch(
            arbetsuppgiftApi.util.updateQueryData(
              'getArbetsuppgifter',
              queryCache[0].originalArgs as string, // to correctly update the current cache (depending on queryParams)
              (data) => {
                const index = data.results.findIndex(
                  (item) => item.id === args.id
                )
                const arbetsuppgift = data.results[index]
                if (arbetsuppgift.owner?.id === args.userId) {
                  if (
                    arbetsuppgift.status === ArbetsuppgiftStatus.Ny &&
                    args.status !== ArbetsuppgiftStatus.Ny
                  ) {
                    updateLocalPlupp(false, api)
                  } else if (
                    arbetsuppgift.status !== ArbetsuppgiftStatus.Ny &&
                    args.status === ArbetsuppgiftStatus.Ny
                  ) {
                    updateLocalPlupp(true, api)
                  }
                }
                data.results[index].status = args.status
                data.results[index].latestStatusUpdate = {
                  occuredAt: new Date().toISOString(),
                  triggeredBy: Actor.User,
                  user: {
                    id: args.userId,
                    firstName: '',
                    lastName: '',
                  },
                  reason: null,
                }
                return data
              }
            )
          )
          await api.queryFulfilled.catch(patchResult.undo)
        },
      }),
    }),
  })

const updateLocalPlupp = (
  increment: boolean,
  api: MutationLifecycleApi<
    UpdateArbetsuppgiftStatusRequest,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      unknown,
      FetchBaseQueryMeta
    >,
    void,
    'api'
  >
) => {
  arbetsuppgiftApi.util.selectInvalidatedBy(api.getState(), ['Plupp'])
  api.dispatch(
    arbetsuppgiftApi.util.updateQueryData('getPlupp', undefined, (data) => {
      data = increment ? (data += 1) : (data -= 1)
      return data
    })
  )
}

export const {
  useGetFacetsForPropertyQuery,
  useLazyGetFacetsForPropertyQuery,
  useGetInitialArbetsuppgiftFacetsQuery,
  useGetArbetsuppgifterQuery,
  useUpdateStatusMutation,
  useGetPluppQuery,
  useLazyGetPluppQuery,
} = arbetsuppgiftApi

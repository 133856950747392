import ChipWithPopper from '@local/src/Components/Chips/ChipWithPopover/ChipWithPopper'
import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IRadgivare } from '@local/src/Views/Case/Case.types'
import FilterAccordion from '@local/src/Components/FilterAccordion/FilterAccordion'
import { useUser } from '@trr/app-shell-data'

import { IHandlaggare, StartklarFilter } from '../../Startklar.types'

interface RadgivareFilterProps {
  count: number
  chosenRadgivare: string[]
  radgivareList: IHandlaggare[] | IRadgivare[]
  isMobile: boolean
  enableNotSetOption?: boolean
  notSetChecked?: boolean
  setFilter: (key: keyof StartklarFilter, values: string | string[]) => void
  clearFilter: () => void
  notSetOptionClicked?: () => void
}

const RadgivareFilter = ({
  count,
  isMobile,
  chosenRadgivare,
  radgivareList,
  notSetChecked,
  enableNotSetOption = false,
  setFilter,
  clearFilter,
  notSetOptionClicked,
}: RadgivareFilterProps) => {
  const currentUserId = useUser().mdid
  const [currentUser, setCurrentUser] = useState<IHandlaggare | IRadgivare>(
    null
  )
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState<string>()
  const [filteredRadgivareList, setFilteredRadgivareList] = useState<
    IHandlaggare[] | IRadgivare[]
  >(radgivareList)

  useEffect(() => {
    const currentUserInList = radgivareList?.filter(
      (item) => item.id === currentUserId
    )?.[0]
    if (currentUserInList) {
      setCurrentUser(currentUserInList)
    }
  }, [radgivareList, filteredRadgivareList])

  const setHandlaggareFilter = (radgivareId: string) => {
    if (chosenRadgivare.some((id) => id === radgivareId)) {
      setFilter(
        'handlaggareIdn',
        chosenRadgivare.filter((id) => id !== radgivareId)
      )
    } else {
      setFilter('handlaggareIdn', [...chosenRadgivare, radgivareId])
    }
  }

  useEffect(() => {
    if (searchValue && searchValue.length > 1) {
      setFilteredRadgivareList(() => {
        const lowerCaseQuery = searchValue.toLocaleLowerCase()
        const results = radgivareList.filter((person) =>
          `${person.fornamn} ${person.efternamn}`
            .toLocaleLowerCase()
            .includes(lowerCaseQuery)
        )
        return results
      })
    } else {
      setFilteredRadgivareList(radgivareList)
    }
  }, [searchValue, radgivareList])

  const handlaggareList = (
    <>
      <Box mb={1} px={2}>
        <TextField
          data-testid="ansvarig-search"
          label="Sök ansvarig"
          value={searchValue}
          // we really do want to autoFocus the user, hence the eslint-disable.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onChange={(evt) => setSearchValue(evt.target.value)}
          fullWidth
          inputProps={{ 'data-testid': 'search-advisor-textfield' }}
        />
      </Box>
      <List>
        {enableNotSetOption && (
          <ListItem key="ejTilldelad">
            <ListItemButton role="checkbox" dense onClick={notSetOptionClicked}>
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  checked={notSetChecked}
                  inputProps={{ 'aria-labelledby': 'Ej tilldelad' }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Ej tilldelad"
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {currentUser && (
          <ListItem key={`${currentUser.fornamn} ${currentUser.efternamn}`}>
            <ListItemButton
              role="checkbox"
              dense
              onClick={() => setHandlaggareFilter(currentUser.id)}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  checked={chosenRadgivare.some((s) => s === currentUser.id)}
                  inputProps={{
                    'aria-labelledby': `${currentUser.fornamn} ${currentUser.efternamn}`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${currentUser.fornamn} ${currentUser.efternamn}`}
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
      <List
        sx={{ overflowY: 'auto', maxHeight: { xs: 'inherit', md: '40vh' } }}
      >
        {filteredRadgivareList
          ?.filter((item) => item.id !== currentUserId)
          .map((radgivare) => (
            <ListItem key={radgivare.id}>
              <ListItemButton
                role="checkbox"
                dense
                onClick={() => setHandlaggareFilter(radgivare.id)}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    checked={chosenRadgivare.some((s) => s === radgivare.id)}
                    inputProps={{
                      'aria-labelledby': `${radgivare.fornamn} ${radgivare.efternamn}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${radgivare.fornamn} ${radgivare.efternamn}`}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  )

  if (isMobile) {
    return (
      <FilterAccordion
        label="Rådgivare"
        indicatorCount={count}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {handlaggareList}
      </FilterAccordion>
    )
  } else {
    return (
      <ChipWithPopper
        label="Rådgivare"
        width={360}
        onClear={clearFilter}
        count={count}
      >
        {handlaggareList}
      </ChipWithPopper>
    )
  }
}

export default RadgivareFilter

import {
  ArbetsuppgiftCategory,
  ArbetsuppgiftFilter,
  ArbetsuppgiftStatus,
} from '../../types'

export const getDefaultFilters = (currentUserId: string) => {
  const defaultFilters: ArbetsuppgiftFilter = {
    searchText: '',
    statusar: [ArbetsuppgiftStatus.Ny, ArbetsuppgiftStatus.Pagaende],
    uppgiftsTyper: [],
    actions: [],
    ownerIds: [currentUserId],
    pagination: {
      page: '1',
      pageSize: '10',
    },
    orderBy: {
      property: 'createdDate',
      direction: 'asc',
    },
  }

  return defaultFilters
}

export const getResetFilter = (currentFilter: Partial<ArbetsuppgiftFilter>) => {
  const emptyFilter: ArbetsuppgiftFilter = {
    searchText: '',
    statusar: [],
    uppgiftsTyper: [],
    actions: [],
    ownerIds: [],
    pagination: {
      page: '1',
      pageSize: currentFilter.pagination.pageSize,
    },
    orderBy: currentFilter.orderBy,
  }

  return emptyFilter
}

export const getCategoryLabel = (category: ArbetsuppgiftCategory) => {
  switch (category) {
    case ArbetsuppgiftCategory.NyStudiestodsAnsokan:
      return 'Ny studiestödsansökan'
    case ArbetsuppgiftCategory.UtbildningAttGodkanna:
      return 'Utbildning att godkänna'
    case ArbetsuppgiftCategory.UppdateradStudiestodsAnsokan:
      return 'Uppdaterad studiestödsansökan'
    case ArbetsuppgiftCategory.NyttOmfattningsbeslut:
      return 'Nytt omfattningsbeslut'
    case ArbetsuppgiftCategory.KommentarFranAvtalErsattningar:
      return 'Kommentar från Avtal & ersättningar'
    case ArbetsuppgiftCategory.BegaranYttrande:
      return 'Begäran om yttrande'
  }
}

import React from 'react'
import { Chip, ListItemButton, ListItemText, Typography } from '@mui/material'
import { formatDateWithMonthStringFromDateTime } from '@local/src/Utils/DateTime'
import { medarbetareBasepath } from '@local/src/basename'

import { ActivityRound } from '../Activities.types'

interface ActivityListItemProps {
  activity: ActivityRound
}

const ActivityListItem = ({ activity }: ActivityListItemProps) => {
  const activityStatusDisplayTexts: {
    [key in string]: {
      displayText: string
      chipVariant:
        | 'filled'
        | 'outlined'
        | 'status-info'
        | 'status-error'
        | 'status-success'
        | 'status-warning'
    }
  } = {
    DidNotAttend: { displayText: 'Deltog ej', chipVariant: 'status-error' },
    Reserve: { displayText: 'Reserv', chipVariant: 'status-warning' },
    Attended: { displayText: 'Deltog', chipVariant: 'status-success' },
    Registered: { displayText: 'Anmäld', chipVariant: 'status-success' },
    Cancelled: { displayText: 'Avbokad', chipVariant: 'status-error' },
  }

  return (
    <ListItemButton
      divider
      sx={{ p: 2, gap: 2 }}
      href={`${medarbetareBasepath}/aktiviteter-for-omstallningskunder/aktivitet-medarbetare/${activity.id}`}
    >
      <ListItemText
        primary={activity.activity.name}
        primaryTypographyProps={{ variant: 'body1' }}
        secondary={`${formatDateWithMonthStringFromDateTime(activity.schedule.start, true)}`}
        secondaryTypographyProps={{ variant: 'body2' }}
      />
      {activity.schedule.lifecycleStatus === 'CANCELLED' ? (
        <Typography variant="subtitle2" color="text.secondary" px={1.5}>
          Inställd
        </Typography>
      ) : (
        <Chip
          variant={
            activityStatusDisplayTexts[
              activity.registration.userRegistration.status
            ].chipVariant
          }
          label={
            activityStatusDisplayTexts[
              activity.registration.userRegistration.status
            ].displayText
          }
        />
      )}
    </ListItemButton>
  )
}

export default ActivityListItem

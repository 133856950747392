import React from 'react'
import PhoneNumber from '@local/src/Components/PhoneNumber/PhoneNumber'
import WorkOutLinedIcon from '@mui/icons-material/WorkOutline'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MuiIconWithText from '@local/src/Components/IconWithText/MuiIconWithText'
import Email from '@local/src/Components/Email'
import {
  Box,
  Stack,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'

interface IStartklarKlientPrimaryInformation {
  companyName: string
  email: string
  jobTitle: string
  phoneNumber: string
  kommun: string
}

const StartklarKlientPrimaryInformation = ({
  companyName,
  email,
  jobTitle,
  phoneNumber,
  kommun,
}: IStartklarKlientPrimaryInformation): JSX.Element => {
  const theme = useTheme()
  const isScreenAboveMd = useMediaQuery(theme.breakpoints.up('md'))

  const firstColumnStyling: SxProps<Theme> = {
    gridColumn: isScreenAboveMd ? 'auto' : '1/4',
  }

  const secondColumnStyling: SxProps<Theme> = {
    gridColumn: isScreenAboveMd ? '2/4' : '1/4',
  }

  return (
    <Stack display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={1}>
      <Box sx={firstColumnStyling}>
        <PhoneNumber>{phoneNumber}</PhoneNumber>
      </Box>
      <Box sx={secondColumnStyling}>
        <Email>{email}</Email>
      </Box>

      <Box sx={firstColumnStyling}>
        <MuiIconWithText
          icon={<HomeOutlinedIcon fontSize="small" />}
          variant="body1"
          text={kommun}
        />
      </Box>
      <Box sx={secondColumnStyling}>
        <MuiIconWithText
          icon={<WorkOutLinedIcon fontSize="small" />}
          text={`${jobTitle}, ${companyName}`}
          variant="body1"
        />
      </Box>
    </Stack>
  )
}

export default StartklarKlientPrimaryInformation

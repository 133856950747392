import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'

import {
  fetchAllocateCaseListInitiate,
  fetchAllocateCaseListFailure,
  fetchAllocateCaseListSuccess,
  fetchAllocateTeamListInitiate,
  fetchAllocateTeamListFailure,
  fetchAllocateTeamListSuccess,
  fetchAllAllocateTeamListInitiate,
  fetchAllAllocateTeamListSuccess,
  fetchAllAllocateTeamListFailure,
  fetchAllocateAdvisorListInitiate,
  fetchAllocateAdvisorListSuccess,
  fetchAllocateAdvisorListFailure,
  allocateCasesInitiated,
  allocateCasesSuccess,
  allocateCasesFailure,
  clearAllocationResponse,
  changeClientAllocationTeamSuccess,
  changeClientAllocationTeamFailure,
  changeClientAllocationTeamInitiated,
  fetchAllocationDetailsInitiate,
  fetchAllocationDetailsSuccess,
  fetchAllocationDetailsFailure,
} from './AllocateCases.actions'
import {
  IAdvisor,
  IAllocateCaseListItem,
  IAllocateCaseState,
  IAllocationDetails,
} from './AllocateCases.types'

export interface IAllocationResponse {
  arendeId: string
  radgivarId: string
  responseCode: string
  error: string
  errorCode: string
}

const increaseIdagWithAllocationCount =
  (allocationResponses: IAllocationResponse[]) => (advisor: IAdvisor) => {
    const amountOfAllocatedCasesWithRelevantAdvisorId =
      allocationResponses.filter(
        (response) =>
          response.radgivarId === advisor.id && response.responseCode === 'OK'
      ).length

    advisor.radgivarStatistik.idag +=
      amountOfAllocatedCasesWithRelevantAdvisorId
    return advisor
  }

const appendAllocationDetails = (
  state: IAllocateCaseState,
  payload: IAllocationDetails
) => {
  return state.caseList.map((item: IAllocateCaseListItem) => {
    return item.id === payload.queriedArendeId
      ? {
          ...item,
          allocationDetails: payload,
        }
      : item
  })
}

export const initialState: IAllocateCaseState = {
  caseList: [],
  fetchingCaseListState: FetchingState.IDLE,

  allocatableTeams: [],
  fetchingTeamListState: FetchingState.IDLE,

  allTeams: [],
  fetchingAllTeamsState: FetchingState.IDLE,

  advisorList: [],
  fetchingAdvisorListState: FetchingState.IDLE,
  allocationDetails: {
    tidigareRadgivare: [],
    queriedKlientId: null,
    queriedArendeId: null,
    ovrigaArenden: [],
  },
  fetchingAllocationDetails: FetchingState.IDLE,

  allocatingCasesState: FetchingState.IDLE,
  changingClientAllocationTeamState: FetchingState.IDLE,
}

const allocateCasesReducer = handleActions(
  {
    [fetchAllocationDetailsInitiate.toString()]: (state) => ({
      ...state,
      fetchingAllocationDetails: FetchingState.LOADING,
    }),
    [fetchAllocationDetailsFailure.toString()]: (state) => ({
      ...state,
      fetchingAllocationDetails: FetchingState.ERROR,
    }),
    [fetchAllocationDetailsSuccess.toString()]: (state, { payload }) => ({
      ...state,
      fetchingAllocationDetails: FetchingState.SUCCESS,
      caseList: appendAllocationDetails(
        state,
        payload.allocationDetails as unknown as IAllocationDetails
      ),
    }),

    [fetchAllocateCaseListInitiate.toString()]: (state) => ({
      ...state,
      fetchingCaseListState: FetchingState.LOADING,
    }),
    [fetchAllocateCaseListFailure.toString()]: (state) => ({
      ...state,
      fetchingCaseListState: FetchingState.ERROR,
    }),
    [fetchAllocateCaseListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingCaseListState: FetchingState.SUCCESS,
      caseList: action.payload.caseList,
    }),
    [fetchAllocateTeamListInitiate.toString()]: (state) => ({
      ...state,
      fetchingTeamListState: FetchingState.LOADING,
    }),
    [fetchAllocateTeamListFailure.toString()]: (state) => ({
      ...state,
      fetchingTeamListState: FetchingState.ERROR,
    }),
    [fetchAllocateTeamListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingTeamListState: FetchingState.SUCCESS,
      allocatableTeams: action.payload.allocatableTeams,
    }),
    [fetchAllAllocateTeamListInitiate.toString()]: (state) => ({
      ...state,
      fetchingAllTeamsState: FetchingState.LOADING,
    }),
    [fetchAllAllocateTeamListFailure.toString()]: (state) => ({
      ...state,
      fetchingAllTeamsState: FetchingState.ERROR,
    }),
    [fetchAllAllocateTeamListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingAllTeamsState: FetchingState.SUCCESS,
      allTeams: action.payload.allTeams,
    }),
    [fetchAllocateAdvisorListInitiate.toString()]: (state) => ({
      ...state,
      fetchingAdvisorListState: FetchingState.LOADING,
    }),
    [fetchAllocateAdvisorListFailure.toString()]: (state) => ({
      ...state,
      fetchingAdvisorListState: FetchingState.ERROR,
    }),
    [fetchAllocateAdvisorListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingAdvisorListState: FetchingState.SUCCESS,
      advisorList: action.payload.advisorList,
    }),
    [allocateCasesInitiated.toString()]: (state) => ({
      ...state,
      allocatingCasesState: FetchingState.LOADING,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [allocateCasesSuccess.toString()]: (state, action: { payload: any }) => {
      const allocatedIds = (action.payload.statusList as IAllocationResponse[])
        .filter(
          (statusPayload: IAllocationResponse) =>
            statusPayload.responseCode !== 'ERROR'
        )
        .map((statusPayload: IAllocationResponse) => statusPayload.arendeId)
      return {
        ...state,
        allocatingCasesState: FetchingState.SUCCESS,
        statusList: action.payload.statusList,
        caseList: state.caseList.filter(
          (oldCase: IAllocateCaseListItem) => !allocatedIds.includes(oldCase.id)
        ),
        advisorList: state.advisorList.map(
          increaseIdagWithAllocationCount(
            action.payload.statusList as IAllocationResponse[]
          )
        ),
      }
    },
    [allocateCasesFailure.toString()]: (state) => ({
      ...state,
      allocatingCasesState: FetchingState.ERROR,
    }),
    [clearAllocationResponse.toString()]: (state) => ({
      ...state,
      statusList: null,
    }),

    [changeClientAllocationTeamInitiated.toString()]: (state) => ({
      ...state,
      changingClientAllocationTeamState: FetchingState.LOADING,
    }),
    [changeClientAllocationTeamFailure.toString()]: (state) => ({
      ...state,
      changingClientAllocationTeamState: FetchingState.ERROR,
    }),
    [changeClientAllocationTeamSuccess.toString()]: (state) => ({
      ...state,
      changingClientAllocationTeamState: FetchingState.SUCCESS,
    }),
  },
  initialState
)

export default allocateCasesReducer

import { EventTypes, IEvent, IEventsPerMonth } from './EventLog.types'

export const createSortedEventsArray = (
  events: IEvent[]
): IEventsPerMonth[] => {
  const eventsPerMonth = [] as IEventsPerMonth[]
  events.forEach((event) => {
    // Pick month and year from data
    const month = new Date(event.occurrenceDate).getMonth()
    const year = new Date(event.occurrenceDate).getFullYear()
    // If new sorted array is empty, create entry with data
    if (eventsPerMonth.length === 0) {
      eventsPerMonth.push({
        date: event.occurrenceDate,
        events: [event],
      })
    } else {
      const isEntryFound = eventsPerMonth.some((monthChunk) => {
        const availableYear = new Date(monthChunk.date).getFullYear()
        const availableMonth = new Date(monthChunk.date).getMonth()

        if (year === availableYear && month === availableMonth) {
          //If entry already exists in array, push data to array.
          monthChunk.events.push(event)
          return true
        }
      })

      if (!isEntryFound)
        // If no entry can be found, create entry
        eventsPerMonth.push({
          date: event.occurrenceDate,
          events: [event],
        })
    }
  })
  eventsPerMonth.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime()
  })
  eventsPerMonth.forEach((monthChunk) => {
    monthChunk.events.sort((a, b) => {
      return (
        new Date(b.occurrenceDate).getTime() -
        new Date(a.occurrenceDate).getTime()
      )
    })
  })
  return eventsPerMonth
}

export const mapDateToTitle = (date: Date): string => {
  const year = new Date(date).getFullYear()
  const month = new Date(date).getMonth()
  switch (month) {
    case 0:
      return `JANUARI ${year}`
    case 1:
      return `FEBRUARI ${year}`
    case 2:
      return `MARS ${year}`
    case 3:
      return `APRIL ${year}`
    case 4:
      return `MAJ ${year}`
    case 5:
      return `JUNI ${year}`
    case 6:
      return `JULI ${year}`
    case 7:
      return `AUGUSTI ${year}`
    case 8:
      return `SEPTEMBER ${year}`
    case 9:
      return `OKTOBER ${year}`
    case 10:
      return `NOVEMBER ${year}`
    case 11:
      return `DECEMBER ${year}`
  }
}

export const getLabelFor = (event: EventTypes) => {
  switch (event) {
    case 'OnSiteMeeting':
      return 'Fysiskt möte'
    case 'VideoMeeting':
      return 'Onlinemöte'
    case 'Sms':
      return 'SMS'
    case 'Phone':
      return 'Telefonmöte'
    case 'Email':
      return 'E-post'
    case 'OtherNote':
      return 'Anteckning'
    case 'Startklar':
      return 'Startklar'
    case 'GroupEmail':
      return 'Gruppmejl'
  }
}

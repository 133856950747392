import React from 'react'
import { useDevice } from '@trr/app-shell-data'

import ArbetsuppgiftMobileFilter from './Mobile/ArbetsuppgiftMobileFilter'
import ArbetsuppgiftDesktopFilter from './Desktop/ArbetsuppgiftDesktopFilter'

const ArbetsuppgiftFilters = () => {
  const isMobile = useDevice().isMobile

  return isMobile ? (
    <ArbetsuppgiftMobileFilter />
  ) : (
    <ArbetsuppgiftDesktopFilter />
  )
}

export default ArbetsuppgiftFilters

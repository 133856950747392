import { Button, Typography, TypographyVariant } from '@mui/material'
import React from 'react'

interface LinkWithIconProps {
  icon: JSX.Element
  text: string
  typographyVariant?: TypographyVariant
  external?: boolean
  onClick?: () => void
  href?: string
  iconPlacement?: 'left' | 'right'
}

const LinkWithIcon = ({
  icon,
  text,
  typographyVariant = 'body1',
  external = false,
  onClick,
  href,
  iconPlacement = 'left',
}: LinkWithIconProps): JSX.Element => {
  const isButtonVariant = href === null || href === undefined

  if (href && onClick) {
    console.error(
      'Both href and onClick props should not be provided simultaneously.'
    )
  }

  if (isButtonVariant && !onClick) {
    console.error('onClick prop is required when href is not set.')
  }

  return (
    <Button
      href={isButtonVariant ? undefined : href}
      component={isButtonVariant ? 'button' : 'a'}
      rel={external ? 'noopener noreferrer' : undefined}
      target={external ? '_blank' : undefined}
      onClick={onClick}
      startIcon={iconPlacement === 'left' && icon}
      endIcon={iconPlacement === 'right' && icon}
      variant="text"
      size="small"
      sx={{ pl: 0, pt: 0.25, pr: 0.75, width: 'auto', alignSelf: 'baseline' }}
    >
      <Typography variant={typographyVariant} component="span">
        {text}
      </Typography>
    </Button>
  )
}

export default LinkWithIcon

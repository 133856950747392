import { ISelectOption } from '@local/src/Common.types'
import { findSelectOption } from '@local/Utils/smallfuncs'
import React, { useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import { useToggle } from '@local/src/Hooks'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { useDispatch } from 'react-redux'
import { isEmpty, isNil } from 'ramda'
import { date, object, string } from 'yup'

import {
  isKompetensstodsArende,
  isOmstallningsArende,
} from '../../../Arenden/Arenden.helpers'
import RemoveEventModal from '../RemoveEventModal/RemoveEventModal'
import { createEvent, deleteEvent, updateEvent } from '../../Case.actions'
import { EventTypes } from '../EventLog.types'

const eventTypeOptions: ISelectOption[] = [
  { value: EventTypes.Meeting, label: 'Fysiskt möte' },
  { value: EventTypes.DigitalMeeting, label: 'Onlinemöte' },
  { value: EventTypes.SMS, label: 'SMS' },
  { value: EventTypes.Phone, label: 'Telefonmöte' },
  { value: EventTypes.Email, label: 'E-post' },
  { value: EventTypes.Note, label: 'Anteckning' },
]

interface EventFormValues {
  bodyText: string
  registeredDate: Date
  eventDate: Date
  eventType: EventTypes
  registeredBy: string
  healthNote: string
  inEdit: boolean
  healthConsent: boolean
  arendeTyp: string
  id: string
}

interface EventFormProps {
  arendeTyp: string
  registeredDate: Date
  eventDate: Date
  healthConsent: boolean
  clientId: string
  isFirstContact?: boolean
  leaveEditMode: () => void
  bodyText?: string
  id?: string
  inEdit?: boolean
  eventType?: EventTypes
  healthNote?: string
  registeredBy?: string
  arendeId?: string
}

const EventForm = ({
  arendeId,
  arendeTyp,
  bodyText,
  id,
  healthNote,
  registeredBy,
  registeredDate,
  eventDate,
  eventType,
  healthConsent,
  inEdit,
  clientId,
  isFirstContact,
  leaveEditMode,
}: EventFormProps) => {
  const dispatch = useDispatch()
  const { isEnabled: isHealthNoteEnabled, toggle: toggleHealthNote } =
    useToggle(healthConsent && !!healthNote && isOmstallningsArende(arendeTyp))
  const {
    isEnabled: isModalActive,
    enable: openModal,
    disable: closeModal,
  } = useToggle()

  const removeEvent = () => {
    closeModal()
    leaveEditMode()
    void dispatch(deleteEvent(id))
  }

  const onSubmit = (values: EventFormValues) => {
    const body = {
      clientId: clientId,
      healthNote: values.healthNote,
      note: values.bodyText,
      noteType: values.eventType,
      occurrenceDate: values.eventDate,
    }

    if (inEdit) {
      dispatch(updateEvent(body, values.id))
    } else {
      dispatch(createEvent(body, arendeId))
    }
  }

  const form = useFormik<EventFormValues>({
    enableReinitialize: false,
    initialValues: {
      bodyText: bodyText ?? '',
      registeredDate: registeredDate,
      eventDate: eventDate,
      eventType: eventType,
      registeredBy: registeredBy,
      healthNote: healthNote ?? '',
      inEdit: inEdit,
      healthConsent: healthConsent,
      arendeTyp: arendeTyp,
      id: id,
    },
    validationSchema: object({
      bodyText: string().nullable(),
      eventDate: date()
        .required('Du måste uppge ett datum för när händelsen inträffade')
        .nullable(),
      eventType: string()
        .required('Du måste uppge vilken typ av händelse det gäller.')
        .nullable(),
    }),
    onSubmit: onSubmit,
  })

  useEffect(() => {
    isHealthNoteEnabled
      ? void form.setFieldValue('healthNote', healthNote)
      : void form.setFieldValue('healthNote', '')
  }, [healthNote, form.setFieldValue, isHealthNoteEnabled])

  const formRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    formRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const leaveEdit = () => {
    !inEdit && window.scrollTo({ top: 0, behavior: 'smooth' }) // scroll to top if creating new note
    leaveEditMode()
  }

  const tioFirstContactWarning =
    isOmstallningsArende(arendeTyp) &&
    !inEdit &&
    form.values.eventType !== undefined &&
    form.values.eventType !== EventTypes.Note &&
    isFirstContact
  const tiaFirstContactWarning =
    isKompetensstodsArende(arendeTyp) &&
    !inEdit &&
    form.values.eventType !== undefined &&
    form.values.eventType === EventTypes.Note &&
    isFirstContact

  return (
    <Stack ref={formRef}>
      {form.isSubmitting ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress sx={{ display: 'flex', alignItems: 'center' }} />
        </Box>
      ) : (
        <>
          <RemoveEventModal
            submit={removeEvent}
            close={closeModal}
            active={isModalActive}
          />
          <Typography variant="h5">
            {inEdit ? 'Ändra' : 'Ny händelse'}
          </Typography>
          {inEdit && (
            <>
              <Typography variant="body1" paddingTop={1}>
                Skapad av {registeredBy}
              </Typography>
              <Divider sx={{ marginY: 1 }} />
            </>
          )}
          {tioFirstContactWarning && (
            <Alert severity="warning" sx={{ marginY: 1 }}>
              {
                'Denna händelse räknas som första kontakt. Om ärendets status är "Ny för mig" eller "Ej kontaktad" kommer ärendestatus ändras till Aktuell. Välj händelsetyp Anteckning om du vill undvika detta.'
              }
            </Alert>
          )}

          {tiaFirstContactWarning && (
            <Alert severity="warning" sx={{ marginY: 1 }}>
              {
                'Denna händelsetyp uppdaterar inte senaste kontakt. Välj en annan händelsetyp om du vill att senaste kontakt ska uppdateras.'
              }
            </Alert>
          )}

          <Box
            display="flex"
            marginY={2}
            justifyContent="space-between"
            gap={2}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          >
            <Dropdown
              fullWidth
              label="Händelsetyp"
              error={form.errors && form.touched.eventType}
              value={
                findSelectOption(form.values.eventType, eventTypeOptions)?.value
              }
              options={eventTypeOptions}
              onChange={(event: SelectChangeEvent) =>
                void form.setFieldValue('eventType', event.target.value)
              }
              getOptionLabel={(option: ISelectOption) => option.label}
              getOptionValue={(option: ISelectOption) => option.value}
              errorText={form.errors.eventType}
            />
            <Box>
              <DesktopDatePicker
                label="Händelsedatum"
                value={form.values.eventDate}
                closeOnSelect
                sx={{ width: '100%' }}
                onChange={(date: Date) =>
                  void form.setFieldValue('eventDate', date)
                }
              />
            </Box>
          </Box>

          {isOmstallningsArende(arendeTyp) && !healthConsent && (
            <Alert severity="warning">
              Hälsosamtycke saknas. Inga noteringar får göras om hälsa.
            </Alert>
          )}

          {isOmstallningsArende(arendeTyp) && healthConsent && (
            <FormControlLabel
              label="Lägg till hälsonotering"
              sx={{ alignSelf: 'baseline' }}
              control={
                <Checkbox
                  checked={isHealthNoteEnabled}
                  onChange={toggleHealthNote}
                />
              }
            />
          )}

          <Box display="flex" flexDirection="column" marginTop={2} gap={1}>
            <Typography variant="h6">Anteckning</Typography>
            <TextField
              fullWidth
              value={form.values.bodyText}
              name="bodyText"
              aria-label="Anteckning"
              onChange={form.handleChange}
              multiline
              minRows={10}
            />
          </Box>

          {isHealthNoteEnabled && (
            <Box display="flex" flexDirection="column" marginTop={2} gap={1}>
              <Typography variant="h6">Hälsonotering</Typography>
              <TextField
                fullWidth
                value={form.values.healthNote}
                name="healthNote"
                onChange={form.handleChange}
                multiline
                minRows={10}
              />
            </Box>
          )}

          {isKompetensstodsArende(arendeTyp) && (
            <Alert severity="warning" sx={{ marginTop: 2 }}>
              <Typography variant="body1">
                Var noga med att följa GDPR-riktlinjer vid alla noteringar.
              </Typography>
              <LinkWithIcon
                text="GDPR-riktlinjer"
                href="https://trygghetsradet.sharepoint.com/sites/ledning/SitePages/GDPR.aspx"
                external
                icon={<LaunchIcon fontSize="small" />}
              />
            </Alert>
          )}

          <Box
            display="flex"
            marginTop={2}
            flexDirection="row"
            flexWrap="wrap"
            gap={2}
          >
            <Button
              disabled={
                (!isHealthNoteEnabled &&
                  (isNil(form.values.bodyText) ||
                    isEmpty(form.values.bodyText))) ||
                (isHealthNoteEnabled &&
                  (isNil(form.values.healthNote) ||
                    isEmpty(form.values.healthNote)))
              }
              size="large"
              onClick={() => form.handleSubmit()}
            >
              Spara
            </Button>
            {inEdit && (
              <Button size="large" variant="outlined" onClick={openModal}>
                Ta bort
              </Button>
            )}
            <Button size="large" variant="outlined" onClick={leaveEdit}>
              Avbryt
            </Button>
          </Box>
        </>
      )}
      <Divider sx={{ marginY: 2 }} />
    </Stack>
  )
}

export default EventForm

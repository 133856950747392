import {
  IArendenFilterState,
  IDinaArendenFilterState,
} from '@local/src/Components/CasesFilter/CasesFilter.types'
import { stringifyFilter } from '@local/src/Utils/helpers'
import { StartklarFilter } from '@local/src/Views/Startklar/Startklar.types'

import { baseApi } from '../baseApi'

interface ChangeRadgivareRequest {
  arendeId: string
  radgivareId: string
}

export interface GetSuggestionsRequest {
  propertyName: string
  searchText: string
  filterState:
    | IArendenFilterState
    | IDinaArendenFilterState
    | Partial<StartklarFilter>
}

const baseUrl = '/v1.0/radgivningsarende'

export const radgivningsArendeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query<string[], GetSuggestionsRequest>({
      query: (params) => {
        const stringifiedFilter = stringifyFilter(
          params.filterState,
          params.propertyName
        )
        return `${baseUrl}/${params.propertyName.toLowerCase()}/suggestions?searchText=${params.searchText}&${stringifiedFilter}`
      },
    }),
    changeRadgivare: builder.mutation<void, ChangeRadgivareRequest>({
      query: (body) => ({
        url: `${baseUrl}/bytradgivare`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: {
          ...body,
        },
      }),
    }),
  }),
})

export const {
  useGetSuggestionsQuery,
  useLazyGetSuggestionsQuery,
  useChangeRadgivareMutation,
} = radgivningsArendeApi

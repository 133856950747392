import ChipWithPopper from '@local/src/Components/Chips/ChipWithPopover/ChipWithPopper'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { useState } from 'react'
import { Facet } from '@local/src/Views/Arenden/Arbetsuppgift/types'
import FilterAccordion from '@local/src/Components/FilterAccordion/FilterAccordion'

import { StartklarFilter } from '../../Startklar.types'

interface ArbetsmarknadsomradeFilterProps {
  count: number
  isMobile: boolean
  chosenValues: string[]
  availableValues: Facet[]
  setFilter: (key: keyof StartklarFilter, values: string | string[]) => void
}

const ArbetsmarknadsomradeFilter = ({
  count,
  isMobile,
  chosenValues,
  availableValues,
  setFilter,
}: ArbetsmarknadsomradeFilterProps) => {
  const [open, setOpen] = useState(false)
  const setArbetsmarknadsomradeFilter = (omrade: string) => {
    if (chosenValues.some((id) => id === omrade)) {
      setFilter(
        'arbetsmarknadsomraden',
        chosenValues.filter((id) => id !== omrade)
      )
    } else {
      setFilter('arbetsmarknadsomraden', [...chosenValues, omrade])
    }
  }

  const filterList = (
    <List>
      {availableValues?.map((omrade) => (
        <ListItem key={omrade.value}>
          <ListItemButton
            role="checkbox"
            dense
            onClick={() => setArbetsmarknadsomradeFilter(omrade.value)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                checked={chosenValues.some((s) => s === omrade.value)}
                inputProps={{ 'aria-labelledby': omrade.value }}
              />
            </ListItemIcon>
            <ListItemText
              primary={omrade.value}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )

  if (isMobile) {
    return (
      <FilterAccordion
        label="Arbetsmarknadsområde"
        indicatorCount={count}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {filterList}
      </FilterAccordion>
    )
  } else {
    return (
      <ChipWithPopper
        label="Arbetsmarknadsområde"
        width={360}
        onClear={() => setFilter('arbetsmarknadsomraden', [])}
        count={count}
      >
        {filterList}
      </ChipWithPopper>
    )
  }
}

export default ArbetsmarknadsomradeFilter

import { useIsSmallScreen } from '@local/src/Hooks/useIsSmallScreen'
import { useEffect, useState } from 'react'

const useDisplayFilter = (): {
  isFilterVisible: boolean
  toggleFilter: () => void
} => {
  const isSmallScreen = useIsSmallScreen()
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(null)

  useEffect(() => {
    setIsFilterVisible(!isSmallScreen)
  }, [isSmallScreen])

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible)
  }

  return { isFilterVisible, toggleFilter }
}

export { useDisplayFilter }

import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'

import { IRadgivare } from '../Case.types'

import {
  AteroppnaPreconditions,
  AvslutOption,
  Befattning,
  Bransch,
  Avslutshandelse,
} from './Avslut.types'
import * as actions from './Avslut.actions'
import { resetAvslut } from './Avslut.actions'

export interface AvslutState {
  avslutskoder: AvslutOption[]
  activeRadgivare: IRadgivare[]
  branscher: Bransch[]
  befattningar: Befattning[]
  orter: string[]
  ateroppnaPreconditions: AteroppnaPreconditions
  fetchingOrterState: FetchingState
  fetchingAvslutskoderState: FetchingState
  fetchingBefattningarState: FetchingState
  fetchingBranscherState: FetchingState
  fetchingAteroppnaPreconditionsState: FetchingState
  fetchingActiveRadgivareState: FetchingState
  ateroppnaArendeState: FetchingState
  avslutaArendeState: FetchingState
  submittingQuestionResponsesState: FetchingState
  tidigareAvslut: Avslutshandelse[]
  tidigareAvslutState: FetchingState
}

export const initialState: AvslutState = {
  avslutskoder: [],
  activeRadgivare: [],
  branscher: [],
  befattningar: [],
  orter: [],
  ateroppnaPreconditions: null,
  fetchingOrterState: FetchingState.IDLE,
  fetchingBranscherState: FetchingState.IDLE,
  fetchingBefattningarState: FetchingState.IDLE,
  fetchingAvslutskoderState: FetchingState.IDLE,
  fetchingAteroppnaPreconditionsState: FetchingState.IDLE,
  fetchingActiveRadgivareState: FetchingState.IDLE,
  ateroppnaArendeState: FetchingState.IDLE,
  avslutaArendeState: FetchingState.IDLE,
  submittingQuestionResponsesState: FetchingState.IDLE,
  tidigareAvslut: [],
  tidigareAvslutState: FetchingState.IDLE,
}

const avslutReducer = handleActions(
  {
    [actions.resetAvslut.toString()]: () => ({
      ...initialState,
    }),

    [actions.avslutaArendeWithoutDateInitiate.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.LOADING,
    }),
    [actions.avslutaArendeWithoutDateSuccess.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.SUCCESS,
    }),
    [actions.avslutaArendeWithoutDateError.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.ERROR,
    }),

    [actions.fetchAvslutskoderInitiate.toString()]: (state) => ({
      ...state,
      fetchingAvslutskoderState: FetchingState.LOADING,
    }),
    [actions.fetchAvslutskoderError.toString()]: (state) => ({
      ...state,
      fetchingAvslutskoderState: FetchingState.ERROR,
    }),
    [actions.fetchAvslutskoderSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingAvslutskoderState: FetchingState.SUCCESS,
      avslutskoder: action.payload.avslutskoder,
    }),

    [actions.fetchActiveRadgivareInitiated.toString()]: (state) => ({
      ...state,
      fetchingActiveRadgivareState: FetchingState.LOADING,
    }),
    [actions.fetchActiveRadgivareSuccess.toString()]: (state, action) => ({
      ...state,
      activeRadgivare: action.payload.activeRadgivare,
      fetchingActiveRadgivareState: FetchingState.SUCCESS,
    }),
    [actions.fetchActiveRadgivareError.toString()]: (state) => ({
      ...state,
      fetchingActiveRadgivareState: FetchingState.ERROR,
    }),

    [actions.fetchAteroppnaPreconditionsInitiate.toString()]: (state) => ({
      ...state,
      fetchingAteroppnaPreconditionsState: FetchingState.LOADING,
    }),
    [actions.fetchAteroppnaPreconditionsSuccess.toString()]: (
      state,
      action
    ) => ({
      ...state,
      fetchingAteroppnaPreconditionsState: FetchingState.SUCCESS,
      ateroppnaPreconditions: action.payload.ateroppnaPreconditions,
    }),
    [actions.fetchAteroppnaPreconditionsError.toString()]: (state) => ({
      ...state,
      fetchingAteroppnaPreconditionsState: FetchingState.ERROR,
    }),

    [actions.ateroppnaArendeInitiate.toString()]: (state) => ({
      ...state,
      ateroppnaArendeState: FetchingState.LOADING,
    }),
    [actions.ateroppnaArendeSuccess.toString()]: (state) => ({
      ...state,
      ateroppnaArendeState: FetchingState.SUCCESS,
    }),
    [actions.ateroppnaArendeError.toString()]: (state) => ({
      ...state,
      ateroppnaArendeState: FetchingState.ERROR,
    }),

    [actions.fetchBranscherInitiate.toString()]: (state) => ({
      ...state,
      fetchingIndustriesState: FetchingState.LOADING,
    }),
    [actions.fetchBranscherError.toString()]: (state) => ({
      ...state,
      fetchingIndustriesState: FetchingState.ERROR,
    }),
    [actions.fetchBranscherSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingBranscherState: FetchingState.SUCCESS,
      branscher: action.payload.branscher,
    }),

    [actions.fetchBefattningarInitiate.toString()]: (state) => ({
      ...state,
      fetchingBefattningarState: FetchingState.LOADING,
    }),
    [actions.fetchBefattningarError.toString()]: (state) => ({
      ...state,
      fetchingBefattningarState: FetchingState.ERROR,
    }),
    [actions.fetchBefattningarSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingBefattningarState: FetchingState.SUCCESS,
      befattningar: action.payload.befattningar,
    }),

    [actions.fetchOrterInitiate.toString()]: (state) => ({
      ...state,
      fetchingOrterState: FetchingState.LOADING,
    }),
    [actions.fetchOrterError.toString()]: (state) => ({
      ...state,
      fetchingOrterState: FetchingState.ERROR,
    }),
    [actions.fetchOrterSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingOrterState: FetchingState.SUCCESS,
      orter: action.payload.orter,
    }),

    [actions.avslutaArendeWithReminderInitiate.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.LOADING,
    }),
    [actions.avslutaArendeWithReminderSuccess.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.SUCCESS,
    }),
    [actions.avslutaArendeWithReminderError.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.ERROR,
    }),

    [actions.avslutaArendeWithQuestionsInitiate.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.LOADING,
    }),
    [actions.avslutaArendeWithQuestionsSuccess.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.SUCCESS,
    }),
    [actions.avslutaArendeWithQuestionsError.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.ERROR,
    }),

    [actions.avslutaArendeWithDateInitiate.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.LOADING,
    }),
    [actions.avslutaArendeWithDateSuccess.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.SUCCESS,
    }),
    [actions.avslutaArendeWithDateError.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.ERROR,
    }),

    [actions.avslutaArendeWithNumberOfMonthsInitiate.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.LOADING,
    }),
    [actions.avslutaArendeWithNumberOfMonthsSuccess.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.SUCCESS,
    }),
    [actions.avslutaArendeWithNumberOfMonthsError.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.ERROR,
    }),

    [actions.avslutaKompetensstodsArendeInitiate.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.LOADING,
    }),
    [actions.avslutaKompetensstodsArendeSuccess.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.SUCCESS,
    }),
    [actions.avslutaKompetensstodsArendeError.toString()]: (state) => ({
      ...state,
      avslutaArendeState: FetchingState.ERROR,
    }),

    [actions.fetchTidigareAvslutInitiate.toString()]: (state) => ({
      ...state,
      tidigareAvslut: [],
      tidigareAvslutState: FetchingState.LOADING,
    }),
    [actions.fetchTidigareAvslutFailure.toString()]: (state) => ({
      ...state,
      tidigareAvslutState: FetchingState.ERROR,
    }),
    [actions.fetchTidigareAvslutSuccess.toString()]: (state, action) => ({
      ...state,
      tidigareAvslutState: FetchingState.SUCCESS,
      tidigareAvslut: action.payload.tidigareAvslut,
    }),

    [actions.submitQuestionsResponsesInitiate.toString()]: (state) => ({
      ...state,
      submittingQuestionResponsesState: FetchingState.LOADING,
    }),
    [actions.submitQuestionsResponsesSuccess.toString()]: (state) => ({
      ...state,
      submittingQuestionResponsesState: FetchingState.SUCCESS,
    }),
    [actions.submitQuestionsResponsesFailure.toString()]: (state) => ({
      ...state,
      submittingQuestionResponsesState: FetchingState.ERROR,
    }),

    [resetAvslut.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState
)

export default avslutReducer

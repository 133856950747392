import { connect } from 'react-redux'
import { createEmail } from '@local/Blocks/CreateEmail/CreateEmail.actions'
import { createEmailState } from '@local/src/Blocks/CreateEmail/CreateEmail.selectors'
import { RootState } from '@local/src/Store/configureStore'

import DinaArenden from './DinaArenden'
import {
  fetchDinaArenden as fetchArenden,
  fetchCaseListFacets,
} from './DinaArenden.actions'
import {
  dinaArendenTotalHits,
  dinaArendenTotalPages,
  caseList,
  facets,
  facetedHits,
  fetchingCaseListState,
} from './DinaArenden.selectors'

const mapStateToProps = (state: RootState) => ({
  cases: caseList(state),
  facetedHits: facetedHits(state),
  facets: facets(state),
  totalHits: dinaArendenTotalHits(state),
  totalPages: dinaArendenTotalPages(state),
  fetchingCaseListState: fetchingCaseListState(state),
  createEmailState: createEmailState(state),
})

const mapDispatchToProps = {
  createEmail,
  fetchArenden,
  fetchCaseListFacets,
}

export default connect(mapStateToProps, mapDispatchToProps)(DinaArenden)

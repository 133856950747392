import {
  Box,
  Button,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import Close from '@mui/icons-material/Close'
import Tune from '@mui/icons-material/Tune'

import { FilterSelectionIconIndicator } from '../FilterSelectionIndicator/FilterSelectionIndicator'
import ClearFilterButton from '../ClearFilterButton/ClearFilterButton'

interface MobileFilterDrawerProps {
  filterCount: number
  filtersActive: boolean
  clearFilter: () => void
  children: React.ReactNode | JSX.Element | JSX.Element[]
}

const MobileFilterDrawer = ({
  filterCount,
  filtersActive,
  clearFilter,
  children,
}: MobileFilterDrawerProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpen(true)}
        startIcon={
          filterCount > 0 ? (
            <FilterSelectionIconIndicator>
              {filterCount}
            </FilterSelectionIconIndicator>
          ) : (
            <Tune />
          )
        }
      >
        Alla filter
      </Button>
      <SwipeableDrawer
        open={open}
        anchor="right"
        PaperProps={{ style: { width: '95%' } }}
        onClose={handleDrawerClose}
        onOpen={() => setOpen(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box flexGrow="1">
            <Box position="sticky" top={0} zIndex={10} bgcolor="#fff">
              <Box
                display="flex"
                pr={1}
                pl={2}
                py={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontWeight="bold">Alla filter</Typography>
                <IconButton
                  aria-label="Stäng väljare"
                  tabIndex={0}
                  onClick={handleDrawerClose}
                >
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </Box>
            {children}
          </Box>
          <Box
            width="100%"
            pb={1}
            position="sticky"
            bottom="0"
            bgcolor="#fff"
            minHeight={53}
          >
            <Divider sx={{ mb: 1 }} />
            <ClearFilterButton
              clearFilter={clearFilter}
              filtersActive={filtersActive}
              sx={{ ml: 1 }}
            />
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  )
}

export default MobileFilterDrawer

import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'
import {
  createEmailFailure,
  createEmailInitiate,
  createEmailSuccess,
} from '@local/Blocks/CreateEmail/CreateEmail.actions'

import { ICreateEmailState } from './CreateEmail.types'

export const initialState: ICreateEmailState = {
  createEmailState: FetchingState.IDLE,
}

const createEmailReducer = handleActions(
  {
    [createEmailInitiate.toString()]: (state) => ({
      ...state,
      createEmailState: FetchingState.LOADING,
    }),
    [createEmailSuccess.toString()]: (state) => ({
      ...state,
      createEmailState: FetchingState.SUCCESS,
    }),
    [createEmailFailure.toString()]: (state) => ({
      ...state,
      createEmailState: FetchingState.ERROR,
    }),
  },
  initialState
)

export default createEmailReducer

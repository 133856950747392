import { CollapseProps, Collapse, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import AccordionBox from '../AccordionBox/AccordionBox'

const StyledCollapse = styled((props: CollapseProps) => (
  <Collapse {...props} />
))((props: CollapseProps) => ({
  position: 'relative',
  ...(!props.in && {
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '54px',
      background: 'linear-gradient(transparent, white)',
    },
  }),
}))

interface CollapsableContentProps {
  children: React.ReactNode
  partiallyOpenedPixels?: number
  showMoreText?: string
  showLessText?: string
  openByDefault?: boolean
  onToggle?: (open: boolean) => void
}

const CollapsableContent = ({
  children,
  partiallyOpenedPixels = 120,
  showMoreText = 'Visa mer',
  showLessText = 'Visa mindre',
  openByDefault = false,
  onToggle,
}: CollapsableContentProps): JSX.Element => {
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [expandableText, setExpandableText] = useState(true)

  useEffect(() => {
    if (elementRef.current) {
      const textHeight = elementRef.current.clientHeight
      setExpandableText(textHeight > partiallyOpenedPixels)
      if (textHeight < partiallyOpenedPixels) {
        setIsOpen(true)
      }
    }
  }, [elementRef])

  useEffect(() => {
    setIsOpen(openByDefault)
  }, [openByDefault])

  useEffect(() => {
    onToggle?.(isOpen)
  }, [isOpen])

  return (
    <>
      <StyledCollapse
        in={isOpen}
        collapsedSize={!expandableText ? 0 : partiallyOpenedPixels}
      >
        <div ref={elementRef}>{children}</div>
      </StyledCollapse>
      {expandableText && (
        <AccordionBox
          label={isOpen ? showLessText : showMoreText}
          expanded={isOpen}
          onToggle={() => setIsOpen(!isOpen)}
        />
      )}
    </>
  )
}

export default CollapsableContent

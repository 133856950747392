import React, { useEffect, useState, SyntheticEvent } from 'react'
import AdvisorSelectorMobile from '@local/Components/AdvisorSelectors/AdvisorSelectorMobile'
import {
  AccordionLink,
  Icons,
  Dropdown,
  Loading,
  Button,
  SystemFeedback,
} from '@trr/common-component-library'
import { ITeam, ISelectOption, FetchingState } from '@local/src/Common.types'
import IconWithText from '@local/src/Components/IconWithText'

import { IAdvisorSelection } from './AdvisorSelection.types'
import s from './AdvisorSelection.module.scss'

const AdvisorSelection = ({
  chooseAdvisor,
  allocatableTeams,
  advisorList,
  setAdvisor,
  selectedCaseIds,
  setSelectedCaseIds,
  caseList,
  fetchActiveAdvisors,
  defaultTeam,
  allocatingCasesState,
  fetchingAdvisorListState,
  allocateErrors,
}: IAdvisorSelection): JSX.Element => {
  const [team, setTeam] = useState(defaultTeam)
  const selectedCases = caseList.filter((item) =>
    selectedCaseIds.includes(item.id)
  )
  const removeItem = (event: SyntheticEvent) => {
    const eventTarget = event.currentTarget as HTMLElement
    const id = eventTarget.getAttribute('data-id')
    setSelectedCaseIds(
      selectedCaseIds.filter((selectedCase: string) => selectedCase !== id)
    )
  }

  const onTeamChange = (event: ISelectOption) => {
    setTeam(
      allocatableTeams.find(
        (teamFromList: ITeam) => teamFromList.id === event.value
      )
    )
  }

  useEffect(() => {
    setAdvisor(null)
    if (team) {
      fetchActiveAdvisors(team.id)
    }
  }, [fetchActiveAdvisors, team, setAdvisor])

  const [accordionExpanded, setAccordionExpanded] = useState(false)

  return (
    <div className={s.AdvisorSelection}>
      <div className={s.FordelningWrapper}>
        <AccordionLink
          label="Visa ärenden"
          expanded={accordionExpanded}
          onToggle={(e) => {
            e.preventDefault()
            setAccordionExpanded(!accordionExpanded)
          }}
        >
          {selectedCases.map((selectedCase) => (
            <div className={s.ArendeItem} key={selectedCase.id}>
              <span>
                {selectedCase.fornamn} {selectedCase.efternamn}
              </span>
              <IconWithText
                icon={
                  <Icons.HomeOutlinedIcon
                    color={s.textColor}
                    size={16}
                    title="Klientens bostadsort"
                  />
                }
              >
                {selectedCase.ort}
              </IconWithText>
              <Button
                icon
                onClick={removeItem}
                attributes={{ 'data-id': selectedCase.id }}
                name={`Ta bort ${selectedCase.fornamn} ${selectedCase.efternamn}`}
              >
                <Icons.CloseIcon size={16} color={s.gray0} />
              </Button>
            </div>
          ))}
        </AccordionLink>
      </div>
      {allocateErrors ? (
        <div>
          {allocateErrors.map(({ id, error }) => (
            <SystemFeedback
              key={id}
              type={'error'}
              content={`${error}, ärende-id: ${id}`}
            />
          ))}
        </div>
      ) : null}

      {allocatingCasesState === FetchingState.LOADING ? (
        <Loading />
      ) : (
        <div className={s.CaseInfoMobile}>
          <div className={s.AdvisorWrapper}>
            <h2>Välj rådgivare</h2>
            <div className={s.FullWidth}>
              <Dropdown
                label="Rådgivarteam"
                placeholder="Rådgivarteam"
                isSearchable={false}
                onChange={onTeamChange}
                value={(({ id, namn }) => ({ value: id, label: namn }))(team)}
                selectOptions={allocatableTeams.map((teamFromList: ITeam) => ({
                  value: teamFromList.id,
                  label: teamFromList.namn,
                }))}
              />
            </div>
          </div>
          {
            <AdvisorSelectorMobile
              fetchingAdvisorListState={fetchingAdvisorListState}
              advisorList={advisorList}
              chooseAdvisor={chooseAdvisor}
            />
          }
        </div>
      )}
    </div>
  )
}

export default AdvisorSelection

import { useEffect, useState } from 'react'

import { useArendenLocalStorage } from './useArendenLocalStorage'

const getMergedValues = <T>(storedValue: T, initialValue: T): T => {
  return Object.entries(initialValue).reduce<T>(
    (acc: any, [key, value]) => {
      if (acc[key] === undefined) {
        acc[key] = value
      }
      return acc as T
    },
    { ...storedValue }
  )
}

const useMergedLocalStorage = <T>(
  arendenEnv: string,
  key: string,
  initialValue: T
): readonly [T, (val: T) => void] => {
  const [storedValue, setStoredValue] = useArendenLocalStorage<T>(
    arendenEnv,
    key,
    initialValue
  )
  const [mergedValue, setMergedValue] = useState<T>(
    getMergedValues(storedValue, initialValue)
  )

  useEffect(() => {
    setMergedValue(getMergedValues(storedValue, initialValue))
  }, [storedValue])

  const setValue = (value: T) => {
    setStoredValue(value)
  }

  return [mergedValue, setValue]
}

export { useMergedLocalStorage }

import { Box, Stack } from '@mui/material'
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridPinnedRowsProp,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded'

import EmptyResult from '../EmptyResult/EmptyResult'
import Loading from '../Loading/Loading'

interface DataGridWrapperProps {
  columns: GridColDef<unknown>[]
  rows: unknown[]
  getRowId: (row: unknown) => string
  pagination?: boolean
  columnVisibilityModel?: GridColumnVisibilityModel
  paginationModel?: GridPaginationModel
  sortModel?: GridSortModel
  getDetailPanelContent?: (params: GridRowParams<unknown>) => React.ReactNode
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => void
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void
  isMobile?: boolean
  rowCount?: number
  loading?: boolean
  labelRowsPerPage?: string
  floatLastColumnRight?: boolean
  enableColumnResize?: boolean
  pinnedRows?: GridPinnedRowsProp
  emptyResultHeader?: string
  emptyResultDescription?: string
  maxHeight?: string
  paginationMode?: 'client' | 'server'
  sortingMode?: 'client' | 'server'
}

const DataGridWrapper = ({
  rows,
  columns,
  pagination = true,
  columnVisibilityModel,
  paginationModel,
  sortModel,
  getRowId,
  onSortModelChange,
  onPaginationModelChange,
  getDetailPanelContent,
  isMobile = false,
  rowCount = rows?.length,
  loading = false,
  floatLastColumnRight = true,
  enableColumnResize = false,
  pinnedRows,
  emptyResultHeader = 'Inga resultat',
  emptyResultDescription = 'Din filtrering fick inga träffar.',
  labelRowsPerPage = 'Rader per sida',
  paginationMode = 'client',
  sortingMode = 'client',
  maxHeight = 'auto',
}: DataGridWrapperProps) => {
  const hasPinnedRows = () => {
    if (!pinnedRows) {
      return false
    }
    return pinnedRows.top?.length > 0 || pinnedRows.bottom?.length > 0
  }

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {!hasPinnedRows() && rows.length === 0 && (
          <EmptyResult
            heading={emptyResultHeader}
            description={emptyResultDescription}
          />
        )}
      </Stack>
    )
  }

  const CustomLoadingOverLay = () => {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        sx={{ background: '#fff' }}
      >
        <Loading sx={{ mt: 4 }} />
      </Box>
    )
  }

  return (
    <Box
      height={rows?.length > 0 ? (rows?.length < 25 ? 'auto' : maxHeight) : 600}
      maxHeight={maxHeight}
      marginTop={2}
    >
      <DataGridPro
        pagination={pagination}
        hideFooterRowCount
        loading={loading}
        rows={rows || []}
        columns={columns}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        pinnedRows={pinnedRows}
        getRowHeight={() => 'auto'}
        rowCount={rowCount}
        sortModel={sortModel}
        disableRowSelectionOnClick
        disableColumnMenu
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        paginationModel={paginationModel}
        columnVisibilityModel={columnVisibilityModel}
        getDetailPanelHeight={() => 'auto'}
        getRowId={getRowId}
        getDetailPanelContent={getDetailPanelContent}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        disableColumnResize={!enableColumnResize}
        columnBuffer={columns.length + 1} // +1 for testing purposes (can mess with rendering in headless.)
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
          detailPanelExpandIcon: KeyboardArrowDownIcon,
          detailPanelCollapseIcon: KeyboardArrowUpIcon,
          loadingOverlay: CustomLoadingOverLay,
        }}
        slotProps={{
          pagination: {
            SelectProps: { IconComponent: ArrowDropDownRounded },
            labelRowsPerPage,
          },
        }}
        autoHeight={
          // when maxHeight is set and autoHeight is enabled, it can cause horizontal and vertical scrolls to appear
          maxHeight === 'auto' ? (rows?.length > 0 ? true : false) : false
        }
        sx={(theme) => ({
          border: 0,
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            padding: isMobile ? 0 : undefined,
          },
          '& .MuiDataGrid-columnHeader': {
            padding: isMobile ? 0 : undefined,
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            flexDirection: 'initial !important',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-columnHeaderTitleContainerContent':
            {
              marginLeft: floatLastColumnRight && 'auto',
            },
          '& .MuiDataGrid-row--detailPanelExpanded': {
            background: theme.palette?.surface?.grey,
          },
          '& .MuiDataGrid-row--detailPanelExpanded > .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-detailPanel': {
            background: theme.palette?.surface?.grey,
          },
        })}
      />
    </Box>
  )
}

export default DataGridWrapper

import React from 'react'
import { Alert, Typography } from '@mui/material'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import { IAdvisorDecision } from '../../../types/IAdvisorDecision'

interface IAdvisorDecisionSuccessAlert {
  advisorDecision: IAdvisorDecision
}
const AdvisorDecisionSuccessAlert: React.FC<IAdvisorDecisionSuccessAlert> = ({
  advisorDecision,
}) => {
  const epi = useEpiString()
  return (
    <Alert sx={{ mt: 2, mb: 2, width: '100%' }} severity="info">
      <Typography variant="subtitle1">
        {epi('common.decisionTaken', {
          decision: advisorDecision.decision ? 'Godkänd' : 'Avböjd',
        })}
      </Typography>
    </Alert>
  )
}

export default AdvisorDecisionSuccessAlert

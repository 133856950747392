import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'

import { ArendeTyp, OmfattningsbeslutStatus } from '../Arenden/Arenden.types'

import { IDocument } from './Documents/Documents'
import {
  ICaseDetails,
  ICaseState,
  IJmtUserStatusRes,
  IMarkning,
  KarriarVagledningStatus,
  PayloadAction,
  UpdateMarkningInitializedActionPayload,
} from './Case.types'
import {
  changeAdvisorInitiate,
  changeAdvisorFailure,
  changeAdvisorSuccess,
  clearChangingAdvisorState,
  clearCase,
  changeTeamInitiated,
  changeTeamSuccess,
  changeTeamFailure,
  clearChangingTeamState,
  fetchEventsInitiate,
  fetchEventsFailure,
  fetchEventsSuccess,
  deleteEventInitiate,
  deleteEventFailure,
  deleteEventSuccess,
  createEventInitiate,
  createEventFailure,
  createEventSuccess,
  updateEventInitiate,
  updateEventFailure,
  updateEventSuccess,
  fetchDocumentsInitiate,
  fetchDocumentsSuccess,
  fetchDocumentsFailure,
  postDocumentInitiate,
  postDocumentSuccess,
  postDocumentFailure,
  fetchDocumentInitiate,
  fetchDocumentSuccess,
  fetchDocumentFailure,
  resetPostDocumentState,
  deleteDocumentInitiate,
  deleteDocumentFailure,
  deleteDocumentSuccess,
  fetchUtbetalningsbeslutInitiate,
  fetchUtbetalningsbeslutSuccess,
  fetchUtbetalningsbeslutFailure,
  updateMarkningFailure,
  updateMarkningInitiate,
  updateMarkningSuccess,
  resetState,
  fetchCaseInitiate,
  fetchCaseSuccess,
  fetchCaseFailure,
  updatePreferredLanguageInitiated,
  updatePreferredLanguageSuccess,
  updatePreferredLanguageFailure,
  fetchUserStatusInitiated,
  fetchUserStatusFailure,
  fetchUserStatusSuccess,
  fetchUserJmtStatusInitiated,
  fetchUserJmtStatusFailure,
  fetchUserJmtStatusSuccess,
} from './Case.actions'
import { IEvent } from './EventLog/EventLog.types'

export const initialCase: ICaseDetails = {
  id: '',
  arendeNummer: '',
  infoText: null,
  arendeTyp: ArendeTyp.Omstallning,
  omfattningsbeslutStatus: OmfattningsbeslutStatus.Odefinierad,
  warningTexts: null,
  klient: undefined,
  omstallningsArende: undefined,
  anstallningsArende: undefined,
  ovrigaArenden: [],
  hasStudieansokningar: false,
  arendeMarkningar: [],
  omfattningspaket: [],
  statusDisplayText: '',
  inbokadeMoten: [],
  ateroppnaAGEValid: false,
  ateroppnaRDGValid: false,
  karriarVagledningProgression: {
    livslinjen: KarriarVagledningStatus.NOTSTARTED,
    personligSpegling: KarriarVagledningStatus.NOTSTARTED,
    varderingskompass: KarriarVagledningStatus.NOTSTARTED,
    karriarguide: KarriarVagledningStatus.NOTSTARTED,
    yrkesprognoser: KarriarVagledningStatus.NOTSTARTED,
    utbildning: KarriarVagledningStatus.NOTSTARTED,
    karriarsok: KarriarVagledningStatus.NOTSTARTED,
    framtidsmal: KarriarVagledningStatus.NOTSTARTED,
  },
}

export const initialState: ICaseState = {
  caseDetails: initialCase,
  caseFetchingState: FetchingState.IDLE,
  changingAdvisorState: FetchingState.IDLE,
  changingTeamState: FetchingState.IDLE,
  fetchUtbetalningsbeslutState: FetchingState.IDLE,
  fetchEventsState: FetchingState.IDLE,
  removeEventState: FetchingState.IDLE,
  createEventState: FetchingState.IDLE,
  updateEventState: FetchingState.IDLE,
  fetchDocumentsState: FetchingState.IDLE,
  postDocumentState: FetchingState.IDLE,
  fetchDocumentState: FetchingState.IDLE,
  deleteDocumentState: FetchingState.IDLE,
  events: [],
  documents: [],
  reload: true,
  studiestodAnsokningarFetchingState: FetchingState.IDLE,
  fetchStudieansokanState: FetchingState.IDLE,
  updatePreferredLanguageState: FetchingState.IDLE,
  karriarVagledningProgression: null,
}

const caseReducer = handleActions(
  {
    [deleteDocumentInitiate.toString()]: (state) => ({
      ...state,
      deleteDocumentState: FetchingState.LOADING,
    }),
    [deleteDocumentFailure.toString()]: (state) => ({
      ...state,
      deleteDocumentState: FetchingState.ERROR,
    }),
    [deleteDocumentSuccess.toString()]: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: any,
      action: { payload: { responseBody: string } }
    ) => ({
      ...(state as ICaseState),
      documents: (state as ICaseState).documents.filter(
        (document: IDocument) => {
          return document.dokumentId !== action.payload.responseBody
        }
      ),
      deleteDocumentState: FetchingState.SUCCESS,
    }),
    [fetchDocumentInitiate.toString()]: (state) => ({
      ...state,
      fetchDocumentState: FetchingState.LOADING,
    }),
    [fetchDocumentFailure.toString()]: (state) => ({
      ...state,
      fetchDocumentState: FetchingState.ERROR,
    }),
    [fetchDocumentSuccess.toString()]: (state) => ({
      ...state,
      fetchDocumentState: FetchingState.SUCCESS,
    }),
    [postDocumentInitiate.toString()]: (state) => ({
      ...state,
      postDocumentState: FetchingState.LOADING,
    }),
    [postDocumentFailure.toString()]: (state) => ({
      ...state,
      postDocumentState: FetchingState.ERROR,
    }),
    [postDocumentSuccess.toString()]: (state) => ({
      ...state,
      postDocumentState: FetchingState.SUCCESS,
    }),

    [fetchDocumentsInitiate.toString()]: (state) => ({
      ...state,
      fetchDocumentsState: FetchingState.LOADING,
    }),
    [fetchDocumentsFailure.toString()]: (state) => ({
      ...state,
      fetchDocumentsState: FetchingState.ERROR,
    }),
    [fetchDocumentsSuccess.toString()]: (state, action) => ({
      ...state,
      fetchDocumentsState: FetchingState.SUCCESS,
      documents: action.payload.documents,
    }),

    [resetPostDocumentState.toString()]: (state) => ({
      ...state,
      postDocumentState: FetchingState.IDLE,
    }),

    [fetchCaseInitiate.toString()]: (state, action) => ({
      ...state,
      caseFetchingState: action.payload.reload
        ? FetchingState.LOADING
        : state.caseFetchingState,
    }),
    [fetchCaseFailure.toString()]: (state) => ({
      ...state,
      caseFetchingState: FetchingState.ERROR,
    }),
    [fetchCaseSuccess.toString()]: (state, action) => ({
      ...state,
      caseFetchingState: FetchingState.SUCCESS,
      caseDetails: action.payload.caseDetails,
    }),
    [changeTeamInitiated.toString()]: (state) => ({
      ...state,
      changingTeamState: FetchingState.LOADING,
    }),
    [changeTeamFailure.toString()]: (state) => ({
      ...state,
      changingTeamState: FetchingState.ERROR,
    }),
    [changeTeamSuccess.toString()]: (state) => ({
      ...state,
      changingTeamState: FetchingState.SUCCESS,
    }),
    [clearChangingTeamState.toString()]: (state) => ({
      ...state,
      changingTeamState: FetchingState.IDLE,
    }),

    [changeAdvisorInitiate.toString()]: (state) => ({
      ...state,
      changingAdvisorState: FetchingState.LOADING,
    }),
    [changeAdvisorFailure.toString()]: (state) => ({
      ...state,
      changingAdvisorState: FetchingState.ERROR,
    }),
    [changeAdvisorSuccess.toString()]: (state) => ({
      ...state,
      changingAdvisorState: FetchingState.SUCCESS,
    }),

    [clearChangingAdvisorState.toString()]: (state) => ({
      ...state,
      changingAdvisorState: FetchingState.IDLE,
    }),
    [clearCase.toString()]: () => ({
      ...initialState,
    }),
    [fetchEventsInitiate.toString()]: (state) => ({
      ...state,
      fetchEventsState: FetchingState.LOADING,
    }),
    [fetchEventsFailure.toString()]: (state) => ({
      ...state,
      fetchEventsState: FetchingState.ERROR,
    }),
    [fetchEventsSuccess.toString()]: (state, action) => ({
      ...state,
      fetchEventsState: FetchingState.SUCCESS,
      events: action.payload.events,
    }),
    [createEventInitiate.toString()]: (state) => ({
      ...state,
      createEventState: FetchingState.LOADING,
    }),
    [createEventFailure.toString()]: (state) => ({
      ...state,
      createEventState: FetchingState.ERROR,
    }),
    [createEventSuccess.toString()]: (state, action) => ({
      ...state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      events: [...state.events, (action as any).payload.responseBody],
      createEventState: FetchingState.SUCCESS,
    }),
    [deleteEventInitiate.toString()]: (state) => {
      return {
        ...state,
        deleteEventState: FetchingState.LOADING,
      }
    },
    [deleteEventFailure.toString()]: (state) => ({
      ...state,
      deleteEventState: FetchingState.ERROR,
    }),
    [deleteEventSuccess.toString()]: (state, action) => {
      return {
        ...state,
        events: state.events.filter((event) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (event as IEvent).id !== (action as any).payload.deletedEventId
        }),
        deleteEventState: FetchingState.SUCCESS,
      }
    },
    [updateEventInitiate.toString()]: (state) => ({
      ...state,
      updateEventState: FetchingState.LOADING,
    }),
    [updateEventFailure.toString()]: (state) => ({
      ...state,
      updateEventState: FetchingState.ERROR,
    }),
    [updateEventSuccess.toString()]: (state, action) => ({
      ...state,
      events: state.events.map((event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
        return (event as IEvent).id === (action as any).payload.responseBody.id
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (action as any).payload.responseBody
          : event
      }),
      updateEventState: FetchingState.SUCCESS,
    }),

    [fetchUtbetalningsbeslutInitiate.toString()]: (state) => ({
      ...state,
      fetchUtbetalningsbeslutState: FetchingState.LOADING,
    }),
    [fetchUtbetalningsbeslutSuccess.toString()]: (state, action) => {
      return {
        ...state,
        utbetalningsbeslut: action.payload,
        fetchUtbetalningsbeslutState: FetchingState.SUCCESS,
      }
    },
    [fetchUtbetalningsbeslutFailure.toString()]: (state) => ({
      ...state,
      fetchUtbetalningsbeslutState: FetchingState.ERROR,
    }),

    [updateMarkningInitiate.toString()]: (
      state,
      action: PayloadAction<UpdateMarkningInitializedActionPayload>
    ) => {
      // in order not to mutate state, we need to create a copy of the original array
      const updatedArendeMarkningar: IMarkning[] = []
      state.caseDetails.arendeMarkningar.forEach((markning) =>
        updatedArendeMarkningar.push(Object.assign({}, markning))
      )

      const index = updatedArendeMarkningar.findIndex(
        (x) => x.id === action.payload.markningId
      )
      updatedArendeMarkningar[index].isSet = action.payload.value

      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          arendeMarkningar: updatedArendeMarkningar,
        },
        markningFetchingState: FetchingState.LOADING,
      }
    },
    [updateMarkningSuccess.toString()]: (state) => ({
      ...state,
      markningFetchingState: FetchingState.SUCCESS,
    }),
    [updateMarkningFailure.toString()]: (state) => ({
      ...state,
      markningFetchingState: FetchingState.ERROR,
    }),

    [updatePreferredLanguageInitiated.toString()]: (state) => ({
      ...state,
      updatePreferredLanguageState: FetchingState.LOADING,
    }),
    [updatePreferredLanguageSuccess.toString()]: (state) => ({
      ...state,
      updatePreferredLanguageState: FetchingState.SUCCESS,
    }),
    [updatePreferredLanguageFailure.toString()]: (state) => ({
      ...state,
      updatePreferredLanguageState: FetchingState.ERROR,
    }),

    [fetchUserStatusInitiated.toString()]: (state) => ({
      ...state,
      fetchUserStatusState: FetchingState.LOADING,
    }),
    [fetchUserStatusSuccess.toString()]: (state, action) => ({
      ...state,
      karriarVagledningProgression: action.payload,
      fetchUserStatusState: FetchingState.SUCCESS,
    }),
    [fetchUserStatusFailure.toString()]: (state) => ({
      ...state,
      fetchUserStatusState: FetchingState.ERROR,
    }),

    [fetchUserJmtStatusInitiated.toString()]: (state) => ({
      ...state,
      fetchUserJmtStatusState: FetchingState.LOADING,
    }),
    [fetchUserJmtStatusSuccess.toString()]: (
      state,
      action: PayloadAction<IJmtUserStatusRes>
    ) => {
      // Transform status to correct status
      const status = action.payload.status.status

      // If both jmt and state.karriarVagledningProgression.karriarguide is complete then both the jmt test and the reflection is done
      const finalStatus: KarriarVagledningStatus =
        status === 2 &&
        state.karriarVagledningProgression?.karriarguide ===
          KarriarVagledningStatus.COMPLETED
          ? KarriarVagledningStatus.COMPLETED
          : status === 2 || status === 1
            ? KarriarVagledningStatus.INPROGRESS
            : KarriarVagledningStatus.NOTSTARTED

      return {
        ...state,
        karriarVagledningProgression: {
          ...state.karriarVagledningProgression,
          karriarguide: finalStatus,
        },
        fetchUserJmtStatusState: FetchingState.SUCCESS,
      }
    },
    [fetchUserJmtStatusFailure.toString()]: (state) => ({
      ...state,
      fetchUserJmtStatusState: FetchingState.ERROR,
    }),

    [resetState.toString()]: (state, action) => ({
      ...state,
      [action.payload.toString()]: FetchingState.IDLE,
    }),
  },
  initialState
)

export default caseReducer

import React from 'react'
import { Box } from '@mui/material'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'

import { FordelningsRadgivare } from '../../Fordelning.types'

import { columns } from './RadgivarStatistikColumns'

interface RadgivarStatistikTableProps {
  radgivareList: FordelningsRadgivare[]
}

const RadgivarStatistikTable = ({
  radgivareList,
}: RadgivarStatistikTableProps) => {
  if (!radgivareList) {
    return <></>
  }

  return (
    <Box>
      {radgivareList?.length > 0 ? (
        <DataGridWrapper
          columns={columns}
          maxHeight="90vh"
          getRowId={(row: FordelningsRadgivare) => row.id}
          rows={radgivareList}
          pagination={false}
          floatLastColumnRight={false}
        />
      ) : (
        <Box pt={3}>
          <EmptyResult description="" />
        </Box>
      )}
    </Box>
  )
}

export default RadgivarStatistikTable

import { Box } from '@mui/material'
import { GridPinnedRowsProp, GridRowParams } from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import { Radgivare, Team } from '@local/src/Common.types'

import {
  ArendeStagedForFordelning,
  FordelningsArende,
  FordelningsRadgivare,
} from '../Fordelning.types'

import { getColumns } from './FordelningTableColumns'
import DetailPanel from './DetailPanel/DetailPanel'

interface FordelningTableProps {
  arenden: FordelningsArende[]
  teams: Team[]
  aktivaRadgivare: FordelningsRadgivare[]
  isFetching: boolean
  stagedArenden: ArendeStagedForFordelning[]
  addStagedArende: (arendeId: string, radgivare: Radgivare) => void
  removeStagedArende: (arendeId: string) => void
}

const FordelningTable = ({
  arenden,
  teams,
  aktivaRadgivare,
  isFetching = true,
  stagedArenden,
  addStagedArende,
  removeStagedArende,
}: FordelningTableProps) => {
  const [rows, setRows] = useState<FordelningsArende[]>([])
  const [pinnedRows, setPinnedRows] = useState<GridPinnedRowsProp>(null)

  const arendeBasePath = useCaseEnvBasePath()

  useEffect(() => {
    if (stagedArenden?.length < pinnedRows?.top?.length) {
      // workaround for MUI bug: https://github.com/mui/mui-x/issues/8013
      setRows([])
      setRows([...arenden])
    }

    setPinnedRows({
      top: stagedArenden.map((arende) => ({
        ...rows.find((ar) => ar.id === arende.arendeId),
      })),
    })
  }, [stagedArenden])

  useEffect(() => {
    arenden && setRows([...arenden])
  }, [arenden])

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <DataGridWrapper
        loading={isFetching}
        pinnedRows={pinnedRows}
        columns={getColumns(
          arendeBasePath,
          aktivaRadgivare,
          addStagedArende,
          removeStagedArende,
          stagedArenden
        )}
        rows={rows}
        getRowId={(row: FordelningsArende) => row.id}
        emptyResultHeader="Klart!"
        emptyResultDescription="Alla ärenden är fördelade. 🎉"
        getDetailPanelContent={(params: GridRowParams<FordelningsArende>) => (
          <DetailPanel params={params} teams={teams} />
        )}
        floatLastColumnRight={false}
      />
    </Box>
  )
}

export default FordelningTable

import React, { useEffect, useRef, useState } from 'react'
import MobileFilterUtilities from '@local/Blocks/Filter/MobileFilterUtilities'
import { useArendenListOptions, useIsSmallScreen } from '@local/src/Hooks'
import { buildTags } from '@local/src/Components/CasesFilter/CasesFilter.helpers'
import {
  buildDinaArendenFacetsQuery,
  buildFetchArendenQuery,
  buildInitialDinaArendenFacetsQuery,
} from '@local/src/Utils/helpers'
import { ICreateEmailForm } from '@local/src/Blocks/CreateEmail/CreateEmail.types'
import { formatAmount, isNotNullOrEmpty } from '@local/src/Utils/smallfuncs'
import CaseList from '@local/src/Components/CaseList'
import CasesFilter from '@local/src/Components/CasesFilter/CasesFilter'
import TodoList from '@local/src/Blocks/TodoList/TodoList'
import { fetchInitialCaseListFacets } from '@local/Views/DinaArenden/DinaArenden.actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@local/Store/configureStore'
import { Facets, FetchingState } from '@local/src/Common.types'
import Modal from '@local/src/Components/Modal/Modal'
import { Box, Typography } from '@mui/material'
import { ICaseListItem } from '@local/src/Components/ActionBasedCaseItem/ActionBasedCaseItem.types'
import { useUser } from '@trr/app-shell-data'
import { ExtendedUser } from '@local/src/App.types'

import ArendenFilterPanel from '../Arenden/ArendenFilterPanel/ArendenFilterPanel'

import { dinaArendenEmptyFilterState } from './DinaArenden.reducers'

interface DinaArendenProps {
  cases: ICaseListItem[]
  facetedHits: number
  facets: Facets
  fetchArenden(query?: string): void
  fetchCaseListFacets(query?: string): void
  totalHits: number
  totalPages: number
  createEmailState?: FetchingState
  fetchingCaseListState: FetchingState
  createEmail: (
    isRadgivare: boolean,
    query: string,
    body: { subject: string }
  ) => void
}

const DinaArenden = ({
  cases,
  createEmail,
  facetedHits,
  facets,
  fetchArenden,
  fetchCaseListFacets,
  totalHits,
  totalPages,
  fetchingCaseListState,
  createEmailState,
}: DinaArendenProps) => {
  const radgivarId = (useUser() as ExtendedUser).mdid
  const hasRadgivarId = radgivarId !== ''
  const isSmallScreen = useIsSmallScreen()
  const {
    page,
    pageSize,
    setArendenPage,
    setPageSize,
    orderBy,
    setArendenOrderBy,
    filter,
    setFilterParameters,
    resetArendenFilter,
    searchText,
    setArendenSearchText,
    removeFilterParameterValuePair,
  } = useArendenListOptions()

  const subStatusFacets = useSelector(
    (state: RootState) => state.dinaArenden.subStatusFacets as Facets
  )
  const dispatch = useDispatch()

  const [listOfTags, setListOfTags] = useState([])

  const filterJson = JSON.stringify(filter)
  const previousFilter = useRef(filterJson)

  useEffect(() => {
    hasRadgivarId &&
      fetchArenden(
        buildFetchArendenQuery(
          filter,
          searchText,
          page,
          pageSize,
          orderBy,
          radgivarId
        )
      )
  }, [
    fetchArenden,
    filterJson,
    orderBy,
    searchText,
    page,
    pageSize,
    radgivarId,
  ])

  useEffect(() => {
    hasRadgivarId &&
      fetchCaseListFacets(
        buildDinaArendenFacetsQuery(radgivarId, filter.subStatusar)
      )
    if (hasRadgivarId && !isNotNullOrEmpty(filter?.radgivarId)) {
      setFilterParameters({ radgivarId: radgivarId })
    }
  }, [filter.subStatusar, radgivarId])

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fetchInitialCaseListFacets(buildInitialDinaArendenFacetsQuery(radgivarId))
    )
  }, [])

  useEffect(() => {
    buildTags(filter, dinaArendenEmptyFilterState, setListOfTags)
    if (filterJson !== previousFilter.current) {
      setArendenPage(1, totalPages)
    }
  }, [filter])

  const [modalDisplay, setModalDisplay] = useState(false)

  const handleCreateEmail = (emailForm: ICreateEmailForm) => {
    createEmail(
      true,
      buildFetchArendenQuery(
        filter,
        searchText,
        page,
        pageSize,
        orderBy,
        radgivarId
      ),
      emailForm
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      {isSmallScreen && (
        <MobileFilterUtilities
          displayModal={() => setModalDisplay(true)}
          removeFilterParameterValuePair={removeFilterParameterValuePair}
          tags={listOfTags}
          isDinaArenden
        />
      )}

      <Box display="flex" gap={4}>
        {isSmallScreen ? (
          <Modal
            label="Filter"
            fullScreen
            confirmText={`Visa ${formatAmount(totalHits)} ärenden`}
            onConfirm={() => setModalDisplay(false)}
            onClose={() => setModalDisplay(false)}
            closeIcon
            open={modalDisplay}
          >
            <CasesFilter
              isDinaArenden
              facets={facets}
              subStatusFacets={subStatusFacets}
              filter={filter}
              setFilterParameters={setFilterParameters}
              emptyFilterState={dinaArendenEmptyFilterState}
            />
          </Modal>
        ) : (
          <ArendenFilterPanel
            isDinaArenden
            filterTags={listOfTags}
            facets={facets}
            subStatusFacets={subStatusFacets}
            filter={filter}
            resetArendenFilter={resetArendenFilter}
            removeFilterParameterValuePair={removeFilterParameterValuePair}
            setFilterParameters={setFilterParameters}
          />
        )}
        <Box flex="1 0">
          {!isSmallScreen && (
            <Box display="flex" justifyContent="space-between" paddingY={0.5}>
              <Typography variant="h3">Dina ärenden</Typography>
              <TodoList />
            </Box>
          )}
          <CaseList
            facetedHits={facetedHits}
            cases={cases}
            orderBy={orderBy}
            page={page}
            pageSize={pageSize}
            searchQuery={searchText}
            setSearchText={setArendenSearchText}
            setOrderBy={setArendenOrderBy}
            setCasesPage={setArendenPage}
            setPageSize={setPageSize}
            totalHits={totalHits}
            totalPages={totalPages}
            createEmail={handleCreateEmail}
            createEmailState={createEmailState}
            fetchingCaseListState={fetchingCaseListState}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DinaArenden
